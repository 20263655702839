import {Component, Input, OnInit} from '@angular/core';
import {ButtonDispensingColdWaterPremiumInterface, ButtonsPremiumState} from '../../../interfaces/simulator-premium';
import {Button} from '../../../shared/button/button.component';

@Component({
  selector: 'app-button-dispense-cold-premium',
  templateUrl: './button-dispense-cold-premium.component.html',
  styleUrls: ['./button-dispense-cold-premium.component.sass']
})
export class ButtonDispenseColdPremiumComponent extends Button implements OnInit {

  @Input() data: ButtonDispensingColdWaterPremiumInterface;
  public state: any;

  constructor() {
    super();
    this.state = ButtonsPremiumState;
  }

  ngOnInit() {
  }

}
