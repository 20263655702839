import Device from '../interfaces/device';

export default class Devices {
  public static list: Array<Device> = [
    {
      id: 'entry',
      name: 'tami4edge',
      img: 'entry.png',
    },
    {
      id: 'premium',
      name: 'tami4edge+',
      img: 'premium.png',
		},
		{
			id: 'app',
			name: 'tami4 App',
			img: 'app.jpg'
		},
		{
			id: 'joey',
			name: 'tami4mini',
			img: 'JOEYcrop.png'
		},
		{
			id: 'kodesh',
			name: 'tami4shabbat',
			img: 'Add on Kodesh.430crop.png'
		}
  ];
}
