import {Component, OnInit} from '@angular/core';
import {SimulatorPremiumPagesInterface} from '../../../interfaces/simulator-premium';
import {LanguageService} from '../../../../../language.service';
import {QuantityAndTemp} from '../../data/quantityAndTemp';

@Component({
  selector: 'app-water-quantity-premium',
  templateUrl: './water-quantity-premium.component.html',
  styleUrls: ['./water-quantity-premium.component.sass']
})
export class WaterQuantityPremiumComponent implements OnInit {

  public lang = null;

  public jug;
  public pages;
  public cup;
  public scaleClass;

  public textTop = {
    he: 'הגדרה והפעלה',
    en: 'Define & Activate'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public skipButtonText = {
    he: 'לדלג',
    en: 'SKIP'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };
  text_step_1 = null;
  text_step_2 = null;
  text_step_3 = null;
  text_step_4 = null;
  text_step_5 = null;
  pot = null;

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Quantity water', lang);
      this.lang = lang;
    });

    this.pages = SimulatorPremiumPagesInterface;
    this.jug = QuantityAndTemp;

    this.pot = QuantityAndTemp.isJugs ? 'jug' : 'cup';

    this.cup = this.getScaleClass();
    this.scaleClass = this.getScaleClass();

    this.text_step_1 = {
      he: {
        line_1: ' שלב 1 מתוך 2',
        line_2: 'יש להניח כלי',
        line_3: 'מתחת לפיית המזיגה',
        line_4: 'להגדרת כמות',
        line_5: 'יש ללחוץ על כפתור מים ' + this.getSpan()
      },
      en: {
        line_1: ' Step 1 of 2',
        line_2: 'Place a ' + this.pot + ' under the spout',
        line_3: '',
        line_4: 'Press ' + this.getSpan() + ' water',
        line_5: '...to start recording quantity',
      }
    };

    this.text_step_2 = {
      he: {
        line_1: 'שלב 1 מתוך 2',
        line_2: 'לסיום ההגדרה',
        line_3: 'יש לשחרר את כפתור המזיגה',
      },
      en: {
        line_1: ' Step 1 of 2',
        line_2: 'Release the button to stop',
        line_3: 'recording quantity',
      }
    };

    this.text_step_3 = {
      he: {
        line_1: 'שלב 1 מתוך 2',
        line_2: '',
        line_3: 'כמות הוגדרה בהצלחה'
      },
      en: {
        line_1: ' Step 1 of 2',
        line_2: 'Quantity recorded',
        line_3: 'successfully'
      }
    };

    this.text_step_4 = {
      he: {
        line_1: 'שלב 2 מתוך 2',
        line_2: 'הגדרת טמפרטורה',
      },
      en: {
        line_1: 'Step 2 of 2',
        line_2: 'Define Pot\'s Temperature',
      }
    };

    this.text_step_5 = {
      he: {
        line_1: '',
        line_2: 'כמות וטמפרטורה הוגדרו בהצלחה',
      },
      en: {
        line_1: 'Quantity &',
        line_2: 'Temperature defined',
      }
    };

    console.log(this.jug.currentDrinks);
  }


  private getSpan() {
    if (this.jug.currentDrinks.run === SimulatorPremiumPagesInterface.MixWaterDispensing) {
      return this.lang === 'he' ? '<span style="color:#FFFFFF">פושרים</span>' : '<span style="color:#FFFFFF">Mix</span>';
    } else if (this.jug.currentDrinks.run === SimulatorPremiumPagesInterface.HotWaterDispensing) {
      return this.lang === 'he' ? '<span style="color:#F20000">חמים</span>' : '<span style="color:#F20000">Hot</span>';
    } else {
      return this.lang === 'he' ? '<span style="color:#1599FF">קרים</span>' : '<span style="color:#1599FF">Cold</span>';
    }
  }


  private getScaleClass() {
    if (this.jug.currentDrinks.run === SimulatorPremiumPagesInterface.MixWaterDispensing) {
      return 'mix';
    } else if (this.jug.currentDrinks.run === SimulatorPremiumPagesInterface.HotWaterDispensing) {
      return 'hot';
    } else {
      return 'cold';
    }
  }

  ngOnInit() {
  }

}
