import Swiper from 'swiper/dist/js/swiper.js';

export class InternalCleaning {
  public static step = 0;
  public static boilingProcess = 0;
  public static isPause = false;
  public static seconds = 0;
  public static minutes = 3;
  private static MINUTES = 3;

  public static cleaningList = {
    he: [
      {
        id: 1,
        name: 'ניקוי אבנית',
      },
      {
        id: 2,
        name: 'סטריליזציה',
      }
    ],
    en: [
      {
        id: 1,
        name: 'Descaling',
      },
      {
        id: 2,
        name: 'Sterilazation',
      }
    ]
  };

  public static statrtBoiling(seconds) {
    return new Promise((resolve) => {
      let interval = setInterval(() => {
        console.log("HERE");
        if (InternalCleaning.boilingProcess < 100) {
          InternalCleaning.boilingProcess += 8;
        } else {
          resolve();
          InternalCleaning.boilingProcess = 0;
          InternalCleaning.isPause = false;
          clearInterval(interval);
        }
      }, seconds);
    });
  }


  public static hotWaterDispensing(seconds) {
    return new Promise((resolve) => {
      let interval = setInterval(() => {
        if (!InternalCleaning.isPause) {
          if (InternalCleaning.boilingProcess < 100) {
            InternalCleaning.boilingProcess += 10;
          } else {
            resolve();
            InternalCleaning.boilingProcess = 0;
            InternalCleaning.isPause = false;
            clearInterval(interval);
          }
        }
      }, seconds);
    });
  }


  public static cleaning() {
    return new Promise((resolve) => {
      let interval = setInterval(() => {
        if (InternalCleaning.seconds === 0) {
          InternalCleaning.boilingProcess += parseFloat((100 / InternalCleaning.MINUTES / 60).toFixed(3));
          InternalCleaning.seconds = 59;
          if (InternalCleaning.minutes >= 0) {
            InternalCleaning.minutes--;
          }
        } else {
          InternalCleaning.seconds--;
          InternalCleaning.boilingProcess += parseFloat((100 / InternalCleaning.MINUTES / 60).toFixed(3));
        }

        if (InternalCleaning.seconds === 0 && InternalCleaning.minutes === 0) {
          resolve();
          InternalCleaning.boilingProcess = 0;
          clearInterval(interval);
        }
      }, 100);

    });
  }


  public static internalSwiper = null;
  public static currentIndex = 0;

  public static initCleaningSwiper() {
    setTimeout(() => {
      InternalCleaning.internalSwiper = new Swiper('#internalSwiper', {
        direction: 'vertical',
        loop: false,
        slidesPerView: 5,
        spaceBetween: 0,
        simulateTouch: false,
        autoHeight: true,
        height: 280,
        centeredSlides: true,
        watchOverflow: false,
        slidesPerColumn: 1,
        normalizeSlideIndex: true,
      });
    }, 0);
  }

  public static reset() {
    InternalCleaning.step = 0;
    InternalCleaning.boilingProcess = 0;
    InternalCleaning.isPause = false;
    InternalCleaning.currentIndex = 0
    InternalCleaning.seconds = 0;
    InternalCleaning.minutes = 3;
    InternalCleaning.MINUTES = 3;
  }
}
