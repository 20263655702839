// Altitude

import {
  ButtonDispenseColdStatesInterface, ButtonDispenseHotStatesInterface,
  ButtonDispenseMixStatesInterface, ButtonHotwaterStatesInterface, ButtonPitcherStatesInterface, ButtonSettingsStatesInterface,
  SimulatorEntryInterface,
  SimulatorPagesInterface
} from '../../interfaces/simulator-entry';

export class EntryPageCL {
  static buttonScreenHot = 0;
  static buttonScreenCold = 0;
  public static pouringAudio = new Audio('/assets/audio/pouring.mp3');
  public static boilingAudio = new Audio('/assets/audio/boiling.mp3');

  static initial(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.page = SimulatorPagesInterface.CL;
    simulatorEntry.display.data = 'CL';
    simulatorEntry.buttons = {
      dispenseCold: {active: false, state: ButtonDispenseColdStatesInterface.FLASHING},
      dispenseMix: {active: false, state: ButtonDispenseMixStatesInterface.OFF},
      dispenseHot: {active: false, state: ButtonDispenseHotStatesInterface.FLASHING},
      pitcher: {active: false, state: ButtonPitcherStatesInterface.OFF},
      settings: {active: false, state: ButtonSettingsStatesInterface.ACTIVE},
      hotWater: {active: false, state: ButtonHotwaterStatesInterface.OFF},
    };

    EntryPageCL.buttonScreenHot = 1;
    EntryPageCL.buttonScreenCold = 1;
  }

  static clickButtonSettings(simulatorEntry: SimulatorEntryInterface): boolean {
    console.log()
    if (EntryPageCL.buttonScreenHot === 2) {
      EntryPageCL.initial(simulatorEntry);
      return true;
    }  else {
      return false;
    }
  }

  // HOT
  static hotButtonClick(simulatorEntry: SimulatorEntryInterface, isMute) {
    console.log("Button Screen",EntryPageCL.buttonScreenHot);
    if (EntryPageCL.buttonScreenHot === 1) {
      EntryPageCL.buttonScreenHot++;
      simulatorEntry.buttons = {
        dispenseCold: {active: false, state: ButtonDispenseColdStatesInterface.OFF},
        dispenseMix: {active: false, state: ButtonDispenseMixStatesInterface.OFF},
        dispenseHot: {active: false, state: ButtonDispenseHotStatesInterface.FLASHING},
        pitcher: {active: false, state: ButtonPitcherStatesInterface.OFF},
        settings: {active: false, state: ButtonSettingsStatesInterface.ACTIVE},
        hotWater: {active: false, state: ButtonHotwaterStatesInterface.OFF},
      };
    } else if (EntryPageCL.buttonScreenHot === 2 || EntryPageCL.buttonScreenHot === 4) {
      simulatorEntry.buttons = {
        dispenseCold: {active: false, state: ButtonDispenseColdStatesInterface.OFF},
        dispenseMix: {active: false, state: ButtonDispenseMixStatesInterface.OFF},
        dispenseHot: {active: false, state: ButtonDispenseHotStatesInterface.OFF},
        pitcher: {active: false, state: ButtonPitcherStatesInterface.OFF},
        settings: {active: false, state: ButtonSettingsStatesInterface.OFF},
        hotWater: {active: false, state: ButtonHotwaterStatesInterface.FLASHING},
      };

      EntryPageCL.boilingAudio.pause();
      EntryPageCL.boilingAudio.volume = 0.1;
      EntryPageCL.boilingAudio.load();
      if (!isMute) {
        EntryPageCL.boilingAudio.play(); // done
      }
      setTimeout(() => {
        simulatorEntry.buttons.hotWater.state = ButtonHotwaterStatesInterface.ACTIVE;
        simulatorEntry.buttons.dispenseHot.state = ButtonDispenseHotStatesInterface.FLASHING;
        const dblBeep = new Audio('/assets/audio/double_beep.mp3');
        dblBeep.volume = 0.2;
        dblBeep.play();
        EntryPageCL.buttonScreenHot++;
      }, 9000);
    } else if (EntryPageCL.buttonScreenHot === 3 || EntryPageCL.buttonScreenHot === 5) {
      simulatorEntry.buttons.hotWater.state = ButtonHotwaterStatesInterface.OFF;
      simulatorEntry.buttons.dispenseHot.state = ButtonDispenseHotStatesInterface.OFF;
      EntryPageCL.pouringAudio.pause();
      EntryPageCL.pouringAudio.load();
      if (!isMute) {
        EntryPageCL.pouringAudio.play(); // done
      }

        let  pouringState = setTimeout(() => {
          EntryPageCL.pouringAudio.pause();
          simulatorEntry.display.data = EntryPageCL.buttonScreenHot === 5 ? '30' : '20';

          let cleaningInterval = setInterval(() => {
            let sec = parseInt(simulatorEntry.display.data);
            if (sec > 0) {
              sec--;
              let res = sec.toString();
              if (sec < 10) {
                res = '0' + res;
              }
              simulatorEntry.display.data = res;
            } else {
              clearInterval(cleaningInterval);
              if (EntryPageCL.buttonScreenHot === 3) {
                EntryPageCL.buttonScreenHot++;
                simulatorEntry.display.data = 'CL';
                simulatorEntry.buttons.dispenseHot.state = ButtonDispenseHotStatesInterface.FLASHING;
              } else if (EntryPageCL.buttonScreenHot === 5) {
                EntryPageCL.buttonScreenHot++;
                simulatorEntry.display.data = '';
                simulatorEntry.buttons = {
                  dispenseCold: {active: false, state: ButtonDispenseColdStatesInterface.ACTIVE},
                  dispenseMix: {active: false, state: ButtonDispenseMixStatesInterface.ACTIVE},
                  dispenseHot: {active: false, state: ButtonDispenseHotStatesInterface.ACTIVE},
                  pitcher: {active: false, state: ButtonPitcherStatesInterface.ACTIVE},
                  settings: {active: false, state: ButtonSettingsStatesInterface.ACTIVE},
                  hotWater: {active: false, state: ButtonHotwaterStatesInterface.ACTIVE},
                };

                const dblBeep = new Audio('/assets/audio/double_beep.mp3');
                dblBeep.volume = 0.2;
                dblBeep.play();
                setTimeout(() => {EntryPageCL.initial(simulatorEntry)}, 5000)
              }
            }
          }, 1000)
        }, 4000);
    }
  }

  // HOT END


  // COLD
  static coldButtonClick(simulatorEntry: SimulatorEntryInterface, isMute) {
    console.log("Button Screen", EntryPageCL.buttonScreenCold);
    if (EntryPageCL.buttonScreenCold === 1) {
      EntryPageCL.buttonScreenCold++;
      simulatorEntry.buttons = {
        dispenseCold: {active: false, state: ButtonDispenseColdStatesInterface.FLASHING},
        dispenseMix: {active: false, state: ButtonDispenseMixStatesInterface.OFF},
        dispenseHot: {active: false, state: ButtonDispenseHotStatesInterface.OFF},
        pitcher: {active: false, state: ButtonPitcherStatesInterface.OFF},
        settings: {active: false, state: ButtonSettingsStatesInterface.ACTIVE},
        hotWater: {active: false, state: ButtonHotwaterStatesInterface.OFF},
      };
    } else if (EntryPageCL.buttonScreenCold === 2 || EntryPageCL.buttonScreenCold === 3 || EntryPageCL.buttonScreenCold === 4 ) {
      simulatorEntry.buttons.dispenseCold.state = ButtonDispenseColdStatesInterface.OFF;
      EntryPageCL.pouringAudio.pause();
      EntryPageCL.pouringAudio.load();
      if (!isMute) {
        EntryPageCL.pouringAudio.play(); // done
      }

      let pouringState = setTimeout(() => {
        EntryPageCL.pouringAudio.pause();
        simulatorEntry.display.data = EntryPageCL.buttonScreenCold === 3 ? '10' : '30';

        let cleaningInterval = setInterval(() => {
          let sec = parseInt(simulatorEntry.display.data);
          if (sec > 0) {
            sec--;
            let res = sec.toString();
            if (sec < 10) {
              res = '0' + res;
            }
            simulatorEntry.display.data = res;
          } else {
            if (EntryPageCL.buttonScreenCold === 2 || EntryPageCL.buttonScreenCold === 3) {
              simulatorEntry.display.data = 'CL';
              EntryPageCL.buttonScreenCold++;
              simulatorEntry.buttons = {
                dispenseCold: {active: false, state: ButtonDispenseColdStatesInterface.FLASHING},
                dispenseMix: {active: false, state: ButtonDispenseMixStatesInterface.OFF},
                dispenseHot: {active: false, state: ButtonDispenseHotStatesInterface.OFF},
                pitcher: {active: false, state: ButtonPitcherStatesInterface.OFF},
                settings: {active: false, state: ButtonSettingsStatesInterface.OFF},
                hotWater: {active: false, state: ButtonHotwaterStatesInterface.OFF},
              };
            } else if (EntryPageCL.buttonScreenCold === 4) {
              simulatorEntry.display.data = 'CL';
              simulatorEntry.buttons = {
                dispenseCold: {active: false, state: ButtonDispenseColdStatesInterface.FLASHING},
                dispenseMix: {active: false, state: ButtonDispenseMixStatesInterface.OFF},
                dispenseHot: {active: false, state: ButtonDispenseHotStatesInterface.OFF},
                pitcher: {active: false, state: ButtonPitcherStatesInterface.OFF},
                settings: {active: false, state: ButtonSettingsStatesInterface.OFF},
                hotWater: {active: false, state: ButtonHotwaterStatesInterface.OFF},
              };

              setTimeout(() => {
                simulatorEntry.buttons = {
                  dispenseCold: {active: false, state: ButtonDispenseColdStatesInterface.ACTIVE},
                  dispenseMix: {active: false, state: ButtonDispenseMixStatesInterface.ACTIVE},
                  dispenseHot: {active: false, state: ButtonDispenseHotStatesInterface.ACTIVE},
                  pitcher: {active: false, state: ButtonPitcherStatesInterface.ACTIVE},
                  settings: {active: false, state: ButtonSettingsStatesInterface.ACTIVE},
                  hotWater: {active: false, state: ButtonHotwaterStatesInterface.ACTIVE},
                };
                simulatorEntry.display.data = '';
                const dblBeep = new Audio('/assets/audio/double_beep.mp3');
                dblBeep.volume = 0.2;
                dblBeep.play();
                setTimeout(() => {EntryPageCL.initial(simulatorEntry)}, 5000)
              })
            }
            clearInterval(cleaningInterval);
          }
        }, 1000)
      }, 4000);
    }
  }
}
