import {Component, Input, OnInit} from '@angular/core';
import {IndicatorPurifierPremiumInterface, IndicatorPurifierStatePremium} from '../../../interfaces/simulator-premium';

@Component({
  selector: 'app-indicator-purifier-premium',
  templateUrl: './indicator-purifier-premium.component.html',
  styleUrls: ['./indicator-purifier-premium.component.sass']
})
export class IndicatorPurifierPremiumComponent implements OnInit {

  @Input() data: IndicatorPurifierPremiumInterface;
  public state: any;

  constructor() {
    this.state = IndicatorPurifierStatePremium;
  }

  ngOnInit() {
  }

}
