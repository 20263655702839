import {Component, Input, OnInit} from '@angular/core';
import {IndicatorLockPremiumInterface, IndicatorLockStatePremium} from '../../../interfaces/simulator-premium';

@Component({
  selector: 'app-indicator-lock-premium',
  templateUrl: './indicator-lock-premium.component.html',
  styleUrls: ['./indicator-lock-premium.component.sass']
})
export class IndicatorLockPremiumComponent implements OnInit {

  @Input() data: IndicatorLockPremiumInterface;
  public state: any;

  constructor() {
    this.state = IndicatorLockStatePremium;
  }

  ngOnInit() {
  }

}
