import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public language: Observable<string>;
  public currentRouter: Router;
  private languageSubject: Subject<string>;

  public defaultLanguage = 'en';

  constructor(public router: Router) {
    this.languageSubject = new Subject<string>();
    this.language = this.languageSubject.asObservable();
    this.currentRouter = router;
    if(this.currentRouter.url.substr(0,4) === '/eng') {
      if (!localStorage.getItem('lang')) {
        localStorage.setItem('lang', 'en');
      }
      this.setLanguage(localStorage.getItem('lang'));
    } else {



    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', this.defaultLanguage);
    }
    this.setLanguage(localStorage.getItem('lang'));
  }
  }

  public setLanguage(lang: string) {
    localStorage.setItem('lang', lang);
    this.languageSubject.next(lang);
  }

  public getLang() {
    console.log(this.currentRouter.url);
    if(this.currentRouter.url.substr(0,4) === '/eng') {
      return 'en';
    }
    return localStorage.getItem('lang');
  }
}
