import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.sass']
})
export class SimulatorComponent implements OnInit {

  public currentDevice = 'premium';

  constructor(private route: ActivatedRoute) {

    // this.route.paramMap.subscribe((param) => {
    //   this.currentDevice = param.get('device');
    // });
  }

  listenScreenWidth(): void {
    console.log('RESIZE', window.innerWidth);
    if (window.innerWidth < 1600) {
      const e = ((window.innerWidth * 100) / 1600) / 100 - 0.2;
      console.log('e', e.toFixed(2));
      // const d = document.getElementById('simulator-id');
      const value = 'scale(' + e + ')';
      setTimeout(() => {
        document.getElementById('simulator-id').style['transform'] = value;
        document.getElementById('simulator-id').style['-moz-transform'] = value;
        document.getElementById('simulator-id').style['-o-transform'] = value;
        document.getElementById('simulator-id').style['-webkit-transform'] = value;
      }, 0);
    } else {
      setTimeout(() => {
        console.log("ELSE");
        if (document.getElementById('simulator-id')) {
          document.getElementById('simulator-id').removeAttribute('style');
        }
      }, 0);
    }
  }

  ngOnInit() {
    this.listenScreenWidth();

    window.onresize = (event) => {
      this.listenScreenWidth();
    };
  }

}
