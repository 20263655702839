import {Component, OnDestroy, OnInit} from '@angular/core';
import {Dispensing} from '../../data/dispensing';
import {LanguageService} from '../../../../../language.service';
import {MuteService} from '../../../../../mute.service';

@Component({
  selector: 'app-hot-water-dispensing-premium',
  templateUrl: './hot-water-dispensing-premium.component.html',
  styleUrls: ['./hot-water-dispensing-premium.component.sass']
})
export class HotWaterDispensingPremiumComponent implements OnInit, OnDestroy {

  public text = {
    he: {
      main: 'מזיגת מים חמים',
      secondary: 'לחיצה על כל כפתור תעצור מזיגה'
    },
    en: {
      main: 'Dispensing hot water',
      secondary: 'Press any key to stop'
    }
  };

  public fastAnimation = '';
  public lang = null;
  public pouringAudio = new Audio('/assets/audio/pouring.mp3');
  public isMute = null;

  constructor(private langService: LanguageService, private muteService: MuteService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Hot water dispensing', lang);
      this.lang = lang;
    });
    if (Dispensing.isAnimationFast) {
      this.fastAnimation = 'fast';
      this.pouringAudio.loop = true;
    }

    this.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      this.isMute = mute;
    });
  }

  ngOnInit() {
    this.pouringAudio.pause();
    setTimeout(() => {
      this.pouringAudio.muted = false;
      if (!this.isMute) {
        this.pouringAudio.play(); // done
      }
    }, 10);
  }

  ngOnDestroy(): void {
    this.pouringAudio.pause();
  }

}
