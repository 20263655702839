/* BUTTONS */

// buttons state
export enum ButtonsPremiumState {
  ICON_BRIGHT,
  ICON_NO_BRIGHT,
  NO_ICON,
  FLASH,
  BORDER,
  NO_BORDER,
  ICON_NO_BRIGHT_BORDER,
  BLINK
}


export enum PouringAnimation {
  NONE,
  HOT,
  MIX,
  COLD
}

export interface ButtonDispensingHotWaterPremiumInterface {
  active: boolean;
  state: ButtonsPremiumState;
}

export interface ButtonDispensingColdWaterPremiumInterface {
  active: boolean;
  state: ButtonsPremiumState;
}


export interface ButtonDispensingMixWaterPremiumInterface {
  active: boolean;
  state: ButtonsPremiumState;
}

export interface ButtonDispensingBoilingPremiumInterface {
  active: boolean;
  state: ButtonsPremiumState;
}

export interface ButtonDispensingJugsPremiumInterface {
  active: boolean;
  state: ButtonsPremiumState;
}

export interface ButtonDispensingPersonalPremiumInterface {
  active: boolean;
  state: ButtonsPremiumState;
}

export interface ButtonsPremiumInterface {
  hot: ButtonDispensingHotWaterPremiumInterface;
  cold: ButtonDispensingColdWaterPremiumInterface;
  mix: ButtonDispensingMixWaterPremiumInterface;
  boiling: ButtonDispensingBoilingPremiumInterface;
  jugs: ButtonDispensingJugsPremiumInterface;
  personal: ButtonDispensingPersonalPremiumInterface;
}


/* INDICATORS */

/* Indicator UV */
export enum IndicatorUvStatePremium {
  RED,
  BLUE,
  OFF
}

export interface IndicatorUvPremiumInterface {
  active: boolean;
  state: IndicatorUvStatePremium;
}

/* Indicator Addon */
export enum IndicatorAddonStatePremium {
  RED,
  BLUE,
  OFF
}

export interface IndicatorAddonPremiumInterface {
  active: boolean;
  state: IndicatorAddonStatePremium;
}


/* Indicator WiFi */
export enum IndicatorWifiStatePremium {
  RED,
  BLUE,
  OFF
}

export interface IndicatorWifiPremiumInterface {
  active: boolean;
  state: IndicatorWifiStatePremium;
}


/* Indicator Clock */
export interface IndicatorClockPremiumInterface {
  active: boolean;
}


/* Indicator Lock */
export enum IndicatorLockStatePremium {
  RED,
  BLUE,
  OFF
}

export interface IndicatorLockPremiumInterface {
  active: boolean;
  state: IndicatorLockStatePremium;
}


/* Indicator Purifier */
export enum IndicatorPurifierStatePremium {
  RED,
  BLUE,
  OFF
}

export interface IndicatorPurifierPremiumInterface {
  active: boolean;
  state: IndicatorPurifierStatePremium;
}


/* Indicator NET */
export enum IndicatorNetStatePremium {
  RED,
  OFF
}

export interface IndicatorNetInterface {
  active: boolean;
  state: IndicatorNetStatePremium;
}

/* PAGES */
export enum SimulatorPremiumPagesInterface {
  IDLE,
  HotWaterDispensing,
  ColdWaterDispensing,
  MixWaterDispensing,
  Boiling,
  Personal,
  PersonalDrinks,
  DefinePersonalDrinks,
  Jugs,
  DefineJugs,
  ActivateJugs,
  Menu,
  Settings,
  Support,
  Preferences,
  Maintenance,
  WashingTank,
  BuiltInTest,
  PurifierAndUv,
  LockButtonMessage,
  LockButtonScreen,
  ShabbatModeConfirmation,
  ShabbatModeScreen,
  Reset,
  Descaling,
  QuantityAndTemp,
  WaterQuantity,
  PersonalQuantity,
  EditPersonalQuantity,
  ShabbatModeConfirmationHome,
  InternalCleaningMain,
  Sterilization,
  Tech,
  ChildLockTech,
  DebugTech,
  ParametersTech,
  TempTech,
  ErrorScreen,
  WashTech
}

export interface IndicatorBarPremiumInterface {
  addon: IndicatorAddonPremiumInterface;
  uv: IndicatorUvPremiumInterface;
  wifi: IndicatorWifiPremiumInterface;
  clock: IndicatorClockPremiumInterface;
  lock: IndicatorLockPremiumInterface;
  purifier: IndicatorPurifierPremiumInterface;
  net: IndicatorNetInterface;
}

/* MAIN STATES */
export interface SimulatorPremiumInterface {
  // state: any;
  indicator: IndicatorBarPremiumInterface;
  page: SimulatorPremiumPagesInterface;
  buttons: ButtonsPremiumInterface;
  pouring: {fast: boolean, animation: PouringAnimation};
}
