import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ContentAreaComponent} from './components/instructor/content-area/content-area.component';
import {SimulatorComponent} from './components/simulator/simulator.component';
import {HomeComponent} from './components/home/home.component';
import {ManualComponent} from './components/manual/manual.component';


const routes: Routes = [
  {path: '', component: ManualComponent},
  {path: 'instructor', component: ManualComponent},
  {path: 'instructor/:id', component: ContentAreaComponent},
  {path: 'simulator', component: SimulatorComponent},
  // {path: 'eng', component: HomeComponent},
  // {path: 'eng/:device/instructor', component: ManualComponent},
  // {path: 'eng/:device/instructor/:id', component: ContentAreaComponent},
	// {path: 'eng/:device/simulator', component: SimulatorComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
