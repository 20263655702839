import {Component, OnDestroy, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import {TechScreen} from '../../data/techScreen';

@Component({
  selector: 'app-boiling-temp-tech-premium',
  templateUrl: './boiling-temp-tech-premium.component.html',
  styleUrls: ['./boiling-temp-tech-premium.component.sass']
})
export class BoilingTempTechPremiumComponent implements OnInit, OnDestroy {

  public temp = null;
  public lang = null;

  public text = {
    he: 'טמפרטורת רתיחה',
    en: 'Boiling Temperature'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'OK'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  public text_h = {
    en: {
      line_1: 'Step 1 of 1',
      line_2: 'Calibrate boiling Temp.'
    },
    he: {
      line_1: 'שלב 1 מתוך 1',
      line_2: 'כיול טמפ.'
    }
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Tech boiling screen premium', lang);
      this.lang = lang;
      TechScreen.initTechScreenSwiper();
      TechScreen.reset();
    });
    this.temp = TechScreen.defaults.temp;
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    TechScreen.defaults.temp.current = TechScreen.defaults.temp.default;
  }

}
