import {Component, Input, OnInit} from '@angular/core';
import {IndicatorNetInterface, IndicatorNetStatePremium} from '../../../interfaces/simulator-premium';

@Component({
  selector: 'app-indicator-net-premium',
  templateUrl: './indicator-net-premium.component.html',
  styleUrls: ['./indicator-net-premium.component.sass']
})
export class IndicatorNetPremiumComponent implements OnInit {

  @Input() data: IndicatorNetInterface;
  public states: any;

  constructor() {
    this.states = IndicatorNetStatePremium;
  }

  ngOnInit() {
  }

}
