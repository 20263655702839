import {
  ButtonsPremiumState,
  IndicatorAddonStatePremium,
  IndicatorLockStatePremium,
  IndicatorNetStatePremium,
  IndicatorPurifierStatePremium,
  IndicatorUvStatePremium,
  IndicatorWifiStatePremium,
  PouringAnimation,
  SimulatorPremiumPagesInterface
} from '../../interfaces/simulator-premium';
import {Personal} from '../data/personal';
import {Jug} from '../data/jug';
import {WashingTank} from '../data/washingTank';
import {BuiltInTest} from '../data/builtInTest';
import {QuantityAndTemp} from '../data/quantityAndTemp';
import {InternalCleaning} from '../data/internalCleaning';
import {ButtonHelper} from '../helpers/buttonHelper';
import {MuteService} from '../../../../mute.service';

export class ColdWaterBtn {

  public static isMute = null;
  constructor(private muteService: MuteService) {
    ColdWaterBtn.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      ColdWaterBtn.isMute = mute;
    });
  }

  public static shortClick(isMute): void {
    ColdWaterBtn.isMute = isMute
    switch (ButtonHelper.simulator.page) {

      // screen IDLE
      case SimulatorPremiumPagesInterface.IDLE:
      case SimulatorPremiumPagesInterface.ParametersTech:
        ButtonHelper.resetMainAfk();
        ButtonHelper.simulatorCold();
        // finish dispensing and go back to idle screen
        ButtonHelper.afkDispensingWater = setTimeout(() => {
          ButtonHelper.mainSimulatorScreen();
        }, ButtonHelper.DISPENSING_WATER_TIME + 2000);
        break;

      // screen dispensing hot water
      case SimulatorPremiumPagesInterface.MixWaterDispensing:
      case SimulatorPremiumPagesInterface.HotWaterDispensing:
      case SimulatorPremiumPagesInterface.ColdWaterDispensing:
        ButtonHelper.mainSimulatorScreen();
        break;

      // screen Personal
      case SimulatorPremiumPagesInterface.Personal:
        ButtonHelper.startMainAfk();
        Personal.currentUser = Personal.users[ButtonHelper.lang][ButtonHelper.buttonsIndexes.cold + Personal.currentUserPage];
        ButtonHelper.simulatorPersonalDrinks();
        break;

      case SimulatorPremiumPagesInterface.PersonalDrinks:
        ButtonHelper.resetMainAfk();
        ButtonHelper.simulatorDispensingPersonal(ButtonHelper.buttonsIndexes.cold);
        Personal.currentUserPage = 0;
        break;

      case SimulatorPremiumPagesInterface.Jugs:
        ButtonHelper.simulatorDispensingJugs(ButtonHelper.buttonsIndexes.cold);
        break;

      case SimulatorPremiumPagesInterface.ActivateJugs:
        ButtonHelper.pouringAudio.load();
        if (Jug.step === 1 && Jug.currentJug.run === SimulatorPremiumPagesInterface.ColdWaterDispensing) {
          Jug.step++;
          ButtonHelper.pouringAudio.loop = true;
          if (!ColdWaterBtn.isMute) {
            ButtonHelper.pouringAudio.play(); // done
          }
          ButtonHelper.resetMainAfk();
          ButtonHelper.simulatorActivateJug();
          ButtonHelper.simulator.pouring = {animation: PouringAnimation.COLD, fast: false};
          if (Jug.step == 3) {
            ButtonHelper.simulator.buttons = {
              hot: {active: true, state: ButtonsPremiumState.NO_ICON},
              cold: {active: true, state: ButtonsPremiumState.NO_ICON},
              mix: {active: true, state: ButtonsPremiumState.NO_ICON},
              boiling: {active: true, state: ButtonsPremiumState.BORDER},
              jugs: {active: true, state: ButtonsPremiumState.NO_ICON},
              personal: {active: true, state: ButtonsPremiumState.BORDER}
            };
          }
        } else if (Jug.step === 2) {
          Jug.step++;
          ButtonHelper.pouringAudio.pause();
          ButtonHelper.startMainAfk();
          ButtonHelper.simulatorActivateJug();
        }
        break;

      case SimulatorPremiumPagesInterface.Menu:
        ButtonHelper.startMainAfk();
        ButtonHelper.simulatorSettings();
        break;

      case SimulatorPremiumPagesInterface.WashingTank:
        if (WashingTank.step === 1) {
          ButtonHelper.resetMainAfk();
          WashingTank.step++;
          ButtonHelper.simulator.buttons.cold.state = ButtonsPremiumState.ICON_NO_BRIGHT;
          WashingTank.startWashingTank('cold').then(() => {
            ButtonHelper.simulator.buttons.hot.state = ButtonsPremiumState.FLASH;
            ButtonHelper.startMainAfk();
          });
        } else if (WashingTank.step === 2) {
          WashingTank.isPause = !WashingTank.isPause;
        } else if (WashingTank.step === 4) {
          WashingTank.isPause = !WashingTank.isPause;
        }
        break;

      case SimulatorPremiumPagesInterface.BuiltInTest:
        if (BuiltInTest.step === 0) {
          ButtonHelper.simulator.buttons = {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
            boiling: {active: true, state: ButtonsPremiumState.NO_BORDER},
            jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
            personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
          };
          BuiltInTest.startBuilt().then(() => {
            ButtonHelper.mainSimulatorScreen();
          });
        }
        break;

      case SimulatorPremiumPagesInterface.QuantityAndTemp:
        QuantityAndTemp.currentDrinks = QuantityAndTemp.drinks[ButtonHelper.lang][ButtonHelper.buttonsIndexes.cold];
        QuantityAndTemp.step = 1;
        ButtonHelper.simulator = {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.WaterQuantity,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.FLASH},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        };
        break;

      case SimulatorPremiumPagesInterface.WaterQuantity:
        ButtonHelper.pouringAudio.load();
        if (QuantityAndTemp.step === 1) {
          if (QuantityAndTemp.currentDrinks.run === SimulatorPremiumPagesInterface.ColdWaterDispensing) {
            ButtonHelper.simulator.buttons.cold.state = ButtonsPremiumState.ICON_BRIGHT;
            ButtonHelper.simulator.buttons.boiling.state = ButtonsPremiumState.ICON_NO_BRIGHT;
            ButtonHelper.simulator.buttons.personal.state = ButtonsPremiumState.ICON_NO_BRIGHT;
            QuantityAndTemp.step = 2;
            ButtonHelper.simulator.pouring.animation = PouringAnimation.COLD;

            ButtonHelper.pouringAudio.loop = true;
            if (!ColdWaterBtn.isMute) {
              ButtonHelper.pouringAudio.play(); // done
            }
          }
          ButtonHelper.resetMainAfk();
        } else if (QuantityAndTemp.step === 2) {
          ButtonHelper.pouringAudio.pause();
          if (QuantityAndTemp.currentDrinks.run === SimulatorPremiumPagesInterface.ColdWaterDispensing) {
            ButtonHelper.simulator.buttons = {
              hot: {active: true, state: ButtonsPremiumState.NO_ICON},
              cold: {active: true, state: ButtonsPremiumState.NO_ICON},
              mix: {active: true, state: ButtonsPremiumState.NO_ICON},
              boiling: {active: true, state: ButtonsPremiumState.BORDER},
              jugs: {active: true, state: ButtonsPremiumState.NO_ICON},
              personal: {active: true, state: ButtonsPremiumState.BORDER}
            };
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
            QuantityAndTemp.step = 3;
          }
        }
        break;

      case SimulatorPremiumPagesInterface.PersonalQuantity:
        Personal.currentUser = Personal.users[ButtonHelper.lang][ButtonHelper.buttonsIndexes.cold + Personal.currentUserPage];
        ButtonHelper.simulatorEditPersonalQuantity();
        ButtonHelper.startMainAfk();
        break;

      case SimulatorPremiumPagesInterface.EditPersonalQuantity:
        QuantityAndTemp.currentDrinks = Personal.currentUser.drinks[ButtonHelper.buttonsIndexes.cold];
        QuantityAndTemp.step = 1;
        ButtonHelper.startMainAfk();
        ButtonHelper.simulator = {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.WaterQuantity,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.FLASH},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        };
        break;

      case SimulatorPremiumPagesInterface.Descaling:
        // cold
        if (InternalCleaning.step === 5 || InternalCleaning.step === 8 || InternalCleaning.step === 13) {
          if (!InternalCleaning.isPause) {
            InternalCleaning.isPause = true;
            ButtonHelper.pouringAudio.pause();
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
          }
        }
        break;

      case SimulatorPremiumPagesInterface.Sterilization:
        ButtonHelper.pouringAudio.loop = true;
        if (InternalCleaning.step === 2) {
          InternalCleaning.step = 3;
          ButtonHelper.simulator.pouring.animation = PouringAnimation.COLD;
          ButtonHelper.pouringAudio.load();
          if (!ColdWaterBtn.isMute) {
            ButtonHelper.pouringAudio.play(); //done
          }
          ButtonHelper.simulator.buttons = {
            hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
          };
          InternalCleaning.hotWaterDispensing(1200).then(() => {
            InternalCleaning.step = 4;
            ButtonHelper.pouringAudio.pause();
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
            InternalCleaning.statrtBoiling(800).then(() => {
              InternalCleaning.step = 5;
              ButtonHelper.simulator.buttons = {
                hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                cold: {active: true, state: ButtonsPremiumState.FLASH},
                mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
              };
            });
          });
        } else if (InternalCleaning.step === 3 || InternalCleaning.step === 6
          || InternalCleaning.step === 9 || InternalCleaning.step === 13) {
          if (!InternalCleaning.isPause) {
            InternalCleaning.isPause = true;
            ButtonHelper.pouringAudio.pause();
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
          }
        } else if (InternalCleaning.step === 5) {
          InternalCleaning.step = 6;
          ButtonHelper.simulator.pouring.animation = PouringAnimation.COLD;
          ButtonHelper.pouringAudio.load();
          if (!ColdWaterBtn.isMute) {
            ButtonHelper.pouringAudio.play(); // done
          }
          ButtonHelper.simulator.buttons = {
            hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
          };
          InternalCleaning.hotWaterDispensing(1200).then(() => {
            InternalCleaning.step = 7;
            ButtonHelper.pouringAudio.pause();
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
            InternalCleaning.statrtBoiling(800).then(() => {
              InternalCleaning.step = 8;
              ButtonHelper.simulator.buttons = {
                hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                cold: {active: true, state: ButtonsPremiumState.FLASH},
                mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
              };
            });
          });
        } else if (InternalCleaning.step === 8) {
          InternalCleaning.step = 9;
          ButtonHelper.simulator.pouring.animation = PouringAnimation.COLD;
          ButtonHelper.pouringAudio.load();
          if (!ColdWaterBtn.isMute) {
            ButtonHelper.pouringAudio.play(); // done
          }
          ButtonHelper.simulator.buttons = {
            hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
          };
          InternalCleaning.hotWaterDispensing(1200).then(() => {
            ButtonHelper.pouringAudio.pause();
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
            InternalCleaning.step = 10;
            InternalCleaning.cleaning().then(() => {
              InternalCleaning.step = 11;

              let interval = setTimeout(() => {
                InternalCleaning.step = 12;
                ButtonHelper.simulator.buttons = {
                  hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                  cold: {active: true, state: ButtonsPremiumState.FLASH},
                  mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                  boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                  jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                  personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
                };
              }, 3000);
            });
          });
        } else if (InternalCleaning.step === 12) {
          InternalCleaning.step = 13;
          ButtonHelper.simulator.pouring.animation = PouringAnimation.COLD;
          ButtonHelper.pouringAudio.load();
          if (!ColdWaterBtn.isMute) {
            ButtonHelper.pouringAudio.play(); // done
          }
          ButtonHelper.simulator.buttons = {
            hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
          };
          InternalCleaning.hotWaterDispensing(1200).then(() => {
            ButtonHelper.pouringAudio.pause();
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
            InternalCleaning.step = 14;
            let interval = setTimeout(() => {
              ButtonHelper.mainSimulatorScreen();
              InternalCleaning.reset();
            }, 3000);
          });
        }
        break;
    }
  }

  public static longClickDown(isMute): void {
    switch (ButtonHelper.simulator.page) {

      case SimulatorPremiumPagesInterface.IDLE:
        ButtonHelper.resetMainAfk();
        ButtonHelper.simulatorCold(true);
        break;

      case SimulatorPremiumPagesInterface.ShabbatModeScreen:
        ButtonHelper.resetMainAfk();
        ButtonHelper.mainSimulatorScreen();
        break;
    }
  }

  public static longClickUp(isMute): void {
    switch (ButtonHelper.simulator.page) {

      case SimulatorPremiumPagesInterface.ColdWaterDispensing:
        ButtonHelper.resetMainAfk();
        ButtonHelper.mainSimulatorScreen();
        break;
    }
  }
}
