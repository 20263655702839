import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import {WashingTank} from '../../data/washingTank';

@Component({
  selector: 'app-washing-tanks-premium',
  templateUrl: './washing-tanks-premium.component.html',
  styleUrls: ['./washing-tanks-premium.component.sass']
})
export class WashingTanksPremiumComponent implements OnInit {

  public washingTank = null;
  public lang = null;
  public topText = {
    he: 'שטיפת מכלים',
    en: 'Washing Tanks'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  public warningText = {
    warningTop: {
      he: 'זהירות!',
      en: 'Warning!'
    },
    warningMiddle: {
      he: 'תהליך השטיפה כולל',
      en: 'Process includes'
    },
    warningBottom: {
      he: 'מזיגת מים <span style="color:#F20000">חמים</span>',
      en: 'pouring <span style="color:#F20000">Hot</span> water'
    }
  };


  public step_1_text = {
    line_1: {
      he: 'שלב 1 מתוך 2',
      en: 'Step 1 of 2'
    },
    line_2: {
      he: 'יש להניח מכל גדול',
      en: 'Place large container'
    },
    line_3: {
      he: 'מתחת לפיית המזיגה',
      en: 'under the spout'
    },
    line_4: {
      he: 'לשטיפת מכל המים הקרים',
      en: 'Press <span style="color: #00C6FF">cold</span> water to'
    },
    line_5: {
      he: 'יש ללחוץ על כפתור מים <span style="color: #00C6FF">קרים</span>',
      en: 'begin washing cold tank'
    }
  };


  public step_2_text = {
    line_1: {
      he: 'שלב 1 מתוך 2',
      en: 'Step 1 of 2'
    },
    line_2: {
      he: 'מכל מים קרים בתהליך שטיפה...',
      en: 'Washing cold tank...'
    },
    line_3: {
      he: 'לחיצה על כל כפתור תעצור מזיגה',
      en: 'Press any key to pause'
    },
    line_4: {
      he: 'לחיצה על המשך תחדש מזיגה',
      en: 'Press any key to continue'
    }
  };


  public step_3_text = {
    line_1: {
      he: 'שלב 2 מתוך 2',
      en: 'Step 2 of 2'
    },
    line_2: {
      he: 'יש להניח מכל גדול',
      en: 'Place large container'
    },
    line_3: {
      he: 'מתחת לפיית המזיגה',
      en: 'under the spout'
    },
    line_4: {
      he: 'לשטיפת מכל המים החמים',
      en: 'Press <span style="color: #F20000">hot</span> water to'
    },
    line_5: {
      he: 'יש ללחוץ על כפתור מים <span style="color: #F20000">חמים</span>',
      en: 'begin washing hot tank'
    }
  };

  public step_4_text = {
    line_1: {
      he: 'שלב 2 מתוך 2',
      en: 'Step 2 of 2'
    },
    line_2: {
      he: 'מיכל מים חמים בתהליך שטיפה...',
      en: 'Washing hot tank...'
    },
    line_3: {
      he: 'לחיצה על כל כפתור תעצור מזיגה',
      en: 'Press any key to pause'
    },
    line_4: {
      he: 'לחיצה על המשך תחדש מזיגה',
      en: 'Press any key to continue'
    }
  };

  public step_5_text = {
    he: 'התהליך הסתיים בהצלחה',
    en: 'Process Completed'
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Washing tanks premium', lang);
      this.lang = lang;
    });
    this.washingTank = WashingTank;
    WashingTank.reset();
  }

  ngOnInit() {
  }

}
