import {
  ButtonsPremiumState,
  IndicatorAddonStatePremium,
  IndicatorLockStatePremium,
  IndicatorNetStatePremium,
  IndicatorPurifierStatePremium,
  IndicatorUvStatePremium,
  IndicatorWifiStatePremium,
  PouringAnimation,
  SimulatorPremiumPagesInterface,
} from "../../interfaces/simulator-premium";
import { Personal } from "../data/personal";
import { Jug } from "../data/jug";
import { Setting } from "../data/setting";
import { Maintenance } from "../data/maintenance";
import { BuiltInTest } from "../data/builtInTest";
import { LockButton } from "../data/lockButton";
import { WashingTank } from "../data/washingTank";
import { Reset } from "../data/reset";
import { Preference } from "../data/preference";
import { QuantityAndTemp } from "../data/quantityAndTemp";
import { InternalCleaning } from "../data/internalCleaning";
import { ButtonHelper } from "../helpers/buttonHelper";
import { MuteService } from "../../../../mute.service";
import { TechScreen } from "../data/techScreen";

export class PersonalBtn {
  public static isMute = null;

  constructor(private muteService: MuteService) {
    PersonalBtn.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      PersonalBtn.isMute = mute;
    });
  }

  public static shortClick(isMute): void {
    PersonalBtn.isMute = isMute;
    switch (ButtonHelper.simulator.page) {
      case SimulatorPremiumPagesInterface.IDLE:
        ButtonHelper.startMainAfk();
        ButtonHelper.simulatorPersonal();
        break;

      case SimulatorPremiumPagesInterface.MixWaterDispensing:
      case SimulatorPremiumPagesInterface.HotWaterDispensing:
      case SimulatorPremiumPagesInterface.ColdWaterDispensing:
        ButtonHelper.mainSimulatorScreen();
        break;

      // screen personal
      case SimulatorPremiumPagesInterface.Personal:
        ButtonHelper.startMainAfk();
        if (!Personal.currentUserPage) {
          Personal.currentUserPage += 4;
          ButtonHelper.simulator.buttons.personal.state =
            ButtonsPremiumState.NO_ICON;
        }
        break;

      case SimulatorPremiumPagesInterface.PersonalDrinks:
      case SimulatorPremiumPagesInterface.EditPersonalQuantity:
        ButtonHelper.resetMainAfk();
        ButtonHelper.simulator = {
          indicator: {
            addon: { active: false, state: IndicatorAddonStatePremium.OFF },
            uv: { active: false, state: IndicatorUvStatePremium.BLUE },
            wifi: { active: false, state: IndicatorWifiStatePremium.OFF },
            clock: { active: false },
            lock: { active: false, state: IndicatorLockStatePremium.OFF },
            purifier: {
              active: false,
              state: IndicatorPurifierStatePremium.BLUE,
            },
            net: { active: false, state: IndicatorNetStatePremium.OFF },
          },
          page: SimulatorPremiumPagesInterface.DefinePersonalDrinks,
          pouring: { fast: false, animation: PouringAnimation.NONE },
          buttons: {
            hot: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            cold: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            mix: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            boiling: {
              active: true,
              state: ButtonsPremiumState.ICON_NO_BRIGHT,
            },
            jugs: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            personal: {
              active: true,
              state: ButtonsPremiumState.ICON_NO_BRIGHT,
            },
          },
        };
        ButtonHelper.afkDispensingWater = setTimeout(() => {
          ButtonHelper.mainSimulatorScreen();
          Personal.currentUserPage = 0;
        }, ButtonHelper.DISPENSING_WATER_TIME);
        break;

      case SimulatorPremiumPagesInterface.Jugs:
        ButtonHelper.resetMainAfk();
        ButtonHelper.simulator = {
          indicator: {
            addon: { active: false, state: IndicatorAddonStatePremium.OFF },
            uv: { active: false, state: IndicatorUvStatePremium.BLUE },
            wifi: { active: false, state: IndicatorWifiStatePremium.OFF },
            clock: { active: false },
            lock: { active: false, state: IndicatorLockStatePremium.OFF },
            purifier: {
              active: false,
              state: IndicatorPurifierStatePremium.BLUE,
            },
            net: { active: false, state: IndicatorNetStatePremium.OFF },
          },
          page: SimulatorPremiumPagesInterface.DefineJugs,
          pouring: { fast: false, animation: PouringAnimation.NONE },
          buttons: {
            hot: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            cold: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            mix: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            boiling: {
              active: true,
              state: ButtonsPremiumState.ICON_NO_BRIGHT,
            },
            jugs: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            personal: {
              active: true,
              state: ButtonsPremiumState.ICON_NO_BRIGHT,
            },
          },
        };
        ButtonHelper.afkDispensingWater = setTimeout(() => {
          ButtonHelper.mainSimulatorScreen();
        }, ButtonHelper.DISPENSING_WATER_TIME);
        break;

      case SimulatorPremiumPagesInterface.ActivateJugs:
        if (Jug.step === 2 || Jug.step === 3 || Jug.step === 4) {
          Jug.step++;
          if (Jug.step === 5) {
            ButtonHelper.resetMainAfk();
          } else {
            ButtonHelper.startMainAfk();
          }
          ButtonHelper.simulatorActivateJug();
          if (Jug.step == 3) {
            ButtonHelper.startMainAfk();
            ButtonHelper.simulator.buttons = {
              hot: { active: true, state: ButtonsPremiumState.NO_ICON },
              cold: { active: true, state: ButtonsPremiumState.NO_ICON },
              mix: { active: true, state: ButtonsPremiumState.NO_ICON },
              boiling: { active: true, state: ButtonsPremiumState.BORDER },
              jugs: { active: true, state: ButtonsPremiumState.NO_ICON },
              personal: { active: true, state: ButtonsPremiumState.BORDER },
            };
          }
        }
        break;

      case SimulatorPremiumPagesInterface.Settings:
        console.log(Setting.currentIndex);
        console.log(
          Setting.settingsList[ButtonHelper.lang][Setting.currentIndex]
        );
        // language
        if (
          Setting.settingsList[ButtonHelper.lang][Setting.currentIndex][
            "type"
          ] === 4
        ) {
          ButtonHelper.startMainAfk();
          if (!Setting.isLanguage) {
            Setting.isLanguage = true;
            Setting.isEditMode = true;
            ButtonHelper.simulator.buttons.jugs.state =
              ButtonsPremiumState.BORDER;
            Setting.initLanguageSwiper();
          } else {
            ButtonHelper.simulator.buttons = {
              hot: { active: true, state: ButtonsPremiumState.NO_BORDER },
              cold: { active: true, state: ButtonsPremiumState.NO_BORDER },
              mix: { active: true, state: ButtonsPremiumState.NO_BORDER },
              boiling: { active: true, state: ButtonsPremiumState.NO_BORDER },
              jugs: { active: true, state: ButtonsPremiumState.NO_BORDER },
              personal: { active: true, state: ButtonsPremiumState.NO_BORDER },
            };
            Setting.isLanguage = false;
            Setting.isLanguageMessage = true;

            let timeout = setTimeout(() => {
              ButtonHelper.simulatorSettings();
              clearTimeout(timeout);
            }, 3000);
          }
          // date/time
        } else if (
          Setting.settingsList[ButtonHelper.lang][Setting.currentIndex][
            "type"
          ] === 3
        ) {
          ButtonHelper.startMainAfk();
          ButtonHelper.simulator.buttons.jugs.state =
            ButtonsPremiumState.BORDER;
          ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.BORDER;
          if (!Setting.isDateTime) {
            Setting.isDateTime = true;
            Setting.isEditMode = true;
            Setting.initDateTimeSwiper();
          } else {
            if (Setting.currentTimePos === 0) {
              Setting.initTime();
              Setting.isSetTime = true;
            } else {
              if (!Setting.isDateMessage) {
                Setting.incTimePosition();
                if (Setting.isTimeMessage || Setting.isDateMessage) {
                  ButtonHelper.simulator.buttons.jugs.state =
                    ButtonsPremiumState.NO_BORDER;
                  ButtonHelper.simulator.buttons.mix.state =
                    ButtonsPremiumState.NO_BORDER;
                } else {
                  ButtonHelper.simulator.buttons.jugs.state =
                    ButtonsPremiumState.BORDER;
                  ButtonHelper.simulator.buttons.mix.state =
                    ButtonsPremiumState.BORDER;
                }
              } else {
                Setting.initTime();
                ButtonHelper.simulatorSettings();
              }
            }
          }
        } else {
          ButtonHelper.startMainAfk();
          if (!Setting.isEditMode) {
            Setting.isEditMode = true;
          } else {
            if (
              Setting.settingsList[ButtonHelper.lang][Setting.currentIndex][
                "value"
              ]
            ) {
              Setting.settingsList[ButtonHelper.lang][Setting.currentIndex][
                "value"
              ] = true;

              if (
                Setting.settingsList[ButtonHelper.lang][Setting.currentIndex][
                  "type"
                ] === 1
              ) {
                Setting.isDeactivatedConformation = false;
                Setting.isMessageScreen = true;

                ButtonHelper.simulator.buttons = {
                  hot: { active: true, state: ButtonsPremiumState.NO_BORDER },
                  cold: { active: true, state: ButtonsPremiumState.NO_BORDER },
                  mix: { active: true, state: ButtonsPremiumState.NO_BORDER },
                  boiling: {
                    active: true,
                    state: ButtonsPremiumState.NO_BORDER,
                  },
                  jugs: { active: true, state: ButtonsPremiumState.NO_BORDER },
                  personal: {
                    active: true,
                    state: ButtonsPremiumState.NO_BORDER,
                  },
                };

                setTimeout(() => {
                  ButtonHelper.afkDispensingWater = setTimeout(() => {
                    ButtonHelper.startMainAfk();
                    ButtonHelper.simulatorSettings();
                  }, 3000);
                });
              } else if (
                Setting.settingsList[ButtonHelper.lang][Setting.currentIndex][
                  "type"
                ] === 2
              ) {
                ButtonHelper.startMainAfk();
                Setting.isConnectivityScreen = true;
                ButtonHelper.simulator.buttons = {
                  hot: { active: true, state: ButtonsPremiumState.NO_BORDER },
                  cold: { active: true, state: ButtonsPremiumState.NO_BORDER },
                  mix: { active: true, state: ButtonsPremiumState.NO_BORDER },
                  boiling: { active: true, state: ButtonsPremiumState.BORDER },
                  jugs: { active: true, state: ButtonsPremiumState.NO_BORDER },
                  personal: {
                    active: true,
                    state: ButtonsPremiumState.NO_BORDER,
                  },
                };
                ButtonHelper.afkDispensingWater = setTimeout(() => {
                  ButtonHelper.simulatorSettings();
                  clearTimeout(ButtonHelper.afkDispensingWater);
                }, 10000);
              }
            } else {
              ButtonHelper.startMainAfk();
              Setting.settingsList[ButtonHelper.lang][Setting.currentIndex][
                "value"
              ] = false;
              if (
                Setting.settingsList[ButtonHelper.lang][Setting.currentIndex][
                  "type"
                ] === 1
              ) {
                Setting.isDeactivatedConformation = true;
                ButtonHelper.simulator.buttons = {
                  hot: { active: true, state: ButtonsPremiumState.NO_BORDER },
                  cold: { active: true, state: ButtonsPremiumState.NO_BORDER },
                  mix: { active: true, state: ButtonsPremiumState.BORDER },
                  boiling: { active: true, state: ButtonsPremiumState.BORDER },
                  jugs: { active: true, state: ButtonsPremiumState.BORDER },
                  personal: {
                    active: true,
                    state: ButtonsPremiumState.NO_BORDER,
                  },
                };
              } else if (
                Setting.settingsList[ButtonHelper.lang][Setting.currentIndex][
                  "type"
                ] === 2
              ) {
                Setting.isEditMode = false;
              }
            }
          }
        }
        break;

      case SimulatorPremiumPagesInterface.Maintenance:
        ButtonHelper.simulator = ButtonHelper.copyObject(
          Maintenance.maintenanceList[ButtonHelper.lang][
            Maintenance.currentIndex
          ].page
        );
        if (
          Maintenance.maintenanceList[ButtonHelper.lang][
            Maintenance.currentIndex
          ].page.page === SimulatorPremiumPagesInterface.BuiltInTest
        ) {
          ButtonHelper.resetMainAfk();
          BuiltInTest.afk().then(() => {
            ButtonHelper.simulator.buttons = {
              hot: { active: true, state: ButtonsPremiumState.NO_BORDER },
              cold: { active: true, state: ButtonsPremiumState.NO_BORDER },
              mix: { active: true, state: ButtonsPremiumState.NO_BORDER },
              boiling: { active: true, state: ButtonsPremiumState.NO_BORDER },
              jugs: { active: true, state: ButtonsPremiumState.NO_BORDER },
              personal: { active: true, state: ButtonsPremiumState.NO_BORDER },
            };
            BuiltInTest.startBuilt().then(() => {
              ButtonHelper.simulatorMaintenance();
            });
          });
        } else if (
          Maintenance.maintenanceList[ButtonHelper.lang][
            Maintenance.currentIndex
          ].page.page === SimulatorPremiumPagesInterface.LockButtonMessage
        ) {
          ButtonHelper.startMainAfk();
          LockButton.isLock = true;
          let afk = setTimeout(() => {
            ButtonHelper.lockButtons();
            clearTimeout(afk);
            ButtonHelper.resetMainAfk();
          }, 3000);
        } else {
          ButtonHelper.startMainAfk();
        }
        break;

      case SimulatorPremiumPagesInterface.WashingTank:
        if (WashingTank.step === 0) {
          ButtonHelper.startMainAfk();
          WashingTank.step++;
          ButtonHelper.simulator.buttons = {
            hot: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            cold: { active: true, state: ButtonsPremiumState.FLASH },
            mix: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            boiling: {
              active: true,
              state: ButtonsPremiumState.ICON_NO_BRIGHT,
            },
            jugs: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            personal: {
              active: true,
              state: ButtonsPremiumState.ICON_NO_BRIGHT,
            },
          };
        } else if (WashingTank.step === 2) {
          WashingTank.isPause = !WashingTank.isPause;
        } else if (WashingTank.step === 4) {
          WashingTank.isPause = !WashingTank.isPause;
        }
        break;

      case SimulatorPremiumPagesInterface.Reset:
        if (Reset.screen === 1) {
          ButtonHelper.startMainAfk();
          Reset.screen = 2;
          ButtonHelper.simulator.buttons.personal.state =
            ButtonsPremiumState.NO_BORDER;
          ButtonHelper.simulator.buttons.jugs.state =
            ButtonsPremiumState.BORDER;
          ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.BORDER;
        }
        break;

      case SimulatorPremiumPagesInterface.Preferences:
        if (
          Preference.preferenceList[ButtonHelper.lang][Preference.currentIndex][
            "id"
          ] === 1
        ) {
          ButtonHelper.simulatorQuantityAndTemp();
        } else {
          ButtonHelper.simulatorQuantityPersonal();
        }
        ButtonHelper.startMainAfk();
        break;

      case SimulatorPremiumPagesInterface.WaterQuantity:
        if (QuantityAndTemp.step === 1 || QuantityAndTemp.step === 3) {
          QuantityAndTemp.step = 4;
          ButtonHelper.simulator.buttons = {
            hot: { active: true, state: ButtonsPremiumState.NO_BORDER },
            cold: { active: true, state: ButtonsPremiumState.NO_BORDER },
            mix: { active: true, state: ButtonsPremiumState.BORDER },
            boiling: { active: true, state: ButtonsPremiumState.BORDER },
            jugs: { active: true, state: ButtonsPremiumState.BORDER },
            personal: { active: true, state: ButtonsPremiumState.BORDER },
          };
        } else if (QuantityAndTemp.step === 4) {
          QuantityAndTemp.step = 5;
          ButtonHelper.simulator.buttons = {
            hot: { active: true, state: ButtonsPremiumState.NO_BORDER },
            cold: { active: true, state: ButtonsPremiumState.NO_BORDER },
            mix: { active: true, state: ButtonsPremiumState.NO_BORDER },
            boiling: { active: true, state: ButtonsPremiumState.NO_BORDER },
            jugs: { active: true, state: ButtonsPremiumState.NO_BORDER },
            personal: { active: true, state: ButtonsPremiumState.NO_BORDER },
          };

          setTimeout(() => {
            ButtonHelper.simulator = {
              indicator: {
                addon: { active: false, state: IndicatorAddonStatePremium.OFF },
                uv: { active: false, state: IndicatorUvStatePremium.OFF },
                wifi: { active: false, state: IndicatorWifiStatePremium.OFF },
                clock: { active: false },
                lock: { active: false, state: IndicatorLockStatePremium.OFF },
                purifier: {
                  active: false,
                  state: IndicatorPurifierStatePremium.OFF,
                },
                net: { active: false, state: IndicatorNetStatePremium.OFF },
              },
              page: SimulatorPremiumPagesInterface.QuantityAndTemp,
              pouring: { fast: false, animation: PouringAnimation.NONE },
              buttons: {
                hot: { active: true, state: ButtonsPremiumState.BORDER },
                cold: { active: true, state: ButtonsPremiumState.BORDER },
                mix: { active: true, state: ButtonsPremiumState.BORDER },
                boiling: { active: true, state: ButtonsPremiumState.BORDER },
                jugs: { active: true, state: ButtonsPremiumState.BORDER },
                personal: { active: true, state: ButtonsPremiumState.BORDER },
              },
            };
            QuantityAndTemp.reset();
            ButtonHelper.startMainAfk();
          }, 3000);
        }
        break;

      case SimulatorPremiumPagesInterface.PersonalQuantity:
        ButtonHelper.startMainAfk();
        if (!Personal.currentUserPage) {
          Personal.currentUserPage += 4;
          ButtonHelper.simulator.buttons.personal.state =
            ButtonsPremiumState.NO_ICON;
        }
        break;

      case SimulatorPremiumPagesInterface.InternalCleaningMain:
        ButtonHelper.resetMainAfk();
        if (
          InternalCleaning.cleaningList[ButtonHelper.lang][
            InternalCleaning.currentIndex
          ].id === 1
        ) {
          ButtonHelper.simulator = {
            indicator: {
              addon: { active: false, state: IndicatorAddonStatePremium.OFF },
              uv: { active: false, state: IndicatorUvStatePremium.BLUE },
              wifi: { active: false, state: IndicatorWifiStatePremium.OFF },
              clock: { active: false },
              lock: { active: false, state: IndicatorLockStatePremium.OFF },
              purifier: {
                active: false,
                state: IndicatorPurifierStatePremium.BLUE,
              },
              net: { active: false, state: IndicatorNetStatePremium.OFF },
            },
            page: SimulatorPremiumPagesInterface.Descaling,
            pouring: { fast: false, animation: PouringAnimation.NONE },
            buttons: {
              hot: { active: true, state: ButtonsPremiumState.NO_ICON },
              cold: { active: true, state: ButtonsPremiumState.NO_ICON },
              mix: { active: true, state: ButtonsPremiumState.NO_ICON },
              boiling: { active: true, state: ButtonsPremiumState.BORDER },
              jugs: { active: true, state: ButtonsPremiumState.NO_ICON },
              personal: { active: true, state: ButtonsPremiumState.BORDER },
            },
          };
        } else {
          ButtonHelper.resetMainAfk();
          ButtonHelper.simulator = {
            indicator: {
              addon: { active: false, state: IndicatorAddonStatePremium.OFF },
              uv: { active: false, state: IndicatorUvStatePremium.BLUE },
              wifi: { active: false, state: IndicatorWifiStatePremium.OFF },
              clock: { active: false },
              lock: { active: false, state: IndicatorLockStatePremium.OFF },
              purifier: {
                active: false,
                state: IndicatorPurifierStatePremium.BLUE,
              },
              net: { active: false, state: IndicatorNetStatePremium.OFF },
            },
            page: SimulatorPremiumPagesInterface.Sterilization,
            pouring: { fast: false, animation: PouringAnimation.NONE },
            buttons: {
              hot: { active: true, state: ButtonsPremiumState.NO_ICON },
              cold: { active: true, state: ButtonsPremiumState.NO_ICON },
              mix: { active: true, state: ButtonsPremiumState.NO_ICON },
              boiling: { active: true, state: ButtonsPremiumState.BORDER },
              jugs: { active: true, state: ButtonsPremiumState.NO_ICON },
              personal: { active: true, state: ButtonsPremiumState.BORDER },
            },
          };
        }
        break;

      case SimulatorPremiumPagesInterface.Descaling:
        //personal
        if (InternalCleaning.step === 0) {
          InternalCleaning.step = 1;
        } else if (InternalCleaning.step === 1) {
          InternalCleaning.step = 2;
          ButtonHelper.simulator.buttons = {
            hot: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            cold: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            mix: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            boiling: { active: true, state: ButtonsPremiumState.FLASH },
            jugs: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            personal: {
              active: true,
              state: ButtonsPremiumState.ICON_NO_BRIGHT,
            },
          };
        } else if (
          InternalCleaning.step === 5 ||
          InternalCleaning.step === 8 ||
          InternalCleaning.step === 13
        ) {
          if (!InternalCleaning.isPause) {
            InternalCleaning.isPause = true;
            ButtonHelper.pouringAudio.pause();
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
          } else {
            InternalCleaning.isPause = false;
            ButtonHelper.pouringAudio.load();
            if (!PersonalBtn.isMute) {
              ButtonHelper.pouringAudio.play(); // done
            }
            ButtonHelper.simulator.pouring.animation = PouringAnimation.HOT;
          }
        }
        break;

      case SimulatorPremiumPagesInterface.Sterilization:
        if (InternalCleaning.step === 0) {
          InternalCleaning.step = 1;
        } else if (InternalCleaning.step === 1) {
          InternalCleaning.step = 2;
          ButtonHelper.simulator.buttons = {
            hot: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            cold: { active: true, state: ButtonsPremiumState.FLASH },
            mix: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            boiling: {
              active: true,
              state: ButtonsPremiumState.ICON_NO_BRIGHT,
            },
            jugs: { active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT },
            personal: {
              active: true,
              state: ButtonsPremiumState.ICON_NO_BRIGHT,
            },
          };
        } else if (
          InternalCleaning.step === 3 ||
          InternalCleaning.step === 6 ||
          InternalCleaning.step === 9 ||
          InternalCleaning.step === 13
        ) {
          if (!InternalCleaning.isPause) {
            InternalCleaning.isPause = true;
            ButtonHelper.pouringAudio.pause();
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
          } else {
            InternalCleaning.isPause = false;
            ButtonHelper.pouringAudio.load();
            if (!PersonalBtn.isMute) {
              ButtonHelper.pouringAudio.play(); // done
            }
            ButtonHelper.simulator.pouring.animation = PouringAnimation.COLD;
          }
        }
        break;

      case SimulatorPremiumPagesInterface.Tech:
        console.log(TechScreen.currentIndex);
        ButtonHelper.simulator = ButtonHelper.copyObject(
          TechScreen.techList[ButtonHelper.lang][TechScreen.currentIndex].page
        );
        break;
      case SimulatorPremiumPagesInterface.ChildLockTech:
        ButtonHelper.techScreen();
        break;
      case SimulatorPremiumPagesInterface.DebugTech:
        ButtonHelper.techScreen();
        break;
      case SimulatorPremiumPagesInterface.ParametersTech:
        ButtonHelper.techScreen();
        break;
      case SimulatorPremiumPagesInterface.TempTech:
        ButtonHelper.techScreen();
        break;
      case SimulatorPremiumPagesInterface.ErrorScreen:
        ButtonHelper.techScreen();
        break;
    }
  }

  public static longClickDown(isMute): void {
    switch (ButtonHelper.simulator.page) {
      case SimulatorPremiumPagesInterface.IDLE:
        ButtonHelper.startMainAfk();
        ButtonHelper.simulatorMenu();
        break;

      case SimulatorPremiumPagesInterface.ShabbatModeScreen:
        ButtonHelper.resetMainAfk();
        ButtonHelper.mainSimulatorScreen();
        break;
    }
  }

  public static personalAndBoilingMultiPress(isMute): void {
    switch (ButtonHelper.simulator.page) {
      case SimulatorPremiumPagesInterface.IDLE:
        if (!LockButton.isLock) {
          ButtonHelper.resetMainAfk();
          LockButton.isLock = true;
          ButtonHelper.lockMessage();
          let timeout = setTimeout(() => {
            ButtonHelper.lockButtons();
            clearTimeout(timeout);
          }, 3000);
        }
        break;

      case SimulatorPremiumPagesInterface.LockButtonScreen:
        if (LockButton.isLock) {
          LockButton.isLock = false;
          ButtonHelper.resetMainAfk();
          ButtonHelper.lockMessage();
          let timeout = setTimeout(() => {
            ButtonHelper.mainSimulatorScreen();
            clearTimeout(timeout);
          }, 3000);
        }
        break;
    }
  }

  public static personalAndJugsMultiPress(isMute): void {
    switch (ButtonHelper.simulator.page) {
      case SimulatorPremiumPagesInterface.IDLE:
        // ButtonHelper.shabbatMode();
        ButtonHelper.startMainAfk();
        ButtonHelper.simulator = {
          indicator: {
            addon: { active: false, state: IndicatorAddonStatePremium.OFF },
            uv: { active: false, state: IndicatorUvStatePremium.OFF },
            wifi: { active: false, state: IndicatorWifiStatePremium.OFF },
            clock: { active: false },
            lock: { active: false, state: IndicatorLockStatePremium.OFF },
            purifier: {
              active: false,
              state: IndicatorPurifierStatePremium.OFF,
            },
            net: { active: false, state: IndicatorNetStatePremium.OFF },
          },
          page: SimulatorPremiumPagesInterface.ShabbatModeConfirmationHome,
          pouring: { fast: false, animation: PouringAnimation.NONE },
          buttons: {
            hot: { active: true, state: ButtonsPremiumState.NO_BORDER },
            cold: { active: true, state: ButtonsPremiumState.NO_BORDER },
            mix: { active: true, state: ButtonsPremiumState.BORDER },
            boiling: { active: true, state: ButtonsPremiumState.BORDER },
            jugs: { active: true, state: ButtonsPremiumState.BORDER },
            personal: { active: true, state: ButtonsPremiumState.NO_BORDER },
          },
        };
        break;
    }
  }

  public static techScreen(isMute): void {
    console.log(ButtonHelper.simulator.page.toString());
    switch (ButtonHelper.simulator.page) {
      case SimulatorPremiumPagesInterface.Menu:
        ButtonHelper.techScreen();
        break;
      case SimulatorPremiumPagesInterface.IDLE:
        ButtonHelper.techScreen();
        break;
    }
  }
}
