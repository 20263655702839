import {Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2} from '@angular/core';

@Directive({
  selector: '[appThreeSecondEntrySettingPress]'
})
export class ThreeSecondEntrySettingPressDirective {

  @Output() longThreeClickDown: EventEmitter<object> = new EventEmitter();
  @Output() longThreeClickUp: EventEmitter<object> = new EventEmitter();

  @Output() goBackMenu: EventEmitter<object> = new EventEmitter();

  private timeout: any;
  private duration = 3000;
  private isLongClickDone = false;
  private secondsCount = 0;
  private secondsInterval = null;
  private beep = new Audio('/assets/audio/beep.mp3');
  private isTenSubmit = false;
  static isGoBack = false;

  constructor(private element: ElementRef, private renderer: Renderer2) {
    this.isTenSubmit = false;
    ThreeSecondEntrySettingPressDirective.isGoBack = false;
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event) {
    this.renderer.addClass(this.element.nativeElement, 'active-btn');
    this.timeout = setTimeout(() => {
      this.isLongClickDone = true;
      // this.longThreeClickDown.emit(event);
    }, this.duration);

    this.secondsInterval = setInterval(() => {
      this.secondsCount++;
      if (this.secondsCount === 3) {
        if (ThreeSecondEntrySettingPressDirective.isGoBack) {
          this.goBackMenu.emit();
          setTimeout(() => {
            ThreeSecondEntrySettingPressDirective.isGoBack = false;
          }, 1200);
          console.log("IN BACK")
        } else {
          this.beep.pause();
          this.beep.play();
        }
      }
      if (this.secondsCount >= 10) {
        if (!this.isTenSubmit) {
          this.longThreeClickDown.emit(event);
          ThreeSecondEntrySettingPressDirective.isGoBack = true;
          this.isTenSubmit = true;
        }
      }
      console.log('in interval three', this.secondsCount)
    }, 1000)
  }

  @HostListener('mouseup', ['$event']) onMouseUp(event) {
    if (this.isLongClickDone && this.secondsCount < 10 && !ThreeSecondEntrySettingPressDirective.isGoBack) {
      this.longThreeClickUp.emit(event);
      ThreeSecondEntrySettingPressDirective.isGoBack = true;
    }
    this.renderer.removeClass(this.element.nativeElement, 'active-btn');
    clearTimeout(this.timeout);
    clearInterval(this.secondsInterval);
    this.isLongClickDone = false;
    this.secondsCount = 0;
    this.isTenSubmit = false;
  }

  @HostListener('mouseout', ['$event']) onMouseOut(event) {
    if (this.isLongClickDone) {
      this.longThreeClickUp.emit(event);
      console.log('HERE')
    }
    this.renderer.removeClass(this.element.nativeElement, 'active-btn');
    clearTimeout(this.timeout);
    clearInterval(this.secondsInterval);
    this.isLongClickDone = false;
    this.secondsCount = 0;
    this.isTenSubmit = false;
  }

}
