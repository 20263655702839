import {
  ButtonDispenseColdStatesInterface,
  ButtonDispenseHotStatesInterface,
  ButtonDispenseMixStatesInterface,
  ButtonHotwaterStatesInterface,
  IndicatorPurifierStateInterface,
  IndicatorUvStateInterface,
  Pouring,
  SimulatorEntryInterface,
  SimulatorPagesInterface
} from '../../interfaces/simulator-entry';

export class EntryPageIdle {

  private static pouringState;

  public static pouringAudio = new Audio('/assets/audio/pouring.mp3');
  public static boilingAudio = new Audio('/assets/audio/boiling.mp3');


  // HOT water buttons start
  static clickButtonDispenseHot(simulatorEntry: SimulatorEntryInterface, isMute) {
    // simulatorEntry.display.data = 'test';
    if (simulatorEntry.pouring.animation === Pouring.NONE) {
      simulatorEntry.pouring.animation = Pouring.HOT;
      EntryPageIdle.pouringAudio.pause();
      EntryPageIdle.pouringAudio.load();
      EntryPageIdle.pouringAudio.loop = true;
      simulatorEntry.indicator.purifier.state = IndicatorPurifierStateInterface.BLUE;
      simulatorEntry.indicator.uv.state = IndicatorUvStateInterface.OFF;
      if (!isMute) {
        EntryPageIdle.pouringAudio.play(); // done
      }
      EntryPageIdle.pouringState = setTimeout(() => {
        EntryPageIdle.stopDispensing(simulatorEntry);
      }, 4000);

    } else {
      EntryPageIdle.stopDispensing(simulatorEntry);
    }
  }


  static longClickButtonDispenseHotDown(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.pouring = {animation: Pouring.HOT, fast: true};
  }

  static longClickButtonDispenseHotUp(simulatorEntry: SimulatorEntryInterface) {
    EntryPageIdle.stopDispensing(simulatorEntry);
  }

  // HOT water buttons END


  // COlD water buttons start
  static clickButtonDispenseCold(simulatorEntry: SimulatorEntryInterface, isMute) {
    if (simulatorEntry.pouring.animation === Pouring.NONE) {
      simulatorEntry.pouring.animation = Pouring.COLD;
      EntryPageIdle.pouringAudio.pause();
      EntryPageIdle.pouringAudio.load();
      EntryPageIdle.pouringAudio.loop = true;
      simulatorEntry.indicator.purifier.state = IndicatorPurifierStateInterface.BLUE;
      simulatorEntry.indicator.uv.state = IndicatorUvStateInterface.BLUE;
      if (!isMute) {
        EntryPageIdle.pouringAudio.play(); // done
      }
      EntryPageIdle.pouringState = setTimeout(() => {
        EntryPageIdle.stopDispensing(simulatorEntry);

      }, 4000);
    } else {
      EntryPageIdle.stopDispensing(simulatorEntry);
    }
  }

  static longClickButtonDispenseColdDown(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.pouring = {animation: Pouring.COLD, fast: true};
  }

  static longClickButtonDispenseColdUp(simulatorEntry: SimulatorEntryInterface) {
    EntryPageIdle.stopDispensing(simulatorEntry);
  }

  // COLD water buttons end

  // MIX water buttons start
  static clickButtonDispenseMix(simulatorEntry: SimulatorEntryInterface, isMute) {
    if (simulatorEntry.pouring.animation === Pouring.NONE) {
      simulatorEntry.pouring.animation = Pouring.MIX;
      EntryPageIdle.pouringAudio.pause();
      EntryPageIdle.pouringAudio.load();
      EntryPageIdle.pouringAudio.loop = true;
      simulatorEntry.indicator.purifier.state = IndicatorPurifierStateInterface.BLUE;
      simulatorEntry.indicator.uv.state = IndicatorUvStateInterface.BLUE;
      if (!isMute) {
        EntryPageIdle.pouringAudio.play(); // done
      }
      EntryPageIdle.pouringState = setTimeout(() => {
        EntryPageIdle.stopDispensing(simulatorEntry);
      }, 4000);
    } else {
      EntryPageIdle.stopDispensing(simulatorEntry);
    }
  }

  static longClickButtonDispenseMixDown(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.pouring = {animation: Pouring.MIX, fast: true};
  }

  static longClickButtonDispenseMixUp(simulatorEntry: SimulatorEntryInterface) {
    EntryPageIdle.stopDispensing(simulatorEntry);
  }

  // MIX water buttons end


  static stopDispensing(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.pouring.fast = false;
    simulatorEntry.pouring.animation = Pouring.NONE;
    simulatorEntry.indicator.purifier.state = IndicatorPurifierStateInterface.OFF;
    simulatorEntry.indicator.uv.state = IndicatorUvStateInterface.OFF;
    clearTimeout(EntryPageIdle.pouringState);
    EntryPageIdle.pouringAudio.pause();
  }


  static clickButtonSettings(simulatorEntry: SimulatorEntryInterface) {
    if (simulatorEntry.pouring.animation !== Pouring.NONE) {
      EntryPageIdle.stopDispensing(simulatorEntry);
    } else {
      simulatorEntry.page = SimulatorPagesInterface.HC;
      simulatorEntry.display.data = 'HC';
      // buttons
      simulatorEntry.buttons.dispenseCold.state = ButtonDispenseColdStatesInterface.FLASHING;
      simulatorEntry.buttons.dispenseMix.state = ButtonDispenseMixStatesInterface.FLASHING;
      simulatorEntry.buttons.dispenseHot.state = ButtonDispenseHotStatesInterface.FLASHING;
    }
  }


  static clickButtonHotwater(simulatorEntry: SimulatorEntryInterface, isMute) {
    if (simulatorEntry.pouring.animation !== Pouring.NONE) {
      EntryPageIdle.stopDispensing(simulatorEntry);
    } else {
      if (simulatorEntry.buttons.hotWater.state !== ButtonHotwaterStatesInterface.FLASHING) {
        EntryPageIdle.boilingAudio.pause();
        EntryPageIdle.boilingAudio.volume = 0.1;
        EntryPageIdle.boilingAudio.load();
        if (!isMute) {
          EntryPageIdle.boilingAudio.play(); // done
        }
        simulatorEntry.buttons.hotWater.state = ButtonHotwaterStatesInterface.FLASHING;
        setTimeout(() => {
          simulatorEntry.buttons.hotWater.state = ButtonHotwaterStatesInterface.ACTIVE;
        }, 8000);
      }
    }
  }

  static clickButtonPitcher(simulatorEntry: SimulatorEntryInterface, isMute) {
    if (simulatorEntry.pouring.animation === Pouring.NONE) {
      simulatorEntry.pouring.animation = Pouring.COLD;
      EntryPageIdle.pouringAudio.pause();
      EntryPageIdle.pouringAudio.load();
      EntryPageIdle.pouringAudio.loop = true;
      simulatorEntry.indicator.purifier.state = IndicatorPurifierStateInterface.BLUE;
      simulatorEntry.indicator.uv.state = IndicatorUvStateInterface.BLUE;
      if (!isMute) {
        EntryPageIdle.pouringAudio.play(); // done
      }
      EntryPageIdle.pouringState = setTimeout(() => {
        EntryPageIdle.stopDispensing(simulatorEntry);
      }, 8000);
    } else {
      EntryPageIdle.stopDispensing(simulatorEntry);
    }
  }


  static openTechScreen(simulatorEntry: SimulatorEntryInterface) {
    if (simulatorEntry.pouring.animation !== Pouring.NONE) {
      EntryPageIdle.stopDispensing(simulatorEntry);
    } else {
      simulatorEntry.page = SimulatorPagesInterface.AL;
      simulatorEntry.display.data = 'AL';
    }
  }

}
