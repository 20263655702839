import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';

@Component({
  selector: 'app-purifier-and-uv-premium',
  templateUrl: './purifier-and-uv-premium.component.html',
  styleUrls: ['./purifier-and-uv-premium.component.sass']
})
export class PurifierAndUvPremiumComponent implements OnInit {

  public lang = null;

  public topText = {
    he: 'מוקד החלפת מתכלים',
    en: 'Next replacement date'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };


  public bottomText = {
    line_1: {
      he: 'תאריך הניקוי הפנימי',
      en: 'Last internal cleaning'
    },
    line_2: {
      he: 'האחרון',
      en: 'date'
    }
  };

  public uvDate = null;
  public purifierDate = null;

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Purifier and UV premium', lang);
      this.lang = lang;
    });
  }

  ngOnInit() {
    const date = new Date();
    const newDateUV = this.addDays(date, -7);
    const newDatePurifier = this.addDays(date, -30);

    this.uvDate = newDateUV.getDate() + '/' + (newDateUV.getMonth() +1) + '/' + newDateUV.getFullYear();
    this.purifierDate = newDatePurifier.getDate() + '/' + (newDatePurifier.getMonth() +1) + '/' + newDatePurifier.getFullYear();
  }

  private addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }

}
