import {
  ButtonsPremiumState,
  IndicatorAddonStatePremium,
  IndicatorLockStatePremium,
  IndicatorNetStatePremium,
  IndicatorPurifierStatePremium,
  IndicatorUvStatePremium,
  IndicatorWifiStatePremium,
  PouringAnimation,
  SimulatorPremiumPagesInterface
} from '../../interfaces/simulator-premium';
import {Personal} from '../data/personal';
import {Jug} from '../data/jug';
import {WashingTank} from '../data/washingTank';
import {QuantityAndTemp} from '../data/quantityAndTemp';
import {InternalCleaning} from '../data/internalCleaning';
import {ButtonHelper} from '../helpers/buttonHelper';
import {MuteService} from '../../../../mute.service';
import {TechScreen} from '../data/techScreen';

export class HotWaterBtn {


  public static isMute = null;

  constructor(private muteService: MuteService) {
    HotWaterBtn.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      HotWaterBtn.isMute = mute;
    });
  }

  public static shortClick(isMute) {
    HotWaterBtn.isMute = isMute
    switch (ButtonHelper.simulator.page) {

      // screen IDLE (main screen)
      case SimulatorPremiumPagesInterface.IDLE:
      case SimulatorPremiumPagesInterface.ParametersTech:
        ButtonHelper.resetMainAfk();
        ButtonHelper.simulatorHot();

        // finish dispensing and go back to idle screen
        ButtonHelper.afkDispensingWater = setTimeout(() => {
          ButtonHelper.mainSimulatorScreen();
        }, ButtonHelper.DISPENSING_WATER_TIME + 2000);

        break;

      // screen dispensing hot water
      case SimulatorPremiumPagesInterface.HotWaterDispensing:
      case SimulatorPremiumPagesInterface.MixWaterDispensing:
      case SimulatorPremiumPagesInterface.ColdWaterDispensing:
        ButtonHelper.mainSimulatorScreen();
        break;

      // screen Personal
      case SimulatorPremiumPagesInterface.Personal:
        ButtonHelper.startMainAfk();
        Personal.currentUser = Personal.users[ButtonHelper.lang][ButtonHelper.buttonsIndexes.hot + Personal.currentUserPage];
        ButtonHelper.simulatorPersonalDrinks();
        break;

      // screen Personal Drinks
      case SimulatorPremiumPagesInterface.PersonalDrinks:
        ButtonHelper.resetMainAfk();
        ButtonHelper.simulatorDispensingPersonal(ButtonHelper.buttonsIndexes.hot);
        Personal.currentUserPage = 0;
        break;

      // screen Jugs and Pots
      case SimulatorPremiumPagesInterface.Jugs:
        ButtonHelper.simulatorDispensingJugs(ButtonHelper.buttonsIndexes.hot);
        break;

      // screen Activate jug
      case SimulatorPremiumPagesInterface.ActivateJugs:
        ButtonHelper.pouringAudio.load();
        if (Jug.step === 1 && Jug.currentJug.run === SimulatorPremiumPagesInterface.HotWaterDispensing) {
          Jug.step++;
          ButtonHelper.pouringAudio.loop = true;
          if (!HotWaterBtn.isMute) {
            ButtonHelper.pouringAudio.play(); // done
          }
          ButtonHelper.resetMainAfk();
          ButtonHelper.simulatorActivateJug();
          ButtonHelper.simulator.pouring = {animation: PouringAnimation.HOT, fast: false};
        } else if (Jug.step === 2) {
          Jug.step++;
          ButtonHelper.pouringAudio.pause();
          ButtonHelper.startMainAfk();
          ButtonHelper.simulatorActivateJug();
          if (Jug.step == 3) {
            ButtonHelper.simulator.buttons = {
              hot: {active: true, state: ButtonsPremiumState.NO_ICON},
              cold: {active: true, state: ButtonsPremiumState.NO_ICON},
              mix: {active: true, state: ButtonsPremiumState.NO_ICON},
              boiling: {active: true, state: ButtonsPremiumState.BORDER},
              jugs: {active: true, state: ButtonsPremiumState.NO_ICON},
              personal: {active: true, state: ButtonsPremiumState.BORDER}
            };
          }
        }
        break;

      case SimulatorPremiumPagesInterface.Menu:
        ButtonHelper.startMainAfk();
        ButtonHelper.simulatorPreferences();
        break;

      case SimulatorPremiumPagesInterface.WashingTank:
        if (WashingTank.step === 2) {
          WashingTank.isPause = !WashingTank.isPause;
        } else if (WashingTank.step === 3) {
          ButtonHelper.resetMainAfk();
          WashingTank.step++;
          ButtonHelper.simulator.buttons.hot.state = ButtonsPremiumState.ICON_NO_BRIGHT;
          WashingTank.startWashingTank('hot').then(() => {
            ButtonHelper.simulator.buttons = {
              hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
              cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
              mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
              boiling: {active: true, state: ButtonsPremiumState.NO_BORDER},
              jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
              personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
            };

            setTimeout(() => {
              ButtonHelper.mainSimulatorScreen();
            }, 3000);
          });
        } else if (WashingTank.step === 4) {
          WashingTank.isPause = !WashingTank.isPause;
        }
        break;

      case SimulatorPremiumPagesInterface.QuantityAndTemp:
        QuantityAndTemp.currentDrinks = QuantityAndTemp.drinks[ButtonHelper.lang][ButtonHelper.buttonsIndexes.hot];
        QuantityAndTemp.step = 1;
        ButtonHelper.simulator = {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.WaterQuantity,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.FLASH},
            cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        };
        break;

      case SimulatorPremiumPagesInterface.WaterQuantity:
        ButtonHelper.pouringAudio.load();
        if (QuantityAndTemp.step === 1) {
          if (QuantityAndTemp.currentDrinks.run === SimulatorPremiumPagesInterface.HotWaterDispensing) {
            ButtonHelper.simulator.buttons.hot.state = ButtonsPremiumState.ICON_BRIGHT;
            ButtonHelper.simulator.buttons.boiling.state = ButtonsPremiumState.ICON_NO_BRIGHT;
            ButtonHelper.simulator.buttons.personal.state = ButtonsPremiumState.ICON_NO_BRIGHT;
            QuantityAndTemp.step = 2;
            ButtonHelper.simulator.pouring.animation = PouringAnimation.HOT;

            ButtonHelper.pouringAudio.loop = true;
            if (!HotWaterBtn.isMute) {
              ButtonHelper.pouringAudio.play(); // done
            }
          }
          ButtonHelper.resetMainAfk();
        } else if (QuantityAndTemp.step === 2) {
          ButtonHelper.pouringAudio.pause();
          if (QuantityAndTemp.currentDrinks.run === SimulatorPremiumPagesInterface.HotWaterDispensing) {
            ButtonHelper.simulator.buttons = {
              hot: {active: true, state: ButtonsPremiumState.NO_ICON},
              cold: {active: true, state: ButtonsPremiumState.NO_ICON},
              mix: {active: true, state: ButtonsPremiumState.NO_ICON},
              boiling: {active: true, state: ButtonsPremiumState.BORDER},
              jugs: {active: true, state: ButtonsPremiumState.NO_ICON},
              personal: {active: true, state: ButtonsPremiumState.BORDER}
            };
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
            QuantityAndTemp.step = 3;
          }
        }
        break;

      case SimulatorPremiumPagesInterface.PersonalQuantity:
        Personal.currentUser = Personal.users[ButtonHelper.lang][ButtonHelper.buttonsIndexes.hot + Personal.currentUserPage];
        ButtonHelper.simulatorEditPersonalQuantity();
        ButtonHelper.startMainAfk();
        break;

      case SimulatorPremiumPagesInterface.EditPersonalQuantity:
        ButtonHelper.startMainAfk();
        QuantityAndTemp.currentDrinks = Personal.currentUser.drinks[ButtonHelper.buttonsIndexes.hot];
        QuantityAndTemp.step = 1;
        ButtonHelper.simulator = {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.WaterQuantity,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.FLASH},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        };
        break;

      case SimulatorPremiumPagesInterface.Descaling:
        //hot
        ButtonHelper.pouringAudio.loop = true;
        if (InternalCleaning.step === 4) {
          if (!HotWaterBtn.isMute) {
            ButtonHelper.pouringAudio.play(); // done
          }
          InternalCleaning.step = 5;
          ButtonHelper.simulator.pouring.animation = PouringAnimation.HOT;
          ButtonHelper.simulator.buttons = {
            hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
          };
          InternalCleaning.hotWaterDispensing(1200).then(() => {
            ButtonHelper.pouringAudio.pause();
            InternalCleaning.step = 6;
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
            InternalCleaning.statrtBoiling(1000).then(() => {
              ButtonHelper.simulator.buttons = {
                hot: {active: true, state: ButtonsPremiumState.FLASH},
                cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
              };
              InternalCleaning.step = 7;
            });
          });
        } else if (InternalCleaning.step === 5 || InternalCleaning.step === 8 || InternalCleaning.step === 13) {
          if (!InternalCleaning.isPause) {
            ButtonHelper.pouringAudio.pause();
            InternalCleaning.isPause = true;
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
          }
        } else if (InternalCleaning.step === 7) {
          InternalCleaning.step = 8;
          ButtonHelper.simulator.buttons = {
            hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
          };
          ButtonHelper.simulator.pouring.animation = PouringAnimation.HOT;
          ButtonHelper.pouringAudio.load();
          if (!HotWaterBtn.isMute) {
            ButtonHelper.pouringAudio.play(); // done
          }
          InternalCleaning.hotWaterDispensing(1200).then(() => {
            ButtonHelper.pouringAudio.pause();
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
            ButtonHelper.simulator.buttons = {
              hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              boiling: {active: true, state: ButtonsPremiumState.FLASH},
              jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
            };
            InternalCleaning.step = 9;
            ButtonHelper.boilingAudio.load();
            if (!HotWaterBtn.isMute) {
              ButtonHelper.boilingAudio.play(); // done
            }
            InternalCleaning.statrtBoiling(900).then(() => {
              ButtonHelper.boilingAudio.pause();
              ButtonHelper.simulator.buttons = {
                hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
              };
              InternalCleaning.step = 10;
              InternalCleaning.cleaning().then(() => {
                ButtonHelper.simulator.buttons = {
                  hot: {active: true, state: ButtonsPremiumState.NO_ICON},
                  cold: {active: true, state: ButtonsPremiumState.NO_ICON},
                  mix: {active: true, state: ButtonsPremiumState.NO_ICON},
                  boiling: {active: true, state: ButtonsPremiumState.NO_ICON},
                  jugs: {active: true, state: ButtonsPremiumState.NO_ICON},
                  personal: {active: true, state: ButtonsPremiumState.NO_ICON}
                };
                InternalCleaning.step = 11;

                let interval = setTimeout(() => {
                  InternalCleaning.step = 12;
                  ButtonHelper.simulator.buttons = {
                    hot: {active: true, state: ButtonsPremiumState.FLASH},
                    cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                    mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                    boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                    jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
                    personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
                  };
                  clearTimeout(interval);
                }, 3000);
              });
            });
          });
        } else if (InternalCleaning.step === 12) {
          InternalCleaning.step = 13;
          ButtonHelper.simulator.pouring.animation = PouringAnimation.HOT;
          ButtonHelper.pouringAudio.load();
          if (!HotWaterBtn.isMute) {
            ButtonHelper.pouringAudio.play(); // done
          }
          ButtonHelper.simulator.buttons = {
            hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
          };

          InternalCleaning.hotWaterDispensing(1200).then(() => {
            ButtonHelper.pouringAudio.pause();
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
            ButtonHelper.simulator.buttons = {
              hot: {active: true, state: ButtonsPremiumState.NO_ICON},
              cold: {active: true, state: ButtonsPremiumState.NO_ICON},
              mix: {active: true, state: ButtonsPremiumState.NO_ICON},
              boiling: {active: true, state: ButtonsPremiumState.NO_ICON},
              jugs: {active: true, state: ButtonsPremiumState.NO_ICON},
              personal: {active: true, state: ButtonsPremiumState.NO_ICON}
            };
            InternalCleaning.step = 14;
            let interval = setTimeout(() => {
              ButtonHelper.mainSimulatorScreen();
              InternalCleaning.reset();
              clearTimeout(interval);
            }, 3000);
          });
        }
        break;

      case SimulatorPremiumPagesInterface.Sterilization:
        // hot
        if (InternalCleaning.step === 3 || InternalCleaning.step === 6 || InternalCleaning.step === 9 || InternalCleaning.step === 13) {
          if (!InternalCleaning.isPause) {
            InternalCleaning.isPause = true;
            ButtonHelper.pouringAudio.pause();
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
          }
        }
        break;

      case SimulatorPremiumPagesInterface.WashTech:
        TechScreen.washingStep++;
        ButtonHelper.simulator.buttons.hot.state = ButtonsPremiumState.ICON_NO_BRIGHT;
        if (!HotWaterBtn.isMute) {
          ButtonHelper.pouringAudio.play(); // done
        }

        const timeout = setTimeout(() => {
          TechScreen.washingStep++;
          const interval = setTimeout(() => {
            ButtonHelper.mainSimulatorScreen();
            clearTimeout(interval);
            clearTimeout(timeout);
            TechScreen.reset();
          }, 3000)
        }, 6000)
        break;
    }
  }

  public static longClickDown(isMute) {
    switch (ButtonHelper.simulator.page) {

      case SimulatorPremiumPagesInterface.IDLE:
        ButtonHelper.resetMainAfk();
        ButtonHelper.simulatorHot(true);
        break;

      case SimulatorPremiumPagesInterface.ShabbatModeScreen:
        ButtonHelper.resetMainAfk();
        ButtonHelper.mainSimulatorScreen();
        break;
    }
  }

  public static longClickUp(isMute) {
    switch (ButtonHelper.simulator.page) {

      case SimulatorPremiumPagesInterface.HotWaterDispensing:
        ButtonHelper.resetMainAfk();
        ButtonHelper.mainSimulatorScreen();
        break;
    }
  }

}
