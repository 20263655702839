import {
  ButtonsPremiumState,
  IndicatorAddonStatePremium,
  IndicatorLockStatePremium,
  IndicatorNetStatePremium,
  IndicatorPurifierStatePremium,
  IndicatorUvStatePremium,
  IndicatorWifiStatePremium,
  PouringAnimation,
  SimulatorPremiumPagesInterface
} from '../../interfaces/simulator-premium';
import {Personal} from '../data/personal';
import {Jug} from '../data/jug';
import {Setting} from '../data/setting';
import {Maintenance} from '../data/maintenance';
import {WashingTank} from '../data/washingTank';
import {Reset} from '../data/reset';
import {Preference} from '../data/preference';
import {QuantityAndTemp} from '../data/quantityAndTemp';
import {InternalCleaning} from '../data/internalCleaning';
import {ButtonHelper} from '../helpers/buttonHelper';
import {TechScreen} from '../data/techScreen';

export class JugBtn {
  // Jug button short click
  public static shortClick(isMute): void {
    switch (ButtonHelper.simulator.page) {

      // IDLE (main) screen
      case SimulatorPremiumPagesInterface.IDLE:
        ButtonHelper.startMainAfk();
        ButtonHelper.simulatorJugs();
        break;

      // Dispensing Hot, Mix, Cold water
      case SimulatorPremiumPagesInterface.MixWaterDispensing:
      case SimulatorPremiumPagesInterface.HotWaterDispensing:
      case SimulatorPremiumPagesInterface.ColdWaterDispensing:
        ButtonHelper.mainSimulatorScreen();
        break;

      // Personal screen
      case SimulatorPremiumPagesInterface.Personal:
        ButtonHelper.startMainAfk();
        Personal.currentUser = Personal.users[ButtonHelper.lang][ButtonHelper.buttonsIndexes.cold + Personal.currentUserPage];
        ButtonHelper.simulatorPersonalDrinks();
        break;

      // Personal drinks screen
      case SimulatorPremiumPagesInterface.PersonalDrinks:
        ButtonHelper.resetMainAfk();
        ButtonHelper.simulatorDispensingPersonal(ButtonHelper.buttonsIndexes.jugs);
        Personal.currentUserPage = 0;
        break;

      // Jugs screen
      case SimulatorPremiumPagesInterface.Jugs:
        ButtonHelper.simulatorDispensingJugs(ButtonHelper.buttonsIndexes.jugs);
        break;

      // Activate jugs screen
      case SimulatorPremiumPagesInterface.ActivateJugs:
        if (Jug.step === 2) {
          ButtonHelper.resetMainAfk();
          Jug.step++;
          if (Jug.step == 3) {
            ButtonHelper.startMainAfk();
            ButtonHelper.simulator.buttons = {
              hot: {active: true, state: ButtonsPremiumState.NO_ICON},
              cold: {active: true, state: ButtonsPremiumState.NO_ICON},
              mix: {active: true, state: ButtonsPremiumState.NO_ICON},
              boiling: {active: true, state: ButtonsPremiumState.BORDER},
              jugs: {active: true, state: ButtonsPremiumState.NO_ICON},
              personal: {active: true, state: ButtonsPremiumState.BORDER}
            };
          }
        } else if (Jug.step === 4) {
          ButtonHelper.startMainAfk();
          Jug.decTemp();
        }
        break;

      // Menu screen
      case SimulatorPremiumPagesInterface.Menu:
        ButtonHelper.startMainAfk();
        ButtonHelper.simulatorMaintenance();
        break;

      // Settings screen
      case SimulatorPremiumPagesInterface.Settings:
        ButtonHelper.startMainAfk();
        if (!Setting.isEditMode) {
          if (Setting.currentIndex < (Setting.settingsList[ButtonHelper.lang].length - 1)) {
            Setting.currentIndex++;
            Setting.swiper.slideNext();
            if (Setting.currentIndex === (Setting.settingsList[ButtonHelper.lang].length - 1)) {
              ButtonHelper.simulator.buttons.jugs.state = ButtonsPremiumState.NO_BORDER;
            } else {
              ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.BORDER;
            }
          }
        } else if (Setting.isLanguage) {
          if (Setting.currentLanguageIndex < (Setting.languages.length - 1)) {
            Setting.currentLanguageIndex++;
            Setting.languageSwiper.slideNext();
          }
        } else if (Setting.isDateTime) {
          if (!Setting.isSetTime && !Setting.isSetDate) {
            if (Setting.currentDateTimeIndex < (Setting.timeFormat.length - 1)) {
              Setting.currentDateTimeIndex++;
              Setting.dateTimeSwiper.slideNext();
            }
          } else {
            Setting.decTime();
          }
        } else {
          if (Setting.settingsList[ButtonHelper.lang][Setting.currentIndex]['type'] === 1) {
            if (!Setting.isDeactivatedConformation) {
              Setting.settingsList[ButtonHelper.lang][Setting.currentIndex]['value'] = true;
            } else {
              Setting.isDeactivatedConformation = false;
              Setting.isMessageScreen = true;
              ButtonHelper.simulator.buttons = {
                hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
                cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
                mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
                boiling: {active: true, state: ButtonsPremiumState.NO_BORDER},
                jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
                personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
              };

              setTimeout(() => {
                ButtonHelper.afkDispensingWater = setTimeout(() => {
                  ButtonHelper.startMainAfk();
                  ButtonHelper.simulatorSettings();
                }, 3000);
              });
            }
          } else if (Setting.settingsList[ButtonHelper.lang][Setting.currentIndex]['type'] === 2) {
            Setting.settingsList[ButtonHelper.lang][Setting.currentIndex]['value'] = true;
          }
        }
        break;

      // Maintenance screen
      case SimulatorPremiumPagesInterface.Maintenance:
        ButtonHelper.startMainAfk();
        if (Maintenance.currentIndex < (Maintenance.maintenanceList[ButtonHelper.lang].length - 1)) {
          Maintenance.currentIndex++;
          Maintenance.maintenanceSwiper.slideNext();
          if (Maintenance.currentIndex === (Maintenance.maintenanceList[ButtonHelper.lang].length - 1)) {
            ButtonHelper.simulator.buttons.jugs.state = ButtonsPremiumState.NO_BORDER;
          } else {
            ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.BORDER;
          }
        }
        break;

      // Washing tank screen
      case SimulatorPremiumPagesInterface.WashingTank:
        if (WashingTank.step === 2) {
          WashingTank.isPause = !WashingTank.isPause;
        } else if (WashingTank.step === 3) {

        } else if (WashingTank.step === 4) {
          WashingTank.isPause = !WashingTank.isPause;
        }
        break;

      // Shabbat mode conformation screen
      case SimulatorPremiumPagesInterface.ShabbatModeConfirmation:
      case SimulatorPremiumPagesInterface.ShabbatModeConfirmationHome:
        ButtonHelper.resetMainAfk();
        ButtonHelper.shabbatMode();
        break;

      // Reset screen
      case SimulatorPremiumPagesInterface.Reset:
        if (Reset.screen === 1) {
          ButtonHelper.startMainAfk();
          if (Reset.currentIndex < (Reset.resetItems[ButtonHelper.lang].length - 1)) {
            Reset.currentIndex++;
            Reset.resetSwiper.slideNext();
            if (Reset.currentIndex === (Reset.resetItems[ButtonHelper.lang].length - 1)) {
              ButtonHelper.simulator.buttons.jugs.state = ButtonsPremiumState.NO_BORDER;
              ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.BORDER;
            }
          }
        } else if (Reset.screen === 2) {
          ButtonHelper.resetMainAfk();
          Reset.screen = 3;
          ButtonHelper.simulator.buttons.jugs.state = ButtonsPremiumState.NO_BORDER;
          ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.NO_BORDER;
          ButtonHelper.simulator.buttons.boiling.state = ButtonsPremiumState.NO_BORDER;

          ButtonHelper.afkDispensingWater = setTimeout(() => {
            Reset.screen = 4;
            ButtonHelper.afkDispensingWater = setTimeout(() => {
              ButtonHelper.simulatorMaintenance();
              ButtonHelper.startMainAfk();
            }, 3000);
          }, 4500);
        }
        break;

      // Preferences screen
      case SimulatorPremiumPagesInterface.Preferences:
        ButtonHelper.startMainAfk();
        if (Preference.currentIndex < (Preference.preferenceList[ButtonHelper.lang].length - 1)) {
          Preference.currentIndex++;
          Preference.preferenceSwiper.slideNext();
          if (Preference.currentIndex === (Preference.preferenceList[ButtonHelper.lang].length - 1)) {
            ButtonHelper.simulator.buttons.jugs.state = ButtonsPremiumState.NO_BORDER;
            ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.BORDER;
          }
        }
        break;

      // Quantity And Temperature screen
      case SimulatorPremiumPagesInterface.QuantityAndTemp:
        if (!QuantityAndTemp.isJugs) {
          ButtonHelper.startMainAfk();
          QuantityAndTemp.drinks = Jug.jugsList;
          QuantityAndTemp.isJugs = true;
        } else {
          ButtonHelper.startMainAfk();
          QuantityAndTemp.currentDrinks = QuantityAndTemp.drinks[ButtonHelper.lang][ButtonHelper.buttonsIndexes.hot];
          QuantityAndTemp.step = 1;
          ButtonHelper.simulator = {
            indicator: {
              addon: {active: false, state: IndicatorAddonStatePremium.OFF},
              uv: {active: false, state: IndicatorUvStatePremium.OFF},
              wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
              clock: {active: false},
              lock: {active: false, state: IndicatorLockStatePremium.OFF},
              purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
              net: {active: false, state: IndicatorNetStatePremium.OFF}
            },
            page: SimulatorPremiumPagesInterface.WaterQuantity,
            pouring: {fast: false, animation: PouringAnimation.NONE},
            buttons: {
              hot: {active: true, state: ButtonsPremiumState.FLASH},
              cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              boiling: {active: true, state: ButtonsPremiumState.BORDER},
              jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              personal: {active: true, state: ButtonsPremiumState.BORDER}
            }
          };
        }
        break;

      // Water Quantity screen
      case SimulatorPremiumPagesInterface.WaterQuantity:
        if (QuantityAndTemp.step === 4) {
          QuantityAndTemp.decTemp();
        }
        break;

      // Personal Quantity screen
      case SimulatorPremiumPagesInterface.PersonalQuantity:
        Personal.currentUser = Personal.users[ButtonHelper.lang][ButtonHelper.buttonsIndexes.jugs + Personal.currentUserPage];
        ButtonHelper.simulatorEditPersonalQuantity();
        ButtonHelper.startMainAfk();
        break;

      // Edit Personal Quantity screen
      case SimulatorPremiumPagesInterface.EditPersonalQuantity:
        ButtonHelper.startMainAfk();
        QuantityAndTemp.currentDrinks = Personal.currentUser.drinks[ButtonHelper.buttonsIndexes.jugs];
        QuantityAndTemp.step = 1;
        ButtonHelper.simulator = {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.WaterQuantity,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.FLASH},
            cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        };
        break;

      // Internal Cleaning Main screen
      case SimulatorPremiumPagesInterface.InternalCleaningMain:
        ButtonHelper.startMainAfk();
        if (InternalCleaning.currentIndex < (InternalCleaning.cleaningList[ButtonHelper.lang].length - 1)) {
          InternalCleaning.currentIndex++;
          InternalCleaning.internalSwiper.slideNext();
          if (InternalCleaning.currentIndex === (InternalCleaning.cleaningList[ButtonHelper.lang].length - 1)) {
            ButtonHelper.simulator.buttons.jugs.state = ButtonsPremiumState.NO_BORDER;
            ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.BORDER;
          }
        }
        break;

      // Descaling screen
      case SimulatorPremiumPagesInterface.Descaling:
        // jugs
        if (InternalCleaning.step === 5 || InternalCleaning.step === 8 || InternalCleaning.step === 13) {
          if (!InternalCleaning.isPause) {
            InternalCleaning.isPause = true;
            ButtonHelper.pouringAudio.pause();
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
          }
        }
        break;

      // Sterilization screen
      case SimulatorPremiumPagesInterface.Sterilization:
        if (InternalCleaning.step === 3 || InternalCleaning.step === 6 || InternalCleaning.step === 9 || InternalCleaning.step === 13) {
          if (!InternalCleaning.isPause) {
            InternalCleaning.isPause = true;
            ButtonHelper.pouringAudio.pause();
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
          }
        }
        break;

      case SimulatorPremiumPagesInterface.Tech:
        if (TechScreen.currentIndex < (TechScreen.techList[ButtonHelper.lang].length - 1)) {
          TechScreen.currentIndex++;
          TechScreen.techScreenSwiper.slideNext();
          if (TechScreen.currentIndex === (TechScreen.techList[ButtonHelper.lang].length - 1)) {
            ButtonHelper.simulator.buttons.jugs.state = ButtonsPremiumState.NO_BORDER;
            ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.BORDER;
          } else {
            ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.BORDER;
          }
        }
        break;
      case SimulatorPremiumPagesInterface.ChildLockTech:
        TechScreen.defaults.childLock.current = true;
        break;

      case SimulatorPremiumPagesInterface.DebugTech:
        TechScreen.defaults.debug.current = true;
        break;
      case SimulatorPremiumPagesInterface.ParametersTech:
        break;

      case SimulatorPremiumPagesInterface.TempTech:
        if (TechScreen.defaults.temp.current > 0) {
          TechScreen.defaults.temp.current--;
        }
        break;
    }
  }

  public static longClickDown(isMute) {
    switch (ButtonHelper.simulator.page) {
      case SimulatorPremiumPagesInterface.ShabbatModeScreen:
        ButtonHelper.resetMainAfk();
        ButtonHelper.mainSimulatorScreen();
        break;
    }
  }
}
