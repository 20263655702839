import {Component, OnInit} from '@angular/core';
import {
  ButtonDispenseColdStatesInterface,
  ButtonDispenseHotStatesInterface,
  ButtonDispenseMixStatesInterface,
  ButtonHotwaterStatesInterface,
  ButtonPitcherStatesInterface,
  ButtonSettingsStatesInterface,
  IndicatorCleanStateInterface,
  IndicatorPurifierStateInterface,
  IndicatorUvStateInterface,
  IndicatorWifiStateInterface,
  Pouring,
  SimulatorEntryInterface,
  SimulatorPagesInterface,
  SimulatorStatesInterface,
  SimulatorSubpagesInterface,
  TempColdInterface,
  TempHotInterface,
  TempMixInterface
} from '../../interfaces/simulator-entry';

import {EntryPageIdle} from '../pages/idle';
import {EntryPageCH} from '../pages/ch';
import {EntryPageFC} from '../pages/fc';
import {EntryPageAL} from '../pages/al';
import {EntryPageHC} from '../pages/hc';
import {EntryPageES} from '../pages/es';
import {EntryPageCO} from '../pages/co';
import {EntryPageSO} from '../pages/so';
import {EntryPageSL} from '../pages/sl';
import {EntryPageSH} from '../pages/sh';
import {MuteService} from '../../../../mute.service';
import {EntryPageFL} from '../pages/fl';
import {LanguageService} from '../../../../language.service';
import {EntryPageCL} from '../pages/cl';
import {EntryPageEr} from '../pages/er';
import {EntryPageC} from '../pages/c';
import {EntryPageDB} from '../pages/db';
import { EntryPageFCS } from '../pages/fcs';

@Component({
  selector: 'app-dashboard-entry',
  templateUrl: './dashboard-entry.component.html',
  styleUrls: ['./dashboard-entry.component.sass']
})
export class DashboardEntryComponent implements OnInit {

  private afkInterval;
  private settingsInterval;
  public direction: string;
  public simulatorEntry: SimulatorEntryInterface;
  public clickSound: any;
  public PouringAnimation = Pouring;
  public clickAudio = new Audio('/assets/audio/water_droplet.mp3');
  public isMute = null;

  // public tooltipTextList = {
  //   CL: 'CL - Internal CLeaning',
  //   SL: 'SL - SLeep',
  //   SO: 'SO - SOund',
  //   CH: 'CH - Child Lock',
  //   ES: 'ES - Energy saving',
  //   CO: 'CO - COnnectivity',
  //   AL: 'AL - Altitude',
  //   HC: 'HC - Temperture Hot/Cold',
  //   CS: 'CS - Quantity Cup Size',
  //   FL: 'FL - Flushing Mode',
  //   SH: 'SH - Shipping Mode',
  //   FC: 'FC - Factory Mode'
  // };

  tooltipTextList = {
    en: {
      CL: 'CL - Internal CLeaning',
      SL: 'SL - SLeep',
      SO: 'SO - SOund',
      CH: 'CH - Child Lock',
      ES: 'ES - Energy saving',
      CO: 'CO - COnnectivity',
      AL: 'AL - Altitude',
      HC: 'HC - Temperture Hot/Cold',
      CS: 'CS - Quantity Cup Size',
      FL: 'FL - Flushing Mode',
      SH: 'SH - Shipping Mode',
      FC: 'FC - Factory Mode',
      ER: 'Er - Error mode',
      C: 'C - Temperature mode',
      DB: 'db - Debug mode'
    },
    he: {
      CL: 'CL - מצב ניקוי פנימי',
      SL: 'SL - הפעלה וכיבוי “מצב שינה”',
      SO: 'SO - הפעלה וכיבוי צלילי בר המים',
      CH: 'CH - מצב הגנת ילדים',
      ES: 'ES - מצב חסכון באנרגיה',
      CO: 'CO - (Wi-Fi) הפעלה והגדרת הרשת האלחוטית',
      AL: 'AL - גובה',
      HC: 'HC - הגדרת טמפרטורת מים חמים, קרים ופושרים',
      CS: 'CS - הגדרת כמות מים מדודה',
      FL: 'FL - מצב שטיפה',
      SH: 'SH - מצב משלוח',
      FC: 'FC - מצב מפעל',
      ER: 'Er - בדיקת תקלות מספריות',
      C: 'C - בדיקת טמפרטורה במים החמים, קרים ופושרים',
      DB: 'db - עדכוני תוכנה מרחוק'
    }
  };

  public tooltipText = null;

  public instText = {
    en: 'Long press on Menu for 10 sec to enter Technician Mode',
    he: 'לחץ לחיצה ארוכה על תפריט למשך 10 שניות לכניסה למצב טכנאי'
  };


  public initIdle() {
    this.tooltipText = null;
    this.simulatorEntry = {
      buttons: {
        dispenseCold: {active: false, state: ButtonDispenseColdStatesInterface.ACTIVE},
        dispenseMix: {active: false, state: ButtonDispenseMixStatesInterface.ACTIVE},
        dispenseHot: {active: false, state: ButtonDispenseHotStatesInterface.ACTIVE},
        pitcher: {active: false, state: ButtonPitcherStatesInterface.ACTIVE},
        settings: {active: false, state: ButtonSettingsStatesInterface.ACTIVE},
        hotWater: {active: false, state: ButtonHotwaterStatesInterface.ACTIVE},
      },
      indicator: {
        uv: {active: false, state: IndicatorUvStateInterface.OFF},
        purifier: {active: false, state: IndicatorPurifierStateInterface.OFF},
        wifi: {active: false, state: IndicatorWifiStateInterface.BLUE},
        clean: {active: false, state: IndicatorCleanStateInterface.OFF},
      },
      temp: {
        cold: TempColdInterface.t4,
        mix: TempMixInterface.t50,
        hot: TempHotInterface.t99
      },
      quantity: {
        cold: 0,
        mix: 0,
        hot: 0,
        pitcher: 0
      },
      pouring: {animation: Pouring.NONE, fast: false},
      settings: {
        sound: false
      },
      display: {data: '  '},
      state: SimulatorStatesInterface.ON,
      page: SimulatorPagesInterface.IDLE,
      subpage: SimulatorSubpagesInterface.NONE
    };
  }

  public lang = null;

  constructor(private muteService: MuteService, private langService: LanguageService) {
    this.direction = localStorage.getItem('direction');
    this.initIdle();
    // Sounds
    this.clickSound = new Audio();
    this.clickSound.src = '/assets/simulator/entry/sounds/button_click.mp3';
    this.clickSound.load();

    console.groupCollapsed('Simulator');
    console.log('Dashboard: ', this.simulatorEntry);
    console.groupEnd();

    this.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      this.isMute = mute;
    });

    this.lang = langService.getLang();

    this.langService.language.subscribe((lang) => {
      this.lang = lang;
    });

    console.log('isMute', this.isMute);
  }

  ngOnInit() {
  }

  /* Check if user afk 10sec+ and go to IDLE */
  afkIntervalChecker(): void {
    clearInterval(this.afkInterval);
    this.afkInterval = setInterval(() => {
      console.log('interval');
      if (this.simulatorEntry.page !== SimulatorPagesInterface.IDLE) {
        this.pageIdleSet();
        console.log('Turn to IDLE');
      }
    }, 20000);
  }

  // Check if device is locked by user
  lockedChecker(): boolean {
    return this.simulatorEntry.state === SimulatorStatesInterface.LOCKED;
  }

  // Check should we play sounds?
  clickSoundChecker(): void {
    if (this.simulatorEntry.settings.sound) {
      if (!this.isMute) {
        this.clickSound.play(); // done
      }
    }
  }

  // Check is device sleeping now
  sleepChecker(): boolean {
    console.log();
    if (this.simulatorEntry.state === SimulatorStatesInterface.SLEEP) {
      if (this.simulatorEntry.display.data !== 'SL') {
        // alert('Now device not sleeping!');
        this.simulatorEntry.state = SimulatorStatesInterface.ON;
        this.simulatorEntry.indicator.wifi.state = IndicatorWifiStateInterface.BLUE;
      }
      return false;
    }
    return true;
  }

  /* Pages */
  pageIdleSet(): void {
    this.tooltipText = null;
    this.simulatorEntry.page = SimulatorPagesInterface.IDLE;
    this.simulatorEntry.subpage = SimulatorSubpagesInterface.NONE;
    this.simulatorEntry.state = SimulatorStatesInterface.ON;
    this.simulatorEntry.display.data = '  ';
    this.simulatorEntry.buttons.dispenseCold.state = ButtonDispenseColdStatesInterface.OFF;
    this.simulatorEntry.buttons.dispenseMix.state = ButtonDispenseMixStatesInterface.OFF;
    this.simulatorEntry.buttons.dispenseHot.state = ButtonDispenseHotStatesInterface.OFF;

    this.simulatorEntry.buttons = {
      dispenseCold: {active: false, state: ButtonDispenseColdStatesInterface.ACTIVE},
      dispenseMix: {active: false, state: ButtonDispenseMixStatesInterface.ACTIVE},
      dispenseHot: {active: false, state: ButtonDispenseHotStatesInterface.ACTIVE},
      pitcher: {active: false, state: ButtonPitcherStatesInterface.ACTIVE},
      settings: {active: false, state: ButtonSettingsStatesInterface.ACTIVE},
      hotWater: {active: false, state: ButtonHotwaterStatesInterface.ACTIVE},
    }
  }


  /* Buttons */
  clickButtonSettings(): void {
    this.afkIntervalChecker();
    if (this.lockedChecker()) {
      // alert('Locked');
      return;
    }
    this.clickSoundChecker();
    if (!this.sleepChecker()) {
      return;
    }
    this.clickAudio.pause();
    this.clickAudio.load();
    if (!this.isMute) {
      this.clickAudio.play(); // done
    }

    switch (this.simulatorEntry.page) {
      // case SimulatorPagesInterface.IDLE:
      //   EntryPageIdle.clickButtonSettings(this.simulatorEntry);
      //   this.tooltipText = this.tooltipTextList[this.lang].HC;
      //   break;
      case SimulatorPagesInterface.HC:
        if (!EntryPageHC.clickButtonSettings(this.simulatorEntry)) {
          this.simulatorEntry.page = SimulatorPagesInterface.CS;
          this.simulatorEntry.display.data = 'CS';
          this.tooltipText = this.tooltipTextList[this.lang].CS;
        }
        break;
      case SimulatorPagesInterface.CS:
        EntryPageCH.initial(this.simulatorEntry);
        this.tooltipText = this.tooltipTextList[this.lang].CH;
        break;
      case SimulatorPagesInterface.CH:
        if (!EntryPageCH.clickButtonSettings(this.simulatorEntry)) {
          EntryPageES.initial(this.simulatorEntry);
          this.tooltipText = this.tooltipTextList[this.lang].ES;
        }
        break;
      case SimulatorPagesInterface.ES:
        if (!EntryPageES.clickButtonSettings(this.simulatorEntry)) {
          EntryPageCO.initial(this.simulatorEntry);
          this.tooltipText = this.tooltipTextList[this.lang].CO;
        } else {
          this.pageIdleSet();
        }
        break;
      case SimulatorPagesInterface.CO:
        if (!EntryPageCO.clickButtonSettings(this.simulatorEntry)) {
          EntryPageSO.initial(this.simulatorEntry);
          this.tooltipText = this.tooltipTextList[this.lang].SO;
        } else {
          this.pageIdleSet();
        }
        break;
      case SimulatorPagesInterface.SO:
        if (!EntryPageSO.clickButtonSettings(this.simulatorEntry)) {
          EntryPageSL.initial(this.simulatorEntry);
          this.tooltipText = this.tooltipTextList[this.lang].SL;
        } else {
          this.pageIdleSet();
        }
        break;
      case SimulatorPagesInterface.SL:
        if (!EntryPageSL.clickButtonSettings(this.simulatorEntry)) {
          this.tooltipText = this.tooltipTextList[this.lang].CL;
          EntryPageCL.initial(this.simulatorEntry);
        } else {
          // don't do anything
          this.pageIdleSet();
        }
        break;

      case SimulatorPagesInterface.CL:
        if (!EntryPageCL.clickButtonSettings(this.simulatorEntry)) {
          this.tooltipText = this.tooltipTextList[this.lang].HC;
          EntryPageHC.initial(this.simulatorEntry);
        } else {
          // don't do anything
          EntryPageCL.clickButtonSettings(this.simulatorEntry);
          // this.pageIdleSet();
        }
        break;

      // submenu
      case SimulatorPagesInterface.AL:
        if (!EntryPageAL.clickButtonSettings(this.simulatorEntry)) {
          this.tooltipText = this.tooltipTextList[this.lang].FC;
          EntryPageFCS.initial(this.simulatorEntry);
        }
        break;

      case SimulatorPagesInterface.FC:
        if (!EntryPageFC.clickButtonSettings(this.simulatorEntry)) {
          EntryPageAL.initial(this.simulatorEntry);
          this.tooltipText = this.tooltipTextList[this.lang].AL;
        }
        break;
// tech screen
      case SimulatorPagesInterface.FCS:
        // if (!EntryPageFCS.clickButtonSettings(this.simulatorEntry)) {
        //   this.simulatorEntry.page = SimulatorPagesInterface.FL;
        //   this.simulatorEntry.display.data = 'FL';
        // }
        // clearInterval(this.afkInterval);
        EntryPageFL.initial(this.simulatorEntry);
        this.tooltipText = this.tooltipTextList[this.lang].FL;
        break;
      case SimulatorPagesInterface.FL:
        // clearInterval(this.afkInterval);
        EntryPageSH.initial(this.simulatorEntry);
        this.tooltipText = this.tooltipTextList[this.lang].SH;
        break;
      case SimulatorPagesInterface.SH:
        // clearInterval(this.afkInterval);
        // EntryPageFL.initial(this.simulatorEntry);
        // this.tooltipText = this.tooltipTextList[this.lang].FL;
        // EntryPageIdle.openTechScreen(this.simulatorEntry);
        // this.tooltipText = this.tooltipTextList[this.lang].FC;
        clearInterval(this.afkInterval);
        EntryPageEr.initial(this.simulatorEntry);
        this.tooltipText = this.tooltipTextList[this.lang].ER;
        break;
      case SimulatorPagesInterface.ER:
        EntryPageC.initial(this.simulatorEntry);
        this.tooltipText = this.tooltipTextList[this.lang].C;
        break;
      case SimulatorPagesInterface.CC:
        if (!EntryPageC.clickButtonSettings(this.simulatorEntry)) {
          EntryPageDB.initial(this.simulatorEntry);
          this.tooltipText = this.tooltipTextList[this.lang].DB;
        } else {
          EntryPageC.clickButtonSettings(this.simulatorEntry);
        }
        break;
      case SimulatorPagesInterface.DB:
        if (!EntryPageDB.clickButtonSettings(this.simulatorEntry)) {
          EntryPageIdle.openTechScreen(this.simulatorEntry);
          this.tooltipText = this.tooltipTextList[this.lang].AL;
        } else {
          EntryPageDB.clickButtonSettings(this.simulatorEntry);
        }
        break;

    }
  }

  // uncomment probably
  longClickButtonSettings(): void {
    // if (this.lockedChecker()) {
    //   this.pageIdleSet();
    // } else {
    //   EntryPageAL.initial(this.simulatorEntry);
    // }
    // if (!this.sleepChecker()) {
    //   return;
    // }
  }

  clickButtonPitcher(): void {
    this.clickAudio.pause();
    this.clickAudio.load();
    if (!this.isMute) {
      this.clickAudio.play(); // done
    }
    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.IDLE:
        EntryPageIdle.clickButtonPitcher(this.simulatorEntry, this.isMute);
        break;
      case SimulatorPagesInterface.DB:
        EntryPageDB.clickPotButton(this.simulatorEntry);
        break;
    }
  }

  clickButtonHotwater(): void {
    this.clickAudio.pause();
    this.clickAudio.load();
    if (!this.isMute) {
      this.clickAudio.play(); // done
    }
    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.IDLE:
        EntryPageIdle.clickButtonHotwater(this.simulatorEntry, this.isMute);
        break;
    }
  }


  // DISPENSE COLD WATER START
  /**
   * Short click
   */
  clickButtonDispenseCold(): void {
    this.clickAudio.pause();
    this.clickAudio.load();
    if (!this.isMute) {
      this.clickAudio.play(); // done
    }
    clearInterval(this.afkInterval);
    if (this.lockedChecker()) {
      //alert('Locked');
      return;
    }
    this.clickSoundChecker();
    if (!this.sleepChecker()) {
      return;
    }

    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.IDLE:
        EntryPageIdle.clickButtonDispenseCold(this.simulatorEntry, this.isMute);
        break;

      case SimulatorPagesInterface.HC:
        EntryPageHC.clickButtonDispenseCold(this.simulatorEntry);
        break;
      case SimulatorPagesInterface.CO:
        EntryPageCO.clickButtonDispenseCold(this.simulatorEntry);
        break;
      case SimulatorPagesInterface.SO:
        EntryPageSO.clickButtonDispenseCold(this.simulatorEntry);
        break;
      case SimulatorPagesInterface.SL:
        EntryPageSL.clickButtonDispenseCold(this.simulatorEntry);
        break;
      case SimulatorPagesInterface.CL:
        EntryPageCL.coldButtonClick(this.simulatorEntry, this.isMute);
        break;
      case SimulatorPagesInterface.CC:
        EntryPageC.clickColdButton(this.simulatorEntry);
        break;
    }
  }


  /**
   * Long click dispense MIX water
   */
  public longClickButtonDispenseColdDown() {
    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.IDLE:
        EntryPageIdle.longClickButtonDispenseColdDown(this.simulatorEntry);
        break;
      case SimulatorPagesInterface.FCS:
      case SimulatorPagesInterface.SH:
        const dblBeep = new Audio('/assets/audio/double_beep.mp3');
        dblBeep.volume = 0.2;
        dblBeep.play();
        const timeout = setTimeout(() => {
          this.initIdle();
          clearTimeout(timeout);
        }, 3000);
        break;
    }
  }

  /**
   * Up after long click
   */
  public longClickButtonDispenseColdUp() {
    this.clickAudio.pause();
    this.clickAudio.load();
    if (!this.isMute) {
      this.clickAudio.play(); // done
    }
    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.IDLE:
        EntryPageIdle.longClickButtonDispenseColdUp(this.simulatorEntry);
        break;
    }
  }

  // DISPENSE COLD WATER END


  //DISPENSE MIX WATER START
  /**
   * Short click
   */
  clickButtonDispenseMix(): void {
    this.clickAudio.pause();
    this.clickAudio.load();
    if (!this.isMute) {
      this.clickAudio.play(); // done
    }
    clearInterval(this.afkInterval);
    if (this.lockedChecker()) {
      // alert('Locked');
      return;
    }
    this.clickSoundChecker();
    if (!this.sleepChecker()) {
      return;
    }

    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.IDLE:
        EntryPageIdle.clickButtonDispenseMix(this.simulatorEntry, this.isMute);
        break;
      case SimulatorPagesInterface.HC:
        EntryPageHC.clickButtonDispenseMix(this.simulatorEntry);
        break;
    }
  }

  /**
   * Long click dispense MIX water
   */
  public longClickButtonDispenseMixDown() {
    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.IDLE:
        EntryPageIdle.longClickButtonDispenseMixDown(this.simulatorEntry);
        break;
    }
  }

  /**
   * Up after long click
   */
  public longClickButtonDispenseMixUp() {
    this.clickAudio.pause();
    this.clickAudio.load();
    if (!this.isMute) {
      this.clickAudio.play(); // done
    }
    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.IDLE:
        EntryPageIdle.longClickButtonDispenseMixUp(this.simulatorEntry);
        break;
    }
  }

  // DISPENSE MIX WATER END


  // DISPENSE HOT WATER START
  /**
   * click dispense Hot water
   */
  clickButtonDispenseHot(): void {
    this.clickAudio.pause();
    this.clickAudio.load();
    if (!this.isMute) {
      this.clickAudio.play(); // done
    }
    clearInterval(this.afkInterval);
    if (this.lockedChecker()) {
      // alert('Locked');
      return;
    }
    this.clickSoundChecker();
    if (!this.sleepChecker()) {
      return;
    }

    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.IDLE:
        EntryPageIdle.clickButtonDispenseHot(this.simulatorEntry, this.isMute);
        break;
      case SimulatorPagesInterface.HC:
        EntryPageHC.clickButtonDispenseHot(this.simulatorEntry);
        break;
      case SimulatorPagesInterface.CS:
        break;
      case SimulatorPagesInterface.CH:
        EntryPageCH.clickButtonDispenseHot(this.simulatorEntry);
        break;
      case SimulatorPagesInterface.ES:
        EntryPageES.clickButtonDispenseHot(this.simulatorEntry);
        break;
      case SimulatorPagesInterface.SO:
        break;
      case SimulatorPagesInterface.SL:
        break;
      case SimulatorPagesInterface.CL:
        EntryPageCL.hotButtonClick(this.simulatorEntry, this.isMute);
        break;
      case SimulatorPagesInterface.AL:
        break;
      case SimulatorPagesInterface.FC:
        break;
      case SimulatorPagesInterface.FL:
        EntryPageIdle.clickButtonDispenseHot(this.simulatorEntry, this.isMute);
        const beep = setTimeout(() => {
          const dblBeep = new Audio('/assets/audio/double_beep.mp3');
          dblBeep.volume = 0.2;
          dblBeep.play();
          const timeout = setTimeout(() => {
            this.initIdle();
            clearTimeout(timeout);
          }, 3000);
        }, 5000);
        break;
        case SimulatorPagesInterface.CC:
          EntryPageC.clickHotButton(this.simulatorEntry);
          break;
    }
  }

  /**
   * Long click dispense hot water
   */
  public longClickButtonDispenseHotDown() {
    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.IDLE:
        EntryPageIdle.longClickButtonDispenseHotDown(this.simulatorEntry);
        break;
      case SimulatorPagesInterface.FCS:
      case SimulatorPagesInterface.SH:
        const dblBeep = new Audio('/assets/audio/double_beep.mp3');
        dblBeep.volume = 0.2;
        dblBeep.play();
        const timeout = setTimeout(() => {
          this.initIdle();
          clearTimeout(timeout);
        }, 3000);
        break;
    }
  }

  /**
   * Up after long click
   */
  public longClickButtonDispenseHotUp() {
    this.clickAudio.pause();
    this.clickAudio.load();
    if (!this.isMute) {
      this.clickAudio.play(); // done
    }
    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.IDLE:
        EntryPageIdle.longClickButtonDispenseHotUp(this.simulatorEntry);
        break;
    }
  }


  public techScreen() {
    this.afkIntervalChecker();
    this.clickAudio.pause();
    this.clickAudio.load();
    if (!this.isMute) {
      this.clickAudio.play(); // done
    }

    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.IDLE:
        EntryPageIdle.openTechScreen(this.simulatorEntry);
        this.tooltipText = this.tooltipTextList[this.lang].AL;

        // setTimeout(() => {
        //   window['jQuery']('#fcModal').modal();
        // }, 250);
        break;
      default:
        this.initIdle();
        break;
    }
  }

  public fcModalText = {
    en: {
      line_1: 'Press on Hot / Cold buttons to approve Restore Factory Mode.',
      line_2: 'In practice, press Hot, Cold & Menu buttons simultaneously for 3 sec.',
    },
    he: {
      line_1: 'לחץ על מים חמים / קרים על מנת לאשר אתחול הגדרות',
      line_2: 'במציאות נלחץ בו- זמנית על מים חמים, קרים ותפריט למשך 3 שניות',
    }
  };

  public longClickButtonSettingsLong() {
    this.afkIntervalChecker();
    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.FL:
      case SimulatorPagesInterface.SH:
        this.initIdle();
      // default:
      //   if (this.lockedChecker()) {
      //     this.pageIdleSet();
      //   } else {
      //     EntryPageAL.initial(this.simulatorEntry);
      //   }
      //   if (!this.sleepChecker()) {
      //     return;
      //   }
      //   break;
    }
  }

  public clickButtonPitcherDown() {
    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.SH:
        const dblBeep = new Audio('/assets/audio/double_beep.mp3');
        dblBeep.volume = 0.2;
        dblBeep.play();
        const timeout = setTimeout(() => {
          this.initIdle();
          clearTimeout(timeout);
        }, 3000);
        break;
    }
  }

  public clickButtonHotWaterDown() {
    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.SH:
        const dblBeep = new Audio('/assets/audio/double_beep.mp3');
        dblBeep.volume = 0.2;
        dblBeep.play();
        const timeout = setTimeout(() => {
          this.initIdle();
          clearTimeout(timeout);
        }, 3000);
        break;
    }
  }

  public openSettingsScreen(): void {
    this.afkIntervalChecker();
    this.clickAudio.pause();
    this.clickAudio.load();
    if (!this.isMute) {
      this.clickAudio.play(); // done
    }
    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.IDLE:
        EntryPageIdle.clickButtonSettings(this.simulatorEntry);
        this.tooltipText = this.tooltipTextList[this.lang].HC;
        break;
      default:
        break;
    }
  }


  public goBackMenu() {
    switch (this.simulatorEntry.page) {
      case SimulatorPagesInterface.HC:
      case SimulatorPagesInterface.CS:
      case SimulatorPagesInterface.CH:
      case SimulatorPagesInterface.ES:
      case SimulatorPagesInterface.CO:
      case SimulatorPagesInterface.SO:
      case SimulatorPagesInterface.SL:
      case SimulatorPagesInterface.CL:
      // submenu
      case SimulatorPagesInterface.AL:
      case SimulatorPagesInterface.FC:
// tech screen
      case SimulatorPagesInterface.FCS:
      case SimulatorPagesInterface.FL:
      case SimulatorPagesInterface.SH:
      case SimulatorPagesInterface.ER:
      case SimulatorPagesInterface.CC:
      case SimulatorPagesInterface.DB:
          this.initIdle();
          break;
    }
  }

}
