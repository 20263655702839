import {Component, Input, OnInit} from '@angular/core';
import {SimulatorPremiumPagesInterface} from '../../../interfaces/simulator-premium';

@Component({
  selector: 'app-display-premium',
  templateUrl: './display-premium.component.html',
  styleUrls: ['./display-premium.component.sass']
})
export class DisplayPremiumComponent implements OnInit {

  @Input() page: SimulatorPremiumPagesInterface;
  public pages: any;

  constructor() {
    this.pages = SimulatorPremiumPagesInterface;
  }

  ngOnInit() {
  }

}
