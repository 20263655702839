import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-temperature-scale',
  templateUrl: './temperature-scale.component.html',
  styleUrls: ['./temperature-scale.component.sass']
})
export class TemperatureScaleComponent implements OnInit {

  @Input() pointer: number;
  @Input() height: number;
  @Input() class: string;

  constructor() { }

  ngOnInit() {
  }

}
