import {Component, OnInit} from '@angular/core';
import {ButtonsPremiumState, PouringAnimation} from '../../interfaces/simulator-premium';
import {Boiling} from '../data/boiling';
import {LanguageService} from '../../../../language.service';
import {ButtonHelper} from '../helpers/buttonHelper';
import {HotWaterBtn} from '../buttons/hot-water-btn';
import {MixWaterBtn} from '../buttons/mix-water-btn';
import {ColdWaterBtn} from '../buttons/cold-water-btn';
import {BoilingBtn} from '../buttons/boiling-btn';
import {JugBtn} from '../buttons/jug-btn';
import {PersonalBtn} from '../buttons/personal-btn';
import {MuteService} from '../../../../mute.service';

@Component({
  selector: 'app-dashboard-premium',
  templateUrl: './dashboard-premium.component.html',
  styleUrls: ['./dashboard-premium.component.sass']
})
export class DashboardPremiumComponent implements OnInit {

  public helper = null;
  public PouringAnimation = PouringAnimation;
  public audioButtonPress = new Audio('/assets/audio/water_droplet.mp3');

  public isMute = null;
  public lang = null;

  public instText = {
    en: 'Long press on Menu for 5 sec to enter Technician Mode',
    he:'לחץ לחיצה כדי להגיע לתפריט הראשי ולחיצה ארוכה על תפריט למשך 5 שניות לכניסה למצב טכנאי'
  };

  constructor(private boiling: Boiling, private langService: LanguageService, private muteService: MuteService) {
    this.helper = ButtonHelper;
    ButtonHelper.lang = langService.getLang();
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Premium dashboard', lang);
      ButtonHelper.lang = lang;
      this.lang = lang;
    });

    ButtonHelper.mainSimulatorScreen();

    this.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      this.isMute = mute;
    });

    // listen when boiling is finish;
    this.boiling.isBoiling.subscribe((isBoiling) => {
      if (!isBoiling) {
        ButtonHelper.simulator.buttons.boiling.state = ButtonsPremiumState.ICON_BRIGHT;
        ButtonHelper.afkDispensingWater = setTimeout(() => {
          ButtonHelper.mainSimulatorScreen();
        }, ButtonHelper.DISPENSING_WATER_TIME);
      }
    });
  }

  ngOnInit() {
    this.audioButtonPress.load();
  }

  public play() { // done
    if (!this.isMute) {
      this.audioButtonPress.pause();
      this.audioButtonPress.load();
      this.audioButtonPress.play(); // done
    }
  };

  /* hot water button short press */
  public hotWaterShort(): void {
    this.play(); //done
    HotWaterBtn.shortClick(this.isMute);
  }

  /* hot water button long press down */
  public hotWaterLongDown(): void {
    console.log("HERE");
    HotWaterBtn.longClickDown(this.isMute);
  }

  /* hot water button long press up */
  public hotWaterLongUp(): void {
    this.play(); // done
    HotWaterBtn.longClickUp(this.isMute);
  }

  /* mix water short press */
  public mixWaterShort(): void {
    this.play(); // done
    MixWaterBtn.shortClick(this.isMute);
  }

  /* mix water long press down */
  public mixWaterLongDown(): void {
    MixWaterBtn.longClickDown(this.isMute);
  }

  /* mix water long press up */
  public mixWaterLongUp(): void {
    this.play(); // done
    MixWaterBtn.longClickUp(this.isMute);
  }

  /* cold water short press */
  public coldWaterShort(): void {
    this.play(); // done
    ColdWaterBtn.shortClick(this.isMute);
  }

  /* cold water long press down end */
  public coldWaterLongDown() {
    ColdWaterBtn.longClickDown(this.isMute);
  }

  /* cold water long press up */
  public coldWaterLongUp(): void {
    this.play(); // done
    ColdWaterBtn.longClickUp(this.isMute);
  }

  //short click boilingProgress
  public boilingShort(): void {
    this.play(); // done
    BoilingBtn.shortClick(this.isMute);
  }

  public boilingLongDown() {
    BoilingBtn.longClickDown(this.isMute);
  }

  //short click jugs
  public jugsShort(): void {
    this.play(); // done
    JugBtn.shortClick(this.isMute);

  }

  public jugsLongDown() {
    this.play(); // done
    JugBtn.longClickDown(this.isMute);
  }

  //short click personal
  personalShort(): void {
    this.play(); // done
    PersonalBtn.shortClick(this.isMute);
  }

  //personal long click down
  personalLongDown(): void {
    this.play(); // done
    PersonalBtn.longClickDown(this.isMute);
    console.log('HELLO LONG DOWN');
  }

  // open tech screen
  personalTechScreen(): void {
    console.log('HELLO TECH');
    this.play();
    PersonalBtn.techScreen(this.isMute);
  }

  //multi buttons press
  personalAndBoilingMultiPress(): void {
    this.play(); // done
    PersonalBtn.personalAndBoilingMultiPress(this.isMute);
  }

  personalAndJugsMultiPress(): void {
    this.play(); // done
    PersonalBtn.personalAndJugsMultiPress(this.isMute);
    BoilingBtn.longClickDown(this.isMute);
  }
}
