import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import {QuantityAndTemp} from '../../data/quantityAndTemp';

@Component({
  selector: 'app-quantity-and-temp-premium',
  templateUrl: './quantity-and-temp-premium.component.html',
  styleUrls: ['./quantity-and-temp-premium.component.sass']
})
export class QuantityAndTempPremiumComponent implements OnInit {

  public lang = null;
  public quantity = null;

  public topText = {
    he: 'כמות וטמפרטורה',
    en: 'Quantity & Temp'
  };

  public addButtonText = {
    he: 'הוספה',
    en: 'ADD'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Quantity and temp drinks premium', lang);
      this.lang = lang;
    });
    this.quantity = QuantityAndTemp;
  }

  ngOnInit() {
  }

}
