import {Component, Input, OnInit} from '@angular/core';
import Devices from '../../helpers/devices';
import {LanguageService} from '../../language.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MuteService} from '../../mute.service';

@Component({
  selector: 'app-bars-select',
  templateUrl: './bars-select.component.html',
  styleUrls: ['./bars-select.component.sass']
})
export class BarsSelectComponent implements OnInit {

  public devices = null;
  public devicesShow = null;
  public currentDevice;
  public lang = null;
  @Input() choosenDevice: string;
  @Input() currentMode: string;
  public isEng = false;
  public isMute = null;

  constructor(private langService: LanguageService, private route: ActivatedRoute, private router: Router, private muteService: MuteService) {
    this.lang = this.langService.getLang();
    this.langService.language.subscribe((lang) => {
      console.log('bars select', lang);
      this.lang = lang;
    });
    this.devices = Devices.list;
    if (router.url.substr(0,4) === '/eng') {
      this.devicesShow = this.devices.slice(0,2);
      this.isEng = true;
    } else {
      this.devicesShow = this.devices;
      this.isEng = false;
    }


    // watcher for route
    this.router.events.subscribe((param) => {
      if (param instanceof NavigationEnd) {
        this.devices.map((device) => {
          if (param.url.includes(device.id)) {
            this.currentDevice = device;
            return;
          }
        });
        if (param.url.substr(0,4) === '/eng') {
          this.devicesShow = this.devices.slice(0,2);
          this.isEng = true;
        } else {
          this.devicesShow = this.devices;
          this.isEng = false;
        }
      }
    });

    this.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      this.isMute = mute;
    });
  }

  ngOnInit() {
    this.devices.map((device) => {
      if (device.id === this.choosenDevice) {
        this.currentDevice = device;
        return;
      }
    });
  }

  public changeDevice(key) {
    if (this.currentDevice.id !== key) {
      if (!this.isMute) {
        window['ion'].sound.play('swipe'); // done
      }
    }
  }
}
