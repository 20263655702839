export class BuiltInTest {
  public static step = 0;
  public static progress = 0;
  private static afkTimeout = null;

  public static afk() {
    return new Promise((resolve) => {
      BuiltInTest.step = 0;
      BuiltInTest.afkTimeout = setTimeout(() => {
        resolve();
      }, 30000);
    });
  }

  public static startBuilt() {
    return new Promise((resolve) => {
      clearTimeout(BuiltInTest.afkTimeout);
      BuiltInTest.progress = 0;
      let interval = setInterval(() => {
        if (BuiltInTest.progress < 100) {
          BuiltInTest.progress += 10;
        } else {
          resolve();
          BuiltInTest.reset();
          clearInterval(interval);
        }
      }, 800);
      BuiltInTest.step++;
    });
  }

  public static reset() {
    BuiltInTest.step = 0;
    clearTimeout(BuiltInTest.afkTimeout);
    BuiltInTest.progress = 0;
  }
}
