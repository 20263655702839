import {Component, Input, OnInit} from '@angular/core';
import {IndicatorAddonPremiumInterface, IndicatorAddonStatePremium} from '../../../interfaces/simulator-premium';

@Component({
  selector: 'app-indicator-addon-premium',
  templateUrl: './indicator-addon-premium.component.html',
  styleUrls: ['./indicator-addon-premium.component.sass']
})
export class IndicatorAddonPremiumComponent implements OnInit {

  @Input() data: IndicatorAddonPremiumInterface;
  public state: any;
  constructor() {
    this.state = IndicatorAddonStatePremium;
  }

  ngOnInit() {
  }

}
