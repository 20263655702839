export class Menu {
  public static menuList = {
    he: [
      {
        id: 1,
        name: "הגדרות",
        img: 'settings.svg'
      },
      {
        id: 2,
        name: "העדפות",
        img: 'preferences.svg'
      },
      {
        id: 3,
        name: "תחזוקה",
        img: 'meanteance.svg'
      },
      {
        id: 4,
        name: "תמיכה",
        img: 'support.svg'
      }
    ],
    en: [
      {
        id: 1,
        name: "Settings",
        img: 'settings.svg'
      },
      {
        id: 2,
        name: "Preferences",
        img: 'preferences.svg'
      },
      {
        id: 3,
        name: "Maintenance",
        img: 'meanteance.svg'
      },
      {
        id: 4,
        name: "Support",
        img: 'support.svg'
      }
    ]
  }
}
