import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import {Reset} from '../../data/reset';

@Component({
  selector: 'app-reset-screen-premium',
  templateUrl: './reset-screen-premium.component.html',
  styleUrls: ['./reset-screen-premium.component.sass']
})
export class ResetScreenPremiumComponent implements OnInit {

  public lang = null;
  public reset = null;

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  public topText = {
    he: 'שחזור הגדרות',
    en: 'Reset'
  };

  public textValueYes = {
    he: 'כן',
    en: 'YES'
  };

  public textValueNo = {
    he: 'לא',
    en: 'NO'
  };

  public conformatonFactoryTextTop = {
    he: '',
    en: 'Are you sure you want to'
  };

  public conformatonFactoryTextBottom = {
    he: 'לשחזר הגדרות יצרן?',
    en: 'restore factory settings?'
  };

  public conformatonHeatingTextTop = {
    he: '',
    en: 'Are you sure you want to'
  };

  public conformatonHeatingTextBottom = {
    he: 'לאפס הגדרות חימום חכם?',
    en: 'reset smart heating?'
  };

  public resetingFactorySettingText = {
    line_1: {
      he: 'משחזר',
      en: 'Restoring'
    },
    line_2: {
      he: 'הגדרות יצרן',
      en: 'factory settings'
    }
  };

  public resetingSmartHeatingText = {
    line_1: {
      he: 'מאפס הגדרות',
      en: 'Reseting'
    },
    line_2: {
      he: 'חימום חכם',
      en: 'smart heating'
    }
  };


  public resetingFactroySettingsMessageText = {
    line_1: {
      he: 'הגדרות יצרן',
      en: 'You have successfully',
    },
    line_2: {
      he: 'שוחזרו בהצלחה',
      en: 'restored factory settings'
    }
  };

  public resetingSmartHeatingMessageText = {
    line_1: {
      he: 'הגדרות חימום חכם',
      en: 'You have successfully',
    },
    line_2: {
      he: 'אופסו בהצלחה',
      en: 'reset smart heating'
    }
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Reset premium', lang);
      this.lang = lang;
    });
    this.reset = Reset;
  }

  ngOnInit() {
    Reset.initResetSwiper();
  }

}
