// Energy saving
import {
  ButtonDispenseColdStatesInterface,
  ButtonDispenseHotStatesInterface,
  ButtonDispenseMixStatesInterface,
  SimulatorEntryInterface,
  SimulatorPagesInterface,
  SimulatorStatesInterface
} from '../../interfaces/simulator-entry';

export class EntryPageES {

  static initial(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.page = SimulatorPagesInterface.ES;
    simulatorEntry.display.data = 'ES';
    simulatorEntry.buttons.dispenseHot.state = ButtonDispenseHotStatesInterface.FLASHING;
    simulatorEntry.buttons.dispenseMix.state = ButtonDispenseMixStatesInterface.OFF;
    simulatorEntry.buttons.dispenseCold.state = ButtonDispenseColdStatesInterface.OFF;

  }

  static clickButtonDispenseHot(simulatorEntry: SimulatorEntryInterface) {
    switch (simulatorEntry.display.data) {
      case 'ES':
        simulatorEntry.display.data = 'ON';
        break;
      case 'ON':
        simulatorEntry.display.data = 'OF';
        break;
      case 'OF':
        simulatorEntry.display.data = 'ON';
        break;
    }
  }

  static clickButtonSettings(simulatorEntry: SimulatorEntryInterface) {
    if (simulatorEntry.display.data !== 'ES') {
      return true;
    } else {
      return false;
    }
  }


}
