import Swiper from 'swiper/dist/js/swiper.js';

export class Setting {
  public static settingsList = {
    he: [
      
      {
        id: 1,
        name: 'שעה ותאריך',
        type: 3,
        value: []
      },
      {
        id: 2,
        name: 'חיסכון באנרגיה',
        type: 1,
        value: true,
        defaultValue: true,
      },
      {
        id: 3,
        name: 'הגנת ילדים',
        type: 1,
        value: true,
        defaultValue: true,
      },
      {
        id: 4,
        name: 'התחברות',
        type: 2,
        value: false,
        defaultValue: false,
      },
      // {
      //   id: 4,
      //   name: 'הגנת ילדים',
      //   type: 1,
      //   value: true,
      //   defaultValue: true,
      // },
      // {
      //   id: 4,
      //   name: 'התחברות',
      //   type: 2,
      //   value: false,
      //   defaultValue: false,
      // },
      {
        id: 5,
        name: 'חימום חכם',
        type: 1,
        value: true,
        defaultValue: true,
      },
      {
        id: 6,
        name: 'צלילי מקשים',
        type: 1,
        value: true,
        defaultValue: true,
      },
      {
        id: 7,
        name: 'תאורת מכשיר',
        type: 1,
        value: true,
        defaultValue: true,
      },
      {
        id: 8,
        name: 'מצב לילה',
        type: 1,
        value: true,
        defaultValue: true,
      },
      {
        id: 9,
        name: 'כפתור לחץ ומזוג',
        type: 1,
        value: true,
        defaultValue: true,
      },
      {
        id: 10,
        name: 'הגדרת שפה',
        type: 4,
        value: [],
      }
    ],
    en: [
      {
        id: 1,
        name: 'Time & Date',
        type: 3,
        value: []
      },
      {
        id: 2,
        name: 'Energy Saving',
        type: 1,
        value: true,
        defaultValue: true,
      },
      {
        id: 3,
        name: 'Child Lock',
        type: 1,
        value: true,
        defaultValue: true,
      },
      {
        id: 4,
        name: 'Connectivity',
        type: 2,
        value: false,
        defaultValue: false,
      },
      // {
      //   id: 4,
      //   name: 'Child Lock',
      //   type: 1,
      //   value: true,
      //   defaultValue: true,
      // },
      // {
      //   id: 4,
      //   name: 'Connectivity',
      //   type: 2,
      //   value: false,
      //   defaultValue: false,
      // },
      {
        id: 5,
        name: 'Smart Heating',
        type: 1,
        value: true,
        defaultValue: true,
      },
      {
        id: 6,
        name: 'Buttons Sound',
        type: 1,
        value: true,
        defaultValue: true,
      },
      {
        id: 7,
        name: 'Lighting',
        type: 1,
        value: true,
        defaultValue: true,
      },
      {
        id: 8,
        name: 'Night Mode',
        type: 1,
        value: true,
        defaultValue: true,
      },
      {
        id: 9,
        name: 'Push to drinks',
        type: 1,
        value: true,
        defaultValue: true,
      },
      {
        id: 10,
        name: 'Language',
        type: 4,
        value: []
      }
    ]
  };

  public static swiper = null;
  public static currentIndex = 3;
  public static isEditMode = false;
  public static isDeactivatedConformation = false;
  public static isMessageScreen = false;
  public static isConnectivityScreen = false;
  public static isLanguage = false;
  public static languages = [
    {
      id: 1,
      name: 'English'
    },
    {
      id: 2,
      name: 'עברית'
    }
  ];
  public static currentLanguageIndex = 0;
  public static languageSwiper = null;
  public static isLanguageMessage = false;

  public static isDateTime = false;

  public static timeFormat = [
    {
      id: 1,
      name: '12H'
    },
    {
      id: 2,
      name: '24H'
    }
  ];

  public static dateTimeSwiper = null;
  public static currentDateTimeIndex = 0;

  public static isSetTime = false;
  public static currentTimePos = 0;

  public static isSetDate = false;
  public static currentDatePos = 0;

  public static reset() {
    Setting.settingsList.en.map((item) => {
      if (item.type < 3) {
        item.value = item.defaultValue;
      }
    });

    Setting.settingsList.he.map((item) => {
      if (item.type < 3) {
        item.value = item.defaultValue;
      }
    });

    Setting.currentIndex = 3;
    Setting.isEditMode = false;
    Setting.isDeactivatedConformation = false;
    Setting.isMessageScreen = false;
    Setting.isConnectivityScreen = false;
    Setting.isLanguage = false;
    Setting.currentLanguageIndex = 0;
    Setting.isLanguageMessage = false;
    Setting.isDateTime = false;
    Setting.currentDateTimeIndex = 0;
    Setting.isSetTime = false;
    Setting.currentTimePos = 0;
    Setting.isSetDate = false;
    Setting.currentDatePos = 0;
  }


  public static initDateTimeSwiper() {
    setTimeout(() => {
      Setting.dateTimeSwiper = new Swiper('#dateTime', {
        direction: 'vertical',
        loop: false,
        slidesPerView: 5,
        spaceBetween: 0,
        simulateTouch: false,
        autoHeight: true,
        height: 280,
        centeredSlides: true,
        watchOverflow: false,
        slidesPerColumn: 1,
        normalizeSlideIndex: true,
      });
    }, 0);
  }

  public static initLanguageSwiper() {
    setTimeout(() => {
      Setting.languageSwiper = new Swiper('#language', {
        direction: 'vertical',
        loop: false,
        slidesPerView: 5,
        spaceBetween: 0,
        simulateTouch: false,
        autoHeight: true,
        height: 280,
        centeredSlides: true,
        watchOverflow: false,
        slidesPerColumn: 1,
        normalizeSlideIndex: true,
      });
    }, 0);
  }

  public static initSwiper() {
    setTimeout(() => {
      Setting.swiper = new Swiper('#settings', {
        direction: 'vertical',
        loop: false,
        slidesPerView: 5,
        spaceBetween: 0,
        simulateTouch: false,
        autoHeight: true,
        height: 280,
        centeredSlides: true,
        watchOverflow: false,
        slidesPerColumn: 1,
        normalizeSlideIndex: true,
        initialSlide: 3
      });

      // Setting.currentIndex++;
      // Setting.swiper.slideNext();
    }, 0);
  }

  // date time part
  public static currentHour = null;
  public static currentMinutes = null;
  public static currentPostfix = null;
  public static postfix = 'AM';
  public static isTimeMessage = false;
  public static isDate = false;
  public static currentDate = null;
  public static currentMonth = null;
  public static currentYear = null;
  public static isDateMessage = false;

  public static initTime() {
    let date = new Date();
    Setting.getTimeHour(date);
    Setting.getTimeMinutes(date);
    Setting.getPostfix(date);
    Setting.currentTimePos = 1;
    Setting.isTimeMessage = false;
    Setting.isDateMessage = false;
    Setting.isDate = false;
    Setting.getDate(date);
    Setting.getMonth(date);
    Setting.getYear(date);
  }

  private static getTimeHour(date: any) {
    Setting.currentHour = date.getHours();
    if (Setting.currentDateTimeIndex === 0) {
      if (Setting.currentHour > 12) {
        Setting.currentHour = Setting.currentHour - 12;
      }
    }
  }


  private static getTimeMinutes(date: any) {
    Setting.currentMinutes = date.getMinutes();
  }

  private static getPostfix(date: any) {
    let hours = date.getHours();
    if (Setting.currentDateTimeIndex === 0) {
      if (hours > 12) {
        Setting.currentPostfix = 'PM';
      } else {
        Setting.currentPostfix = 'AM';
      }
    } else {
      Setting.currentPostfix = null;
    }
  }

  private static getDate(date: any) {
    Setting.currentDate = date.getDate();
  }

  private static getMonth(date: any) {
    Setting.currentMonth = date.getMonth() + 1;
  }

  private static getYear(date: any) {
    Setting.currentYear = date.getFullYear();
  }

  public static incTimePosition() {
    if (!Setting.isDate) {
      if (!Setting.isTimeMessage) {
        if (Setting.currentDateTimeIndex === 0) {
          if (Setting.currentTimePos + 1 <= 3) {
            Setting.currentTimePos++;
          } else {
            Setting.isTimeMessage = true;
          }
        } else {
          if (Setting.currentTimePos + 1 <= 2) {
            Setting.currentTimePos++;
          } else {
            Setting.isTimeMessage = true;
          }
        }
      } else {
        Setting.isDate = true;
        Setting.isSetTime = false;
        Setting.isSetDate = true;
        Setting.currentDatePos = 1;
        Setting.isTimeMessage = false;
      }
    } else {
      if (!Setting.isDateMessage) {
        if (Setting.currentDatePos + 1 <= 3) {
          Setting.currentDatePos++;
        } else {
          Setting.isDateMessage = true;
        }
      }
    }
  }

  public static decTimePosition() {
    if (!Setting.isDate) {
      if (!Setting.isTimeMessage) {
        if (Setting.currentTimePos - 1 > 0) {
          Setting.currentTimePos--;
        } else {
          Setting.isSetTime = false;
          Setting.currentTimePos = 0;
          Setting.initDateTimeSwiper();
        }
      } else {
        Setting.isTimeMessage = false;
        Setting.currentTimePos = 1;
      }
    } else {
      if (!Setting.isDateMessage) {
        if (Setting.currentDatePos - 1 > 0) {
          Setting.currentDatePos--;
        } else {
          Setting.isSetDate = false;
          Setting.isSetTime = true;
          Setting.isDate = false;
          Setting.isDateMessage = false;
          Setting.currentTimePos = 1;
        }
      } else {
        Setting.isSetDate = true;
        Setting.isSetTime = false;
        Setting.isDate = true;
        Setting.isDateMessage = false;
        Setting.currentDatePos = 1;
      }
    }
  }

  public static incTime() {
    if (!Setting.isDate) {
      if (Setting.currentTimePos === 1) {
        if (Setting.currentDateTimeIndex === 0) {
          if (Setting.currentHour + 1 <= 12) {
            Setting.currentHour++;
          } else {
            Setting.currentHour = 1;
          }
        } else {
          if (Setting.currentHour + 1 <= 23) {
            Setting.currentHour++;
          } else {
            Setting.currentHour = 0;
          }
        }
      } else if (Setting.currentTimePos === 2) {
        if (Setting.currentMinutes + 1 <= 59) {
          Setting.currentMinutes++;
        } else {
          Setting.currentMinutes = 0;
        }
      } else {
        if (Setting.postfix === 'AM') {
          Setting.postfix = 'PM';
        } else {
          Setting.postfix = 'AM';
        }
      }
    } else {
      if (Setting.currentDatePos === 1) {
        if (Setting.currentDate + 1 <= Setting.getDateMax()) {
          Setting.currentDate++;
        } else {
          Setting.currentDate = 1;
        }
      } else if (Setting.currentDatePos === 2) {
        if (Setting.currentMonth + 1 <= 12) {
          Setting.currentMonth++;
        } else {
          Setting.currentMonth = 1;
        }
      } else if (Setting.currentDatePos === 3) {
        if (Setting.currentYear + 1 <= 2999) {
          Setting.currentYear++;
        } else {
          Setting.currentYear = 1900;
        }
      }
    }

  }

  public static decTime() {
    if (!Setting.isDate) {
      if (Setting.currentTimePos === 1) {
        if (Setting.currentDateTimeIndex === 0) {
          if (Setting.currentHour - 1 > 0) {
            Setting.currentHour--;
          } else {
            Setting.currentHour = 12;
          }
        } else {
          if (Setting.currentHour - 1 >= 0) {
            Setting.currentHour--;
          } else {
            Setting.currentHour = 23;
          }
        }
      } else if (Setting.currentTimePos === 2) {
        if (Setting.currentMinutes - 1 >= 0) {
          Setting.currentMinutes--;
        } else {
          Setting.currentMinutes = 59;
        }
      } else {
        if (Setting.postfix === 'AM') {
          Setting.postfix = 'PM';
        } else {
          Setting.postfix = 'AM';
        }
      }
    } else {
      if (Setting.currentDatePos === 1) {
        if (Setting.currentDate - 1 >= 1) {
          Setting.currentDate--;
        } else {
          Setting.currentDate = Setting.getDateMax();
        }
      } else if (Setting.currentDatePos === 2) {
        if (Setting.currentMonth - 1 > 0) {
          Setting.currentMonth--;
        } else {
          Setting.currentMonth = 12;
        }
      } else if (Setting.currentDatePos === 3) {
        if (Setting.currentYear -1 > 1900) {
          Setting.currentYear--;
        } else {
          Setting.currentYear = 2999;
        }
      }
    }
  }


  private static getDateMax() {
    let date = 1;
    switch (Setting.currentMonth) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        date = 31;
        break;
      case 4:
      case 6:
      case 9:
      case 11:
        date = 30;
        break;
      case 2:
        date = Setting.currentYear % 4 === 0 ? 29 : 28;
        break;
    }
    return date;
  }
}
