import {Component, Input, OnInit} from '@angular/core';
import {Button} from '../../../shared/button/button.component';
import {ButtonDispensingBoilingPremiumInterface, ButtonsPremiumState} from '../../../interfaces/simulator-premium';

@Component({
  selector: 'app-button-boiling-premium',
  templateUrl: './button-boiling-premium.component.html',
  styleUrls: ['./button-boiling-premium.component.sass']
})
export class ButtonBoilingPremiumComponent extends Button implements OnInit {

  @Input() data: ButtonDispensingBoilingPremiumInterface;
  public state: any;

  constructor() {
    super();
    this.state = ButtonsPremiumState;
  }

  ngOnInit() {
  }

}
