import {Component, Input, OnInit} from '@angular/core';
import {IndicatorDisplayInterface} from '../../../interfaces/simulator-entry';

@Component({
  selector: 'app-display-entry',
  templateUrl: './display-entry.component.html',
  styleUrls: ['./display-entry.component.sass']
})
export class DisplayEntryComponent implements OnInit {

  @Input() data: IndicatorDisplayInterface
  constructor() {  }

  ngOnInit() {
    if (this.data.data.length > 2) { this.data.data = 'Er'; }
  }

}
