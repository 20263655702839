import Swiper from 'swiper/dist/js/swiper.js';
import {
  ButtonsPremiumState,
  IndicatorAddonStatePremium,
  IndicatorLockStatePremium,
  IndicatorNetStatePremium,
  IndicatorPurifierStatePremium,
  IndicatorUvStatePremium,
  IndicatorWifiStatePremium,
  PouringAnimation,
  SimulatorPremiumPagesInterface
} from '../../interfaces/simulator-premium';

export class TechScreen {
  public static techList = {
    he: [
      {
        id: 1,
        name: 'הגנת ילדים',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.ChildLockTech,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.BORDER},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        }
      },
      {
        id: 2,
        name: 'טמפ\' רתיחה',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.TempTech,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.BORDER},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        }
      },
      {
        id: 3,
        name: 'פרמטרים',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.ParametersTech,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
            jugs: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.ICON_BRIGHT}
          }
        }
      },
      {
        id: 4,
        name: 'ניפוי',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.DebugTech,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.BORDER},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        }
      },
      {
        id: 5,
        name: 'שטיפת מיכל מים חמים',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.WashTech,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.FLASH},
            cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
          }
        }
      },
      {
        id: 6,
        name: 'תקלות',
        page: {
          indicator: {
            addon: {active: true, state: IndicatorAddonStatePremium.OFF},
            uv: {active: true, state: IndicatorUvStatePremium.OFF},
            wifi: {active: true, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: true, state: IndicatorLockStatePremium.OFF},
            purifier: {active: true, state: IndicatorPurifierStatePremium.OFF},
            net: {active: true, state: IndicatorNetStatePremium.OFF}
          },
          pouring: {fast: false, animation: PouringAnimation.NONE},
          page: SimulatorPremiumPagesInterface.ErrorScreen,
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_ICON},
            cold: {active: true, state: ButtonsPremiumState.NO_ICON},
            mix: {active: true, state: ButtonsPremiumState.NO_ICON},
            boiling: {active: true, state: ButtonsPremiumState.NO_ICON},
            jugs: {active: true, state: ButtonsPremiumState.NO_ICON},
            personal: {active: true, state: ButtonsPremiumState.ICON_BRIGHT}
          }
        }
      },
    ],
    en: [
      {
        id: 1,
        name: 'Child Lock',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.ChildLockTech,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.BORDER},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        }
      },
      {
        id: 2,
        name: 'Boiling Temp',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.TempTech,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.BORDER},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        }
      },
      {
        id: 3,
        name: 'Parameters',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.ParametersTech,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
            jugs: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.ICON_BRIGHT}
          }
        }
      },
      {
        id: 4,
        name: 'Debug',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.DebugTech,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.BORDER},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        }
      },
      {
        id: 5,
        name: 'Wash Hot Tank',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.WashTech,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.FLASH},
            cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
          }
        }
      },
      {
        id: 6,
        name: 'Errors',
        page: {
          indicator: {
            addon: {active: true, state: IndicatorAddonStatePremium.OFF},
            uv: {active: true, state: IndicatorUvStatePremium.OFF},
            wifi: {active: true, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: true, state: IndicatorLockStatePremium.OFF},
            purifier: {active: true, state: IndicatorPurifierStatePremium.OFF},
            net: {active: true, state: IndicatorNetStatePremium.OFF}
          },
          pouring: {fast: false, animation: PouringAnimation.NONE},
          page: SimulatorPremiumPagesInterface.ErrorScreen,
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_ICON},
            cold: {active: true, state: ButtonsPremiumState.NO_ICON},
            mix: {active: true, state: ButtonsPremiumState.NO_ICON},
            boiling: {active: true, state: ButtonsPremiumState.NO_ICON},
            jugs: {active: true, state: ButtonsPremiumState.NO_ICON},
            personal: {active: true, state: ButtonsPremiumState.ICON_BRIGHT}
          }
        }
      },
    ]
  };

  public static techScreenSwiper = null;
  public static currentIndex = 0;

  public static defaults = {
    childLock: {
      default: false,
      current: false
    },
    debug: {
      default: false,
      current: false
    },
    temp: {
      default: 96,
      current: 96
    }
  };

  public static washingStep = 1;

  public static initTechScreenSwiper() {
    setTimeout(() => {
      TechScreen.techScreenSwiper = new Swiper('#options', {
        direction: 'vertical',
        loop: false,
        slidesPerView: 5,
        spaceBetween: 0,
        simulateTouch: false,
        autoHeight: true,
        height: 280,
        centeredSlides: true,
        watchOverflow: false,
        slidesPerColumn: 1,
        normalizeSlideIndex: true,
        initialSlide: 0
      });
    }, 0);
  }

  public static reset() {
    TechScreen.techScreenSwiper = null;
    TechScreen.currentIndex = 0;
    TechScreen.washingStep = 1;
  }
}
