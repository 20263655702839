import {Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2} from '@angular/core';

@Directive({
  selector: '[appTechScreen]'
})
export class TechScreenDirective {

  @Output() techClickDown: EventEmitter<object> = new EventEmitter();
  @Output() techClickUp: EventEmitter<object> = new EventEmitter();

  private timeout: any;
  private duration = 3000;
  private isLongClickDone = false;

  constructor(private element: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event) {
    this.renderer.addClass(this.element.nativeElement, 'active-btn');
    this.timeout = setTimeout(() => {
      this.isLongClickDone = true;
      this.techClickDown.emit(event);
    }, this.duration);
  }

  @HostListener('mouseup', ['$event']) onMouseUp(event) {
    if (this.isLongClickDone) {
      this.techClickUp.emit(event);
    }
    this.renderer.removeClass(this.element.nativeElement, 'active-btn');
    clearTimeout(this.timeout);
    this.isLongClickDone = false;
  }

  @HostListener('mouseout', ['$event']) onMouseOut(event) {
    if (this.isLongClickDone) {
      this.techClickUp.emit(event);
    }
    this.renderer.removeClass(this.element.nativeElement, 'active-btn');
    clearTimeout(this.timeout);
    this.isLongClickDone = false;
  }

}
