import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.sass']
})
export class ColorPickerComponent implements OnInit {

  public colors = [
    {
      name: 'Blue',
      hex: '#353E57'
    },
    {
      name: 'Black',
      hex: '#1C1C1C'
    },
    {
      name: 'Gray',
      hex: '#B7B2A5'
    },
    {
      name: 'White',
      hex: '#FFFFFF'
    },
    {
      name: 'Brown',
      hex: '#912D2D'
    }
  ];

  public default = this.colors[3];

  constructor() {
  }

  ngOnInit() {

  }

  public changeColor(index: number): void {
    if (this.colors[index]) {
      this.default = this.colors[index];
    }
  }

}
