import {Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2} from '@angular/core';

@Directive({
  selector: '[appShortPress]'
})
export class ShortPressDirective {

  @Output() shortPress: EventEmitter<object> = new EventEmitter();
  @Input() key: string;
  @Input() disabledKey: boolean;
  private duration = 250;
  private timeout: any;
  private isShortClick = true;

  constructor(private element: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
    if (this.isShortClick) {
      if (event.code === this.key) {
        this.renderer.addClass(this.element.nativeElement, 'active-btn');
        this.timeout = setTimeout(() => {
          this.isShortClick = false;
        }, this.duration);
      }
    }
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event) {
    if (event.code === this.key) {
      if (this.isShortClick && !this.disabledKey) {
        this.shortPress.emit(event);
      }
      this.renderer.removeClass(this.element.nativeElement, 'active-btn');
      clearTimeout(this.timeout);
      this.isShortClick = true;
    }
  }

}
