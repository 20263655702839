import {Component, OnDestroy, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import Swiper from 'swiper/dist/js/swiper.js';
import {TechScreen} from '../../data/techScreen';

@Component({
  selector: 'app-debug-tech-premium',
  templateUrl: './debug-tech-premium.component.html',
  styleUrls: ['./debug-tech-premium.component.sass']
})
export class DebugTechPremiumComponent implements OnInit, OnDestroy {

  public lang = null;

  public jugs;

  public text = {
    he: 'טכנאי',
    en: 'Technician'
  };

  public menuText = {
    en: 'Debug',
    he: 'איתור ותיקון שגיאות תוכנה'
  };

  public textValueOn = {
    he: 'מופעל',
    en: 'ON'
  };

  public textValueOff = {
    he: 'מופסק',
    en: 'OFF'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'OK'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  public swiper = null;
  public techScreen = null;

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Debug tech premium', lang);
      this.lang = lang;
    });
    this.techScreen = TechScreen.defaults.debug;
  }

  ngOnInit() {
    this.initSwiper();
  }

  ngOnDestroy(): void {
    TechScreen.defaults.childLock.current = TechScreen.defaults.childLock.default;
  }

  public initSwiper() {
    setTimeout(() => {
      this.swiper = new Swiper('#debug', {
        direction: 'vertical',
        loop: false,
        slidesPerView: 5,
        spaceBetween: 0,
        simulateTouch: false,
        autoHeight: true,
        height: 280,
        centeredSlides: true,
        watchOverflow: false,
        slidesPerColumn: 1,
        normalizeSlideIndex: true,
      });
    }, 0);
  }

}
