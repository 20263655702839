import { Component, OnInit } from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import {Personal} from '../../data/personal';

@Component({
  selector: 'app-personal-quantity-premium',
  templateUrl: './personal-quantity-premium.component.html',
  styleUrls: ['./personal-quantity-premium.component.sass']
})
export class PersonalQuantityPremiumComponent implements OnInit {

  public personal = null;

  public lang = null;

  public text = {
    he: 'פרופיל משתמש',
    en: 'User Profiles'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Personal quantity premium', lang);
      this.lang = lang;
    });
    this.personal = Personal;
  }

  ngOnInit() {
  }

}
