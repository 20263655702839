import { Component, OnInit } from '@angular/core';
import {Personal} from '../../data/personal';
import {LanguageService} from '../../../../../language.service';

@Component({
  selector: 'app-personal-edit-quantity-premium',
  templateUrl: './personal-edit-quantity-premium.component.html',
  styleUrls: ['./personal-edit-quantity-premium.component.sass']
})
export class PersonalEditQuantityPremiumComponent implements OnInit {

  public personal = null;
  public lang = null;

  public leftText = {
    he: 'המשקאות של ' + Personal.currentUser.name,
    en: Personal.currentUser.name + '\'s drinks',
  };

  public rightText = {
    he: 'ערוך',
    en: 'Edit'
  };
  public addButtonText = {
    he: 'הוספה',
    en: 'ADD'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Personal drinks premium', lang);
      this.lang = lang;
    });
    this.personal = Personal;
  }

  ngOnInit() {
  }

}
