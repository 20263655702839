import {
  ButtonDispenseColdStatesInterface,
  ButtonDispenseHotStatesInterface,
  ButtonDispenseMixStatesInterface,
  ButtonHotwaterStatesInterface,
  ButtonPitcherStatesInterface,
  ButtonSettingsStatesInterface,
  SimulatorEntryInterface,
  SimulatorPagesInterface
} from '../../interfaces/simulator-entry';

export class EntryPageDB {
  static isOn = false;
  static isNext = false;
  static initial(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.page = SimulatorPagesInterface.DB;
    simulatorEntry.display.data = 'db';
    simulatorEntry.buttons =  {
      dispenseCold: {active: false, state: ButtonDispenseColdStatesInterface.OFF},
      dispenseMix: {active: false, state: ButtonDispenseMixStatesInterface.OFF},
      dispenseHot: {active: false, state: ButtonDispenseHotStatesInterface.OFF},
      pitcher: {active: false, state: ButtonPitcherStatesInterface.FLASHING},
      settings: {active: false, state: ButtonSettingsStatesInterface.ACTIVE},
      hotWater: {active: false, state: ButtonHotwaterStatesInterface.OFF},
    }

    EntryPageDB.isNext = false;
  }

  static clickButtonSettings(simulatorEntry: SimulatorEntryInterface) {
    if ( EntryPageDB.isNext) {
      EntryPageDB.initial(simulatorEntry);
      return true;
    } else {
      return false;
    }
  }

  static clickPotButton(simulatorEntry: SimulatorEntryInterface) {
    EntryPageDB.isNext = true;
    EntryPageDB.isOn = !EntryPageDB.isOn;
    if (EntryPageDB.isOn) {
      simulatorEntry.display.data = 'ON';
    } else {
      simulatorEntry.display.data = 'OF';
    }
  }

}
