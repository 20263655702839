import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';

@Component({
  selector: 'app-idle-page-premium',
  templateUrl: './idle-page-premium.component.html',
  styleUrls: ['./idle-page-premium.component.sass']
})
export class IdlePagePremiumComponent implements OnInit {

  lang = null;
  time: string;
  date: string;
  postfix = null;

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('IDLE', lang);
      this.lang = lang;
      this.initDate();
      // setInterval(() => this.initDate(), 1000);
    });
  }

  ngOnInit() {
    this.initDate();
    // setInterval(() => this.initDate(), 1000);
  }

  initDate() {
    const currentDateTime = new Date();
    // this.time = this.formatTime('12H', currentDateTime);
    this.time = "11:20";
    this.postfix = "AM";
    this.date = this.formatDateStatic(this.lang);
  }

  private formatTime(format: string, dateTime: Date): string {
    let hours: number = dateTime.getHours();
    const minutes: number = dateTime.getMinutes();

    if (format === '12H') {
      hours = hours > 12 ? (dateTime.getHours() - 12) : hours;
      this.postfix = dateTime.getHours() > 12 ? 'PM' : 'AM';
      return hours.toString() + ':' + (minutes < 10 ? '0' + minutes.toString() : minutes.toString());
    }
    return (hours < 10 ? '0' + hours.toString() : hours.toString()) + ':' + (minutes < 10 ? '0' + minutes.toString() : minutes.toString());
  }

  private formatDate(lang: string, dateTime: Date): string {
    const monthNames = {
      en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      he: ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר']
    };

    const dayNames = {
      en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      he: ['יום רשון', 'יום שיני', 'יום שלישי', 'יום רביעי', 'יום חמישי', 'יום ששי', 'שבת']
    };

    return dayNames[lang][dateTime.getDay()] + ', ' + dateTime.getDate() + ' ' + monthNames[lang][dateTime.getMonth()];
  }

  private formatDateStatic(lang: string): string {
    const monthNames = {
      en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      he: ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר']
    };

    const dayNames = {
      en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      he: ['יום רשון', 'יום שיני', 'יום שלישי', 'יום רביעי', 'יום חמישי', 'יום ששי', 'שבת']
    };

    return dayNames[lang][3] + ', ' + 22 + ' ' + monthNames[lang][6];
  }

}
