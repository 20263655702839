import {EventEmitter, Input, Output} from '@angular/core';

/**
 *
 */
export class Button {
  @Input() public buttonText: string;
  @Input() public className = '';
  @Input() public disable = false;
  @Output() public longClickUp: EventEmitter<object> = new EventEmitter<object>();
  @Output() public longClickDown: EventEmitter<object> = new EventEmitter<object>();
  @Output() public shortClick: EventEmitter<object> = new EventEmitter<object>();
  @Output() public longPressUp: EventEmitter<object> = new EventEmitter<object>();
  @Output() public longPressDown: EventEmitter<object> = new EventEmitter<object>();
  @Output() public shortPress: EventEmitter<object> = new EventEmitter<object>();

  @Output() public techClickUp: EventEmitter<object> = new EventEmitter<object>();
  @Output() public techClickDown: EventEmitter<object> = new EventEmitter<object>();

  @Output() public techPressUp: EventEmitter<object> = new EventEmitter<object>();
  @Output() public techPressDown: EventEmitter<object> = new EventEmitter<object>();
}
