import {Component, Input, OnInit} from '@angular/core';
import {IndicatorUvPremiumInterface, IndicatorUvStatePremium} from '../../../interfaces/simulator-premium';

@Component({
  selector: 'app-indicator-uv-premium',
  templateUrl: './indicator-uv-premium.component.html',
  styleUrls: ['./indicator-uv-premium.component.sass']
})
export class IndicatorUvPremiumComponent implements OnInit {

  @Input() data: IndicatorUvPremiumInterface;
  public state: any;

  constructor() {
    this.state = IndicatorUvStatePremium;
  }

  ngOnInit() {
  }

}
