import { Component, OnInit } from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import {TechScreen} from '../../data/techScreen';

@Component({
  selector: 'app-tech-screen-premium',
  templateUrl: './tech-screen-premium.component.html',
  styleUrls: ['./tech-screen-premium.component.sass']
})
export class TechScreenPremiumComponent implements OnInit {

  public options = null;

  public lang = null;

  public text = {
    he: 'טכנאי',
    en: 'Technician'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'OK'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Tech screen premium', lang);
      this.lang = lang;
      TechScreen.initTechScreenSwiper();
      TechScreen.reset();
    });
    this.options = TechScreen;
  }

  ngOnInit() {
  }

}
