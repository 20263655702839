import {Component, Input, OnInit} from '@angular/core';
import {LanguageService} from '../../language.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {InstructorService} from '../../instructor.service';
import Devices from '../../helpers/devices';
import baron from 'baron';
import {MuteService} from '../../mute.service';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.sass']
})
export class SideNavComponent implements OnInit {

  public lang = null;
  @Input() currentDeviceId: string;
  @Input() currentMode: string;
  public isEng = false;

  public headerNavigation = [
    {
      id: 'instructor',
      name: {
        he: 'מדריך',
        en: 'Manual'
      },
      class: 'default',
    },
    {
      id: 'simulator',
      name: {
        he: 'סימולציה',
        en: 'Simulation'
      },
      class: 'default',
    },
    // {
    //   name: {
    //     he: 'עמוד ראשי',
    //     en: 'Home page'
    //   },
    //   class: 'home',
    //   link: '/'
    // }
  ];

  public instructions = null;
  private currentItem = null;
  private vScroll = null;
  private levelPositions = null;
  public expand = true;
  public isSimulator = false;
  public animation = null;
  public isMute = null;

  constructor(
    private langService: LanguageService,
    private route: ActivatedRoute,
    private router: Router,
    public instService: InstructorService,
    private muteService: MuteService
  ) {
    // watcher for current level positions
    this.instService.levelPositions.subscribe((levelPositions) => {
      this.levelPositions = levelPositions;
      this.changeDevice();
      if (this.currentItem) {
        this.instService.setCurrentSteps(this.getCurrentSteps());
      }

      this.lang = this.langService.getLang();
      this.langService.language.subscribe((lang) => {
        console.log('side-bar-component', lang);
        this.lang = lang;

        // todo uncomment this line when content on english will be ready
        this.changeDevice();
      });
    });
    if (router.url.substr(0,4) === '/eng') {
      this.isEng = true;
    } else {
      this.isEng = false;
    }


    this.lang = this.langService.getLang();
    this.langService.language.subscribe((lang) => {
      console.log('side-nav-component', lang);
      this.lang = lang;
      this.instructions = this.instService.getInstructor()[this.currentDeviceId][this.lang];
    });

    // watcher for route
    this.router.events.subscribe((param) => {
      if (param instanceof NavigationEnd) {

        if (param.url.substr(0,4) === '/eng') {
          this.isEng = true;
        } else {
          this.isEng = false;
        }
        this.headerNavigation.map((link) => {
          if (param.url.includes(link.id)) {
            this.currentMode = link.id;

          }
        });
        // this.instService.setLevelPositions({level_1: 0, level_2: 0, level_3: 0});
        // this.changeDevice();
        // this.instService.setCurrentDevice(this.route.root.firstChild.params['value']['device']);
        // console.log(this.route.root)
        this.getItemIdFromUrl(param.url.substr(param.url.lastIndexOf('/') + 1));

        Devices.list.map((device) => {
          if (param.url.includes(device.id)) {
            this.currentDeviceId = device.id;
            this.instructions = this.instService.getInstructor()[this.currentDeviceId][this.lang];
            return;
          }
        });
      }
    });

    this.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      this.isMute = mute
    })
  }


  private changeDevice(): void {
    this.currentItem = this.instructions[this.levelPositions.level_1];
    this.currentItem.show = true;
    this.currentItem.expand = true;
    if (this.currentItem.children) {
      this.currentItem.children[this.levelPositions.level_2].show = true;
      this.currentItem.children[this.levelPositions.level_2].expand = true;
    }


    setTimeout(() => {
      this.instService.setCurrentSteps(this.getCurrentSteps());
    }, 0);
  }

  ngOnInit() {
    this.instructions = this.instService.getInstructor()[this.currentDeviceId][this.lang];

    setTimeout(() => {
      this.vScroll = baron({
        root: '.baron-chapters',
        scroller: '.baron-chapters__scroller',
        bar: '.baron-chapters__bar',
      });
    }, 0);

    if (this.currentMode === 'simulator') {
      this.isSimulator = true;
    }
  }

  private getItemIdFromUrl(url) {
    if (url === 'instructor' || url === 'simulator') {
      this.isSimulator = false;
      this.clearAllAttr();
      if (url === 'simulator') {
        this.isSimulator = true;
      }
    }
  };

  private getCurrentSteps() {
    this.currentItem = this.instructions[this.levelPositions.level_1];
    this.clearActiveAttr();
    if (this.currentItem.steps) {
      this.currentItem.active = true;
      this.currentItem.expand = true;
      return this.currentItem;
    } else if (this.currentItem.children[this.levelPositions.level_2].steps) {
      this.currentItem.active = true;
      this.currentItem.expand = true;
      this.currentItem.children[this.levelPositions.level_2].active = true;
      return this.currentItem.children[this.levelPositions.level_2];
    } else {
      this.currentItem.active = true;
      this.currentItem.expand = true;
      this.currentItem.children[this.levelPositions.level_2].active = true;
      this.currentItem.children[this.levelPositions.level_2].active = true;
      this.currentItem.children[this.levelPositions.level_2].children[this.levelPositions.level_3].active = true;
      return this.currentItem.children[this.levelPositions.level_2].children[this.levelPositions.level_3];
    }
  }


  public play() { // done
    if(!this.isMute) {
      window['ion'].sound.play('water_droplet'); // done
    }
  }

  public clickItem(item: object, l1 = 0, l2 = 0, l3 = 0, exapnd = false, close = false) {
      this.play(); // done

    if (!this.isSimulator) {
      if (exapnd) {
        item['expand'] = !item['expand'];
      }

      window['jQuery']('#stepArea').animate({
        scrollTop:  0
      }, 500);

      console.log('ITEM', item);
      this.expand = false;
      // this.clearActiveAttr();
      // if (item['expand']) {

      if (!close) {
        this.router.navigate([(this.isEng ? '/eng/' : '/') + '/' + this.currentMode + '/' + item['id']]);
      }
      // }
      // this.instService.setLevelPositions({level_1: l1, level_2: l2, level_3: l3});

      setTimeout(() => {
        this.vScroll.update();
      }, 250);
    }
  }

  private clearActiveAttr() {
    this.instructions.map((level1) => {
      level1.active = false;
      if (level1.children) {
        level1.children.map((level2) => {
          level2.active = false;
          if (level2.children) {
            level2.children.map((level3) => {
              level3.active = false;
            });
          }
        });
      }
    });
  }

  private clearAllAttr() {
    this.instructions.map((level1) => {
      delete level1.active;
      delete level1.expand;
      delete level1.show;
      if (level1.children) {
        level1.children.map((level2) => {
          delete level2.active;
          delete level2.expand;
          delete level2.show;
          if (level2.children) {
            level2.children.map((level3) => {
              delete level3.active;
              delete level3.expand;
              delete level3.show;
            });
          }
        });
      }
    });
  }

  public scrollUp() {
    document.getElementById('scrollId').scrollTop = 0;
  }

}
