import {
  ButtonDispenseColdStatesInterface, ButtonDispenseHotStatesInterface,
  ButtonDispenseMixStatesInterface, ButtonHotwaterStatesInterface, ButtonPitcherStatesInterface, ButtonSettingsStatesInterface,
  SimulatorEntryInterface,
  SimulatorPagesInterface
} from '../../interfaces/simulator-entry';

export class EntryPageFCS {
  static initial(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.page = SimulatorPagesInterface.FCS;
    simulatorEntry.display.data = 'FC';
    simulatorEntry.buttons =  {
      dispenseCold: {active: false, state: ButtonDispenseColdStatesInterface.ACTIVE},
      dispenseMix: {active: false, state: ButtonDispenseMixStatesInterface.ACTIVE},
      dispenseHot: {active: false, state: ButtonDispenseHotStatesInterface.ACTIVE},
      pitcher: {active: false, state: ButtonPitcherStatesInterface.ACTIVE},
      settings: {active: false, state: ButtonSettingsStatesInterface.ACTIVE},
      hotWater: {active: false, state: ButtonHotwaterStatesInterface.ACTIVE},
    }
  }

  static clickButtonSettings(simulatorEntry: SimulatorEntryInterface) {
    if (simulatorEntry.display.data !== 'FC') {
      if (simulatorEntry.display.data === 'ON') {
        simulatorEntry.settings.sound = true;
      } else {
        simulatorEntry.settings.sound = false;
      }
      return true;
    } else {
      return false;
    }
  }
}
