import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import {Setting} from '../../data/setting';

@Component({
  selector: 'app-settings-premium',
  templateUrl: './settings-premium.component.html',
  styleUrls: ['./settings-premium.component.sass']
})
export class SettingsPremiumComponent implements OnInit {

  public settings = null;

  public lang = null;

  public text = {
    he: 'העדפות',
    en: 'Settings'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  public textValueOn = {
    he: 'מופעל',
    en: 'ON'
  };

  public textValueOff = {
    he: 'מופסק',
    en: 'OFF'
  };


  public textValueYes = {
    he: 'כן',
    en: 'YES'
  };

  public textValueNo = {
    he: 'לא',
    en: 'NO'
  };

  public conformatonTextTop = {
    he: '',
    en: 'Are you sure you want to'
  };

  public conformatonTextBottom = {
    he: 'מופסקת',
    en: 'deactivated the '
  };

  public messageDeactivatedTextTop = {
    he: '',
    en: 'is'
  };
  public messageDeactivatedTextBottom = {
    he: 'מופסקת',
    en: 'deactivated'
  };

  public messageActivatedTextTop = {
    he: '',
    en: 'is'
  };
  public messageActivatedTextBottom = {
    he: 'מופעל',
    en: 'activated'
  };

  public defineNetworkTop = {
    he: 'הגדר רשת WIFI',
    en: 'Define network'
  };
  public defineNetworkMiddle = {
    he: 'דרך האפליקציה',
    en: 'from App'
  };
  public defineNetworkBottom = {
    he: '...ממתין לאפליקציה',
    en: 'Waiting for App...'
  };

  public dateTimeStep = {
    step_1: {
      he: 'שלב 1 מתוך 2',
      en: 'Step 1 of 2'
    },
    step_2: {
      he: 'שלב 2 מתוך 2',
      en: 'Step 2 of 2'
    }
  };

  public timeMessage = {
    line_1: {
      he: 'שעה הוגדרה',
      en: 'Time set'
    },
    line_2: {
      he: 'בהצלחה',
      en: 'Successfully'
    }
  };

  public dateMessage = {
    line_1: {
      he: 'תאריך הוגדר',
      en: 'Date set'
    },
    line_2: {
      he: 'בהצלחה',
      en: 'Successfully'
    }
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Settings premium', lang);
      this.lang = lang;

      if (Setting.isLanguage) {
        Setting.initLanguageSwiper();
        Setting.currentLanguageIndex = 0;
      } else if (Setting.isDateTime) {
        Setting.initTime();
        Setting.currentDateTimeIndex = 0;
      } else {
        Setting.initSwiper();
        Setting.reset();
        Setting.currentIndex = 3;
      }
    });
    this.settings = Setting;
  }

  ngOnInit() {
  }
}
