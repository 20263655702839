import { Component, OnInit } from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import {Maintenance} from '../../data/maintenance';

@Component({
  selector: 'app-maintenance-premium',
  templateUrl: './maintenance-premium.component.html',
  styleUrls: ['./maintenance-premium.component.sass']
})
export class MaintenancePremiumComponent implements OnInit {

  public maintenance = null;

  public lang = null;

  public text = {
    he: 'תחזוקה',
    en: 'Maintenance'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Settings premium', lang);
      this.lang = lang;
      Maintenance.initMaintenanceSwiper();
      Maintenance.reset();
    });
    this.maintenance = Maintenance;
  }

  ngOnInit() {
  }

}
