import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';

@Component({
  selector: 'app-errors-tech-premium',
  templateUrl: './errors-tech-premium.component.html',
  styleUrls: ['./errors-tech-premium.component.sass']
})
export class ErrorsTechPremiumComponent implements OnInit {

  public lang = null;

  public params =
    [
      'CT:  3', 'DV:OFF', 'HT: 91',
      'FN:OFF', 'SV:OFF', 'HB: 91',
      'CP:OFF', 'BV:OFF', 'HE:OFF',
      'UI: IN', 'SS:000', 'HV:OFF',
      'UR:OFF', 'BC:232', 'BT:100',
      'CC:OFF', 'HS:OFF', 'SW: ON',
      'CV:OFF', 'MN: 11.9', 'MV:OFF',
      null, 'SQ:011', 'WU:986',
      null, null, 'WM:000'
    ];

  public text = 'TS 4.09.7';

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Jugs premium', lang);
      this.lang = lang;
    });
  }

  ngOnInit() {
  }

}
