import {Component, Input, OnInit} from '@angular/core';
import {Button} from '../../../shared/button/button.component';
import {ButtonDispensingHotWaterPremiumInterface, ButtonsPremiumState} from '../../../interfaces/simulator-premium';

@Component({
  selector: 'app-button-dispense-hot-premium',
  templateUrl: './button-dispense-hot-premium.component.html',
  styleUrls: ['./button-dispense-hot-premium.component.sass']
})
export class ButtonDispenseHotPremiumComponent extends Button implements OnInit {

  @Input() data: ButtonDispensingHotWaterPremiumInterface;
  public state: any;

  constructor() {
    super();
    this.state = ButtonsPremiumState;
  }

  ngOnInit() {
  }

}
