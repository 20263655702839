import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';

@Component({
  selector: 'app-shabbat-mode-screen-premium',
  templateUrl: './shabbat-mode-screen-premium.component.html',
  styleUrls: ['./shabbat-mode-screen-premium.component.sass']
})
export class ShabbatModeScreenPremiumComponent implements OnInit {

  public lang = null;

  public instuctorText = {
    line_1: {
      he: 'ליציאה ממצב שינה',
      en: 'To exit Sleep Mode'
    },
    line_2: {
      he: 'יש ללחוץ על כל כפתור',
      en: 'press any key for 2 sec'
    },
    line_3: {
      he: 'למשך 2 שניות',
      en: ''
    }
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Shabbat mode screen premium', lang);
      this.lang = lang;
    });
  }

  ngOnInit() {
  }

}
