import {Component, OnInit} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import Devices from '../../helpers/devices';
import {LanguageService} from '../../language.service';
import {MuteService} from '../../mute.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  public headerText = {
    he: 'המדריך הדיגיטלי',
    en: 'Digital guide'
  };

  public headerSubText = {
    he: 'לומדים על המכשירים שלנו בקלות',
    en: 'Learn about our devices easily'
  };

  public barText = {
    he: 'בחר בר מים',
    en: 'Choose a water bar'
  };

  public lang = null;
  public devices = null;
  public currentDevice = null;
  public devicesShow = null;

  public firstLoad = true;
  public isMute = null;
  public isEng = false;

  constructor(private langService: LanguageService, private muteService: MuteService, private router: Router) {
    this.lang = this.langService.getLang();
    this.langService.language.subscribe((lang) => {
      console.log('Home component', lang);
      this.lang = lang;
    });


    this.devices = Devices.list;
    if (router.url.substr(0,4) === '/eng') {
      this.devicesShow = this.devices.slice(0,2);
      this.isEng = true;
    } else {
      this.devicesShow = this.devices;
      this.isEng = false;
    }
    this.currentDevice = this.devices[0];

    this.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      this.isMute = mute;
    });
    this.router.events.subscribe((param) => {
      if (param instanceof NavigationEnd) {
        if (param.url.substr(0,4) === '/eng') {
          this.devicesShow = this.devices.slice(0,2);
          this.isEng = true;
        } else {
          this.devicesShow = this.devices;
          this.isEng = false;
        }
      }
    });
  }

  ngOnInit() {
    console.log(Devices.list, this.lang);
  }


  public clickPlay() { // done
    if (!this.isMute) {
      window['ion'].sound.play('water_droplet'); // done
    }
  }

  public changeDevice(index) {
    this.firstLoad = false;
    if (this.currentDevice) {
      if (this.currentDevice.id !== this.devices[index].id) {
        this.currentDevice = null;
        if (!this.isMute) {
          window['ion'].sound.play('swipe'); // done
        }
        setTimeout(() => {
          this.currentDevice = this.devices[index];
        }, 100);
      }
    }
  }
}
