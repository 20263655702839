import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {InstructorService} from '../../instructor.service';
import baron from 'baron';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LanguageService} from '../../language.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.sass'],
})
export class SideBarComponent implements OnInit {

  public lang = null;
  @Output() toggle = new EventEmitter<void>();
  public instructions = null;
  private currentItem = null;
  private vScroll = null;
  private levelPositions = null;
  public expand = true;

  public currentMode = 'instructor';
  public scrollId = null;
  public isEng = false;

  coolers = [
    {
      id: 'premium',
      name: 'Premium',
      img: 'premium_bar.png',
      active: true
    },
    {
      id: 'entry',
      name: 'Entry',
      img: 'entry.png',
      active: false
    }
  ];

  public isShowManual = true;

  constructor(private instService: InstructorService, private route: ActivatedRoute, private router: Router, private langService: LanguageService) {
    // watcher for current level positions
    this.instService.levelPositions.subscribe((levelPositions) => {
      this.levelPositions = levelPositions;
      if (this.currentItem) {
        this.instService.setCurrentSteps(this.getCurrentSteps());
      }

      this.lang = this.langService.getLang();
      this.langService.language.subscribe((lang) => {
        console.log('side-bar-component', lang);
        this.lang = lang;

        // todo uncomment this line when content on english will be ready
        this.changeDevice(this.route.root.firstChild.params['value']['device']);
      });
    });

    if (router.url.substr(0,4) === '/eng') {
      this.isEng = true;
    } else {
      this.isEng = false;
    }

    // watcher for route
    this.router.events.subscribe((param) => {
      if (param instanceof NavigationEnd) {if (param.url.substr(0,4) === '/eng') {
        this.isEng = true;
      } else {
        this.isEng = false;
      }
        this.instService.setLevelPositions({level_1: 0, level_2: 0, level_3: 0});
        this.changeDevice(this.route.root.firstChild.params['value']['device']);
        // this.instService.setCurrentDevice(this.route.root.firstChild.params['value']['device']);
        // console.log(this.route.root)
        this.getItemIdFromUrl(param.url.substr(param.url.lastIndexOf('/') + 1));

      }


    });
  }

  toggleSidebar() {
    this.toggle.emit();
  }

  private getItemIdFromUrl(url) {
    if (url !== 'instructor' && url !== 'simulator') {
      setTimeout(() => {
        if (this.expand) {
          let doc = document.getElementById(url + '_sc_item');
          doc.scrollIntoView();
        }
      }, 0);
      this.getLevelById(url);
    } else {
      this.currentMode = url;
      this.isShowManual = url !== 'simulator';
    }
  };

  private getLevelById(id) {
    if (id) {
      for (let i = 0; i < this.instructions.length; i++) {
        if (this.instructions[i].id === id) {
          setTimeout(() => {
            this.instService.setLevelPositions({level_1: i, level_2: 0, level_3: 0});
          }, 0);
          console.log('LEVEL 1', i);
          if (this.expand) {
            this.instructions[i].expand = true;
            this.instructions[i].show = true;
          }
          return;
        } else {
          if (this.instructions[i].children) {
            for (let j = 0; j < this.instructions[i].children.length; j++) {
              if (this.instructions[i].children[j].id === id) {
                console.log('LEVEL 2', j);
                if (this.expand) {
                  this.instructions[i].expand = true;
                  this.instructions[i].show = true;
                  this.instructions[i].children[j].expand = true;
                  this.instructions[i].children[j].show = true;
                }
                setTimeout(() => {
                  this.instService.setLevelPositions({level_1: i, level_2: j, level_3: 0});
                }, 0);
                return;
              } else {
                if (this.instructions[i].children[j].children) {
                  for (let k = 0; k < this.instructions[i].children[j].children.length; k++) {
                    if (this.instructions[i].children[j].children[k].id === id) {
                      console.log('LEVEL 3', k);
                      if (this.expand) {
                        this.instructions[i].expand = true;
                        this.instructions[i].show = true;
                        this.instructions[i].children[j].expand = true;
                        this.instructions[i].children[j].show = true;
                      }

                      setTimeout(() => {
                        this.instService.setLevelPositions({level_1: i, level_2: j, level_3: k});
                      }, 0);
                      return;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ngOnInit() {
    // init custom scroll
    setTimeout(() => {
      this.vScroll = baron({
        root: '.baron-chapters',
        scroller: '.baron-chapters__scroller',
        bar: '.baron-chapters__bar',
      });
    }, 0);
  }

  private changeDevice(cooler: any): void {
    this.coolers.map((item) => {
      if (item.id !== cooler) {
        item.active = false;
      } else {
        item.active = true;
      }
    });

    this.instructions = this.instService.getInstructor()[cooler][this.lang];
    this.currentItem = this.instructions[this.levelPositions.level_1];
    this.currentItem.show = true;
    this.currentItem.expand = true;
    this.currentItem.children[this.levelPositions.level_2].show = true;
    this.currentItem.children[this.levelPositions.level_2].expand = true;

    setTimeout(() => {
      this.instService.setCurrentSteps(this.getCurrentSteps());
    }, 0);
  }

  private getCurrentSteps() {
    this.currentItem = this.instructions[this.levelPositions.level_1];
    this.clearActiveAttr();
    if (this.currentItem.steps) {
      this.currentItem.active = true;
      return this.currentItem;
    } else if (this.currentItem.children[this.levelPositions.level_2].steps) {
      this.currentItem.children[this.levelPositions.level_2].active = true;
      return this.currentItem.children[this.levelPositions.level_2];
    } else {
      this.currentItem.children[this.levelPositions.level_2].children[this.levelPositions.level_3].active = true;
      return this.currentItem.children[this.levelPositions.level_2].children[this.levelPositions.level_3];
    }
  }

  public clickItem(item: object, l1 = 0, l2 = 0, l3 = 0, exapnd = false) {
    // this.currentItem = item;

    if (exapnd) {
      item['expand'] = !item['expand'];
    }
    this.expand = false;
    this.clearActiveAttr();
    this.router.navigate([(this.isEng ? '/eng/' : '/') + this.route.root.firstChild.params['value']['device'] + '/' + this.currentMode + '/' + item['id']]);
    // this.instService.setLevelPositions({level_1: l1, level_2: l2, level_3: l3});

    setTimeout(() => {
      this.vScroll.update();
    }, 250);
  }

  private clearActiveAttr() {
    this.instructions.map((level1) => {
      level1.active = false;
      if (level1.children) {
        level1.children.map((level2) => {
          level2.active = false;
          if (level2.children) {
            level2.children.map((level3) => {
              level3.active = false;
            });
          }
        });
      }
    });
  }

  public scrollUp() {
    document.getElementById('scrollId').scrollTop = 0;
  }

}
