import Swiper from 'swiper/dist/js/swiper.js';

export class Preference {
  public static preferenceSwiper = null;
  public static currentIndex = 0;

  public static preferenceList = {
    he: [
      {
        id: 1,
        name: 'כמות וטמפרטורה',
      },
      {
        id: 2,
        name: 'משקאות שלי',
      },
    ],
    en: [
      {
        id: 1,
        name: 'Quantity & Temp.',
      },
      {
        id: 2,
        name: 'My Drinks',
      },
    ]
  };

  public static initPreferencesSwiper() {
    setTimeout(() => {
      Preference.preferenceSwiper = new Swiper('#preferences', {
        direction: 'vertical',
        loop: false,
        slidesPerView: 5,
        spaceBetween: 0,
        simulateTouch: false,
        autoHeight: true,
        height: 280,
        centeredSlides: true,
        watchOverflow: false,
        slidesPerColumn: 1,
        normalizeSlideIndex: true,
      });
    }, 0);
  }

  public static reset() {
    Preference.preferenceSwiper = null;
    Preference.currentIndex = 0;
  }


}
