import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SideBarComponent} from './components/side-bar/side-bar.component';
import {ContentAreaComponent} from './components/instructor/content-area/content-area.component';
import {ColorPickerComponent} from './components/instructor/color-picker/color-picker.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TopNavComponent} from './components/top-nav/top-nav.component';
import {DashboardEntryComponent} from './components/simulator/entry/dashboard-entry/dashboard-entry.component';
import {DashboardPremiumComponent} from './components/simulator/premium/dashboard-premium/dashboard-premium.component';
import {DashboardAppComponent} from './components/simulator/app/dashboard-app/dashboard-app.component';
import {DisplayEntryComponent} from './components/simulator/entry/components/display-entry/display-entry.component';
import {IndicatorWifiEntryComponent} from './components/simulator/entry/components/indicator-wifi-entry/indicator-wifi-entry.component';
import {IndicatorCleanEntryComponent} from './components/simulator/entry/components/indicator-clean-entry/indicator-clean-entry.component';
import {IndicatorUvEntryComponent} from './components/simulator/entry/components/indicator-uv-entry/indicator-uv-entry.component';
import {IndicatorPurifierEntryComponent} from './components/simulator/entry/components/indicator-purifier-entry/indicator-purifier-entry.component';
import {ButtonDispenseHotEntryComponent} from './components/simulator/entry/components/button-dispense-hot-entry/button-dispense-hot-entry.component';
import {ButtonDispenseMixEntryComponent} from './components/simulator/entry/components/button-dispense-mix-entry/button-dispense-mix-entry.component';
import {ButtonDispenseColdEntryComponent} from './components/simulator/entry/components/button-dispense-cold-entry/button-dispense-cold-entry.component';
import {ButtonHotwaterEntryComponent} from './components/simulator/entry/components/button-hotwater-entry/button-hotwater-entry.component';
import {ButtonPitcherEntryComponent} from './components/simulator/entry/components/button-pitcher-entry/button-pitcher-entry.component';
import {ButtonSettingsEntryComponent} from './components/simulator/entry/components/button-settings-entry/button-settings-entry.component';
import {SimulatorComponent} from './components/simulator/simulator.component';
import {LongClickDirective} from './components/simulator/directives/long-click.directive';
import {LongPressDirective} from './components/simulator/directives/long-press.directive';
import {ShortPressDirective} from './components/simulator/directives/short-press.directive';
import {ShortClickDirective} from './components/simulator/directives/short-click.directive';
import {IndicatorBarPremiumComponent} from './components/simulator/premium/components/indicator-bar-premium/indicator-bar-premium.component';
import {IndicatorPurifierPremiumComponent} from './components/simulator/premium/components/indicator-purifier-premium/indicator-purifier-premium.component';
import {IndicatorNetPremiumComponent} from './components/simulator/premium/components/indicator-net-premium/indicator-net-premium.component';
import {IndicatorLockPremiumComponent} from './components/simulator/premium/components/indicator-lock-premium/indicator-lock-premium.component';
import {IndicatorClockPremiumComponent} from './components/simulator/premium/components/indicator-clock-premium/indicator-clock-premium.component';
import {IndicatorWifiPremiumComponent} from './components/simulator/premium/components/indicator-wifi-premium/indicator-wifi-premium.component';
import {IndicatorUvPremiumComponent} from './components/simulator/premium/components/indicator-uv-premium/indicator-uv-premium.component';
import {IndicatorAddonPremiumComponent} from './components/simulator/premium/components/indicator-addon-premium/indicator-addon-premium.component';
import {ButtonDispenseColdPremiumComponent} from './components/simulator/premium/components/button-dispense-cold-premium/button-dispense-cold-premium.component';
import {ButtonDispenseHotPremiumComponent} from './components/simulator/premium/components/button-dispense-hot-premium/button-dispense-hot-premium.component';
import {ButtonDispenseMixPremiumComponent} from './components/simulator/premium/components/button-dispense-mix-premium/button-dispense-mix-premium.component';
import {ButtonBoilingPremiumComponent} from './components/simulator/premium/components/button-boiling-premium/button-boiling-premium.component';
import {ButtonJugsPremiumComponent} from './components/simulator/premium/components/button-jugs-premium/button-jugs-premium.component';
import {ButtonPersonalPremiumComponent} from './components/simulator/premium/components/button-personal-premium/button-personal-premium.component';
import {DisplayPremiumComponent} from './components/simulator/premium/components/display-premium/display-premium.component';
import {IdlePagePremiumComponent} from './components/simulator/premium/pages/idle-page-premium/idle-page-premium.component';
import {HotWaterDispensingPremiumComponent} from './components/simulator/premium/pages/hot-water-dispensing-premium/hot-water-dispensing-premium.component';
import {ColdWaterDispensingPremiumComponent} from './components/simulator/premium/pages/cold-water-dispensing-premium/cold-water-dispensing-premium.component';
import {MixWaterDispensingPremiumComponent} from './components/simulator/premium/pages/mix-water-dispensing-premium/mix-water-dispensing-premium.component';
import {BoilingPremiumComponent} from './components/simulator/premium/pages/boiling-premium/boiling-premium.component';
import {PersonalPremiumComponent} from './components/simulator/premium/pages/personal-premium/personal-premium.component';
import {PersonalDrinksPremiumComponent} from './components/simulator/premium/pages/personal-drinks-premium/personal-drinks-premium.component';
import {DefinePersonalDrinksPremiumComponent} from './components/simulator/premium/pages/define-personal-drinks-premium/define-personal-drinks-premium.component';
import {Boiling} from './components/simulator/premium/data/boiling';
import {JugsPremiumComponent} from './components/simulator/premium/pages/jugs-premium/jugs-premium.component';
import {DefineJugsPremiumComponent} from './components/simulator/premium/pages/define-jugs-premium/define-jugs-premium.component';
import {ActivateJugsPremiumComponent} from './components/simulator/premium/pages/activate-jugs-premium/activate-jugs-premium.component';
import {TemperatureScaleComponent} from './components/simulator/premium/components/temperature-scale/temperature-scale.component';
import {LanguageComponent} from './components/language/language.component';
import {SafeHtmlPipe} from './safe-html.pipe';
import {MenuPremiumComponent} from './components/simulator/premium/pages/menu-premium/menu-premium.component';
import {SettingsPremiumComponent} from './components/simulator/premium/pages/settings-premium/settings-premium.component';
import {SupportPremiumComponent} from './components/simulator/premium/pages/support-premium/support-premium.component';
import {MaintenancePremiumComponent} from './components/simulator/premium/pages/maintenance-premium/maintenance-premium.component';
import {WashingTanksPremiumComponent} from './components/simulator/premium/pages/washing-tanks-premium/washing-tanks-premium.component';
import {BuiltInTestPremiumComponent} from './components/simulator/premium/pages/built-in-test-premium/built-in-test-premium.component';
import {PurifierAndUvPremiumComponent} from './components/simulator/premium/pages/purifier-and-uv-premium/purifier-and-uv-premium.component';
import {LockButtonsMessagePremiumComponent} from './components/simulator/premium/pages/lock-buttons-message-premium/lock-buttons-message-premium.component';
import {LockButtonsScreenPremiumComponent} from './components/simulator/premium/pages/lock-buttons-screen-premium/lock-buttons-screen-premium.component';
import {MultiKeyLongClickDirective} from './components/simulator/directives/multi-key-long-click.directive';
import {ShabbatModeConfirmationPremiumComponent} from './components/simulator/premium/pages/shabbat-mode-confirmation-premium/shabbat-mode-confirmation-premium.component';
import {ShabbatModeScreenPremiumComponent} from './components/simulator/premium/pages/shabbat-mode-screen-premium/shabbat-mode-screen-premium.component';
import {ResetScreenPremiumComponent} from './components/simulator/premium/pages/reset-screen-premium/reset-screen-premium.component';
import {InternalCleaningPremiumComponent} from './components/simulator/premium/pages/internal-cleaning-premium/internal-cleaning-premium.component';
import {PreferencesPremiumComponent} from './components/simulator/premium/pages/preferences-premium/preferences-premium.component';
import {QuantityAndTempPremiumComponent} from './components/simulator/premium/pages/quantity-and-temp-premium/quantity-and-temp-premium.component';
import {WaterQuantityPremiumComponent} from './components/simulator/premium/pages/water-quantity-premium/water-quantity-premium.component';
import {PersonalQuantityPremiumComponent} from './components/simulator/premium/pages/personal-quantity-premium/personal-quantity-premium.component';
import {PersonalEditQuantityPremiumComponent} from './components/simulator/premium/pages/personal-edit-quantity-premium/personal-edit-quantity-premium.component';
import {InternalCleaningMainPremiumComponent} from './components/simulator/premium/pages/internal-cleaning-main-premium/internal-cleaning-main-premium.component';
import {SterilizationPremiumComponent} from './components/simulator/premium/pages/sterilization-premium/sterilization-premium.component';
import {HomeComponent} from './components/home/home.component';
import {ManualComponent} from './components/manual/manual.component';
import {SideNavComponent} from './components/side-nav/side-nav.component';
import {BarsSelectComponent} from './components/bars-select/bars-select.component';
import { TechScreenPremiumComponent } from './components/simulator/premium/pages/tech-screen-premium/tech-screen-premium.component';
import { TechScreenDirective } from './components/simulator/directives/tech-screen.directive';
import { TechScreenPressDirective } from './components/simulator/directives/tech-screen-press.directive';
import { ChildLockTechPremiumComponent } from './components/simulator/premium/pages/child-lock-tech-premium/child-lock-tech-premium.component';
import { ParametersTechPremiumComponent } from './components/simulator/premium/pages/parameters-tech-premium/parameters-tech-premium.component';
import { BoilingTempTechPremiumComponent } from './components/simulator/premium/pages/boiling-temp-tech-premium/boiling-temp-tech-premium.component';
import { DebugTechPremiumComponent } from './components/simulator/premium/pages/debug-tech-premium/debug-tech-premium.component';
import { WashHotTankTechPremiumComponent } from './components/simulator/premium/pages/wash-hot-tank-tech-premium/wash-hot-tank-tech-premium.component';
import { ErrorsTechPremiumComponent } from './components/simulator/premium/pages/errors-tech-premium/errors-tech-premium.component';
import {TenSecondEntrySettingPressDirective} from './components/simulator/directives/ten-second-entry-setting-press.directive';
import { ThreeSecondEntrySettingPressDirective } from './components/simulator/directives/three-second-entry-setting-press.directive';
import { SimulatorJoeyComponent } from './components/simulator/joey/simulator-joey.component';
import { SimulatorKodeshComponent } from './components/simulator/kodesh/simulator-kodesh.component';

@NgModule({
  declarations: [
    AppComponent,
    SideBarComponent,
    ContentAreaComponent,
    ColorPickerComponent,
    TopNavComponent,
    DashboardEntryComponent,
    DashboardPremiumComponent,
    DashboardAppComponent,
    SimulatorJoeyComponent,
    SimulatorKodeshComponent,
    DisplayEntryComponent,
    IndicatorWifiEntryComponent,
    IndicatorCleanEntryComponent,
    IndicatorUvEntryComponent,
    IndicatorPurifierEntryComponent,
    ButtonDispenseHotEntryComponent,
    ButtonDispenseMixEntryComponent,
    ButtonDispenseColdEntryComponent,
    ButtonHotwaterEntryComponent,
    ButtonPitcherEntryComponent,
    ButtonSettingsEntryComponent,
    SimulatorComponent,
    LongClickDirective,
    LongPressDirective,
    ShortPressDirective,
    ShortClickDirective,
    IndicatorBarPremiumComponent,
    IndicatorPurifierPremiumComponent,
    IndicatorNetPremiumComponent,
    IndicatorLockPremiumComponent,
    IndicatorClockPremiumComponent,
    IndicatorWifiPremiumComponent,
    IndicatorUvPremiumComponent,
    IndicatorAddonPremiumComponent,
    ButtonDispenseColdPremiumComponent,
    ButtonDispenseHotPremiumComponent,
    ButtonDispenseMixPremiumComponent,
    ButtonBoilingPremiumComponent,
    ButtonJugsPremiumComponent,
    ButtonPersonalPremiumComponent,
    DisplayPremiumComponent,
    IdlePagePremiumComponent,
    HotWaterDispensingPremiumComponent,
    ColdWaterDispensingPremiumComponent,
    MixWaterDispensingPremiumComponent,
    BoilingPremiumComponent,
    PersonalPremiumComponent,
    PersonalDrinksPremiumComponent,
    DefinePersonalDrinksPremiumComponent,
    JugsPremiumComponent,
    DefineJugsPremiumComponent,
    ActivateJugsPremiumComponent,
    TemperatureScaleComponent,
    LanguageComponent,
    SafeHtmlPipe,
    MenuPremiumComponent,
    SettingsPremiumComponent,
    SupportPremiumComponent,
    MaintenancePremiumComponent,
    WashingTanksPremiumComponent,
    BuiltInTestPremiumComponent,
    PurifierAndUvPremiumComponent,
    LockButtonsMessagePremiumComponent,
    LockButtonsScreenPremiumComponent,
    MultiKeyLongClickDirective,
    ShabbatModeConfirmationPremiumComponent,
    ShabbatModeScreenPremiumComponent,
    ResetScreenPremiumComponent,
    InternalCleaningPremiumComponent,
    PreferencesPremiumComponent,
    QuantityAndTempPremiumComponent,
    WaterQuantityPremiumComponent,
    PersonalQuantityPremiumComponent,
    PersonalEditQuantityPremiumComponent,
    InternalCleaningMainPremiumComponent,
    SterilizationPremiumComponent,
    HomeComponent,
    ManualComponent,
    SideNavComponent,
    BarsSelectComponent,
    TechScreenPremiumComponent,
    TechScreenDirective,
    TechScreenPressDirective,
    ChildLockTechPremiumComponent,
    ParametersTechPremiumComponent,
    BoilingTempTechPremiumComponent,
    DebugTechPremiumComponent,
    WashHotTankTechPremiumComponent,
    ErrorsTechPremiumComponent,
    TenSecondEntrySettingPressDirective,
    ThreeSecondEntrySettingPressDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppRoutingModule,
  ],
  providers: [Boiling],
  bootstrap: [AppComponent]
})
export class AppModule {
}
