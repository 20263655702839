// Connectivity setings enable / disable wifi

import {
  ButtonDispenseColdStatesInterface,
  ButtonDispenseHotStatesInterface,
  ButtonDispenseMixStatesInterface,
  IndicatorWifiStateInterface,
  SimulatorEntryInterface,
  SimulatorPagesInterface
} from '../../interfaces/simulator-entry';

export class EntryPageCO {

  static initial(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.page = SimulatorPagesInterface.CO;
    simulatorEntry.display.data = 'CO';
    simulatorEntry.buttons.dispenseHot.state = ButtonDispenseHotStatesInterface.OFF;
    simulatorEntry.buttons.dispenseMix.state = ButtonDispenseMixStatesInterface.OFF;
    simulatorEntry.buttons.dispenseCold.state = ButtonDispenseColdStatesInterface.FLASHING;

  }

  static clickButtonDispenseCold(simulatorEntry: SimulatorEntryInterface) {
    switch (simulatorEntry.display.data) {
      case 'CO':
        simulatorEntry.display.data = 'ON';
        break;
      case 'ON':
        simulatorEntry.display.data = 'OF';
        break;
      case 'OF':
        simulatorEntry.display.data = 'ON';
        break;
    }
  }

  static clickButtonSettings(simulatorEntry: SimulatorEntryInterface) {
    if (simulatorEntry.display.data !== 'CO') {
      if (simulatorEntry.display.data === 'ON') {
        simulatorEntry.indicator.wifi.state = IndicatorWifiStateInterface.BLUE_FLASH;
        setTimeout(() => {
          simulatorEntry.indicator.wifi.state = IndicatorWifiStateInterface.BLUE;
        }, 1500);
      } else {
        simulatorEntry.indicator.wifi.state = IndicatorWifiStateInterface.OFF;
      }
      return true;
    } else {
      return false;
    }
  }


}
