// Sound settings

import {
  ButtonDispenseColdStatesInterface,
  ButtonDispenseHotStatesInterface,
  ButtonDispenseMixStatesInterface,
  IndicatorWifiStateInterface,
  SimulatorEntryInterface,
  SimulatorPagesInterface
} from '../../interfaces/simulator-entry';

export class EntryPageSO {

  static initial(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.page = SimulatorPagesInterface.SO;
    simulatorEntry.display.data = 'SO';
    simulatorEntry.buttons.dispenseHot.state = ButtonDispenseHotStatesInterface.OFF;
    simulatorEntry.buttons.dispenseMix.state = ButtonDispenseMixStatesInterface.OFF;
    simulatorEntry.buttons.dispenseCold.state = ButtonDispenseColdStatesInterface.FLASHING;

  }

  static clickButtonDispenseCold(simulatorEntry: SimulatorEntryInterface) {
    switch (simulatorEntry.display.data) {
      case 'SO':
        simulatorEntry.display.data = 'ON';
        break;
      case 'ON':
        simulatorEntry.display.data = 'OF';
        break;
      case 'OF':
        simulatorEntry.display.data = 'ON';
        break;
    }
  }

  static clickButtonSettings(simulatorEntry: SimulatorEntryInterface) {
    if (simulatorEntry.display.data !== 'SO') {
      if (simulatorEntry.display.data === 'ON') {
        simulatorEntry.settings.sound = true;
      } else {
        simulatorEntry.settings.sound = false;
      }
      return true;
    } else {
      return false;
    }
  }


}
