import { Component, OnInit } from '@angular/core';
import {LanguageService} from '../../../../../language.service';

@Component({
  selector: 'app-define-jugs-premium',
  templateUrl: './define-jugs-premium.component.html',
  styleUrls: ['./define-jugs-premium.component.sass']
})
export class DefineJugsPremiumComponent implements OnInit {

  public lang = null;

  public text_top = {
    he: 'הסופת קנקנים וסירים',
    en: 'Add more Jugs & Pots'
  };
  public text_bottom = {
    he: 'באמצעות האפליקיציה',
    en: 'from the mobile App'
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Define jugs', lang);
      this.lang = lang;
    });
  }

  ngOnInit() {
  }

}
