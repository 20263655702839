import {Component, Input, OnInit} from '@angular/core';
import {IndicatorBarPremiumInterface} from '../../../interfaces/simulator-premium';

@Component({
  selector: 'app-indicator-bar-premium',
  templateUrl: './indicator-bar-premium.component.html',
  styleUrls: ['./indicator-bar-premium.component.sass']
})
export class IndicatorBarPremiumComponent implements OnInit {

  @Input() indicator: IndicatorBarPremiumInterface;
  constructor() { }

  ngOnInit() {
  }

}
