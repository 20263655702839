import {Component, OnInit} from '@angular/core';
import {LanguageService} from './language.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import Devices from './helpers/devices';
import {InstructorService} from './instructor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})

export class AppComponent implements OnInit {

  public lang = 'en';
  public isSideNav = false;
  public currentDeviceId = 'premium';
  public currentMode = null;
  public instructions = null;
  public out = null;

  constructor(
    private langService: LanguageService,
    private route: ActivatedRoute,
    private router: Router,
    public instService: InstructorService,
  ) {

    this.lang = langService.getLang();
    this.langService.language.subscribe((lang) => {
      console.log('app-component', lang);
      this.lang = lang;
    });

    document.body.className = '';
    document.body.className = this.lang === 'he' ? 'dir-rtl h-100' : 'dir-ltr h-100';


    // watcher for route
    // open side navigation if route is not home
    // hide side navigation on home page
    this.router.events.subscribe((param) => {
      if (param instanceof NavigationEnd) {
        if (param.url.substr(0,4) === '/eng') {
          this.langService.setLanguage('en');
          document.body.className = this.lang === 'he' ? 'dir-rtl h-100' : 'dir-ltr h-100';
        } else {

        }
        // if (param.url === '/' || param.url === '/eng') {
        //   this.out = this.lang === 'he' ? 'slideOutRight' : 'slideOutLeft';
        //   setTimeout(() => {
        //     this.isSideNav = false;
        //   }, 1000);
        // } else {

          // check for selected device bar
          // Devices.list.map((device) => {
          //   if (param.url.includes(device.id)) {
          //     this.currentDeviceId = device.id;
          //     this.instService.setCurrentDevice(device.id);
          //     return;
          //   }
          // });

          // check mode instructor or simulator
          if (param.url.includes('simulator')) {
            this.currentMode = 'simulator';
          } else {
            this.instructions = this.instService.getInstructor()[this.currentDeviceId][this.lang];
            this.getItemIdFromUrl(param.url.substr(param.url.lastIndexOf('/') + 1));
            this.currentMode = 'instructor';
          }
          this.out = null;
          this.isSideNav = true;
        }
      // }
    });

    if (!localStorage.getItem('mute')) {
      localStorage.setItem('mute', 'false');
    }
  }


  ngOnInit(): void {
    window['ion'].sound({
      sounds: [
        {
          name: 'boiling',
          volume: 0.1,
          multiplay: false
        },
        {
          name: 'pouring',
          volume: 0.6,
          loop: true,
          multiplay: false
        },
        {
          name: 'snap',
        },
        {
          name: 'swipe',
        },
        {
          name: 'water_droplet',
        }
      ],
      volume: 0.5,
      path: '/assets/audio/',
      preload: true,
      multiplay: true
    });
  }

  private getItemIdFromUrl(url) {
    if (url !== 'instructor' && url !== 'simulator') {
      this.getLevelById(url);
    }
  };

  private getLevelById(id) {
    if (id) {
      for (let i = 0; i < this.instructions.length; i++) {
        if (this.instructions[i].id === id) {
          setTimeout(() => {
            this.instService.setLevelPositions({level_1: i, level_2: 0, level_3: 0});
          }, 0);
          console.log('LEVEL 1', i);
          return;
        } else {
          if (this.instructions[i].children) {
            for (let j = 0; j < this.instructions[i].children.length; j++) {
              if (this.instructions[i].children[j].id === id) {
                console.log('LEVEL 2', j);
                setTimeout(() => {
                  this.instService.setLevelPositions({level_1: i, level_2: j, level_3: 0});
                }, 0);
                return;
              } else {
                if (this.instructions[i].children[j].children) {
                  for (let k = 0; k < this.instructions[i].children[j].children.length; k++) {
                    if (this.instructions[i].children[j].children[k].id === id) {
                      console.log('LEVEL 3', k);
                      setTimeout(() => {
                        this.instService.setLevelPositions({level_1: i, level_2: j, level_3: k});
                      }, 0);
                      return;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
