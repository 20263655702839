import {Component, Input, OnInit} from '@angular/core';
import {IndicatorClockPremiumInterface} from '../../../interfaces/simulator-premium';

@Component({
  selector: 'app-indicator-clock-premium',
  templateUrl: './indicator-clock-premium.component.html',
  styleUrls: ['./indicator-clock-premium.component.sass']
})

export class IndicatorClockPremiumComponent implements OnInit {

  @Input() data: IndicatorClockPremiumInterface;

  time = null;

  constructor() {
  }

  ngOnInit() {
    this.initDate();
   // setInterval(() => this.initDate(), 1000);
  }

  initDate() {
    // const currentDateTime = new Date();
    // this.time = this.formatTime('12H', currentDateTime);
    this.time = "11:20";
  }

  private formatTime(format: string, dateTime: Date): string {
    let hours: number = dateTime.getHours();
    const minutes: number = dateTime.getMinutes();

    if (format === '12H') {
      hours = hours > 12 ? (dateTime.getHours() - 12) : hours;
      return hours.toString() + ':' + (minutes < 10 ? '0' + minutes.toString() : minutes.toString());
    }
    return (hours < 10 ? '0' + hours.toString() : hours.toString()) + ':' + (minutes < 10 ? '0' + minutes.toString() : minutes.toString());
  }

}
