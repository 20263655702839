import {Component, Input, OnInit} from '@angular/core';
import {Button} from '../../../shared/button/button.component';
import {ButtonDispenseColdInterface, ButtonDispenseColdStatesInterface} from '../../../interfaces/simulator-entry';

@Component({
  selector: 'app-button-dispense-cold-entry',
  templateUrl: './button-dispense-cold-entry.component.html',
  styleUrls: ['./button-dispense-cold-entry.component.sass']
})
export class ButtonDispenseColdEntryComponent extends Button implements OnInit {

  @Input() data: ButtonDispenseColdInterface;
  public states;

  ngOnInit() {
    this.states = ButtonDispenseColdStatesInterface;
  }

}
