import {SimulatorPremiumPagesInterface} from '../../interfaces/simulator-premium';

export class Jug {
  public static jugsList = {
    he: [
      {
        id: 1,
        name: 'קנקן קר',
        img: 'cold_jug.svg',
        img_active: 'cold_jug_active.svg',
        active: true,
        run: SimulatorPremiumPagesInterface.ColdWaterDispensing
      },
      {
        id: 2,
        name: 'קנקן חם',
        img: 'hot_jug.svg',
        img_active: 'hot_jug_active.svg',
        active: false,
        run: SimulatorPremiumPagesInterface.HotWaterDispensing

      },
      {
        id: 3,
        name: 'סיר',
        img: 'pot.svg',
        img_active: 'pot_active.svg',
        active: false,
        run: SimulatorPremiumPagesInterface.HotWaterDispensing
      },
      {
        id: 4,
        name: 'קנקן פושר',
        img: 'warm_jug.svg',
        img_active: 'warm_jug_active.svg',
        active: false,
        run: SimulatorPremiumPagesInterface.MixWaterDispensing
      }
    ],
    en: [
      {
        id: 1,
        name: 'Cold Jug',
        img: 'cold_jug.svg',
        img_active: 'cold_jug_active.svg',
        active: true,
        run: SimulatorPremiumPagesInterface.ColdWaterDispensing
      },
      {
        id: 2,
        name: 'Hot Jug',
        img: 'hot_jug.svg',
        img_active: 'hot_jug_active.svg',
        active: false,
        run: SimulatorPremiumPagesInterface.HotWaterDispensing

      },
      {
        id: 3,
        name: 'Cooking',
        img: 'pot.svg',
        img_active: 'pot_active.svg',
        active: false,
        run: SimulatorPremiumPagesInterface.HotWaterDispensing
      },
      {
        id: 4,
        name: 'Mix jug',
        img: 'warm_jug.svg',
        img_active: 'warm_jug_active.svg',
        active: false,
        run: SimulatorPremiumPagesInterface.MixWaterDispensing
      }
    ]
  };

  public static currentJug = null;
  public static step = 0;
  public static temp = 45;


  public static incTemp() {
    if (Jug.temp < 96) {
      Jug.temp++;
    }
  }

  public static decTemp() {
    if (Jug.temp > 0) {
      Jug.temp--;
    }
  }

  public static resetTemp() {
    Jug.temp = 45;
  }
}
