import {Component, Input, OnInit} from '@angular/core';
import {IndicatorUVInterface, IndicatorUvStateInterface} from '../../../interfaces/simulator-entry';
@Component({
  selector: 'app-indicator-uv-entry',
  templateUrl: './indicator-uv-entry.component.html',
  styleUrls: ['./indicator-uv-entry.component.sass']
})
export class IndicatorUvEntryComponent implements OnInit {

  @Input() data: IndicatorUVInterface;
  public states;

  constructor() {
    this.states = IndicatorUvStateInterface;
  }

  ngOnInit() {
    console.groupCollapsed('Simulator - Indicator - UV');
    console.log('ngOnInit state: ', this.data);
    console.groupEnd();
  }

}
