import {Component, OnDestroy, OnInit} from '@angular/core';
import {Boiling} from '../../data/boiling';
import {LanguageService} from '../../../../../language.service';
import {MuteService} from '../../../../../mute.service';

@Component({
  selector: 'app-boiling-premium',
  templateUrl: './boiling-premium.component.html',
  styleUrls: ['./boiling-premium.component.sass']
})
export class BoilingPremiumComponent implements OnInit, OnDestroy {


  public text = {
    he: {
      main: 'הרתחה',
      complete: 'הרתחה הסתיימה'
    },
    en: {
      main: 'Boiling',
      complete: 'Boiling completed'
    }
  };

  public time = '11:20';
  public postfix = 'AM';
  public boilingProgress = 0;
  public afkBoiling = null;
  public lang = null;
  public isMute = null;

  constructor(private boiling: Boiling, private langService: LanguageService, private muteService: MuteService) {
    // this.initDate();

    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Boiling', lang);
      this.lang = lang;
    });

    clearInterval(this.afkBoiling);
    this.boilingProgress = 0;
    this.boiling.setBoiling(true);

    this.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      this.isMute = mute;
    });
  }

  ngOnInit() {
    if (!this.isMute) {
      window['ion'].sound.play('boiling'); // done
    }
    this.startBoiling();
  }

  initDate() {
    this.postfix = 'AM';
    this.time = '11:20';
  }

  private startBoiling() {
    this.afkBoiling = setInterval(() => {
      if (this.boilingProgress < 100) {
        this.boilingProgress += 8;
      } else {
        this.boiling.setBoiling(false);
        clearInterval(this.afkBoiling);
      }
    }, 500);

  }

  ngOnDestroy(): void {
    if (!this.isMute) {
      window['ion'].sound.pause('water_droplet'); // done
    }
  }
}
