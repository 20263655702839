import {Component, Input, OnInit} from '@angular/core';
import {ButtonDispensingMixWaterPremiumInterface, ButtonsPremiumState} from '../../../interfaces/simulator-premium';
import {Button} from '../../../shared/button/button.component';

@Component({
  selector: 'app-button-dispense-mix-premium',
  templateUrl: './button-dispense-mix-premium.component.html',
  styleUrls: ['./button-dispense-mix-premium.component.sass']
})
export class ButtonDispenseMixPremiumComponent extends Button implements OnInit {

  @Input() data: ButtonDispensingMixWaterPremiumInterface;
  public state: any;

  constructor() {
    super();
    this.state = ButtonsPremiumState;
  }

  ngOnInit() {
  }

}
