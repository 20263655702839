import Swiper from 'swiper/dist/js/swiper.js';
import {
  ButtonsPremiumState,
  IndicatorAddonStatePremium,
  IndicatorLockStatePremium,
  IndicatorNetStatePremium,
  IndicatorPurifierStatePremium,
  IndicatorUvStatePremium,
  IndicatorWifiStatePremium, PouringAnimation,
  SimulatorPremiumPagesInterface
} from '../../interfaces/simulator-premium';

export class Maintenance {
  public static maintenanceList = {
    he: [
      {
        id: 1,
        name: 'שטיפת מכלים',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.WashingTank,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
            boiling: {active: true, state: ButtonsPremiumState.NO_BORDER},
            jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        }
      },
      {
        id: 2,
        name: 'בדיקת מכשיר',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.BuiltInTest,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.FLASH},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT_BORDER},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
          }
        }
      },
      {
        id: 3,
        name: 'נעילת מקשים',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.LockButtonMessage,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
            boiling: {active: true, state: ButtonsPremiumState.NO_BORDER},
            jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
            personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
          }
        }
      },
      {
        id: 4,
        name: 'מטהר מים ונורת UV',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.PurifierAndUv,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
            personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
          }
        }
      },
      // {
      //   id: 4,
      //   name: 'נעילת מקשים',
      //   page: {
      //     indicator: {
      //       addon: {active: false, state: IndicatorAddonStatePremium.OFF},
      //       uv: {active: false, state: IndicatorUvStatePremium.OFF},
      //       wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
      //       clock: {active: false},
      //       lock: {active: false, state: IndicatorLockStatePremium.OFF},
      //       purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
      //       net: {active: false, state: IndicatorNetStatePremium.OFF}
      //     },
      //     page: SimulatorPremiumPagesInterface.LockButtonMessage,
      //     pouring: {fast: false, animation: PouringAnimation.NONE},
      //     buttons: {
      //       hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       boiling: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
      //     }
      //   }
      // },
      // {
      //   id: 4,
      //   name: 'מטהר מים ונורת UV',
      //   page: {
      //     indicator: {
      //       addon: {active: false, state: IndicatorAddonStatePremium.OFF},
      //       uv: {active: false, state: IndicatorUvStatePremium.OFF},
      //       wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
      //       clock: {active: false},
      //       lock: {active: false, state: IndicatorLockStatePremium.OFF},
      //       purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
      //       net: {active: false, state: IndicatorNetStatePremium.OFF}
      //     },
      //     page: SimulatorPremiumPagesInterface.PurifierAndUv,
      //     pouring: {fast: false, animation: PouringAnimation.NONE},
      //     buttons: {
      //       hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       boiling: {active: true, state: ButtonsPremiumState.BORDER},
      //       jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
      //     }
      //   }
      // },
      {
        id: 5,
        name: 'מצב שינה',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.ShabbatModeConfirmation,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.BORDER},
            personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
          }
        }
      },
      {
        id: 6,
        name: 'ניקוי פנימי',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          pouring: {fast: false, animation: PouringAnimation.NONE},
          page: SimulatorPremiumPagesInterface.InternalCleaningMain,
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.BORDER},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        }
      },
      {
        id: 7,
        name: 'שחזור הגדרות',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.Reset,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.BORDER},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        }
      },
    ],
    en: [
      {
        id: 1,
        name: 'Washing Tanks',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.WashingTank,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
            boiling: {active: true, state: ButtonsPremiumState.NO_BORDER},
            jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        }
      },
      {
        id: 2,
        name: 'Built in Test',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.BuiltInTest,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.FLASH},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT_BORDER},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
          }
        }
      },
      {
        id: 3,
        name: 'Buttons Lock',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.LockButtonMessage,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
            boiling: {active: true, state: ButtonsPremiumState.NO_BORDER},
            jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
            personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
          }
        }
      },
      {
        id: 4,
        name: 'Purifier & UV',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.PurifierAndUv,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
            personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
          }
        }
      },
      // {
      //   id: 4,
      //   name: 'Buttons Lock',
      //   page: {
      //     indicator: {
      //       addon: {active: false, state: IndicatorAddonStatePremium.OFF},
      //       uv: {active: false, state: IndicatorUvStatePremium.OFF},
      //       wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
      //       clock: {active: false},
      //       lock: {active: false, state: IndicatorLockStatePremium.OFF},
      //       purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
      //       net: {active: false, state: IndicatorNetStatePremium.OFF}
      //     },
      //     page: SimulatorPremiumPagesInterface.LockButtonMessage,
      //     pouring: {fast: false, animation: PouringAnimation.NONE},
      //     buttons: {
      //       hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       boiling: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
      //     }
      //   }
      // },
      // {
      //   id: 4,
      //   name: 'Purifier & UV',
      //   page: {
      //     indicator: {
      //       addon: {active: false, state: IndicatorAddonStatePremium.OFF},
      //       uv: {active: false, state: IndicatorUvStatePremium.OFF},
      //       wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
      //       clock: {active: false},
      //       lock: {active: false, state: IndicatorLockStatePremium.OFF},
      //       purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
      //       net: {active: false, state: IndicatorNetStatePremium.OFF}
      //     },
      //     page: SimulatorPremiumPagesInterface.PurifierAndUv,
      //     pouring: {fast: false, animation: PouringAnimation.NONE},
      //     buttons: {
      //       hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       boiling: {active: true, state: ButtonsPremiumState.BORDER},
      //       jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
      //       personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
      //     }
      //   }
      // },
      {
        id: 5,
        name: 'Sleep Mode',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.ShabbatModeConfirmation,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.BORDER},
            personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
          }
        }
      },
      {
        id: 6,
        name: 'Internal Cleaning',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.InternalCleaningMain,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.BORDER},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        }
      },
      {
        id: 7,
        name: 'Reset',
        page: {
          indicator: {
            addon: {active: false, state: IndicatorAddonStatePremium.OFF},
            uv: {active: false, state: IndicatorUvStatePremium.OFF},
            wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: false, state: IndicatorLockStatePremium.OFF},
            purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.Reset,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.BORDER},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          }
        }
      }
    ]
  };

  public static maintenanceSwiper = null;
  public static currentIndex = 3;

  public static initMaintenanceSwiper() {
    setTimeout(() => {
      Maintenance.maintenanceSwiper = new Swiper('#maintenance', {
        direction: 'vertical',
        loop: false,
        slidesPerView: 5,
        spaceBetween: 0,
        simulateTouch: false,
        autoHeight: true,
        height: 280,
        centeredSlides: true,
        watchOverflow: false,
        slidesPerColumn: 1,
        normalizeSlideIndex: true,
        initialSlide: 3
      });
      // Maintenance.currentIndex++;
    }, 0);
  }

  public static reset() {
    Maintenance.maintenanceSwiper = null;
    Maintenance.currentIndex = 3;
  }
}
