import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import {Menu} from '../../data/menu';

@Component({
  selector: 'app-menu-premium',
  templateUrl: './menu-premium.component.html',
  styleUrls: ['./menu-premium.component.sass']
})
export class MenuPremiumComponent implements OnInit {

  public lang = null;
  public menu = null;
  public text = {
    he: 'תפריט',
    en: 'Menu'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Menu premium', lang);
      this.lang = lang;
    });

    this.menu = Menu;
  }

  ngOnInit() {
  }

}
