import {SimulatorPremiumPagesInterface} from '../../interfaces/simulator-premium';

export class QuantityAndTemp {

  public static currentDrinks = null;

  public static currentJug = null;
  public static step = 0;
  public static temp = 45;
  public static isJugs = false;
  public static isPersonal = false;


  public static incTemp() {
    if (QuantityAndTemp.temp < 96) {
      QuantityAndTemp.temp++;
    }
  }

  public static decTemp() {
    if (QuantityAndTemp.temp > 0) {
      QuantityAndTemp.temp--;
    }
  }

  public static reset() {
    QuantityAndTemp.temp = 45;
    QuantityAndTemp.step = 0;
    QuantityAndTemp.currentDrinks = 0;
    QuantityAndTemp.isJugs = false;
    QuantityAndTemp.drinks = QuantityAndTemp.allDrinks;
    QuantityAndTemp.isPersonal = false;
  }

  public static allDrinks = {
    he: [
      {
        id: 1,
        name: 'מים קרים',
        img: 'cold_jug.svg',
        run: SimulatorPremiumPagesInterface.ColdWaterDispensing
      },
      {
        id: 2,
        name: 'מים חמים',
        img: 'hot_jug.svg',
        run: SimulatorPremiumPagesInterface.HotWaterDispensing

      },
      {
        id: 3,
        name: 'קנקנים וסירים',
        img: 'warm_jug.svg',
        run: SimulatorPremiumPagesInterface.Jugs
      },
      {
        id: 4,
        name: 'מים פושרים',
        img: 'warm_jug.svg',
        run: SimulatorPremiumPagesInterface.MixWaterDispensing
      }
    ],
    en: [
      {
        id: 1,
        name: 'Cold Water',
        img: 'cold_jug.svg',
        run: SimulatorPremiumPagesInterface.ColdWaterDispensing
      },
      {
        id: 2,
        name: 'Hot Water',
        img: 'hot_jug.svg',
        run: SimulatorPremiumPagesInterface.HotWaterDispensing

      },
      {
        id: 3,
        name: 'Jugs & Pots',
        img: 'pot.svg',
        run: SimulatorPremiumPagesInterface.Jugs
      },
      {
        id: 4,
        name: 'Mix Water',
        img: 'warm_jug.svg',
        run: SimulatorPremiumPagesInterface.MixWaterDispensing
      }
    ]
  };

  public static drinks = QuantityAndTemp.allDrinks;

}
