import {
  ButtonDispenseColdStatesInterface,
  ButtonDispenseHotStatesInterface, ButtonDispenseMixStatesInterface,
  SimulatorEntryInterface,
  SimulatorPagesInterface,
  SimulatorStatesInterface
} from '../../interfaces/simulator-entry';

export class EntryPageCH {

  static initial(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.page = SimulatorPagesInterface.CH;
    simulatorEntry.display.data = 'CH';
    simulatorEntry.buttons.dispenseHot.state = ButtonDispenseHotStatesInterface.FLASHING;
    simulatorEntry.buttons.dispenseMix.state = ButtonDispenseMixStatesInterface.OFF;
    simulatorEntry.buttons.dispenseCold.state = ButtonDispenseColdStatesInterface.OFF;
  }

  static clickButtonDispenseHot(simulatorEntry: SimulatorEntryInterface) {
    switch (simulatorEntry.display.data) {
      case 'CH':
        simulatorEntry.display.data = 'ON';
        break;
      case 'ON':
        simulatorEntry.display.data = 'OF';
        break;
      case 'OF':
        simulatorEntry.display.data = 'ON';
        break;
    }
  }

  static clickButtonSettings(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.buttons.dispenseHot.state = ButtonDispenseHotStatesInterface.OFF;
    if (simulatorEntry.display.data === 'ON') {
      simulatorEntry.state = SimulatorStatesInterface.LOCKED;
      simulatorEntry.display.data = '  ';
      return true;
    } else {
      return false;
    }
  }


}
