import { Component, OnInit } from '@angular/core';
import {SimulatorPremiumPagesInterface} from '../../../interfaces/simulator-premium';
import {LanguageService} from '../../../../../language.service';
import {TechScreen} from '../../data/techScreen';
@Component({
  selector: 'app-wash-hot-tank-tech-premium',
  templateUrl: './wash-hot-tank-tech-premium.component.html',
  styleUrls: ['./wash-hot-tank-tech-premium.component.sass']
})
export class WashHotTankTechPremiumComponent implements OnInit {

  public lang = null;
  public pages = SimulatorPremiumPagesInterface;
  public cup;
  public techScreen = TechScreen;

  public textTop = {
    he: 'הגדרה והפעלה',
    en: 'Define & Activate'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };
  text_step_1 = null;
  text_step_2 = null;
  text_step_5 = null;

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Activate jugs', lang);
      this.lang = lang;
    });

    this.cup = 'hot';

    this.text_step_1 = {
      he: {
        line_1: 'יש להניח מכל גדול',
        line_2: 'מתחת לפיית המזיגה',
        line_3: 'לשטיפת מכל המים החמים',
        line_4: 'יש ללחוץ על כפתור מים ' + this.getSpan()
      },
      en: {
        line_1: 'Place a large container under the spout',
        line_2: 'under the spout',
        line_3: 'Press ' + this.getSpan() + ' water to',
        line_4: 'begin washing hot tank',
      }
    };

    this.text_step_2 = {
      he: {
        line_1: 'מיכל מים חמים בתהליך שטיפה',
        line_2: 'אנא המתן...',
      },
      en: {
        line_1: 'Washing tank',
        line_2: 'Please wait...',
      }
    };


    this.text_step_5 = {
      he: 'התהליך הסתיים',
      en: 'Process completed'
    };
  }


  private getSpan() {
      return this.lang === 'he' ? '<span style="color:#F20000">חמים</span>' : '<span style="color:#F20000">Hot</span>';
  }

  ngOnInit(): void {
  }

}
