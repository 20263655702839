import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LanguageService} from '../../language.service';
import {MuteService} from '../../mute.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.sass']
})
export class TopNavComponent implements OnInit {

  public currentDevice = 'entry';
  public lang = null;
  public navigation = [
    {
      title: {
        he: 'מדריך',
        en: 'Manual'
      },
      active: true,
      link: 'instructor'
    },
    {
      title: {
        he: 'סימולציה',
        en: 'Simulator'
      },
      active: false,
      link: 'simulator'
    }
  ];

  public isMute = null;
  public isEng = false;

  constructor(private router: Router, private route: ActivatedRoute, private langService: LanguageService, private muteService: MuteService) {
    this.lang = langService.getLang();

    this.langService.language.subscribe((lang) => {
      console.log('top-nav-component', lang);
      this.lang = lang;
    });

    this.router.events.subscribe((param) => {
      if (param instanceof NavigationEnd) {
        let currentMode = param.url;
        let params = currentMode.split('/');
        currentMode = params[2];
        currentMode = !currentMode ? 'instructor' : currentMode;
        this.navigation.map((element) => {
          element.active = element.link === currentMode;
        });

        this.currentDevice = this.route.root.firstChild.params['value']['device'];
        this.isEng = param.url.substr(0,4) === '/eng';
      }
    });

    this.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      this.isMute = mute;
    })
  }

  ngOnInit() {
  }

  public toggleMute() {
    this.isMute = !this.isMute;
    this.muteService.setMode(this.isMute);
  }


}
