// Factory mode

import {SimulatorEntryInterface, SimulatorPagesInterface} from '../../interfaces/simulator-entry';

export class EntryPageFC {
  static initial(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.page = SimulatorPagesInterface.FC;
    simulatorEntry.display.data = 'FC';
  }

  static clickButtonSettings(simulatorEntry: SimulatorEntryInterface): boolean {
    return false;
  }
}
