import {Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2} from '@angular/core';

@Directive({
  selector: '[appTechScreenPress]'
})
export class TechScreenPressDirective {

  @Output() techPressDown: EventEmitter<object> = new EventEmitter();
  @Output() techPressUp: EventEmitter<object> = new EventEmitter();
  @Input() key: string;
  @Input() disabledKey: boolean;

  private timeout: any;
  private duration = 1000;
  private isLongClick = true;
  private isLongClickDone = false;

  constructor(private element: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
    if (this.isLongClick) {
      this.isLongClick = false;
      this.timeout = setTimeout(() => {
        if (event.code === this.key && !this.disabledKey) {
          this.renderer.addClass(this.element.nativeElement, 'active-btn');
          this.techPressDown.emit(event);
          this.isLongClickDone = true;
          clearInterval(this.timeout);
        }
      }, this.duration);
    }
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event) {
    this.isLongClick = true;

    if (this.isLongClickDone) {
      this.techPressUp.emit(event);
    }
    this.isLongClickDone = false;
    this.renderer.removeClass(this.element.nativeElement, 'active-btn');
    clearInterval(this.timeout);
  }

}
