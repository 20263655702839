import {Component, Input, OnInit} from '@angular/core';
import {IndicatorWifiPremiumInterface, IndicatorWifiStatePremium} from '../../../interfaces/simulator-premium';

@Component({
  selector: 'app-indicator-wifi-premium',
  templateUrl: './indicator-wifi-premium.component.html',
  styleUrls: ['./indicator-wifi-premium.component.sass']
})
export class IndicatorWifiPremiumComponent implements OnInit {

  @Input() data: IndicatorWifiPremiumInterface;
  public state: any;

  constructor() {
    this.state = IndicatorWifiStatePremium;
  }

  ngOnInit() {
  }

}
