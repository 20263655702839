import { Component, OnInit } from "@angular/core";
import {
  ButtonsPremiumState,
  PouringAnimation,
} from "../interfaces/simulator-premium";
import { MuteService } from "../../../mute.service";

@Component({
  selector: "app-simulator-joey",
  templateUrl: "./simulator-joey.component.html",
  styleUrls: ["./simulator-joey.component.sass"],
})
export class SimulatorJoeyComponent implements OnInit {
  public helper = null;
  public PouringAnimation = PouringAnimation;
  public audioButtonPress = new Audio("/assets/audio/water_droplet.mp3");

  public isMute = null;
  public lang = null;
  public current = null;

  public playing = false;

  public gif = new Image();
  public gifDouble = new Image();
  public gifSrc = null;

  public isSingleClick = false;

  constructor(private muteService: MuteService) {
    this.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      this.isMute = mute;
    });
    this.gif.src = "/assets/img/joey-pour.png";
    this.gifDouble.src = "/assets/img/joey-pour-jug.png";
  }

  ngOnInit() {
    this.audioButtonPress.load();
  }

  public play() {
    // done
    if (!this.isMute) {
      this.audioButtonPress.pause();
      this.audioButtonPress.load();
      this.audioButtonPress.play(); // done
    }
  }

  public pour() {
    this.isSingleClick = true;
    setTimeout(() => {
      if (this.isSingleClick) {
        this.playing = true;
        this.gifSrc = null;
        this.gifSrc = this.gif.src;
        setTimeout(() => {
          this.playing = false;
          this.gifSrc = null;
        }, 2400);
      }
    }, 250);
  }

  public pourDouble() {
    this.isSingleClick = false;
    this.playing = true;
    this.gifSrc = null;
    this.gifSrc = this.gifDouble.src;
    setTimeout(() => {
      this.playing = false;
      this.gifSrc = null;
    }, 2400);
  }
}
