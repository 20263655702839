import { Component, OnInit } from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import {Maintenance} from '../../data/maintenance';
import {Preference} from '../../data/preference';

@Component({
  selector: 'app-preferences-premium',
  templateUrl: './preferences-premium.component.html',
  styleUrls: ['./preferences-premium.component.sass']
})
export class PreferencesPremiumComponent implements OnInit {

  public preferences = null;

  public lang = null;

  public text = {
    he: 'העדפות',
    en: 'Preferences'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Preferences premium', lang);
      this.lang = lang;
      Preference.reset();
      Preference.initPreferencesSwiper();
    });
    this.preferences = Preference;
  }

  ngOnInit() {
  }

}
