import {Component, OnInit} from '@angular/core';
import {InstructorService} from '../../../instructor.service';
import baron from 'baron';
import {LanguageService} from '../../../language.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MuteService} from '../../../mute.service';

@Component({
  selector: 'app-content-area',
  templateUrl: './content-area.component.html',
  styleUrls: ['./content-area.component.sass']
})
export class ContentAreaComponent implements OnInit {
  public id = null;
  public title = null;
  public steps = null;
  public info = null;
  public currentStep = null;
  private vScroll = null;
  private levelPosition = null;
  public lang = null;
  public imgPath = null;
  public isVisible = true;

  public subtitle = {
    he: 'גללו מטה כדי להתקדם בתהליך',
    en: 'Scroll down to move forward'
  };
  public btnCommon = {
    he: 'התהליך',
    en: 'Process'
  };
  public btnNext = {
    he: 'הבא',
    en: 'NEXT'
  };

  public btnPrev = {
    he: 'הקודם',
    en: 'PREV'
  };

  public prevDisable: boolean;
  public nextDisable: boolean;
  public device = null;
  public isMute = null;
  public isEng = false;

  constructor(private instService: InstructorService, private langService: LanguageService, private route: ActivatedRoute, private router: Router,  private muteService: MuteService) {

    this.lang = langService.getLang();
    // todo fix it back
    this.imgPath = this.lang === 'he' ? 'hebrew' : 'english';
    this.langService.language.subscribe((lang) => {
      console.log('content-area-component', lang);
      this.isVisible = false;

      this.lang = lang;
      this.imgPath = this.lang === 'he' ? 'hebrew' : 'english';
    });


    this.isEng = true;
    this.instService.levelPositions.subscribe((levelPositions) => {
      this.levelPosition = levelPositions;
      this.setStep({}, 0, true);
    });

    this.instService.prevButtonDisable.subscribe((isDisable) => {
      this.prevDisable = isDisable;
    });

    this.instService.nextButtonDisable.subscribe((isDisable) => {
      this.nextDisable = isDisable;
    });

    // this.route.params.subscribe(params => {
    //   this.device = params['device'];
    //
    // });
    this.device = 'premium'

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
          if (e.url.substr(0,4) === '/eng') {
            this.isEng = true;
          } else {
            this.isEng = true;
          }
      }
    })

    this.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      this.isMute = mute
    })
  }

  ngOnInit() {
    // init custom scroll
    this.vScroll = baron({
      root: '.baron-steps',
      scroller: '.baron-steps__scroller',
      bar: '.baron-steps__bar'
    });
    // subscribe for current steps
    this.instService.currentSteps.subscribe((content) => {
      this.id = content.id;
      this.title = content.title;
      this.steps = content.steps.items;
      this.steps[0].active = true;
      this.info = content.steps.info;
      this.currentStep = this.steps[0];
      document.getElementById('stepArea').scrollTo(0, 0);
      //
      setTimeout(() => {
        this.vScroll.update();
      }, 250);

    });
  }

  public mouseSetStep(step: object, index: number, clear = false): void {
    if(!this.isMute) {
      window['ion'].sound.play('snap'); // done
    }
    this.setStep(step, index, clear);
  }

  public setStep(step: object, index: number, clear = false): void {

    if (this.steps) {
      if (clear) {
        for (let i = 0; i < this.steps.length; i++) {
          this.steps[i].active = false;
          this.steps[i].passed = false;
        }
      }
      for (let i = 0; i < this.steps.length; i++) {
        this.steps[i].active = false;
        this.steps[i].passed = false;
      }

      for (let i = 0; i < index; i++) {
        this.steps[i].active = false;
        this.steps[i].passed = true;
      }
      this.currentStep = this.steps[index];
      this.steps[index].active = true;
    }
  }

  public goNext() {
    if (!this.isMute) {
      window['ion'].sound.play('water_droplet'); // done
    }
    let url = this.instService.setLevelPositionsNext(this.levelPosition);
    this.router.navigate([(this.isEng ? '/eng/' : '/') + 'instructor/' + url]);
    // let doc = document.getElementById(url + '_sc_item');
    // doc.scrollIntoView({block: 'center', inline: 'center'});


    window['jQuery']('#scrollId').animate({
      scrollTop: window['jQuery']('#' + url + '_sc_item').offset().top - window['jQuery']('#chaptersList').offset().top
    }, 500);

    // window['jQuery']('#stepArea').animate({
    //   scrollTop: 0
    // }, 500);
  }

  public goPrev() {
    if (!this.isMute) {
      window['ion'].sound.play('water_droplet'); // done
    }
    let url = this.instService.setLevelPositionsPrev(this.levelPosition);
    this.router.navigate([(this.isEng ? '/eng/' : '/') + 'instructor/' + url]);
    // let doc = document.getElementById(url + '_sc_item');
    // doc.scrollIntoView({block: 'center', inline: 'center'});\

    window['jQuery']('#scrollId').animate({
      scrollTop: window['jQuery']('#' + url + '_sc_item').offset().top - window['jQuery']('#chaptersList').offset().top
    }, 500);

    // window['jQuery']('#stepArea').animate({
    //   scrollTop: 0
    // }, 500);
  }

}
