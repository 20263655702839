import {Component, Input, OnInit} from '@angular/core';
import {IndicatorWifiInterface, IndicatorWifiStateInterface} from '../../../interfaces/simulator-entry';

@Component({
  selector: 'app-indicator-wifi-entry',
  templateUrl: './indicator-wifi-entry.component.html',
  styleUrls: ['./indicator-wifi-entry.component.sass']
})
export class IndicatorWifiEntryComponent implements OnInit {

  @Input() data: IndicatorWifiInterface;
  public state;

  constructor() {
    this.state = IndicatorWifiStateInterface;
  }

  ngOnInit() {
    console.groupCollapsed('Simulator - Indicator - WiFi');
    console.log('ngOnInit state: ', this.data);
    console.groupEnd();
  }

}
