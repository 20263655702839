// COLD / MIX / HOT - Temperature settigns

import {
  ButtonDispenseColdStatesInterface,
  ButtonDispenseHotStatesInterface,
  ButtonDispenseMixStatesInterface,
  SimulatorEntryInterface,
  SimulatorPagesInterface,
  SimulatorSubpagesInterface,
  TempColdInterface,
  TempHotInterface,
  TempMixInterface
} from '../../interfaces/simulator-entry';

export class EntryPageHC {
  static initial(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.page = SimulatorPagesInterface.HC;
    simulatorEntry.display.data = 'HC';

    // buttons
    simulatorEntry.buttons.dispenseCold.state = ButtonDispenseColdStatesInterface.FLASHING;
    simulatorEntry.buttons.dispenseMix.state = ButtonDispenseMixStatesInterface.FLASHING;
    simulatorEntry.buttons.dispenseHot.state = ButtonDispenseHotStatesInterface.FLASHING;
  }

  static clickButtonSettings(simulatorEntry: SimulatorEntryInterface): boolean {
    switch (simulatorEntry.subpage) {
      case SimulatorSubpagesInterface.NONE:
        return false;
        break;
      case SimulatorSubpagesInterface.HC_COLD:
        // alert('cold saved');
        simulatorEntry.subpage = SimulatorSubpagesInterface.NONE;
        this.clickButtonDispenseMix(simulatorEntry);
        return true;
        break;
      case SimulatorSubpagesInterface.HC_MIX:
        // alert('mix saved');
        simulatorEntry.subpage = SimulatorSubpagesInterface.NONE;
        this.clickButtonDispenseHot(simulatorEntry);
        return true;
        break;
      case SimulatorSubpagesInterface.HC_HOT:
        // alert('hot saved - now go back to main interface');
        simulatorEntry.subpage = SimulatorSubpagesInterface.NONE;
        this.initial(simulatorEntry);
        return true;
        break;

    }
    return false;
  }

  static clickButtonDispenseCold(simulatorEntry: SimulatorEntryInterface): boolean {
    if (simulatorEntry.subpage === SimulatorSubpagesInterface.NONE) {
      simulatorEntry.subpage = SimulatorSubpagesInterface.HC_COLD;
      simulatorEntry.buttons.dispenseCold.state = ButtonDispenseColdStatesInterface.FLASHING;
      simulatorEntry.buttons.dispenseMix.state = ButtonDispenseMixStatesInterface.OFF;
      simulatorEntry.buttons.dispenseHot.state = ButtonDispenseHotStatesInterface.OFF;
    } else {
      switch (simulatorEntry.temp.cold) {
        case TempColdInterface.t4:
          simulatorEntry.temp.cold = TempColdInterface.t5;
          break;
        case TempColdInterface.t5:
          simulatorEntry.temp.cold = TempColdInterface.t6;
          break;
        case TempColdInterface.t6:
          simulatorEntry.temp.cold = TempColdInterface.t7;
          break;
        case TempColdInterface.t7:
          simulatorEntry.temp.cold = TempColdInterface.t8;
          break;
        case TempColdInterface.t8:
          simulatorEntry.temp.cold = TempColdInterface.t9;
          break;
        case TempColdInterface.t9:
          simulatorEntry.temp.cold = TempColdInterface.t10;
          break;
        case TempColdInterface.t10:
          simulatorEntry.temp.cold = TempColdInterface.t11;
          break;
        case TempColdInterface.t11:
          simulatorEntry.temp.cold = TempColdInterface.t12;
          break;
        case TempColdInterface.t12:
          simulatorEntry.temp.cold = TempColdInterface.t4;
          break;
      }
    }
    simulatorEntry.display.data = ('' + simulatorEntry.temp.cold).length > 1 ? '' + simulatorEntry.temp.cold : ' ' + simulatorEntry.temp.cold;

    return false;
  }

  static clickButtonDispenseMix(simulatorEntry: SimulatorEntryInterface): boolean {
    if (simulatorEntry.subpage === SimulatorSubpagesInterface.NONE) {
      simulatorEntry.subpage = SimulatorSubpagesInterface.HC_MIX;
      simulatorEntry.buttons.dispenseCold.state = ButtonDispenseColdStatesInterface.OFF;
      simulatorEntry.buttons.dispenseMix.state = ButtonDispenseMixStatesInterface.FLASHING;
      simulatorEntry.buttons.dispenseHot.state = ButtonDispenseHotStatesInterface.OFF;
    } else {
      switch (simulatorEntry.temp.mix) {
        case TempMixInterface.t10:
          simulatorEntry.temp.mix = TempMixInterface.t13;
          break;
        case TempMixInterface.t13:
          simulatorEntry.temp.mix = TempMixInterface.t16;
          break;
        case TempMixInterface.t16:
          simulatorEntry.temp.mix = TempMixInterface.t19;
          break;
        case TempMixInterface.t19:
          simulatorEntry.temp.mix = TempMixInterface.t22;
          break;
        case TempMixInterface.t22:
          simulatorEntry.temp.mix = TempMixInterface.t25;
          break;
        case TempMixInterface.t25:
          simulatorEntry.temp.mix = TempMixInterface.t28;
          break;
        case TempMixInterface.t28:
          simulatorEntry.temp.mix = TempMixInterface.t30;
          break;
        case TempMixInterface.t30:
          simulatorEntry.temp.mix = TempMixInterface.t33;
          break;
        case TempMixInterface.t33:
          simulatorEntry.temp.mix = TempMixInterface.t35;
          break;
        case TempMixInterface.t35:
          simulatorEntry.temp.mix = TempMixInterface.t37;
          break;
        case TempMixInterface.t37:
          simulatorEntry.temp.mix = TempMixInterface.t40;
          break;
        case TempMixInterface.t40:
          simulatorEntry.temp.mix = TempMixInterface.t50;
          break;
        case TempMixInterface.t50:
          simulatorEntry.temp.mix = TempMixInterface.t55;
          break;
        case TempMixInterface.t55:
          simulatorEntry.temp.mix = TempMixInterface.t60;
          break;
        case TempMixInterface.t60:
          simulatorEntry.temp.mix = TempMixInterface.t70;
          break;
        case TempMixInterface.t70:
          simulatorEntry.temp.mix = TempMixInterface.t82;
          break;
        case TempMixInterface.t82:
          simulatorEntry.temp.mix = TempMixInterface.t85;
          break;
        case TempMixInterface.t85:
          simulatorEntry.temp.mix = TempMixInterface.t87;
          break;
        case TempMixInterface.t87:
          simulatorEntry.temp.mix = TempMixInterface.t10;
          break;
      }
    }
    simulatorEntry.display.data = ('' + simulatorEntry.temp.mix).length > 1 ? '' + simulatorEntry.temp.mix : ' ' + simulatorEntry.temp.mix;

    return false;
  }

  static clickButtonDispenseHot(simulatorEntry: SimulatorEntryInterface): boolean {
    if (simulatorEntry.subpage === SimulatorSubpagesInterface.NONE) {
      simulatorEntry.subpage = SimulatorSubpagesInterface.HC_HOT;
      simulatorEntry.buttons.dispenseCold.state = ButtonDispenseColdStatesInterface.OFF;
      simulatorEntry.buttons.dispenseMix.state = ButtonDispenseMixStatesInterface.OFF;
      simulatorEntry.buttons.dispenseHot.state = ButtonDispenseHotStatesInterface.FLASHING;
    } else {
      switch (simulatorEntry.temp.hot) {
        case TempHotInterface.t88:
          simulatorEntry.temp.hot = TempHotInterface.t90;
          break;
        case TempHotInterface.t90:
          simulatorEntry.temp.hot = TempHotInterface.t92;
          break;
        case TempHotInterface.t92:
          simulatorEntry.temp.hot = TempHotInterface.t94;
          break;
        case TempHotInterface.t94:
          simulatorEntry.temp.hot = TempHotInterface.t96;
          break;
        case TempHotInterface.t96:
          simulatorEntry.temp.hot = TempHotInterface.t97;
          break;
        case TempHotInterface.t97:
          simulatorEntry.temp.hot = TempHotInterface.t98;
          break;
        case TempHotInterface.t98:
          simulatorEntry.temp.hot = TempHotInterface.t99;
          break;
        case TempHotInterface.t99:
          simulatorEntry.temp.hot = TempHotInterface.t100;
          break;
        case TempHotInterface.t100:
          simulatorEntry.temp.hot = TempHotInterface.t88;
          break;
      }
    }
    simulatorEntry.display.data = ('' + simulatorEntry.temp.hot).length > 1 ? '' + simulatorEntry.temp.hot : ' ' + simulatorEntry.temp.hot;

    return false;
  }
}
