import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import {LockButton} from '../../data/lockButton';

@Component({
  selector: 'app-lock-buttons-message-premium',
  templateUrl: './lock-buttons-message-premium.component.html',
  styleUrls: ['./lock-buttons-message-premium.component.sass']
})
export class LockButtonsMessagePremiumComponent implements OnInit {

  public lang = null;
  public lockButton = null;

  public buttonsLock = {
    line_1: {
      he: 'הכפתורים',
      en: ''
    },
    line_2: {
      he: 'מקשים נעולים',
      en: 'are locked'
    }
  };

  public buttonsUnlock = {
    line_1: {
      he: '',
      en: 'Buttons'
    },
    line_2: {
      he: 'נעילת מקשים מבוטלת',
      en: 'are unlocked'
    }
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Lock buttons message premium', lang);
      this.lang = lang;
    });
    this.lockButton = LockButton;
  }

  ngOnInit() {
  }

}
