import {
  ButtonDispenseColdStatesInterface,
  ButtonDispenseHotStatesInterface,
  ButtonDispenseMixStatesInterface,
  ButtonHotwaterStatesInterface,
  ButtonPitcherStatesInterface,
  ButtonSettingsStatesInterface,
  SimulatorEntryInterface,
  SimulatorPagesInterface
} from '../../interfaces/simulator-entry';

export class EntryPageC {
  static isGoNext = false;
  static initial(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.page = SimulatorPagesInterface.CC;
    simulatorEntry.display.data = ' C';
    simulatorEntry.buttons =  {
      dispenseCold: {active: false, state: ButtonDispenseColdStatesInterface.ACTIVE},
      dispenseMix: {active: false, state: ButtonDispenseMixStatesInterface.OFF},
      dispenseHot: {active: false, state: ButtonDispenseHotStatesInterface.ACTIVE},
      pitcher: {active: false, state: ButtonPitcherStatesInterface.OFF},
      settings: {active: false, state: ButtonSettingsStatesInterface.ACTIVE},
      hotWater: {active: false, state: ButtonHotwaterStatesInterface.OFF},
    }
    EntryPageC.isGoNext = false;
  }

  static clickButtonSettings(simulatorEntry: SimulatorEntryInterface) {
    if ( EntryPageC.isGoNext) {
      EntryPageC.initial(simulatorEntry);
      return true
    } else {
      return false;
    }
  }

  static clickHotButton(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.display.data = '90';
    EntryPageC.isGoNext = true;
  }

  static clickColdButton(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.display.data = '4';
    EntryPageC.isGoNext = true;
  }
}
