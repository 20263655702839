import {
  ButtonDispenseColdStatesInterface, ButtonDispenseHotStatesInterface,
  ButtonDispenseMixStatesInterface, ButtonHotwaterStatesInterface, ButtonPitcherStatesInterface, ButtonSettingsStatesInterface,
  SimulatorEntryInterface,
  SimulatorPagesInterface
} from '../../interfaces/simulator-entry';

export class EntryPageSH {
  static initial(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.page = SimulatorPagesInterface.SH;
    simulatorEntry.display.data = 'SH';

    simulatorEntry.buttons =  {
      dispenseCold: {active: false, state: ButtonDispenseColdStatesInterface.FLASHING},
      dispenseMix: {active: false, state: ButtonDispenseMixStatesInterface.ACTIVE},
      dispenseHot: {active: false, state: ButtonDispenseHotStatesInterface.FLASHING},
      pitcher: {active: false, state: ButtonPitcherStatesInterface.FLASHING},
      settings: {active: false, state: ButtonSettingsStatesInterface.ACTIVE},
      hotWater: {active: false, state: ButtonHotwaterStatesInterface.FLASHING},
    }
  }

  static clickButtonSettings(simulatorEntry: SimulatorEntryInterface): boolean {
    if (simulatorEntry.display.data !== 'SH') {
      if (simulatorEntry.display.data === 'ON') {
        simulatorEntry.settings.sound = true;
      } else {
        simulatorEntry.settings.sound = false;
      }
      return true;
    } else {
      return false;
    }
  }
}
