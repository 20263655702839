import {Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2} from '@angular/core';

@Directive({
  selector: '[appShortClick]'
})
export class ShortClickDirective {

  @Output() shortClick: EventEmitter<object> = new EventEmitter();
  private duration = 250;
  private timeout: any;
  private isShortClick: boolean;

  constructor(private element: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('mousedown') onMouseDown() {
    this.isShortClick = true;
    this.renderer.addClass(this.element.nativeElement, 'active-btn');
    this.timeout = setTimeout(() => {
      this.isShortClick = false;
    }, this.duration);
  }

  @HostListener('mouseup', ['$event']) onMouseUp(event) {
    if (this.isShortClick) {
      this.shortClick.emit(event);
    }
    this.renderer.removeClass(this.element.nativeElement, 'active-btn');
    clearTimeout(this.timeout);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.renderer.removeClass(this.element.nativeElement, 'active-btn');
    clearTimeout(this.timeout);
  }

}
