import {Component, OnInit} from '@angular/core';
import {SimulatorPremiumPagesInterface} from '../../../interfaces/simulator-premium';
import {Jug} from '../../data/jug';
import {LanguageService} from '../../../../../language.service';

@Component({
  selector: 'app-activate-jugs-premium',
  templateUrl: './activate-jugs-premium.component.html',
  styleUrls: ['./activate-jugs-premium.component.sass'],
})
export class ActivateJugsPremiumComponent implements OnInit {

  public lang = null;

  public jug;
  public pages = SimulatorPremiumPagesInterface;
  public cup;
  public scaleClass;

  public textTop = {
    he: 'הגדרה והפעלה',
    en: 'Define & Activate'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };
  text_step_1 = null;
  text_step_2 = null;
  text_step_3 = null;
  text_step_4 = null;
  text_step_5 = null;

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Activate jugs', lang);
      this.lang = lang;
    });

    this.jug = Jug;

    this.cup = this.getScaleClass();
    this.scaleClass = this.getScaleClass();

    this.text_step_1 = {
      he: {
        line_1: ' שלב 1 מתוך 2',
        line_2: 'יש להניח כלי',
        line_3: 'מתחת לפיית המזיגה',
        line_4: 'להגדרת כמות',
        line_5: 'יש ללחוץ על כפתור מים ' + this.getSpan()
      },
      en: {
        line_1: ' Step 1 of 2',
        line_2: 'Place a pot under the spout',
        line_3: '',
        line_4: 'Press ' + this.getSpan() + ' water',
        line_5: '...to start recording quantity',
      }
    };

    this.text_step_2 = {
      he: {
        line_1: 'שלב 1 מתוך 2',
        line_2: 'מגדיר כמות',
        line_3: 'לחיצה על כל כפתור תעצור מזיגה',
      },
      en: {
        line_1: ' Step 1 of 2',
        line_2: 'Recording quantity',
        line_3: 'Press any key to stop',
      }
    };

    this.text_step_3 = {
      he: {
        line_1: 'שלב 1 מתוך 2',
        line_2: 'כמות הוגדרה',
        line_3: 'בהצלחה'
      },
      en: {
        line_1: ' Step 1 of 2',
        line_2: 'Quantity recorded',
        line_3: 'successfully'
      }
    };

    this.text_step_4 = {
      he: {
        line_1: 'שלב 2 מתוך 2',
        line_2: 'הגדרת טמפרטורה',
      },
      en: {
        line_1: 'Step 2 of 2',
        line_2: 'Define Pot\'s Temperature',
      }
    };

    this.text_step_5 = {
      he: Jug.currentJug.name + ' הוגדר בהצלחה',
      en: Jug.currentJug.name + ' is activated'
    };

    console.log(this.jug.currentJug);
  }


  private getSpan() {
    if (this.jug.currentJug.run === SimulatorPremiumPagesInterface.MixWaterDispensing) {
      return this.lang === 'he' ? '<span style="color:#FFFFFF">פושרים</span>' : '<span style="color:#FFFFFF">Mix</span>';
    } else if (this.jug.currentJug.run === SimulatorPremiumPagesInterface.HotWaterDispensing) {
      return this.lang === 'he' ? '<span style="color:#F20000">חמים</span>' : '<span style="color:#F20000">Hot</span>';
    } else {
      return this.lang === 'he' ? '<span style="color:#1599FF">קרים</span>' : '<span style="color:#1599FF">Cold</span>';
    }
  }

  // private getCupImg() {
  //   if (this.jug.currentJug.run === SimulatorPremiumPagesInterface.MixWaterDispensing) {
  //     return 'mix';
  //   } else if (this.jug.currentJug.run === SimulatorPremiumPagesInterface.HotWaterDispensing) {
  //     return 'hot';
  //   } else {
  //     return 'cold';
  //   }
  // }


  private getScaleClass() {
    if (this.jug.currentJug.run === SimulatorPremiumPagesInterface.MixWaterDispensing) {
      return 'mix';
    } else if (this.jug.currentJug.run === SimulatorPremiumPagesInterface.HotWaterDispensing) {
      return 'hot';
    } else {
      return 'cold';
    }
  }

  ngOnInit() {
  }

}
