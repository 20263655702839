/* BUTTONS */


/* INDICATORS */
/* INDICATORS - UV */
export enum IndicatorUvStateInterface {
  OFF,
  RED,
  RED_FLASH,
  BLUE,
  BLUE_FLASH
}
export interface IndicatorUVInterface {
  active: boolean;
  state: IndicatorUvStateInterface;
}
/* INDICATORS - Purifier */
export enum IndicatorPurifierStateInterface {
  OFF,
  RED,
  RED_FLASH,
  BLUE,
  BLUE_FLASH
}
export interface IndicatorPurifierInterface {
  active: boolean;
  state: IndicatorPurifierStateInterface;
}
/* INDICATORS - WiFi */
export enum IndicatorWifiStateInterface {
  OFF,
  RED,
  RED_FLASH,
  BLUE,
  BLUE_FLASH
}
export interface IndicatorWifiInterface {
  active: boolean;
  state: IndicatorWifiStateInterface;
}
/* INDICATORS - Clean */
export enum IndicatorCleanStateInterface {
  OFF,
  RED,
  RED_FLASH,
  M // TODO: Check, coz looks like it's not correct state
}
export interface IndicatorCleanInterface {
  active: boolean;
  state: IndicatorCleanStateInterface;
}

/* Display */
export interface IndicatorDisplayInterface {
  data: string;
}


/* BUTTONS */

/* BUTTONS - Dispense hot */
export enum ButtonDispenseHotStatesInterface {
  OFF,
  ACTIVE,
  FLASHING
}
export interface ButtonDispenseHotInterface {
  active: boolean;
  state: ButtonDispenseHotStatesInterface;
}

/* BUTTONS - Dispense mix */
export enum ButtonDispenseMixStatesInterface {
  OFF,
  ACTIVE,
  FLASHING
}

export interface ButtonDispenseMixInterface {
  active: boolean;
  state: ButtonDispenseMixStatesInterface;
}

/* BUTTONS - Dispense cold */
export enum ButtonDispenseColdStatesInterface {
  OFF,
  ACTIVE,
  FLASHING
}

export interface ButtonDispenseColdInterface {
  active: boolean;
  state: ButtonDispenseColdStatesInterface;
}

/* BUTTONS - Pitcher */
export enum ButtonPitcherStatesInterface {
  OFF,    // gray color
  ACTIVE, // blue color
  FLASHING// blue flashing
}

export interface ButtonPitcherInterface {
  active: boolean;
  state: ButtonPitcherStatesInterface;
}


/* BUTTONS - Settings */
export enum ButtonSettingsStatesInterface {
  OFF,    // gray color
  ACTIVE, // blue color
}

export interface ButtonSettingsInterface {
  active: boolean;
  state: ButtonSettingsStatesInterface;
}


/* BUTTONS - Hotwater */
export enum ButtonHotwaterStatesInterface {
  OFF,
  ACTIVE,
  FLASHING
}

export interface ButtonHotwaterInterface {
  active: boolean;
  state: ButtonHotwaterStatesInterface;
}


/* Temp interfaces */
export enum TempColdInterface {
  t4 = -1,
  t5 = -2,
  t6 = -3,
  t7 = -4,
  t8 = -5,
  t9 = -6,
  t10 = -7,
  t11 = -8,
  t12 = -9
}

export enum TempMixInterface {
  t10 = -9,
  t13 = -8,
  t16 = -7,
  t19 = -6,
  t22 = -5,
  t25 = -4,
  t28 = -3,
  t30 = -2,
  t33 = -1,
  t35 = 0,
  t37 = 1,
  t40 = 2,
  t50 = 3,
  t55 = 4,
  t60 = 5,
  t70 = 6,
  t82 = 7,
  t85 = 8,
  t87 = 9
}

export enum TempHotInterface {
  t88 = 1,
  t90 = 2,
  t92 = 3,
  t94 = 4,
  t96 = 5,
  t97 = 6,
  t98 = 7,
  t99 = 8,
  t100 = 9
}


/* Pages */
/* TODO: Rename to SimulatorEntryPagesInterface */
export enum SimulatorPagesInterface {
  IDLE,
  HC,     // M1.1 temerature hot/cold
  CS,     // M1.2 quantity Cup size
  CH,     // M1.3 Child Lock
  ES,     // M1.4 Energy Saving
  CO,     // M1.5 Wifi connectivity
  SO,     // M1.6 Sound
  SL,     // M1.7 Sleep
  CL,     // M1.7.1 Internal cleaning
  AL,     // M1.8 Altitude
  FC,     // M1.9 Factory Mode
  FL,
  SH,
  FCS,
  ER,
  CC,
  DB

}

export enum SimulatorSubpagesInterface {
  NONE,
  HC_COLD,
  HC_MIX,
  HC_HOT
}

// export interface SimulatorEntryPageInterface {
//   //clickButtonDispenceHot(SimulatorEntryInterface): any;
//   //clickLongButtonDispenseHot(SimulatorEntryInterface): any;
// }

export enum SimulatorStatesInterface {
  OFF,
  ON,
  LOCKED,
  SLEEP
}

export enum Pouring {
  NONE,
  HOT,
  COLD,
  MIX
}

/* MAIN INTERFACE */
export interface SimulatorEntryInterface {
  buttons: {
    dispenseCold: ButtonDispenseColdInterface;
    dispenseMix: ButtonDispenseMixInterface;
    dispenseHot: ButtonDispenseHotInterface;
    pitcher: ButtonPitcherInterface;
    settings: ButtonSettingsInterface;
    hotWater: ButtonHotwaterInterface;
  };
  indicator: {
    uv: IndicatorUVInterface;
    purifier: IndicatorPurifierInterface;
    wifi: IndicatorWifiInterface;
    clean: IndicatorCleanInterface;
  };
  settings: {
    sound: boolean;
  };
  display: IndicatorDisplayInterface;
  state: SimulatorStatesInterface;
  page: SimulatorPagesInterface;
  subpage: SimulatorSubpagesInterface;
  temp: {
    cold: TempColdInterface; // 4 - 12
    mix: TempMixInterface;  // 88 - 99: 88, 90, 92, 94, 95, 96, 97, 98, 99
    hot: TempHotInterface;  // 10 - 85: 10, 13, 16, 19, 22, 25, 28, 30, 33, 35, 37, 40, 50, 55, 60, 70, 82, 85
  };
  pouring: {animation: Pouring, fast: boolean}
  quantity: {
    cold: number;
    mix: number;
    hot: number;
    pitcher: number;
  };
}
