import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import {BuiltInTest} from '../../data/builtInTest';

@Component({
  selector: 'app-built-in-test-premium',
  templateUrl: './built-in-test-premium.component.html',
  styleUrls: ['./built-in-test-premium.component.sass']
})
export class BuiltInTestPremiumComponent implements OnInit {

  public lang = null;

  public topText = {
    he: 'בדיקת מכשיר',
    en: 'Built in Test'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  public builtInTest = null;
  public step_1_text = {
    line_1: {
      he: 'יש להניח כוס',
      en: 'Place a cup under'
    },
    line_2: {
      he: 'מתחת לפיית המזיגה',
      en: 'the spout'
    },
    line_3: {
      he: 'יש ללחוץ על כפתור מים  <span style="color: #00C6FF">קרים</span>',
      en: 'Press <span style="color: #00C6FF">Cold</span> water'
    },
    line_4: {
      he: 'לבדיקת מזיגה',
      en: 'to check dispensing'
    }
  };

  public step_2_text = {
    line_1: {
      he: 'תהליך זה ייקח',
      en: 'This process may take'
    },
    line_2: {
      he: 'מספר דקות',
      en: 'few minutes'
    }
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Built in test premium', lang);
      this.lang = lang;
    });
    this.builtInTest = BuiltInTest;
  }

  ngOnInit() {
  }

}
