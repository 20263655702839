import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Button} from '../../../shared/button/button.component';
import {ButtonDispensingPersonalPremiumInterface, ButtonsPremiumState} from '../../../interfaces/simulator-premium';

@Component({
  selector: 'app-button-personal-premium',
  templateUrl: './button-personal-premium.component.html',
  styleUrls: ['./button-personal-premium.component.sass']
})
export class ButtonPersonalPremiumComponent extends Button implements OnInit {

  @Input() data: ButtonDispensingPersonalPremiumInterface;
  @Output() public longThreeClickDown: EventEmitter<object> = new EventEmitter<object>();
  @Output() public longThreeClickUp: EventEmitter<object> = new EventEmitter<object>();

  public state: any;

  constructor() {
    super();
    this.state = ButtonsPremiumState;
  }


  ngOnInit() {
  }

}
