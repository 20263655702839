import {Component, Input, OnInit} from '@angular/core';
import {IndicatorCleanInterface, IndicatorCleanStateInterface} from '../../../interfaces/simulator-entry';

@Component({
  selector: 'app-indicator-clean-entry',
  templateUrl: './indicator-clean-entry.component.html',
  styleUrls: ['./indicator-clean-entry.component.sass']
})
export class IndicatorCleanEntryComponent implements OnInit {

  @Input() data: IndicatorCleanInterface;
  public states;

  constructor() {
    this.states = IndicatorCleanStateInterface;
  }

  ngOnInit() {
    console.groupCollapsed('Simulator - Indicator - Clean');
    console.log('ngOnInit state: ', this.data);
    console.groupEnd();
  }

}
