import {Component, Input, OnInit} from '@angular/core';
import {Button} from '../../../shared/button/button.component';
import {ButtonHotwaterInterface, ButtonHotwaterStatesInterface} from '../../../interfaces/simulator-entry';

@Component({
  selector: 'app-button-hotwater-entry',
  templateUrl: './button-hotwater-entry.component.html',
  styleUrls: ['./button-hotwater-entry.component.sass']
})
export class ButtonHotwaterEntryComponent extends Button implements OnInit {

  @Input() data: ButtonHotwaterInterface;
  public states;

  ngOnInit() {
    this.states = ButtonHotwaterStatesInterface;
  }

}
