import {Component, OnInit} from '@angular/core';
import {Personal} from '../../data/personal';
import {LanguageService} from '../../../../../language.service';

@Component({
  selector: 'app-personal-drinks-premium',
  templateUrl: './personal-drinks-premium.component.html',
  styleUrls: ['./personal-drinks-premium.component.sass']
})
export class PersonalDrinksPremiumComponent implements OnInit {

  public personal = null;
  public lang = null;

  public leftText = {
    he: 'המשקאות של ' + Personal.currentUser.name,
    en: Personal.currentUser.name + '\'s drinks',
  };

  public rightText = {
    he: 'צריכה יומית',
    en: 'Today\'s Consumption'
  };
  public addButtonText = {
    he: 'הוספה',
    en: 'ADD'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Personal drinks premium', lang);
      this.lang = lang;
    });
    this.personal = Personal;
  }

  ngOnInit() {
  }

}
