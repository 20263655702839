import { Component, OnInit } from "@angular/core";
import {
  ButtonsPremiumState,
  PouringAnimation,
} from "../../interfaces/simulator-premium";
import { LanguageService } from "../../../../language.service";
import { MuteService } from "../../../../mute.service";

@Component({
  selector: "app-dashboard-app",
  templateUrl: "./dashboard-app.component.html",
  styleUrls: ["./dashboard-app.component.sass"],
})
export class DashboardAppComponent implements OnInit {
  public helper = null;
  public PouringAnimation = PouringAnimation;
  public audioButtonPress = new Audio("/assets/audio/water_droplet.mp3");

  public isMute = null;
  public lang = null;
  public current = null;

  footerAreas = {
    en: [
      {
        style: {
          top: "10%",
          left: "20%",
          bottom: "10%",
          right: "62%",
          borderRadius: "1000px",
        },
        to: "hydration",
        id: 2,
      },
      {
        style: {
          top: "10%",
          left: "60%",
          bottom: "10%",
          right: "20%",
          borderRadius: "1000px",
        },
        to: "status",
        id: 4,
      },
      {
        style: {
          top: "10%",
          left: "40%",
          bottom: "10%",
          right: "43.6%",
          borderRadius: "1000px",
        },
        to: "myDrinks",
        id: 3,
      },
      {
        style: {
          top: "10%",
          left: "0%",
          bottom: "10%",
          right: "80%",
          borderRadius: "1000px",
        },
        to: "settings",
        id: 1,
      },
    ],
    he: [
      {
        style: {
          top: "10%",
          right: "20%",
          bottom: "10%",
          left: "60%",
          borderRadius: "1000px",
        },
        to: "hydration",
        id: 2,
      },
      {
        style: {
          top: "10%",
          right: "60%",
          bottom: "10%",
          left: "20%",
          borderRadius: "1000px",
        },
        to: "status",
        id: 4,
      },
      {
        style: {
          top: "10%",
          right: "40%",
          bottom: "10%",
          left: "40%",
          borderRadius: "1000px",
        },
        to: "myDrinks",
        id: 3,
      },
      {
        style: {
          top: "10%",
          right: "0%",
          bottom: "10%",
          left: "80%",
          borderRadius: "1000px",
        },
        to: "settings",
        id: 1,
      },
    ],
  };

  assetBase = "/assets/simulator/app/";
  data = {
    home: {
      toggle: false,
      current: 0,
      texts: [],
      screens: [
        {
          he: {
            background: 'Home HE',
            areas: [
              {
                style: {
                  top: '44%',
                  bottom: '36%',
                  left: '20%',
                  right: '3%',
                  borderRadius: '1000px'
                },
                to: 'myDrinks'
              },
              {
                style: {
                  top: '44%',
                  bottom: '36%',
                  left: '-24%',
                  right: '81.8%',
                  borderRadius: '1000px'
                },
                to: 'boiling'
              },
              {
                style: {
                  top: '28%',
                  right: '12%',
                  bottom: '56%',
                  left: '20%',
                  borderRadius: '1000px'
                },
                to: 'hydration'
              },
              {
                style: {
                  top: '64%',
                  right: '12%',
                  bottom: '20%',
                  left: '16%',
                  borderRadius: '1000px'
                },
                to: 'status'
              },
              {
                style: {
                  top: '17.1994%',
                  right: '19.7722%',
                  bottom: '72%',
                  left: '22%',
                  borderRadius: '1000px'
                },
                to: 'settings'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'profile'
              },
              {
                style: {
                  top: '4%',
                  left: '85%',
                  bottom: '86%',
                  right: '1%',
                  borderRadius: '1000px'
                },
                to: 'addCup'
              }
            ]
          },
          en: {
            background: 'Home',
            areas: [
              {
                style: {
                  top: '44%',
                  bottom: '36%',
                  left: '3%',
                  right: '20%',
                  borderRadius: '1000px'
                },
                to: 'myDrinks'
              },
              {
                style: {
                  top: '44%',
                  bottom: '36%',
                  left: '81.8%',
                  right: '-24%',
                  borderRadius: '1000px'
                },
                to: 'boiling'
              },
              {
                style: {
                  top: '28%',
                  right: '20%',
                  bottom: '56%',
                  left: '12%',
                  borderRadius: '1000px'
                },
                to: 'hydration'
              },
              {
                style: {
                  top: '64%',
                  right: '16%',
                  bottom: '20%',
                  left: '12%',
                  borderRadius: '1000px'
                },
                to: 'status'
              },
              {
                style: {
                  top: '17.1994%',
                  right: '22%',
                  bottom: '72%',
                  left: '19.7722%',
                  borderRadius: '1000px'
                },
                to: 'settings'
              },
              {
                style: {
                  top: '4%',
                  left: '1%',
                  bottom: '86%',
                  right: '85%',
                  borderRadius: '1000px'
                },
                to: 'profile'
              },
              {
                style: {
                  top: '4%',
                  right: '1%',
                  bottom: '86%',
                  left: '85%',
                  borderRadius: '1000px'
                },
                to: 'addCup'
              }
            ]
          }
        }
      ]
    },
    myDrinks: {
      toggle: true,
      current: 0,
      texts: ['אין משקאות שלי', 'יש משקאות שלי'],
      screens: [
        {
          he: {
            background: 'My water No drinks HE',
            footer: 3,
            areas: [
              {
                style: {
                  top: '70%',
                  right: '41%',
                  bottom: '20%',
                  left: '41%',
                  borderRadius: '1000px'
                },
                to: 'addDrinks'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'My water No drinks',
            footer: 3,
            areas: [
              {
                style: {
                  top: '70%',
                  right: '41%',
                  bottom: '20%',
                  left: '41%',
                  borderRadius: '1000px'
                },
                to: 'addDrinks'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        },
        {
          he: {
            background: 'My water make drink HE',
            footer: 3,
            areas: [
              {
                style: {
                  top: '41%',
                  right: '17%',
                  bottom: '21%',
                  left: '17%',
                  borderRadius: '1000px'
                },
                to: 'prepareDrink'
              },
              {
                style: {
                  top: '79%',
                  right: '60%',
                  bottom: '14%',
                  left: '28%',
                  borderRadius: '1000px'
                },
                to: 'editDrink'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'My water make drink',
            footer: 3,
            areas: [
              {
                style: {
                  top: '41%',
                  right: '14.5%',
                  bottom: '21%',
                  left: '18%',
                  borderRadius: '1000px'
                },
                to: 'prepareDrink'
              },
              {
                style: {
                  top: '79%',
                  right: '12%',
                  bottom: '14%',
                  left: '76%',
                  borderRadius: '1000px'
                },
                to: 'editDrink'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        }
      ]
    },
    addDrinks: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'My water add drink HE',
            areas: [
              {
                style: {
                  top: '24%',
                  right: '74%',
                  bottom: '62%',
                  left: '7%',
                  borderRadius: '1vh'
                },
                to: 'editDrink'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'My water add drink',
            areas: [
              {
                style: {
                  top: '23%',
                  right: '3%',
                  bottom: '62%',
                  left: '71%',
                  borderRadius: '1vh'
                },
                to: 'editDrink'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        }
      ]
    },
    editDrink: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'HE20.3',
            footer: 3,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'editDrinkPopup'
              },
              {
                style: {
                  top: '75%',
                  right: '59%',
                  bottom: '21%',
                  left: '34%',
                  borderRadius: '1000px'
                },
                to: 'includePopup'
              },
              {
                style: {
                  top: '82%',
                  right: '20.9053%',
                  bottom: '10.8%',
                  left: '20.9053%',
                  borderRadius: '1000px'
                },
                to: 'saveDrink'
              },
              {
                style: {
                  top: '82%',
                  right: '82.7%',
                  bottom: '10.8%',
                  left: '4%',
                  borderRadius: '2vh'
                },
                to: 'deleteDrink'
              }
            ]
          },
          en: {
            background: 'EN20.3',
            footer: 3,
            areas: [
              {
                style: {
                  top: '4%',
                  left: '1%',
                  bottom: '86%',
                  right: '85%',
                  borderRadius: '1000px'
                },
                to: 'editDrinkPopup'
              },
              // {
              //   style: {
              //     top: '75%',
              //     right: '34%',
              //     bottom: '21%',
              //     left: '59%',
              //     borderRadius: '1000px'
              //   },
              //   to: 'includePopup'
              // },
              {
                style: {
                  top: '82%',
                  right: '20.9053%',
                  bottom: '10.8%',
                  left: '20.9053%',
                  borderRadius: '1000px'
                },
                to: 'saveDrink'
              },
              {
                style: {
                  top: '82%',
                  right: '4%',
                  bottom: '10.8%',
                  left: '82.7%',
                  borderRadius: '2vh'
                },
                to: 'deleteDrink'
              }
            ]
          }
        }
      ]
    },
    saveDrink: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'HE20.3',
            timeout: { delay: 3000, to: 'editDrink' },
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'editDrinkPopup'
              },
              {
                style: {
                  top: '75%',
                  right: '59%',
                  bottom: '21%',
                  left: '34%',
                  borderRadius: '1000px'
                },
                to: 'includePopup'
              },
              {
                style: {
                  top: '82%',
                  right: '82.7%',
                  bottom: '10.8%',
                  left: '4%',
                  borderRadius: '2vh'
                },
                to: 'deleteDrink'
              }
            ]
          },
          en: {
            background: 'EN20.3',
            timeout: { delay: 3000, to: 'editDrink' },
            areas: [
              {
                style: {
                  top: '4%',
                  right: '1%',
                  bottom: '86%',
                  left: '85%',
                  borderRadius: '1000px'
                },
                to: 'editDrinkPopup'
              },
              // {
              //   style: {
              //     top: '75%',
              //     right: '34%',
              //     bottom: '21%',
              //     left: '59%',
              //     borderRadius: '1000px'
              //   },
              //   to: 'includePopup'
              // },
              {
                style: {
                  top: '82%',
                  right: '4%',
                  bottom: '10.8%',
                  left: '82.7%',
                  borderRadius: '2vh'
                },
                to: 'deleteDrink'
              }
            ]
          }
        }
      ]
    },
    editDrinkPopup: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'My Water edit popup HE',
            areas: [
              {
                style: {
                  top: '40%',
                  right: '59.5%',
                  bottom: '53%',
                  left: '17.3%',
                  borderRadius: '2vh'
                },
                to: 'deleteDrink'
              },
              {
                style: {
                  top: '40%',
                  right: '17%',
                  bottom: '53%',
                  left: '62.3%',
                  borderRadius: '2vh'
                },
                to: 'saveDrinkPopup'
              }
            ]
          },
          en: {
            background: 'My Water edit popup',
            areas: [
              {
                style: {
                  top: '40%',
                  right: '17.3%',
                  bottom: '53%',
                  left: '56.1%',
                  borderRadius: '2vh'
                },
                to: 'deleteDrink'
              },
              {
                style: {
                  top: '40%',
                  right: '57.6%',
                  bottom: '53%',
                  left: '17%',
                  borderRadius: '2vh'
                },
                to: 'saveDrinkPopup'
              }
            ]
          }
        }
      ]
    },
    prepareDrink: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'My water making drink – 1 HE',
            footer: 3,
            timeout: { delay: 4000, to: 'readyToDispense' },
            areas: [
              {
                style: {
                  top: '79%',
                  right: '60%',
                  bottom: '14%',
                  left: '28%',
                  borderRadius: '1000px'
                },
                to: 'editDrink'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'My water making drink',
            footer: 3,
            timeout: { delay: 4000, to: 'readyToDispense' },
            areas: [
              {
                style: {
                  top: '79%',
                  right: '28%',
                  bottom: '14%',
                  left: '60%',
                  borderRadius: '1000px'
                },
                to: 'editDrink'
              },

              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        }
      ]
    },
    readyToDispense: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'My water drink ready toast HE',
            footer: 3,
            timeout: { delay: 3000, to: 'myDrinks' },
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'My water drink ready toast',
            footer: 3,
            timeout: { delay: 3000, to: 'myDrinks' },
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        }
      ]
    },
    readyToDispensePopup: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'Notification Drink ready HE',
            areas: [
              {
                style: {
                  top: '41%',
                  right: '31%',
                  bottom: '52%',
                  left: '31%',
                  borderRadius: '3px'
                },
                to: 'readyToDispenseGotIt'
              }
            ]
          },
          en: {
            background: 'Notification Drink ready',
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        }
      ]
    },
    includePopup: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'Include popup HE',
            areas: [
              {
                style: {
                  top: '35%',
                  right: '40%',
                  bottom: '60%',
                  left: '40%',
                  borderRadius: '3px'
                },
                to: 'editDrink'
              }
            ]
          },
          en: {
            background: 'Include popup',
            areas: [
              {
                style: {
                  top: '35%',
                  right: '40%',
                  bottom: '60%',
                  left: '40%',
                  borderRadius: '3px'
                },
                to: 'editDrink'
              }
            ]
          }
        }
      ]
    },
    settings: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'Settings  HE',
            footer: 1,
            areas: [
              {
                style: {
                  top: '27%',
                  right: '61%',
                  bottom: '68%',
                  left: '30.6%',
                  borderRadius: '1000px'
                },
                to: 'editName'
              },
              {
                style: {
                  top: '56.3025%',
                  right: '0',
                  bottom: '36.5032%',
                  left: '0'
                },
                to: 'editTemp'
              },
              {
                style: {
                  top: '63.2805%',
                  right: '0',
                  bottom: '29.5%',
                  left: '0'
                },
                to: 'jugs'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              },
              {
                style: {
                  top: '101.382%',
                  right: '80.0024%',
                  bottom: '-6.28716%',
                  left: '4.34585%',
                  borderRadius: '1000px'
                },
                to: 'childLockPopup'
              }
            ]
          },
          en: {
            background: 'Settings',
            footer: 1,
            areas: [
              {
                style: {
                  top: '27%',
                  right: '19.4%',
                  bottom: '68%',
                  left: '72.1%',
                  borderRadius: '1000px'
                },
                to: 'editName'
              },
              {
                style: {
                  top: '50.3025%',
                  right: '0',
                  bottom: '42.5032%',
                  left: '0'
                },
                to: 'editTemp'
              },
              {
                style: {
                  top: '57.2805%',
                  right: '0',
                  bottom: '35.5%',
                  left: '0'
                },
                to: 'jugs'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              },
              // {
              //   style: {
              //     top: '95.382%',
              //     right: '5.34585%',
              //     bottom: '0%',
              //     left: '80.0024%',
              //     borderRadius: '1000px'
              //   },
              //   to: 'childLockPopup'
              // }
            ]
          }
        }
      ]
    },
    editName: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'Settings serial HE',
            footer: 1,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              },
              {
                style: {
                  top: '59.2619%',
                  right: '5.60611%',
                  bottom: '33.3673%',
                  left: '5.26135%',
                  borderRadius: '1000px'
                },
                to: 'settings'
              },
              {
                style: {
                  top: '69.2303%',
                  right: '24.3916%',
                  bottom: '24.9939%',
                  left: '24.7698%',
                  borderRadius: '1000px'
                },
                to: 'disconnectPopup'
              }
            ]
          },
          en: {
            background: 'Settings serial',
            footer: 1,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              },
              {
                style: {
                  top: '59.2619%',
                  right: '5.26135%',
                  bottom: '33.3673%',
                  left: '5.60611%',
                  borderRadius: '1000px'
                },
                to: 'settings'
              },
              {
                style: {
                  top: '69.2303%',
                  right: '24.7698%',
                  bottom: '24.9939%',
                  left: '24.3916%',
                  borderRadius: '1000px'
                },
                to: 'disconnectPopup'
              }
            ]
          }
        }
      ]
    },
    disconnectPopup: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'Settings popup 2 HE',
            areas: [
              {
                style: {
                  top: '56.5%',
                  right: '61%',
                  bottom: '36.6%',
                  left: '17.3%',
                  borderRadius: '2vh'
                },
                to: 'editName'
              },
              {
                style: {
                  top: '56.5%',
                  right: '17%',
                  bottom: '36.6%',
                  left: '60.3%',
                  borderRadius: '2vh'
                },
                to: 'disconnectPair'
              }
            ]
          },
          en: {
            background: 'Settings popup2',
            areas: [
              {
                style: {
                  top: '64.7%',
                  left: '17.3%',
                  bottom: '30%',
                  right: '61%',
                  borderRadius: '2vh'
                },
                to: 'editName'
              },
              {
                style: {
                  top: '64.7%',
                  left: '60.3%',
                  bottom: '30%',
                  right: '17%',
                  borderRadius: '2vh'
                },
                to: 'disconnectPair'
              }
            ]
          }
        }
      ]
    },
    disconnectPair: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'First Time Pairing HE',
            toggle: false,
            areas: [
              {
                style: {
                  top: '81.2%',
                  right: '43%',
                  bottom: '11.4%',
                  left: '43%',
                  borderRadius: '1000px'
                },
                to: 'disconnectGuide'
              }
            ]
          },
          en: {
            background: 'First Time Pairing',
            toggle: false,
            areas: [
              {
                style: {
                  top: '81.2%',
                  right: '43%',
                  bottom: '11.4%',
                  left: '43%',
                  borderRadius: '1000px'
                },
                to: 'disconnectGuide'
              }
            ]
          }
        }
      ]
    },
    disconnectGuide: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'HE6.2',
            toggle: false,
            areas: [
              {
                style: {
                  top: '67.2%',
                  right: '30%',
                  bottom: '24.6%',
                  left: '30%',
                  borderRadius: '2vh'
                },
                to: 'editName'
              }
            ]
          },
          en: {
            background: 'EN6.2',
            toggle: false,
            areas: [
              {
                style: {
                  top: '67.2%',
                  right: '30%',
                  bottom: '24.6%',
                  left: '30%',
                  borderRadius: '2vh'
                },
                to: 'editName'
              }
            ]
          }
        }
      ]
    },
    status: {
      toggle: false,
      current: 0,
      texts: [],
      screens: [
        {
          he: {
            background: 'Water Quality HE',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'Water Quality',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        },
        {
          he: {
            background: 'Water Quality replace Purifier HE',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'Water Quality replace Purifier',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '1%',
                  bottom: '86%',
                  left: '85%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        },
        {
          he: {
            background: 'Water Quality replace UV HE',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'Water Quality replace UV',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '1%',
                  bottom: '86%',
                  left: '85%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        },
        {
          he: {
            background: 'Water Quality replace both HE',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'Water Quality replace both',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '1%',
                  bottom: '86%',
                  left: '85%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        },
        {
          he: {
            background: 'Water Quality Purifier not detected HE',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'Water Quality Purifier not detected',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '1%',
                  bottom: '86%',
                  left: '85%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        },
        {
          he: {
            background: 'Water Quality porifier over 2000 HE',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'Water Quality porifier over 2000',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '1%',
                  bottom: '86%',
                  left: '85%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        },
        {
          he: {
            background: 'Water Quality UV not detected HE',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'Water Quality UV not detected',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '1%',
                  bottom: '86%',
                  left: '85%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        },
        {
          he: {
            background: 'Water Quality Purifier _ UV not detected HE',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'Water Quality Purifier _ UV not detected',
            footer: 4,
            areas: [
              {
                style: {
                  top: '4%',
                  right: '1%',
                  bottom: '86%',
                  left: '85%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        }
      ]
    },
    boiling: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            timeout: { delay: 3750, to: 'boiled' },
            background: 'Boiling HE',
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              },
              {
                style: {
                  top: '50.5%',
                  right: '38%',
                  bottom: '43%',
                  left: '38%',
                  borderRadius: '2vh'
                },
                to: 'cancelBoil'
              }
            ]
          },
          en: {
            timeout: { delay: 3750, to: 'boiled' },
            background: 'Boiling',
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              },
              {
                style: {
                  top: '50.5%',
                  right: '38%',
                  bottom: '43%',
                  left: '38%',
                  borderRadius: '2vh'
                },
                to: 'cancelBoil'
              }
            ]
          }
        }
      ]
    },
    boiled: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            timeout: { delay: 2000, to: 'home' },
            background: 'Boiled HE',
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            timeout: { delay: 2000, to: 'home' },
            background: 'Boiled',
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        }
      ]
    },
    boiledPopup: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            timeout: { delay: 2000, to: 'home' },
            background: 'Notification water boiled HE',
            areas: [
              {
                style: {
                  top: '41%',
                  right: '31%',
                  bottom: '52%',
                  left: '31%',
                  borderRadius: '3px'
                },
                to: 'boiledGotIt'
              }
            ]
          },
          en: {
            timeout: { delay: 2000, to: 'home' },
            background: 'Notification water boiled',
            areas: [
              {
                style: {
                  top: '41%',
                  right: '31%',
                  bottom: '52%',
                  left: '31%',
                  borderRadius: '3px'
                },
                to: 'boiledGotIt'
              }
            ]
          }
        }
      ]
    },
    childLockPopup: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'Settings popup 1 HE (1)',
            areas: [
              {
                style: {
                  top: '40%',
                  right: '59.5%',
                  bottom: '53%',
                  left: '17.3%',
                  borderRadius: '2vh'
                },
                to: 'settings'
              },
              {
                style: {
                  top: '40%',
                  right: '17%',
                  bottom: '53%',
                  left: '60.5%',
                  borderRadius: '2vh'
                },
                to: 'settings'
              }
            ]
          },
          en: {
            background: 'Settings popup 1 HE ',
            areas: [
              {
                style: {
                  top: '40%',
                  right: '17.3%',
                  bottom: '53%',
                  left: '59.5%',
                  borderRadius: '2vh'
                },
                to: 'settings'
              },
              {
                style: {
                  top: '40%',
                  right: '60.5%',
                  bottom: '53%',
                  left: '17%',
                  borderRadius: '2vh'
                },
                to: 'settings'
              }
            ]
          }
        }
      ]
    },
    editTemp: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            footer: 1,
            background: 'Cup 300ml HE',
            areas: [
              {
                style: {
                  top: '82.5%',
                  right: '22%',
                  bottom: '11%',
                  left: '22%',
                  borderRadius: '1000px'
                },
                to: 'editTempSave'
              },
              {
                style: {
                  top: '82.5%',
                  right: '83%',
                  bottom: '11%',
                  left: '4%',
                  borderRadius: '1000px'
                },
                to: 'settings'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'editTempPopup'
              }
            ]
          },
          en: {
            footer: 1,
            background: 'Cup 300ml',
            areas: [
              {
                style: {
                  top: '82.5%',
                  right: '22%',
                  bottom: '11%',
                  left: '22%',
                  borderRadius: '1000px'
                },
                to: 'editTempSave'
              },
              // {
              //   style: {
              //     top: '82.5%',
              //     right: '4%',
              //     bottom: '11%',
              //     left: '83%',
              //     borderRadius: '1000px'
              //   },
              //   to: 'settings'
              // },
              {
                style: {
                  top: '4%',
                  left: '1%',
                  bottom: '86%',
                  right: '85%',
                  borderRadius: '1000px'
                },
                to: 'editTempPopup'
              }
            ]
          }
        }
      ]
    },
    editTempPopup: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            footer: 1,
            background: 'My Water edit popup HE',
            areas: [
              {
                style: {
                  top: '40%',
                  right: '59.5%',
                  bottom: '53%',
                  left: '17.3%',
                  borderRadius: '2vh'
                },
                to: 'settings'
              },
              {
                style: {
                  top: '40%',
                  right: '17%',
                  bottom: '53%',
                  left: '62.3%',
                  borderRadius: '2vh'
                },
                to: 'settings'
              }
            ]
          },
          en: {
            footer: 1,
            background: 'My Water edit popup',
            areas: [
              {
                style: {
                  top: '40%',
                  right: '17.3%',
                  bottom: '53%',
                  left: '57.5%',
                  borderRadius: '2vh'
                },
                to: 'settings'
              },
              {
                style: {
                  top: '40%',
                  right: '57.3%',
                  bottom: '53%',
                  left: '17%',
                  borderRadius: '2vh'
                },
                to: 'settings'
              }
            ]
          }
        }
      ]
    },
    editTempSave: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            footer: 1,
            timeout: { delay: 1500, to: 'settings' },
            background: 'Vol _ Temp changes saved HE',
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'settings'
              }
            ]
          },
          en: {
            footer: 1,
            timeout: { delay: 1500, to: 'settings' },
            background: 'Vol _ Temp changes saved',
            areas: [
              {
                style: {
                  top: '4%',
                  left: '1%',
                  bottom: '86%',
                  right: '85%',
                  borderRadius: '1000px'
                },
                to: 'settings'
              }
            ]
          }
        }
      ]
    },
    jugs: {
      toggle: true,
      current: 0,
      screens: [
        {
          he: {
            footer: 1,
            background: 'HE14.3',
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'settings'
              },
              {
                style: {
                  top: '15.6%',
                  right: '25.45%',
                  bottom: '77%',
                  left: '62%',
                  borderRadius: '1000px'
                },
                to: 'chooseJug'
              }
            ]
          },
          en: {
            footer: 1,
            background: 'EN14.3',
            areas: [
              {
                style: {
                  top: '4%',
                  left: '1%',
                  bottom: '86%',
                  right: '85%',
                  borderRadius: '1000px'
                },
                to: 'settings'
              },
              {
                style: {
                  top: '15.6%',
                  right: '62%',
                  bottom: '77%',
                  left: '25.45%',
                  borderRadius: '1000px'
                },
                to: 'chooseJug'
              }
            ]
          }
        }
      ]
    },
    chooseJug: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            footer: 1,
            background: 'Jugs _ Pots None HE',
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'jugs'
              },
              {
                style: {
                  top: '39%',
                  right: '75%',
                  bottom: '44%',
                  left: '0%',
                  borderRadius: '2vh'
                },
                to: 'editJug'
              },
              {
                style: {
                  top: '39%',
                  right: '50%',
                  bottom: '44%',
                  left: '25%',
                  borderRadius: '2vh'
                },
                to: 'editJug'
              },
              {
                style: {
                  top: '39%',
                  right: '25%',
                  bottom: '44%',
                  left: '50%',
                  borderRadius: '2vh'
                },
                to: 'editJug'
              },
              {
                style: {
                  top: '39%',
                  right: '0%',
                  bottom: '44%',
                  left: '75%',
                  borderRadius: '2vh'
                },
                to: 'editJug'
              }
            ]
          },
          en: {
            footer: 1,
            background: 'Jugs _ Pots None',
            areas: [
              {
                style: {
                  top: '4%',
                  left: '1%',
                  bottom: '86%',
                  right: '85%',
                  borderRadius: '1000px'
                },
                to: 'jugs'
              },
              {
                style: {
                  top: '39%',
                  right: '0%',
                  bottom: '44%',
                  left: '75%',
                  borderRadius: '2vh'
                },
                to: 'editJug'
              },
              {
                style: {
                  top: '39%',
                  right: '25%',
                  bottom: '44%',
                  left: '50%',
                  borderRadius: '2vh'
                },
                to: 'editJug'
              },
              {
                style: {
                  top: '39%',
                  right: '50%',
                  bottom: '44%',
                  left: '25%',
                  borderRadius: '2vh'
                },
                to: 'editJug'
              },
              {
                style: {
                  top: '39%',
                  right: '75%',
                  bottom: '44%',
                  left: '0%',
                  borderRadius: '2vh'
                },
                to: 'editJug'
              }
            ]
          }
        }
      ]
    },
    editJug: {
      toggle: true,
      current: 0,
      screens: [
        {
          he: {
            footer: 1,
            background: 'HE14.5',
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'settings'
              },
              {
                style: {
                  top: '82%',
                  right: '82.7%',
                  bottom: '10.8%',
                  left: '4%',
                  borderRadius: '2vh'
                },
                to: 'jugs'
              },
              {
                style: {
                  top: '82%',
                  right: '20.9053%',
                  bottom: '10.8%',
                  left: '20.9053%',
                  borderRadius: '1000px'
                },
                to: 'saveJug'
              }
            ]
          },
          en: {
            footer: 1,
            background: 'EN14.5',
            areas: [
              {
                style: {
                  top: '4%',
                  left: '1%',
                  bottom: '86%',
                  right: '85%',
                  borderRadius: '1000px'
                },
                to: 'settings'
              },
              {
                style: {
                  top: '82%',
                  right: '4%',
                  bottom: '10.8%',
                  left: '82.7%',
                  borderRadius: '2vh'
                },
                to: 'jugs'
              },
              {
                style: {
                  top: '82%',
                  right: '20.9053%',
                  bottom: '10.8%',
                  left: '20.9053%',
                  borderRadius: '1000px'
                },
                to: 'saveJug'
              }
            ]
          }
        }
      ]
    },
    saveJug: {
      toggle: true,
      current: 0,
      screens: [
        {
          he: {
            footer: 1,
            timeout: { delay: 3000, to: 'editJug' },
            background: 'HE14.8',
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'settings'
              }
            ]
          },
          en: {
            footer: 1,
            timeout: { delay: 3000, to: 'editJug' },
            background: 'EN14.8(1)',
            areas: [
              {
                style: {
                  top: '4%',
                  left: '1%',
                  bottom: '86%',
                  right: '85%',
                  borderRadius: '1000px'
                },
                to: 'settings'
              }
            ]
          }
        }
      ]
    },
    profile: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'Hamburger Menu HE',
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              },
              {
                style: {
                  top: '47.5%',
                  right: '0',
                  bottom: '44.4969%',
                  left: '0'
                },
                to: 'editProfileNotifications'
              },
              {
                style: {
                  top: '39.7287%',
                  right: '0',
                  bottom: '52.8706%',
                  left: '0'
                },
                to: 'editProfile'
              }
            ]
          },
          en: {
            background: 'Hamburger Menu',
            areas: [
              {
                style: {
                  top: '4%',
                  left: '1%',
                  bottom: '86%',
                  right: '85%',
                  borderRadius: '1000px'
                },
                to: 'home'
              },
              {
                style: {
                  top: '56.2%',
                  right: '0',
                  bottom: '34.4969%',
                  left: '0'
                },
                to: 'editProfileNotifications'
              },
              {
                style: {
                  top: '39.7287%',
                  right: '0',
                  bottom: '52.8706%',
                  left: '0'
                },
                to: 'editProfile'
              }
            ]
          }
        }
      ]
    },
    editProfile: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'Edit Profile HE (1)',
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'profile'
              },
              {
                style: {
                  top: '67.046%',
                  right: '5.57068%',
                  bottom: '25.759%',
                  left: '5.05731%',
                  borderRadius: '1000px'
                },
                to: 'editProfileSave'
              },
              {
                style: {
                  top: '30.7618%',
                  right: '18.3304%',
                  bottom: '63.8393%',
                  left: '72.2892%',
                  borderRadius: '1000px'
                },
                to: 'editProfilePicker'
              }
            ]
          },
          en: {
            background: 'Edit Profile',
            areas: [
              {
                style: {
                  top: '4%',
                  left: '1%',
                  bottom: '86%',
                  right: '85%',
                  borderRadius: '1000px'
                },
                to: 'profile'
              },
              {
                style: {
                  top: '67.046%',
                  right: '5.05731%',
                  bottom: '25.759%',
                  left: '5.57068%',
                  borderRadius: '1000px'
                },
                to: 'editProfileSave'
              },
              {
                style: {
                  top: '30.7618%',
                  right: '72.2892%',
                  bottom: '63.8393%',
                  left: '18.3304%',
                  borderRadius: '1000px'
                },
                to: 'editProfilePicker'
              }
            ]
          }
        }
      ]
    },
    editProfileSave: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'he9_optimized.6',
            timeout: { delay: 3000, to: 'editProfile' },
            areas: [
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'profile'
              },
              // {
              //   style: {
              //     top: '67.046%',
              //     right: '5.57068%',
              //     bottom: '25.759%',
              //     left: '5.05731%',
              //     borderRadius: '1000px'
              //   },
              //   to: 'profile'
              // },
              {
                style: {
                  top: '30.7618%',
                  right: '18.3304%',
                  bottom: '63.8393%',
                  left: '72.2892%',
                  borderRadius: '1000px'
                },
                to: 'editProfilePicker'
              }
            ]
          },
          en: {
            background: 'EN9.6(1)',
            timeout: { delay: 3000, to: 'editProfile' },
            areas: [
              {
                style: {
                  top: '4%',
                  left: '1%',
                  bottom: '86%',
                  right: '85%',
                  borderRadius: '1000px'
                },
                to: 'profile'
              },
              // {
              //   style: {
              //     top: '67.046%',
              //     right: '5.05731%',
              //     bottom: '25.759%',
              //     left: '5.57068%',
              //     borderRadius: '1000px'
              //   },
              //   to: 'profile'
              // },
              {
                style: {
                  top: '30.7618%',
                  right: '72.2892%',
                  bottom: '63.8393%',
                  left: '18.3304%',
                  borderRadius: '1000px'
                },
                to: 'editProfilePicker'
              }
            ]
          }
        }
      ]
    },
    editProfilePicker: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'Edit Profile HE',
            areas: [
              {
                style: {
                  top: '79.4066%',
                  right: '77.1682%',
                  bottom: '10.2079%',
                  left: '4%',
                  borderRadius: '1000px'
                },
                to: 'editProfile'
              },
              {
                style: {
                  top: '79.4066%',
                  right: '53.0661%',
                  bottom: '10.2079%',
                  left: '28.4532%',
                  borderRadius: '1000px'
                },
                to: 'editProfile'
              },
              {
                style: {
                  top: '79.4066%',
                  right: '28.964%',
                  bottom: '10.2079%',
                  left: '53.267%',
                  borderRadius: '1000px'
                },
                to: 'editProfile'
              },
              {
                style: {
                  top: '79.4066%',
                  right: '4.50736%',
                  bottom: '10.2079%',
                  left: '77.7237%',
                  borderRadius: '1000px'
                },
                to: 'editProfile'
              }
            ]
          },
          en: {
            background: 'Edit Profile 2',
            areas: [
              {
                style: {
                  top: '79.4066%',
                  right: '4%',
                  bottom: '10.2079%',
                  left: '77.1682%',
                  borderRadius: '1000px'
                },
                to: 'editProfile'
              },
              {
                style: {
                  top: '79.4066%',
                  right: '28.4532%',
                  bottom: '10.2079%',
                  left: '53.0661%',
                  borderRadius: '1000px'
                },
                to: 'editProfile'
              },
              {
                style: {
                  top: '79.4066%',
                  right: '53.267%',
                  bottom: '10.2079%',
                  left: '28.964%',
                  borderRadius: '1000px'
                },
                to: 'editProfile'
              },
              {
                style: {
                  top: '79.4066%',
                  right: '77.7237%',
                  bottom: '10.2079%',
                  left: '4.50736%',
                  borderRadius: '1000px'
                },
                to: 'editProfile'
              }
            ]
          }
        }
      ]
    },
    editProfileNotifications: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'Notifications HE',
            areas: [
              {
                style: {
                  top: '5.2%',
                  right: '86%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'profile'
              }
            ]
          },
          en: {
            background: 'Notifications',
            areas: [
              {
                style: {
                  top: '5.2%',
                  left: '1%',
                  bottom: '86%',
                  right: '86%',
                  borderRadius: '1000px'
                },
                to: 'profile'
              }
            ]
          }
        }
      ]
    },
    hydration: {
      toggle: true,
      current: 1,
      texts: ['לא הוגדר יעד שתייה', 'הוגדר יעד שתייה', 'יעד שתייה הושג'],
      screens: [
        {
          he: {
            background: 'Hydration daily goal not set HE',
            footer: 2,
            areas: [
              {
                style: {
                  top: '26%',
                  right: '70.5%',
                  bottom: '60%',
                  left: '8.1%',
                  borderRadius: '2vh'
                },
                to: 'hydration'
              },
              {
                style: {
                  top: '26%',
                  right: '8.1%',
                  bottom: '60%',
                  left: '70.5%',
                  borderRadius: '2vh'
                },
                to: 'hydration'
              },
              {
                style: {
                  top: '56.7501%',
                  right: '46.5481%',
                  bottom: '37.3482%',
                  left: '43.2345%',
                  borderRadius: '1000px'
                },
                to: 'editHydrationGoal'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'Hydration daily goal not set',
            footer: 2,
            areas: [
              {
                style: {
                  top: '26%',
                  right: '8.1%',
                  bottom: '60%',
                  left: '70.5%',
                  borderRadius: '2vh'
                },
                to: 'hydration'
              },
              {
                style: {
                  top: '26%',
                  right: '70.5%',
                  bottom: '60%',
                  left: '8.1%',
                  borderRadius: '2vh'
                },
                to: 'hydration'
              },
              {
                style: {
                  top: '55.7055%',
                  right: '50.9725%',
                  bottom: '38.3928%',
                  left: '37.8813%',
                  borderRadius: '1000px'
                },
                to: 'editHydrationGoal'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        },
        {
          he: {
            background: 'Hydration Screen HE',
            footer: 2,
            areas: [
              {
                style: {
                  top: '26%',
                  right: '70.5%',
                  bottom: '60%',
                  left: '8.1%',
                  borderRadius: '2vh'
                },
                to: 'fulfillHydrationGoal'
              },
              {
                style: {
                  top: '26%',
                  right: '8.1%',
                  bottom: '60%',
                  left: '70.5%',
                  borderRadius: '2vh'
                },
                to: 'hydration'
              },
              {
                style: {
                  top: '56.7501%',
                  right: '48.6747%',
                  bottom: '37.3482%',
                  left: '40.0446%',
                  borderRadius: '1000px'
                },
                to: 'editHydrationGoal'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'Hydration Screen',
            footer: 2,
            areas: [
              {
                style: {
                  top: '26%',
                  right: '8.1%',
                  bottom: '60%',
                  left: '70.5%',
                  borderRadius: '2vh'
                },
                to: 'fulfillHydrationGoal'
              },
              {
                style: {
                  top: '26%',
                  right: '70.5%',
                  bottom: '60%',
                  left: '8.1%',
                  borderRadius: '2vh'
                },
                to: 'hydration'
              },
              {
                style: {
                  top: '56.7501%',
                  right: '44.0446%',
                  bottom: '37.3482%',
                  left: '45.2747%',
                  borderRadius: '1000px'
                },
                to: 'editHydrationGoal'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        },
        {
          he: {
            background: 'Hydration Goal Reached HE',
            footer: 2,
            areas: [
              {
                style: {
                  top: '26%',
                  right: '70.5%',
                  bottom: '60%',
                  left: '8.1%',
                  borderRadius: '2vh'
                },
                to: 'hydration'
              },
              {
                style: {
                  top: '26%',
                  right: '8.1%',
                  bottom: '60%',
                  left: '70.5%',
                  borderRadius: '2vh'
                },
                to: 'saveHydrationGoal'
              },
              {
                style: {
                  top: '56.7501%',
                  right: '48.6747%',
                  bottom: '37.3482%',
                  left: '40.0446%',
                  borderRadius: '1000px'
                },
                to: 'editHydrationGoal'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          },
          en: {
            background: 'Hydration Goal Reached',
            footer: 2,
            areas: [
              {
                style: {
                  top: '26%',
                  right: '8.1%',
                  bottom: '60%',
                  left: '70.5%',
                  borderRadius: '2vh'
                },
                to: 'hydration'
              },
              {
                style: {
                  top: '26%',
                  right: '70.5%',
                  bottom: '60%',
                  left: '8.1%',
                  borderRadius: '2vh'
                },
                to: 'saveHydrationGoal'
              },
              {
                style: {
                  top: '56.7501%',
                  right: '44.0446%',
                  bottom: '37.3482%',
                  left: '45.2747%',
                  borderRadius: '1000px'
                },
                to: 'editHydrationGoal'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'home'
              }
            ]
          }
        }
      ]
    },
    editHydrationGoal: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'Edit Hydration Goals HE',
            areas: [
              {
                style: {
                  top: '93.2344%',
                  right: '35.9153%',
                  bottom: '1.46127%',
                  left: '36.1458%',
                  borderRadius: '1000px'
                },
                to: 'removeHydrationGoal'
              },
              {
                style: {
                  top: '84.861%',
                  right: '20.6749%',
                  bottom: '7.65253%',
                  left: '20.9053%',
                  borderRadius: '1000px'
                },
                to: 'saveChangesHydrationGoal'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'hydration'
              }
            ]
          },
          en: {
            background: 'Edit Hydration Goals',
            areas: [
              {
                style: {
                  top: '93.2344%',
                  right: '36.1458%',
                  bottom: '1.46127%',
                  left: '35.9153%',
                  borderRadius: '1000px'
                },
                to: 'removeHydrationGoal'
              },
              {
                style: {
                  top: '84.861%',
                  right: '20.9053%',
                  bottom: '7.65253%',
                  left: '20.6749%',
                  borderRadius: '1000px'
                },
                to: 'saveChangesHydrationGoal'
              },
              {
                style: {
                  top: '4%',
                  left: '1%',
                  bottom: '86%',
                  right: '85%',
                  borderRadius: '1000px'
                },
                to: 'hydration'
              }
            ]
          }
        }
      ]
    },
    saveChangesHydrationGoal: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            background: 'he17_optimized.5',
            timeout: { to: 'hydration', delay: 3000 },
            areas: [
              {
                style: {
                  top: '93.2344%',
                  right: '35.9153%',
                  bottom: '1.46127%',
                  left: '36.1458%',
                  borderRadius: '1000px'
                },
                to: 'removeHydrationGoal'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'hydration'
              }
            ]
          },
          en: {
            background: 'EN17.5(1)',
            timeout: { to: 'hydration', delay: 3000 },
            areas: [
              {
                style: {
                  top: '93.2344%',
                  right: '36.1458%',
                  bottom: '1.46127%',
                  left: '35.9153%',
                  borderRadius: '1000px'
                },
                to: 'removeHydrationGoal'
              },
              {
                style: {
                  top: '4%',
                  left: '1%',
                  bottom: '86%',
                  right: '85%',
                  borderRadius: '1000px'
                },
                to: 'hydration'
              }
            ]
          }
        }
      ]
    },
    addCup: {
      toggle: false,
      current: 0,
      screens: [
        {
          he: {
            timeout: { delay: 3000, to: 'home' },
            background: 'HE24.2',
            areas: [
              {
                style: {
                  top: '92.0471%',
                  right: '71.8757%',
                  bottom: '4.10048%',
                  left: '9.36485%',
                  borderRadius: '1000px'
                },
                to: 'home'
              },
              {
                style: {
                  top: '44%',
                  bottom: '36%',
                  left: '20%',
                  right: '3%',
                  borderRadius: '1000px'
                },
                to: 'myDrinks'
              },
              {
                style: {
                  top: '44%',
                  bottom: '36%',
                  left: '-24%',
                  right: '81.8%',
                  borderRadius: '1000px'
                },
                to: 'boiling'
              },
              {
                style: {
                  top: '28%',
                  right: '12%',
                  bottom: '56%',
                  left: '20%',
                  borderRadius: '1000px'
                },
                to: 'hydration'
              },
              {
                style: {
                  top: '64%',
                  right: '12%',
                  bottom: '20%',
                  left: '16%',
                  borderRadius: '1000px'
                },
                to: 'status'
              },
              {
                style: {
                  top: '17.1994%',
                  right: '19.7722%',
                  bottom: '72%',
                  left: '22%',
                  borderRadius: '1000px'
                },
                to: 'settings'
              },
              {
                style: {
                  top: '4%',
                  right: '85%',
                  bottom: '86%',
                  left: '1%',
                  borderRadius: '1000px'
                },
                to: 'profile'
              }
            ]
          },
          en: {
            timeout: { delay: 3000, to: 'home' },
            background: 'EN24.2',
            areas: [
              {
                style: {
                  top: '91.1766%',
                  right: '9.36485%',
                  bottom: '4.44872%',
                  left: '70.3281%',
                  borderRadius: '1000px'
                },
                to: 'home'
              },
              {
                style: {
                  top: '44%',
                  bottom: '36%',
                  left: '3%',
                  right: '20%',
                  borderRadius: '1000px'
                },
                to: 'myDrinks'
              },
              {
                style: {
                  top: '44%',
                  bottom: '36%',
                  left: '81.8%',
                  right: '-24%',
                  borderRadius: '1000px'
                },
                to: 'boiling'
              },
              {
                style: {
                  top: '28%',
                  right: '20%',
                  bottom: '56%',
                  left: '12%',
                  borderRadius: '1000px'
                },
                to: 'hydration'
              },
              {
                style: {
                  top: '64%',
                  right: '16%',
                  bottom: '20%',
                  left: '12%',
                  borderRadius: '1000px'
                },
                to: 'status'
              },
              {
                style: {
                  top: '17.1994%',
                  right: '22%',
                  bottom: '72%',
                  left: '19.7722%',
                  borderRadius: '1000px'
                },
                to: 'settings'
              },
              {
                style: {
                  top: '4%',
                  right: '1%',
                  bottom: '86%',
                  left: '85%',
                  borderRadius: '1000px'
                },
                to: 'profile'
              }
            ]
          }
        }
      ]
    }
  }
    ;

  screen;
  currentScreen = "home";

  constructor(
    private langService: LanguageService,
    private muteService: MuteService
  ) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      let a = this.data[this.currentScreen];
      this.lang = lang;
      this.screen = a.screens[a.current][lang];
    });
    this.screen = this.data.home.screens[0][this.lang];

    this.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      this.isMute = mute;
    });
  }

  myDrinks = [];
  currentTimeout = null;
  drinkReadyPopup = null;
  boiledPopup = null;

  goToToggle(idx) {
    let a = this.data[this.currentScreen];
    if (a.current !== idx) {
      a.current = idx;
      this.screen = a.screens[idx][this.lang];
    }
  }

  goTo(to) {
    if (to === "removeHydrationGoal") {
      to = "hydration";
      this.data.hydration.current = 0;
    }

    if (to === "saveChangesHydrationGoal") {
      this.data.hydration.current = 1;
    }
    if (to === "saveDrink") {
      this.data.myDrinks.current = 1;
      to = 'myDrinks'; //TODO
    }

    if (to === "deleteDrink") {
      this.data.myDrinks.current = 0;
      to = "myDrinks";
    }
    if (to === "saveDrinkPopup") {
      this.data.myDrinks.current = 1;
      to = "myDrinks";
    }

    if (to === 'cancelBoil') {
      clearTimeout(this.currentTimeout);
      to = 'home';
    }

    if (to === "saveHydrationGoal") {
      to = "hydration";
      this.data.hydration.current = 1;
      let a = this.data[to];
      this.currentScreen = to;
      this.screen = a.screens[a.current][this.lang];
      let b = this.screen.timeout;
      if (b) {
        this.currentTimeout = setTimeout(() => {
          this.currentTimeout = null;
          this.goTo(b.to);
        }, b.delay);
      }
    }
    if (to === "fulfillHydrationGoal") {
      to = "hydration";
      this.data.hydration.current = 2;
      let a = this.data[to];
      this.currentScreen = to;
      this.screen = a.screens[a.current][this.lang];
      let b = this.screen.timeout;
      if (b) {
        this.currentTimeout = setTimeout(() => {
          this.currentTimeout = null;
          this.goTo(b.to);
        }, b.delay);
      }
    }

    if (to === "readyToDispenseGotIt") {
      to = this.drinkReadyPopup;
      this.drinkReadyPopup = null;

    }

    if (this.currentScreen === "addCup" && this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }

    if (this.currentScreen === "boiledPopup" && this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }

    if (this.currentScreen === "editTempSave" && this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }

    if (this.currentScreen === "saveJug" && this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }


    if (this.currentScreen === "editProfileSave" && this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }
    if (this.currentScreen === "saveChangesHydrationGoal" && this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }

    if (this.currentScreen === "saveDrink" && this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }

    if (to === "readyToDispense" && this.currentScreen != "prepareDrink") {
      this.drinkReadyPopup = this.currentScreen;
      to = "readyToDispensePopup";
    }

    if (to === "boiledGotIt") {
      to = this.boiledPopup;
      this.boiledPopup = null;
    }

    if (to === "boiled" && this.currentScreen != "boiling") {
      this.boiledPopup = this.currentScreen;
      to = "boiledPopup";
    }

    if (this.currentScreen !== to) {
      let a = this.data[to];
      this.currentScreen = to;
      this.screen = a.screens[a.current][this.lang];
      let b = this.screen.timeout;
      if (b) {
        this.currentTimeout = setTimeout(() => {
          this.currentTimeout = null;
          this.goTo(b.to);
        }, b.delay);
      }
    }
  }

  ngOnInit() {
    this.audioButtonPress.load();
  }

  public play() {
    // done
    if (!this.isMute) {
      this.audioButtonPress.pause();
      this.audioButtonPress.load();
      this.audioButtonPress.play(); // done
    }
  }
}
