import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../language.service';
import Devices from '../../helpers/devices';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.sass']
})
export class ManualComponent implements OnInit {

  public lang = null;
  public devices = Devices.list;
  public currentDevice = null;

  public manualHeader = {
    he: "בחר פעולה מהתפריט",
    en: "Select an action from the menu"
  };

  public manualText = {
    he: 'שים לב, הפעולות קיימות בשני מצבים:',
    en: 'Note that the actions exist in two situations:'
  };

  public manualList = [
    {
      title: {
        he: "מדריך",
        en: "Manual"
      },
      description: {
        he: "מאפשר בחירה מהתפריט צד את הפעולות ומספק הסבר רחב על כל הפעולות",
        en: "Allows you to select actions from the side menu and provides a broad explanation of all actions"
      }
    },
    {
      title: {
        he: 'סימולציה',
        en: 'Simulator'
      },
      description: {
        he: 'מצב של חופש בחירה מוחלט, לחץ על הצג של המכשיר והתנסה בפעולות',
        en: 'In absolute freedom of choice, click on the device\'s display and experiment'
      }
    }
  ];

  constructor(private langService: LanguageService, private route: ActivatedRoute, private router: Router) {
    this.lang = this.langService.getLang();
    this.langService.language.subscribe((lang) => {
      console.log('Manual lang', lang);
      this.lang = lang;
      if (this.currentDevice) {
        this.currentDevice.animation = this.lang === 'he' ? 'slideInLeft' : 'slideInRight';
      }
    });

    this.route.paramMap.subscribe((params) => {
      console.log('params', params);
      this.devices.map((device) => {
        if (device.id === 'premium') {
          if (!this.currentDevice) {
            this.currentDevice = device;
          } else {
            this.currentDevice = null;
            setTimeout(() => {
              this.currentDevice = device;
              this.currentDevice.animation = this.lang === 'he' ? 'slideInLeft' : 'slideInRight';
            }, 100);
          }
        }
      });

    });
  }

  ngOnInit() {
    console.log(this.currentDevice);
  }

}
