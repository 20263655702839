import {Component, Input, OnInit} from '@angular/core';
import {Button} from '../../../shared/button/button.component';
import {ButtonDispenseHotInterface, ButtonDispenseHotStatesInterface} from '../../../interfaces/simulator-entry';

@Component({
  selector: 'app-button-dispense-hot-entry',
  templateUrl: './button-dispense-hot-entry.component.html',
  styleUrls: ['./button-dispense-hot-entry.component.sass']
})
export class ButtonDispenseHotEntryComponent extends Button implements OnInit {

  @Input() data: ButtonDispenseHotInterface;
  public states;

  ngOnInit() {
    this.states = ButtonDispenseHotStatesInterface;
  }

}

