import {Component, OnInit} from '@angular/core';
import {Jug} from '../../data/jug';
import {LanguageService} from '../../../../../language.service';

@Component({
  selector: 'app-jugs-premium',
  templateUrl: './jugs-premium.component.html',
  styleUrls: ['./jugs-premium.component.sass']
})
export class JugsPremiumComponent implements OnInit {

  public lang = null;

  public jugs;

  public text = {
    he: 'קנקנים וסירים',
    en: 'Jugs & Pots'
  };

  public nextButtonText = {
    he: 'הוספה',
    en: 'ADD'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Jugs premium', lang);
      this.lang = lang;
    });
    this.jugs = Jug;
  }

  ngOnInit() {
  }

}
