import {
  ButtonDispenseColdStatesInterface,
  ButtonDispenseHotStatesInterface,
  ButtonDispenseMixStatesInterface,
  ButtonHotwaterStatesInterface,
  ButtonPitcherStatesInterface,
  ButtonSettingsStatesInterface,
  SimulatorEntryInterface,
  SimulatorPagesInterface
} from '../../interfaces/simulator-entry';

export class EntryPageEr {
  static initial(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.page = SimulatorPagesInterface.ER;
    simulatorEntry.display.data = 'Er';
    simulatorEntry.buttons =  {
      dispenseCold: {active: false, state: ButtonDispenseColdStatesInterface.OFF},
      dispenseMix: {active: false, state: ButtonDispenseMixStatesInterface.OFF},
      dispenseHot: {active: false, state: ButtonDispenseHotStatesInterface.OFF},
      pitcher: {active: false, state: ButtonPitcherStatesInterface.FLASHING},
      settings: {active: false, state: ButtonSettingsStatesInterface.ACTIVE},
      hotWater: {active: false, state: ButtonHotwaterStatesInterface.OFF},
    }
  }

  static clickButtonSettings(simulatorEntry: SimulatorEntryInterface) {
      return false;
  }
}
