import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import instructor from '../data/instructor.json';

@Injectable({
  providedIn: 'root'
})
export class InstructorService {

  public currentSteps: Observable<any>;
  private currentStepsSubj: Subject<any>;

  public levelPositions: Observable<object>;
  private levelPositionsSubj: Subject<object>;

  private instructor = instructor;
  private lang = 'en';
  private device = 'premium';

  public nextButtonDisable: Observable<boolean>;
  private nextButtonDisableSubj: Subject<boolean>;

  public prevButtonDisable: Observable<boolean>;
  private prevButtonDisableSubj: Subject<boolean>;


  constructor() {
    this.currentStepsSubj = new Subject<any>();
    this.currentSteps = this.currentStepsSubj.asObservable();


    this.levelPositionsSubj = new Subject<object>();
    this.levelPositions = this.levelPositionsSubj.asObservable();

    this.nextButtonDisableSubj = new Subject<boolean>();
    this.nextButtonDisable = this.nextButtonDisableSubj.asObservable();

    this.prevButtonDisableSubj = new Subject<boolean>();
    this.prevButtonDisable = this.prevButtonDisableSubj.asObservable();


    // setTimeout(() => {
    //   this.levelPositionsSubj.next({level_1: 0, level_2: 0, level_3: 0});
    // }, 0);
  }

  public setCurrentSteps(step: any) {
    this.currentStepsSubj.next(step);
  }

  public setCurrentDevice(device: string) {
    this.device = device;
  }

  public getInstructor() {
    return this.instructor;
  }

  public setLevelPositions(pos) {
    if (pos.level_1 === 0 && pos.level_2 === 0 && pos.level_3 === 0) {
      this.prevButtonDisableSubj.next(true);
      this.nextButtonDisableSubj.next(false);
    } else {
      // get last index for first level 1
      let level_1_MAX = this.instructor[this.device][this.lang].length - 1;
      let level_2_MAX = 0;
      let level_3_MAX = 0;

      // check if level 1 has children. if it's true set last index for level 2
      if (this.instructor[this.device][this.lang][level_1_MAX].hasOwnProperty('children')) {
        level_2_MAX = this.instructor[this.device][this.lang][level_1_MAX].children.length - 1;

        // check if level 2 has children. if it's true set last index for level 3
        if (this.instructor[this.device][this.lang][level_1_MAX].children[level_2_MAX].hasOwnProperty('children')) {
          level_3_MAX = this.instructor[this.device][this.lang][level_1_MAX].children[level_2_MAX].children.length - 1;
        }
      }

      if (pos.level_1 === level_1_MAX && pos.level_2 === level_2_MAX && pos.level_3 === level_3_MAX) {
        this.nextButtonDisableSubj.next(true);
      } else {
        this.nextButtonDisableSubj.next(false);
      }
      this.prevButtonDisableSubj.next(false);
    }
    this.levelPositionsSubj.next(pos);
  }


  // go to next item
  public setLevelPositionsNext(pos) {
    if (this.instructor[this.device][this.lang][pos.level_1]) {
      // check if current level 1 has children
      if (this.instructor[this.device][this.lang][pos.level_1].hasOwnProperty('children')) {
        // check if current level 2 has children
        if (this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].hasOwnProperty('children')) {
          // check if exists next item for current level_3. If it true inc pos.level_3
          if (this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].children[pos.level_3 + 1]) {
            pos.level_3++;

            console.log('NEXT_POS_1');
            // if it false make pos.3 = 0 and inc pos.level 2
          } else {
            pos.level_3 = 0;

            // check if exists next item for level 2
            if (this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2 + 1]) {
              pos.level_2++;

              // if false
            } else {
              pos.level_2 = 0;
              pos.level_1++;
            }

            console.log('NEXT_POS_2');
          }

          // if current level 2 does not have children itmes
        } else {
          // check if exists next item for current level 2
          if (this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2 + 1]) {
            pos.level_3 = 0;
            pos.level_2++;

            console.log('NEXT_POS_3');

            // if it false make pos.2 = 0 and inc pos level 1
          } else {
            pos.level_3 = 0;
            pos.level_2 = 0;
            pos.level_1++;

            console.log('NEXT_POS_4');
          }
        }

        this.instructor[this.device][this.lang][pos.level_1].show = true;

        if (this.instructor[this.device][this.lang][pos.level_1].hasOwnProperty('children')) {
          this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].show = true;
        }
        // if level 1 does not have children
      } else {
        // check if exists next item for level 1
        if (this.instructor[this.device][this.lang][pos.level_1 + 1]) {
          pos.level_3 = 0;
          pos.level_2 = 0;
          pos.level_1++;

          this.instructor[this.device][this.lang][pos.level_1].show = true;
          console.log('NEXT_POS_5');
        } else {
          console.log('END');
        }
      }
    }

    if (this.instructor[this.device][this.lang][pos.level_1].hasOwnProperty('children')) {
      if (this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].hasOwnProperty('children')) {
        return this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].children[pos.level_3].id;
      } else {
        return this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].id;
      }
    } else {
      return this.instructor[this.device][this.lang][pos.level_1].id;
    }
  }


  // go to prev items
  public setLevelPositionsPrev(pos) {
    if (this.instructor[this.device][this.lang][pos.level_1]) {
      // check if current level 1 has children

      if (this.instructor[this.device][this.lang][pos.level_1].hasOwnProperty('children')) {
        // check if current level 2 has children

        if (this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].hasOwnProperty('children')) {
          // check if exists prev item for current level 3
          if (this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].children[pos.level_3 - 1]) {
            pos.level_3--;

            // if not exist
          } else {
            // check if exist prev item for level 2
            if (this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2 - 1]) {
              pos.level_2--;

              // set level 3 last index in level 2 or 0
              pos.level_3 = this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].hasOwnProperty('children')
                ? this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].chilren.length - 1
                : 0;

              // if it false
            } else {
              pos.level_1--;

              pos.level_2 = this.instructor[this.device][this.lang][pos.level_1].hasOwnProperty('children')
                ? this.instructor[this.device][this.lang][pos.level_1].children.length - 1
                : 0;

              pos.level_3 = this.instructor[this.device][this.lang][pos.level_1].hasOwnProperty('children')
                ? this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].hasOwnProperty('children')
                  ? this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].children.length - 1
                  : 0
                : 0;
            }
          }

          // if it false
        } else {
          // check if exist prev item for level 2
          if (this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2 - 1]) {
            pos.level_2--;

            pos.level_3 = this.instructor[this.device][this.lang][pos.level_1].hasOwnProperty('children')
              ? this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].hasOwnProperty('children')
                ? this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].children.length - 1
                : 0
              : 0;
          } else {
            pos.level_1--;

            pos.level_2 = this.instructor[this.device][this.lang][pos.level_1].hasOwnProperty('children')
              ? this.instructor[this.device][this.lang][pos.level_1].children.length - 1
              : 0;

            pos.level_3 = this.instructor[this.device][this.lang][pos.level_1].hasOwnProperty('children')
              ? this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].hasOwnProperty('children')
                ? this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].children.length - 1
                : 0
              : 0;
          }
        }

        this.instructor[this.device][this.lang][pos.level_1].show = true;
        if (this.instructor[this.device][this.lang][pos.level_1].hasOwnProperty('children')) {
          this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].show = true;
        }

        // if it false
      } else {
        // check if exist prev item for level 1
        if (this.instructor[this.device][this.lang][pos.level_1 - 1]) {
          pos.level_1--;

          pos.level_2 = this.instructor[this.device][this.lang][pos.level_1].hasOwnProperty('children')
            ? this.instructor[this.device][this.lang][pos.level_1].children.length - 1
            : 0;

          pos.level_3 = this.instructor[this.device][this.lang][pos.level_1].hasOwnProperty('children')
            ? this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].hasOwnProperty('children')
              ? this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].children.length - 1
              : 0
            : 0;

        } else {
          pos.level_1 = 0;

          pos.level_2 = this.instructor[this.device][this.lang][pos.level_1].hasOwnProperty('children')
            ? this.instructor[this.device][this.lang][pos.level_1].children.length - 1
            : 0;

          pos.level_3 = this.instructor[this.device][this.lang][pos.level_1].hasOwnProperty('children')
            ? this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].hasOwnProperty('children')
              ? this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].children.length - 1
              : 0
            : 0;
        }

        this.instructor[this.device][this.lang][pos.level_1].show = true;
      }
    }

    if (this.instructor[this.device][this.lang][pos.level_1].hasOwnProperty('children')) {
      if (this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].hasOwnProperty('children')) {
        return this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].children[pos.level_3].id;
      } else {
        return this.instructor[this.device][this.lang][pos.level_1].children[pos.level_2].id;
      }
    } else {
      return this.instructor[this.device][this.lang][pos.level_1].id;
    }
  }


}
