import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Button} from '../../../shared/button/button.component';
import {ButtonSettingsInterface, ButtonSettingsStatesInterface} from '../../../interfaces/simulator-entry';

@Component({
  selector: 'app-button-settings-entry',
  templateUrl: './button-settings-entry.component.html',
  styleUrls: ['./button-settings-entry.component.sass']
})
export class ButtonSettingsEntryComponent extends Button implements OnInit {

  @Input() data: ButtonSettingsInterface;
  public states;

  @Output() public longThreeClickDown: EventEmitter<object> = new EventEmitter<object>();
  @Output() public longThreeClickUp: EventEmitter<object> = new EventEmitter<object>();
  @Output() public goBackMenu: EventEmitter<object> = new EventEmitter<object>();

  ngOnInit() {
    this.states = ButtonSettingsStatesInterface;
  }

}
