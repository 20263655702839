import {Component, Input, OnInit} from '@angular/core';
import {Button} from '../../../shared/button/button.component';
import {ButtonPitcherInterface, ButtonPitcherStatesInterface} from '../../../interfaces/simulator-entry';

@Component({
  selector: 'app-button-pitcher-entry',
  templateUrl: './button-pitcher-entry.component.html',
  styleUrls: ['./button-pitcher-entry.component.sass']
})
export class ButtonPitcherEntryComponent extends Button implements OnInit {


  @Input() data: ButtonPitcherInterface;
  public states;

  ngOnInit() {
    this.states = ButtonPitcherStatesInterface;
  }

}
