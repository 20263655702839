import {Component, OnInit} from '@angular/core';
import {Personal} from '../../data/personal';
import {LanguageService} from '../../../../../language.service';

@Component({
  selector: 'app-personal-premium',
  templateUrl: './personal-premium.component.html',
  styleUrls: ['./personal-premium.component.sass']
})
export class PersonalPremiumComponent implements OnInit {

  public personal = null;

  public lang = null;

  public text = {
    he: 'פרופיל משתמש',
    en: 'User Profiles'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Personal premium', lang);
      this.lang = lang;
    });
    this.personal = Personal;
  }

  ngOnInit() {
  }

}
