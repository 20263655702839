import {Directive, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[appMultiKeyLongClick]'
})
export class MultiKeyLongClickDirective {

  @Output() multiKeyLongClick: EventEmitter<object> = new EventEmitter();
  @Input() keyMap: Array<string>;
  private keys: object = {};
  private timeout: any;
  private duration = 2000;
  private isLongClick = true;

  ngOnInit(): void {
    this.initKeyMap();
  }

  private initKeyMap() {
    for (const index in this.keyMap) {
      this.keys[this.keyMap[index]] = false;
    }
  }

  private checkKeys() {
    let allKeysPressed = true;

    for (const index in this.keys) {

      if (!this.keys[index]) {
        allKeysPressed = false;
      }
    }
    return allKeysPressed;
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
    if (this.isLongClick && this.keys[event.code] !== undefined) {
      this.keys[event.code] = true;

      if (this.checkKeys()) {
        this.isLongClick = false;

        this.timeout = setTimeout(() => {
          this.multiKeyLongClick.emit(event);
        }, this.duration);
      }
    }
  }

  @HostListener('document:keyup') onKeyupHandler() {
    this.isLongClick = true;
    clearInterval(this.timeout);
    this.initKeyMap();
  }

}
