import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';

@Component({
  selector: 'app-define-personal-drinks-premium',
  templateUrl: './define-personal-drinks-premium.component.html',
  styleUrls: ['./define-personal-drinks-premium.component.sass']
})
export class DefinePersonalDrinksPremiumComponent implements OnInit {

  public lang = null;

  public text_top = {
    he: 'הגדרת משקאות אישיים',
    en: 'Define more personal drinks'
  };
  public text_bottom = {
    he: 'אפשרית דרך האפליקציה',
    en: 'from the mobile App'
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Define personal drinks premium', lang);
      this.lang = lang;
    });
  }

  ngOnInit() {
  }

}
