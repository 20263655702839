import {Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2} from '@angular/core';

@Directive({
  selector: '[appLongClick]'
})
export class LongClickDirective {

  @Output() longClickDown: EventEmitter<object> = new EventEmitter();
  @Output() longClickUp: EventEmitter<object> = new EventEmitter();

  private timeout: any;
  private duration = 1000;
  private isLongClickDone = false;
  private secondsCount = 0;
  private secondsInterval = null;

  constructor(private element: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event) {
    this.renderer.addClass(this.element.nativeElement, 'active-btn');
    this.timeout = setTimeout(() => {
      this.isLongClickDone = true;
      this.longClickDown.emit(event);
    }, this.duration);

    this.secondsInterval = setInterval(() => {
      this.secondsCount++;
      console.log('in interval', this.secondsCount)
    }, 1000)
  }

  @HostListener('mouseup', ['$event']) onMouseUp(event) {
    if (this.isLongClickDone && this.secondsCount < 4) {
      this.longClickUp.emit(event);
    }
    this.renderer.removeClass(this.element.nativeElement, 'active-btn');
    clearTimeout(this.timeout);
    clearInterval(this.secondsInterval);
    this.isLongClickDone = false;
    this.secondsCount = 0;
  }

  @HostListener('mouseout', ['$event']) onMouseOut(event) {
    if (this.isLongClickDone) {
      this.longClickUp.emit(event);
    }
    this.renderer.removeClass(this.element.nativeElement, 'active-btn');
    clearTimeout(this.timeout);
    clearInterval(this.secondsInterval);
    this.isLongClickDone = false;
    this.secondsCount = 0;
  }

}
