import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import {InternalCleaning} from '../../data/internalCleaning';

@Component({
  selector: 'app-internal-cleaning-main-premium',
  templateUrl: './internal-cleaning-main-premium.component.html',
  styleUrls: ['./internal-cleaning-main-premium.component.sass']
})
export class InternalCleaningMainPremiumComponent implements OnInit {

  public cleaning = null;

  public lang = null;

  public text = {
    he: 'ניקוי פנימי',
    en: 'Internal cleaning'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Internal cleaning main premium', lang);
      this.lang = lang;
      InternalCleaning.initCleaningSwiper();
      InternalCleaning.reset();
    });
    this.cleaning = InternalCleaning;
  }

  ngOnInit() {
    InternalCleaning.initCleaningSwiper();
    InternalCleaning.reset();
  }

}
