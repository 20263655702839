import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import {WashingTank} from '../../data/washingTank';
import {InternalCleaning} from '../../data/internalCleaning';

@Component({
  selector: 'app-internal-cleaning-premium',
  templateUrl: './internal-cleaning-premium.component.html',
  styleUrls: ['./internal-cleaning-premium.component.sass']
})
export class InternalCleaningPremiumComponent implements OnInit {

  public lang = null;
  public internalCleaning;
  public topText = {
    he: 'ניקוי אבנית',
    en: 'Descaling'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  public welcomeText = {
    line_1: {
      he: 'יש להוציא את מטהר המים',
      en: 'Remove the Purifier'
    },
    line_2: {
      he: 'להכניס את קפסולת הניקוי',
      en: 'and insert the'
    },
    line_3: {
      he: '',
      en: 'Descaling capsule'
    },
    line_4: {
      he: 'יש ללחוץ אישור לאחר',
      en: 'Press OK after inserting'
    },
    line_5: {
      he: 'הכנסת קפסולת הניקוי',
      en: 'the Descaling capsule'
    }
  };

  public warningText = {
    warningTop: {
      he: 'אזהרה!',
      en: 'Warning!'
    },
    warningMiddle: {
      he: 'התהליך כולל',
      en: 'Process includes'
    },
    warningBottom: {
      he: 'שופכים מים <span style="color:#F20000">חמים</span>',
      en: 'pouring <span style="color:#F20000">Hot</span> water'
    },

    warningInstuctor: {
      line_1: {
        he: 'בר המים לא יהיה פעיל',
        en: 'Bar will be inactive'
      },
      line_2: {
        he: 'למשך 30 דקות',
        en: 'for 30 min'
      }
    }
  };

  public text_step_2 = {
    he: {
      line_1: 'שלב 1 מתוך 5',
      line_2: 'הניחו מיכל גדול',
      line_3: 'מתחת לזרבובית',
      line_4: 'יש ללחוץ על כפתור',
      line_5: 'ההרתחה<span style="color: #F20000"> להמשך</span>'
    },
    en: {
      line_1: 'Step 1 of 5',
      line_2: 'Place a large container',
      line_3: 'under the spout',
      line_4: 'Press boil to continue',
      line_5: 'The <span style="color: #F20000">Hot</span> water will start boiling',
    }
  };

  public text_step_3 = {
    he: {
      line_1: '',
      line_2: 'בתהליך הרתחה. יש להמתין...',
    },
    en: {
      line_1: '',
      line_2: 'Boiling water. Please wait...',
    }
  };

  public text_step_4 = {
    he: {
      line_1: 'שלב 2 מתוך 5',
      line_2: 'לחץ על מים <span style="color:#F20000">חמים</span>',
      line_3: 'להתחיל לוותר',
    },
    en: {
      line_1: 'Step 2 of 5',
      line_2: 'Press <span style="color: #F20000">Hot</span> water',
      line_3: 'to start dispensing',
    }
  };

  public text_step_5 = {
    he: {
      line_1: 'שלב 2 מתוך 5',
      line_2: 'לחיצה על כל כפתור תעצור מזיגה',
      line_3: 'לחיצה על המשך תחדש מזיגהך',
    },
    en: {
      line_1: 'Step 2 of 5',
      line_2: 'Press any key to pause',
      line_3: 'Press Resume to continue',
    }
  };

  public resumeText = {
    he: 'המשך',
    en: 'RESUME'
  };

  public text_step_6 = {
    he: {
      line_1: 'יש להמתין 20 שניות',
    },
    en: {
      line_1: 'Please wait 20 sec',
    }
  };

  public text_step_7 = {
    he: {
      line_1: 'שלב 3 מתוך 5',
      line_2: 'לחץ על מים <span style="color:#F20000">חמים</span>',
      line_3: 'להתחיל לוותר',
    },
    en: {
      line_1: 'Step 3 of 5',
      line_2: 'Press <span style="color: #F20000">Hot</span> water',
      line_3: 'to start dispensing',
    }
  };

  public text_step_8 = {
    he: {
      line_1: 'שלב 3 מתוך 5',
      line_2: 'לחיצה על כל כפתור תעצור מזיגה',
      line_3: 'לחיצה על המשך תחדש מזיגהך',
    },
    en: {
      line_1: 'Step 3 of 5',
      line_2: 'Press any key to pause',
      line_3: 'Press Resume to continue',
    }
  };

  public text_step_9 = {
    he: {
      line_1: '',
      line_2: 'בתהליך הרתחה. יש להמתין...',
    },
    en: {
      line_1: '',
      line_2: 'Boiling water. Please wait...',
    }
  };

  public text_step_10 = {
    he: {
      line_1: '',
      line_2: 'בתהליך ניקוי',
    },
    en: {
      line_1: '',
      line_2: 'Descaling in process',
    }
  };

  public text_step_11 = {
    he: {
      line_1: 'שלב 4 מתוך 5',
      line_2: 'יש להוציא את קפסולת הניקוי',
      line_3: 'ולהכניס את מטהר המים',
    },
    en: {
      line_1: 'Step 4 of 5',
      line_2: 'Remove the capsule',
      line_3: 'and insert the purifier',
    }
  };

  public text_step_12 = {
    he: {
      line_1: 'שלב 5 מתוך 5',
      line_2: 'הניחו מיכל גדול',
      line_3: 'מתחת לזרבובית',
      line_4: 'יש ללחוץ על כפתור',
      line_5: 'ההרתחה<span style="color: #F20000"> להמשך</span>'
    },
    en: {
      line_1: 'Step 5 of 5',
      line_2: 'Place a large container',
      line_3: 'under the spout',
      line_4: 'Press boil to continue',
      line_5: 'The <span style="color: #F20000">Hot</span> water will start boiling',
    }
  };
  public text_step_13 = {
    he: {
      line_1: 'שלב 5 מתוך 5',
      line_2: 'לחיצה על כל כפתור תעצור מזיגה',
      line_3: 'לחיצה על המשך תחדש מזיגהך',
    },
    en: {
      line_1: 'Step 5 of 5',
      line_2: 'Press any key to pause',
      line_3: 'Press Resume to continue',
    }
  };
  public text_step_14 = {
    he: {
      line_1: 'התהליך הסתיים בהצלחה',
    },
    en: {
      line_1: 'Process Completed',
    }
  };


  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Internal cleaning premium', lang);
      this.lang = lang;
    });
    this.internalCleaning = InternalCleaning;
    WashingTank.reset();
  }

  ngOnInit() {
  }

}
