import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';

@Component({
  selector: 'app-shabbat-mode-confirmation-premium',
  templateUrl: './shabbat-mode-confirmation-premium.component.html',
  styleUrls: ['./shabbat-mode-confirmation-premium.component.sass']
})
export class ShabbatModeConfirmationPremiumComponent implements OnInit {

  public lang = null;

  public text = {
    he: 'העדפות',
    en: 'Settings'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  public textValueYes = {
    he: 'כן',
    en: 'YES'
  };

  public textValueNo = {
    he: 'לא',
    en: 'NO'
  };

  public conformatonTextTop = {
    he: '',
    en: 'Are you sure you want to'
  };

  public conformatonTextBottom = {
    he: 'להפעיל מצב שינה?',
    en: 'turn on Sleep mode?'
  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Shabbat mode confirmation premium', lang);
      this.lang = lang;
    });
  }

  ngOnInit() {
  }

}
