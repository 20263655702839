import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';

@Component({
  selector: 'app-support-premium',
  templateUrl: './support-premium.component.html',
  styleUrls: ['./support-premium.component.sass']
})
export class SupportPremiumComponent implements OnInit {

  public lang = null;
  public topText = {
    he: 'תמיכה',
    en: 'Support'
  };

  public nextButtonText = {
    he: 'אישור',
    en: 'NEXT'
  };

  public prevButtonText = {
    he: 'חזרה',
    en: 'BACK'
  };

  public message = {
    he: 'אנא צרו קשר',
    en: 'Please contact us'
  };

  public phoneNumber = '*6944';
  public website = "www.strauss-water.com"

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Support premium', lang);
      this.lang = lang;
    });
  }

  ngOnInit() {
  }

}
