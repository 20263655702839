// Sleeping mode
/* TODO: After device finishing sleep we don't save prev. states and just fake on wifi */
import {
  ButtonDispenseColdStatesInterface,
  ButtonDispenseHotStatesInterface,
  ButtonDispenseMixStatesInterface,
  IndicatorWifiStateInterface,
  SimulatorEntryInterface,
  SimulatorPagesInterface,
  SimulatorStatesInterface
} from '../../interfaces/simulator-entry';

export class EntryPageSL {
  static initial(simulatorEntry: SimulatorEntryInterface) {
    simulatorEntry.page = SimulatorPagesInterface.SL;
    simulatorEntry.display.data = 'SL';
    simulatorEntry.buttons.dispenseHot.state = ButtonDispenseHotStatesInterface.OFF;
    simulatorEntry.buttons.dispenseMix.state = ButtonDispenseMixStatesInterface.OFF;
    simulatorEntry.buttons.dispenseCold.state = ButtonDispenseColdStatesInterface.FLASHING;
  }

  static clickButtonDispenseCold(simulatorEntry: SimulatorEntryInterface) {
    switch (simulatorEntry.display.data) {
      case 'SL':
        simulatorEntry.display.data = 'ON';
        break;
      case 'ON':
        simulatorEntry.display.data = 'OF';
        break;
      case 'OF':
        simulatorEntry.display.data = 'ON';
        break;
    }
  }

  static clickButtonSettings(simulatorEntry: SimulatorEntryInterface) {
    if (simulatorEntry.display.data !== 'SL') {
      if (simulatorEntry.display.data === 'ON') {
        // lets sleep
        simulatorEntry.display.data = 'SL';
        simulatorEntry.state = SimulatorStatesInterface.SLEEP;
        simulatorEntry.buttons.dispenseCold.state = ButtonDispenseColdStatesInterface.OFF;
        simulatorEntry.indicator.wifi.state = IndicatorWifiStateInterface.OFF;
        // clear sleep message
        setTimeout(() => {
          simulatorEntry.display.data = '  ';
        }, 7000);
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
