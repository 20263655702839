import { Component, OnInit } from "@angular/core";
import {
  ButtonsPremiumState,
  PouringAnimation,
} from "../interfaces/simulator-premium";
import { MuteService } from "../../../mute.service";

@Component({
  selector: "app-simulator-kodesh",
  templateUrl: "./simulator-kodesh.component.html",
  styleUrls: ["./simulator-kodesh.component.sass"],
})
export class SimulatorKodeshComponent implements OnInit {
  public helper = null;
  public PouringAnimation = PouringAnimation;
  public audioButtonPress = new Audio("/assets/audio/water_droplet.mp3");

  public isMute = null;
  public lang = null;
  public current = null;

  public playing = false;

  public gif = new Image();
  public gifSrc = null;

  constructor(private muteService: MuteService) {
    this.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      this.isMute = mute;
    });
    this.gif.src = "/assets/img/kodesh-pour.png";
  }

  ngOnInit() {
    this.audioButtonPress.load();
  }

  public play() {
    // done
    if (!this.isMute) {
      this.audioButtonPress.pause();
      this.audioButtonPress.load();
      this.audioButtonPress.play(); // done
    }
  }

  public pour() {
    this.playing = true;
    this.gifSrc = null;
    this.gifSrc = this.gif.src;
    setTimeout(() => {
      this.playing = false;
      this.gifSrc = null;
    }, 1500);
  }
}
