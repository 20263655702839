import {SimulatorPremiumPagesInterface} from '../../interfaces/simulator-premium';

export class Personal {

  public static users = {
    he: [
      {
        id: 1,
        name: 'דוד',
        img: 'dad.svg',
        drinks: [
          {
            id: 1,
            name: 'מים קרים',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'מים פושרים',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'תה',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'קפה',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      },
      {
        id: 2,
        name: 'אנה',
        img: 'mom.svg',
        drinks: [
          {
            id: 1,
            name: 'מים קרים',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'מים פושרים',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'תה',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'קפה',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      },
      {
        id: 3,
        name: 'בן',
        img: 'boy.svg',
        drinks: [
          {
            id: 1,
            name: 'מים קרים',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'מים פושרים',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'תה',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'קפה',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      },
      {
        id: 4,
        name: 'אמי',
        img: 'girl.svg',
        drinks: [
          {
            id: 1,
            name: 'מים קרים',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'מים פושרים',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'תה',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'קפה',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      },
      {
        id: 5,
        name: 'מיקי',
        img: 'dad.svg',
        drinks: [
          {
            id: 1,
            name: 'מים קרים',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'מים פושרים',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'תה',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'קפה',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      },
      {
        id: 6,
        name: 'יעל',
        img: 'mom.svg',
        drinks: [
          {
            id: 1,
            name: 'מים קרים',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'מים פושרים',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'תה',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'קפה',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      },
      {
        id: 7,
        name: 'רן',
        img: 'boy.svg',
        drinks: [
          {
            id: 1,
            name: 'מים קרים',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'מים פושרים',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'תה',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'קפה',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      },
      {
        id: 8,
        name: 'שרה',
        img: 'girl.svg',
        drinks: [
          {
            id: 1,
            name: 'מים קרים',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'מים פושרים',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'תה',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'קפה',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      }
    ],

    en: [
      {
        id: 1,
        name: 'David',
        img: 'dad.svg',
        drinks: [
          {
            id: 1,
            name: 'Cold',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'Mix',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'Tea',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'Coffee',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      },
      {
        id: 2,
        name: 'Anna',
        img: 'mom.svg',
        drinks: [
          {
            id: 1,
            name: 'Cold',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'Mix',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'Tea',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'Coffee',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      },
      {
        id: 3,
        name: 'Ben',
        img: 'boy.svg',
        drinks: [
          {
            id: 1,
            name: 'Cold',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'Mix',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'Tea',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'Coffee',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      },
      {
        id: 4,
        name: 'Ammy',
        img: 'girl.svg',
        drinks: [
          {
            id: 1,
            name: 'Cold',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'Mix',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'Tea',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'Coffee',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      },
      {
        id: 5,
        name: 'Micky',
        img: 'dad.svg',
        drinks: [
          {
            id: 1,
            name: 'Cold',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'Mix',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'Tea',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'Coffee',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      },
      {
        id: 6,
        name: 'Yael',
        img: 'mom.svg',
        drinks: [
          {
            id: 1,
            name: 'Cold',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'Mix',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'Tea',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'Coffee',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      },
      {
        id: 7,
        name: 'Dan',
        img: 'boy.svg',
        drinks: [
          {
            id: 1,
            name: 'Cold',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'Mix',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'Tea',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'Coffee',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      },
      {
        id: 8,
        name: 'Sarah',
        img: 'girl.svg',
        drinks: [
          {
            id: 1,
            name: 'Cold',
            img: 'cup_drink_cold.svg',
            run: SimulatorPremiumPagesInterface.ColdWaterDispensing
          },
          {
            id: 2,
            name: 'Mix',
            img: 'cup_drink_mix.svg',
            run: SimulatorPremiumPagesInterface.MixWaterDispensing
          },
          {
            id: 3,
            name: 'Tea',
            img: 'cup_drink_tea.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          },
          {
            id: 4,
            name: 'Coffee',
            img: 'cup_drink_coffee.svg',
            run: SimulatorPremiumPagesInterface.HotWaterDispensing
          }
        ]
      }
    ],
  };

  public static currentUserPage = 0;
  public static currentDrinkPage = 0;
  public static currentUser = null;

}
