import {Observable, Subject} from 'rxjs';

export class Boiling {
  public isBoiling: Observable<Boolean>;
  private isBoolingSubject: Subject<boolean>;

  constructor() {
    this.isBoolingSubject = new Subject<boolean>();
    this.isBoiling = this.isBoolingSubject.asObservable();
  }

  public setBoiling(boiling: boolean) {
    this.isBoolingSubject.next(boiling);
  }
}
