import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';
import {LockButton} from '../../data/lockButton';

@Component({
  selector: 'app-lock-buttons-screen-premium',
  templateUrl: './lock-buttons-screen-premium.component.html',
  styleUrls: ['./lock-buttons-screen-premium.component.sass']
})
export class LockButtonsScreenPremiumComponent implements OnInit {

  public lang = null;
  public lockButton = null;

  public buttonText = {
    he: 'הקש',
    en: 'TAP'
  };

  public lockText = {
    he: 'מקשים נעולים',
    en: 'Buttons are locked'
  };

  public instructionText = {
    line_1: {
      he: 'לביטול הנעילה יש להחזיק',
      en: 'Tap buttons for 2 sec'
    },
    line_2: {
      he:'את הכפתורים למשך 2 שניות',
      en: ''
    }

  };

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Lock buttons screen premium', lang);
      this.lang = lang;
    });
    this.lockButton = LockButton;
  }

  ngOnInit() {
  }

}
