import {Component, OnInit} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import {LanguageService} from '../../language.service';
import {MuteService} from '../../mute.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.sass']
})
export class LanguageComponent implements OnInit {

  public languages = {
    he: {
      value: 'he',
      smallText: 'עב',
      largeText: 'עברית',
      checked: true
    },
    en: {
      value: 'en',
      smallText: 'Eng',
      largeText: 'English',
      checked: false
    }
  };

  currentLang = null;
  public isMute = null;
  isEng = false;

  constructor(private langService: LanguageService, private muteService: MuteService, public router: Router) {


    this.switchLanguage(langService.getLang(), false);
    langService.language.subscribe((lang) => {
      this.currentLang = this.languages[lang];
    });


    this.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      this.isMute = mute;
    });
  }

  ngOnInit() {
  }

  public resetCheck() {
    this.languages.en.checked = false;
    this.languages.he.checked = false;
  }

  public switchLanguage(key, audio = true) {
    this.resetCheck();

    if (audio && (this.currentLang !== this.languages[key])) {
      if (!this.isMute) {
        window['ion'].sound.play('swipe'); // done
      }
    }

    this.languages[key]['checked'] = true;
    this.currentLang = this.languages[key];
    this.langService.setLanguage(key);

    document.body.className = '';
    document.body.className = key === 'he' ? 'dir-rtl h-100' : 'dir-ltr h-100';

  }

}
