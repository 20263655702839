import {
  ButtonsPremiumState,
  IndicatorAddonStatePremium,
  IndicatorLockStatePremium,
  IndicatorNetStatePremium,
  IndicatorPurifierStatePremium,
  IndicatorUvStatePremium,
  IndicatorWifiStatePremium,
  PouringAnimation,
  SimulatorPremiumPagesInterface
} from '../../interfaces/simulator-premium';
import {Personal} from '../data/personal';
import {Jug} from '../data/jug';
import {Setting} from '../data/setting';
import {WashingTank} from '../data/washingTank';
import {BuiltInTest} from '../data/builtInTest';
import {Reset} from '../data/reset';
import {QuantityAndTemp} from '../data/quantityAndTemp';
import {InternalCleaning} from '../data/internalCleaning';
import {ButtonHelper} from '../helpers/buttonHelper';
import {MuteService} from '../../../../mute.service';

export class BoilingBtn {

  public static isMute = null;

  constructor(private muteService: MuteService) {
    BoilingBtn.isMute = this.muteService.getMode();
    this.muteService.mute.subscribe((mute) => {
      BoilingBtn.isMute = mute;
    });
  }

  public static shortClick(isMute): void {
    BoilingBtn.isMute = isMute;
    switch (ButtonHelper.simulator.page) {

      // IDLE (main) screen
      case SimulatorPremiumPagesInterface.IDLE:
      case SimulatorPremiumPagesInterface.ParametersTech:
        ButtonHelper.resetMainAfk();
        ButtonHelper.simulator = {
          indicator: {
            addon: {active: true, state: IndicatorAddonStatePremium.OFF},
            uv: {active: true, state: IndicatorUvStatePremium.OFF},
            wifi: {active: true, state: IndicatorWifiStatePremium.OFF},
            clock: {active: false},
            lock: {active: true, state: IndicatorLockStatePremium.OFF},
            purifier: {active: true, state: IndicatorPurifierStatePremium.OFF},
            net: {active: false, state: IndicatorNetStatePremium.OFF}
          },
          page: SimulatorPremiumPagesInterface.Boiling,
          pouring: {fast: false, animation: PouringAnimation.NONE},
          buttons: {
            hot: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.FLASH},
            jugs: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.ICON_BRIGHT}
          }
        };
        break;

      // Dispensing Mix, Hot, Cold water
      case SimulatorPremiumPagesInterface.MixWaterDispensing:
      case SimulatorPremiumPagesInterface.HotWaterDispensing:
      case SimulatorPremiumPagesInterface.ColdWaterDispensing:
        ButtonHelper.mainSimulatorScreen();
        break;

      // Personal screen
      case SimulatorPremiumPagesInterface.Personal:
        if (!Personal.currentUserPage) {
          ButtonHelper.resetMainAfk();
          ButtonHelper.mainSimulatorScreen();
        } else {
          ButtonHelper.startMainAfk();
          ButtonHelper.simulator.buttons.personal.state = ButtonsPremiumState.BORDER;
          Personal.currentUserPage = 0;
        }
        break;

      // Personal Drinks screen
      case SimulatorPremiumPagesInterface.PersonalDrinks:
        ButtonHelper.startMainAfk();
        ButtonHelper.simulatorPersonal();
        Personal.currentUserPage = 0;
        break;

      // Jugs screen
      case SimulatorPremiumPagesInterface.Jugs:
        ButtonHelper.resetMainAfk();
        ButtonHelper.mainSimulatorScreen();
        break;

      // Activate Jug screen
      case SimulatorPremiumPagesInterface.ActivateJugs:
        if (Jug.step === 1) {
          Jug.currentJug = null;
          ButtonHelper.startMainAfk();
          ButtonHelper.simulatorJugs();
        } else if (Jug.step === 2) {
          Jug.step++;
          ButtonHelper.startMainAfk();
          ButtonHelper.simulatorActivateJug();
          if (Jug.step == 3) {
            ButtonHelper.simulator.buttons = {
              hot: {active: true, state: ButtonsPremiumState.NO_ICON},
              cold: {active: true, state: ButtonsPremiumState.NO_ICON},
              mix: {active: true, state: ButtonsPremiumState.NO_ICON},
              boiling: {active: true, state: ButtonsPremiumState.BORDER},
              jugs: {active: true, state: ButtonsPremiumState.NO_ICON},
              personal: {active: true, state: ButtonsPremiumState.BORDER}
            };
          }
        } else if (Jug.step === 3 || Jug.step === 4) {
          Jug.step = 1;
          ButtonHelper.startMainAfk();
          ButtonHelper.simulatorActivateJug();
        }
        break;

      // Menu screen
      case SimulatorPremiumPagesInterface.Menu:
        ButtonHelper.resetMainAfk();
        ButtonHelper.mainSimulatorScreen();
        break;

      // Settings screen
      case SimulatorPremiumPagesInterface.Settings:
        if (!Setting.isEditMode) {
          ButtonHelper.startMainAfk();
          ButtonHelper.simulatorMenu();
        } else if (Setting.isConnectivityScreen || Setting.isLanguage) {
          ButtonHelper.startMainAfk();
          clearTimeout(ButtonHelper.afkDispensingWater);
          ButtonHelper.simulatorSettings();
        } else if (Setting.isDateTime) {
          if (!Setting.isSetTime && !Setting.isSetDate) {
            ButtonHelper.startMainAfk();
            ButtonHelper.simulatorSettings();
          } else {
            if (Setting.currentTimePos || Setting.currentDatePos) {
              ButtonHelper.startMainAfk();
              Setting.decTimePosition();
              if (!Setting.isTimeMessage && !Setting.isDateMessage) {
                ButtonHelper.simulator.buttons.jugs.state = ButtonsPremiumState.BORDER;
                ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.BORDER;
              }
            }
          }
        } else {
          ButtonHelper.startMainAfk();
          ButtonHelper.simulator.buttons = {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.BORDER},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          };
          if (Setting.isDeactivatedConformation) {
            Setting.initSwiper();
            Setting.isEditMode = false;
            Setting.isDeactivatedConformation = false;

          } else {
            Setting.settingsList[ButtonHelper.lang][Setting.currentIndex]['value'] =
              Setting.settingsList[ButtonHelper.lang][Setting.currentIndex]['defaultValue'];

            Setting.isEditMode = false;
          }
        }
        break;

      // Support screen
      case SimulatorPremiumPagesInterface.Support:
        ButtonHelper.simulatorMenu();
        ButtonHelper.startMainAfk();
        break;

      // Maintenance screen
      case SimulatorPremiumPagesInterface.Maintenance:
        ButtonHelper.simulatorMenu();
        ButtonHelper.startMainAfk();
        break;

      // WashingTank screen
      case SimulatorPremiumPagesInterface.WashingTank:
        if (WashingTank.step === 2) {
          WashingTank.isPause = !WashingTank.isPause;
        } else if (WashingTank.step === 4) {
          WashingTank.isPause = !WashingTank.isPause;
        }
        break;

      // BuiltInTest screen
      case SimulatorPremiumPagesInterface.BuiltInTest:
        if (BuiltInTest.step === 0) {
          BuiltInTest.reset();
          ButtonHelper.simulatorMaintenance();
          ButtonHelper.startMainAfk();
        }
        break;

      // Purifier And Uv screen
      case SimulatorPremiumPagesInterface.PurifierAndUv:
      case SimulatorPremiumPagesInterface.ShabbatModeConfirmation:
        ButtonHelper.simulatorMaintenance();
        ButtonHelper.startMainAfk();
        break;

      case SimulatorPremiumPagesInterface.ShabbatModeConfirmationHome:
        ButtonHelper.mainSimulatorScreen();
        ButtonHelper.resetMainAfk();
        break;

      case SimulatorPremiumPagesInterface.Reset:
        if (Reset.screen === 1) {
          Reset.reset();
          ButtonHelper.simulatorMaintenance();
          ButtonHelper.startMainAfk();
        }
        if (Reset.screen === 2) {
          Reset.screen = 1;
          ButtonHelper.simulatorFactory();
          ButtonHelper.startMainAfk();
        }
        break;

      case SimulatorPremiumPagesInterface.Preferences:
        ButtonHelper.simulatorMenu();
        ButtonHelper.startMainAfk();
        break;

      case SimulatorPremiumPagesInterface.QuantityAndTemp:
        if (!QuantityAndTemp.isJugs) {
          ButtonHelper.simulatorPreferences();
          ButtonHelper.startMainAfk();
        } else {
          QuantityAndTemp.reset();
        }
        break;

      case SimulatorPremiumPagesInterface.WaterQuantity:
        if (QuantityAndTemp.step === 1) {
          if (!QuantityAndTemp.isPersonal) {
            QuantityAndTemp.reset();
            ButtonHelper.simulator = {
              indicator: {
                addon: {active: false, state: IndicatorAddonStatePremium.OFF},
                uv: {active: false, state: IndicatorUvStatePremium.OFF},
                wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
                clock: {active: false},
                lock: {active: false, state: IndicatorLockStatePremium.OFF},
                purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
                net: {active: false, state: IndicatorNetStatePremium.OFF}
              },
              page: SimulatorPremiumPagesInterface.QuantityAndTemp,
              pouring: {fast: false, animation: PouringAnimation.NONE},
              buttons: {
                hot: {active: true, state: ButtonsPremiumState.BORDER},
                cold: {active: true, state: ButtonsPremiumState.BORDER},
                mix: {active: true, state: ButtonsPremiumState.BORDER},
                boiling: {active: true, state: ButtonsPremiumState.BORDER},
                jugs: {active: true, state: ButtonsPremiumState.BORDER},
                personal: {active: true, state: ButtonsPremiumState.BORDER}
              }
            };
          } else {
            ButtonHelper.simulatorEditPersonalQuantity();
          }
        } else if (QuantityAndTemp.step === 3 || QuantityAndTemp.step === 4) {
          QuantityAndTemp.step = 1;
          if (QuantityAndTemp.currentDrinks.run == SimulatorPremiumPagesInterface.HotWaterDispensing) {
            ButtonHelper.simulator.buttons = {
              hot: {active: true, state: ButtonsPremiumState.FLASH},
              cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              boiling: {active: true, state: ButtonsPremiumState.BORDER},
              jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              personal: {active: true, state: ButtonsPremiumState.BORDER}
            };
          } else if (QuantityAndTemp.currentDrinks.run == SimulatorPremiumPagesInterface.MixWaterDispensing) {
            ButtonHelper.simulator.buttons = {
              hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              mix: {active: true, state: ButtonsPremiumState.FLASH},
              boiling: {active: true, state: ButtonsPremiumState.BORDER},
              jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              personal: {active: true, state: ButtonsPremiumState.BORDER}
            };
          } else {
            ButtonHelper.simulator.buttons = {
              hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              cold: {active: true, state: ButtonsPremiumState.FLASH},
              mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              boiling: {active: true, state: ButtonsPremiumState.BORDER},
              jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              personal: {active: true, state: ButtonsPremiumState.BORDER}
            };
          }
        }
        break;

      case SimulatorPremiumPagesInterface.PersonalQuantity:
        if (!Personal.currentUserPage) {
          ButtonHelper.simulatorPreferences();
          QuantityAndTemp.reset();
          ButtonHelper.startMainAfk();
        } else {
          ButtonHelper.simulator.buttons.personal.state = ButtonsPremiumState.BORDER;
          Personal.currentUserPage = 0;
          ButtonHelper.startMainAfk();
        }
        break;

      case SimulatorPremiumPagesInterface.EditPersonalQuantity:
        ButtonHelper.simulatorQuantityPersonal();
        ButtonHelper.startMainAfk();
        break;

      case SimulatorPremiumPagesInterface.InternalCleaningMain:
        ButtonHelper.simulatorMaintenance();
        InternalCleaning.reset();
        ButtonHelper.startMainAfk();
        break;

      case SimulatorPremiumPagesInterface.Descaling:
        // boiling
        ButtonHelper.boilingAudio.load();
        ButtonHelper.boilingAudio.volume = 0.1;
        ButtonHelper.pouringAudio.load();
        if (InternalCleaning.step === 0) {
          ButtonHelper.simulatorInternalCleaning();
        } else if (InternalCleaning.step === 1) {
          InternalCleaning.step = 0;
          ButtonHelper.simulator.buttons = {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          };
        } else if (InternalCleaning.step === 2) {
          ButtonHelper.simulator.buttons = {
            hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            boiling: {active: true, state: ButtonsPremiumState.FLASH},
            jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
            personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
          };
          InternalCleaning.step = 3;
          if (!BoilingBtn.isMute) {
            ButtonHelper.boilingAudio.play(); // done
          }
          InternalCleaning.statrtBoiling(700).then(() => {
            InternalCleaning.step = 4;
            ButtonHelper.boilingAudio.pause();
            ButtonHelper.simulator.buttons = {
              hot: {active: true, state: ButtonsPremiumState.FLASH},
              cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
              personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
            };
          });
        } else if (InternalCleaning.step === 5 || InternalCleaning.step === 8 || InternalCleaning.step === 13) {
          if (!InternalCleaning.isPause) {
            InternalCleaning.isPause = true;
            ButtonHelper.pouringAudio.pause();
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
          }
        }
        break;

      case SimulatorPremiumPagesInterface.Sterilization:
        if (InternalCleaning.step === 0) {
          ButtonHelper.simulatorInternalCleaning();
        } else if (InternalCleaning.step === 1) {
          InternalCleaning.step = 0;
          ButtonHelper.simulator.buttons = {
            hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
            cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
            mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
            boiling: {active: true, state: ButtonsPremiumState.BORDER},
            jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
            personal: {active: true, state: ButtonsPremiumState.BORDER}
          };
        } else if (InternalCleaning.step === 3 || InternalCleaning.step === 6
          || InternalCleaning.step === 9 || InternalCleaning.step === 13) {
          if (!InternalCleaning.isPause) {
            InternalCleaning.isPause = true;
            ButtonHelper.pouringAudio.pause();
            ButtonHelper.simulator.pouring.animation = PouringAnimation.NONE;
          }
        }
        break;

      // tech screen
      case SimulatorPremiumPagesInterface.Tech:
        ButtonHelper.resetMainAfk();
        ButtonHelper.mainSimulatorScreen();
        break;

      case SimulatorPremiumPagesInterface.ChildLockTech:
        ButtonHelper.techScreen();
        break;

      case SimulatorPremiumPagesInterface.DebugTech:
        ButtonHelper.techScreen();
        break;

      case SimulatorPremiumPagesInterface.TempTech:
        ButtonHelper.techScreen();
        break;

    }
  }

  public static longClickDown(isMute) {
    switch (ButtonHelper.simulator.page) {

      case SimulatorPremiumPagesInterface.ShabbatModeScreen:
        ButtonHelper.resetMainAfk();
        ButtonHelper.mainSimulatorScreen();
        break;
    }
  }
}
