import Swiper from 'swiper/dist/js/swiper.js';

export class Reset {
  public static resetItems = {
    he: [
      {
        id: 1,
        name: 'אפס את הגדרות היצרן'
      },
      {
        id: 2,
        name: 'אפס חימום חכם'
      }
    ],
    en: [
      {
        id: 1,
        name: 'Reset Factory Settings'
      },
      {
        id: 2,
        name: 'Reset Smart Heating'
      }
    ]
  };

  public static screen = 1;

  public static currentIndex = 0;

  public static resetSwiper = null;

  public static reset() {
    Reset.currentIndex = 0;
    Reset.screen = 1;
  };

  public static initResetSwiper() {
    setTimeout(() => {
      Reset.resetSwiper = new Swiper('#reset', {
        direction: 'vertical',
        loop: false,
        slidesPerView: 5,
        spaceBetween: 0,
        simulateTouch: false,
        autoHeight: true,
        height: 280,
        centeredSlides: true,
        watchOverflow: false,
        slidesPerColumn: 1,
        normalizeSlideIndex: true,
      });
    }, 0);
  }
}
