import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MuteService {

  public mute: Observable<boolean>;
  private muteSubject: Subject<boolean>;

  public defaultMode = false;

  constructor() {
    this.muteSubject = new Subject<boolean>();
    this.mute = this.muteSubject.asObservable();

    if (!localStorage.getItem('mute')) {
      localStorage.setItem('lang', this.defaultMode.toString());
    }
    this.setMode(JSON.parse(localStorage.getItem('mute')));
  }

  public setMode(mode: boolean) {
    localStorage.setItem('mute', mode.toString());
    this.muteSubject.next(mode);
  }

  public getMode() {
    return JSON.parse(localStorage.getItem('mute'))
  }
}
