export class WashingTank {
  public static step = 0;
  public static isPause = false;
  public static washingColdProcess = 0;
  public static washingHotProcess = 0;

  public static reset() {
    WashingTank.step = 0;
    WashingTank.isPause = false;
    WashingTank.washingColdProcess = 0;
    WashingTank.washingHotProcess = 0;
  }

  public static startWashingTank(tank) {
    return new Promise((resolve) => {
      let interval = setInterval(() => {
        if (!WashingTank.isPause) {
          if (tank === 'cold') {
            if (WashingTank.washingColdProcess < 100) {
              WashingTank.washingColdProcess += 10;
            } else {
              WashingTank.step++;
              resolve();
              clearInterval(interval);
            }
          }else {
            if (WashingTank.washingHotProcess < 100) {
              WashingTank.washingHotProcess += 10;
            } else {
              WashingTank.step++;
              resolve();
              clearInterval(interval);
            }
          }
        }
      }, 800);
    });
  }
}
