import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../../../language.service';

@Component({
  selector: 'app-parameters-tech-premium',
  templateUrl: './parameters-tech-premium.component.html',
  styleUrls: ['./parameters-tech-premium.component.sass']
})
export class ParametersTechPremiumComponent implements OnInit {

  public lang = null;

  public params =
    [
      "CT: 6",
      "PWR_T: xx",
      "HT: 92",
      "MCU_T: xx",
      "BSP: 99",
      "Hot_B: 0",
      "HE: off",
      "Cold_b: 0",
      "Comp: off",
      "Mix_B: 0",
      "Fan: off",
      "jug_B: 0",
      "In_V: off",
      "Eh_B: 0",
      "Co_V: off",
      "Me_B: 0",
      "Ho_V: off",
      "Ch_B: 0",
      "Br_V: off",
      "Leak: 0",
      "Elec: F",
      "Tray: 0",
      "UV: in",
      "Prox: xxx",
      "UV_st: off",
      "Light: ?",
      "12V_PWR: xx.x",
      "N_M: off",
      "WU: xxxx"

    ];

  public text = 'FW 0.6.27';

  constructor(private langService: LanguageService) {
    this.lang = langService.getLang();
    langService.language.subscribe((lang) => {
      console.log('Jugs premium', lang);
      this.lang = lang;
    });
  }

  ngOnInit(): void {
  }

}
