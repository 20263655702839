import {
  ButtonsPremiumState,
  IndicatorAddonStatePremium,
  IndicatorLockStatePremium,
  IndicatorNetStatePremium,
  IndicatorPurifierStatePremium,
  IndicatorUvStatePremium,
  IndicatorWifiStatePremium,
  PouringAnimation,
  SimulatorPremiumInterface,
  SimulatorPremiumPagesInterface
} from '../../interfaces/simulator-premium';
import {Dispensing} from '../data/dispensing';
import {Personal} from '../data/personal';
import {Jug} from '../data/jug';
import {Setting} from '../data/setting';
import {Maintenance} from '../data/maintenance';
import {Reset} from '../data/reset';
import {Preference} from '../data/preference';
import {QuantityAndTemp} from '../data/quantityAndTemp';
import {InternalCleaning} from '../data/internalCleaning';
import {TechScreen} from '../data/techScreen';

export class ButtonHelper {


  public static simulator: SimulatorPremiumInterface;
  public static page: any;
  public static lang: any;
  public static DISPENSING_WATER_TIME = 3000;
  public static afkDispensingWater = null;
  public static buttonsIndexes = {
    cold: 0,
    hot: 1,
    jugs: 2,
    mix: 3,
    personal: 4,
    boiling: 5
  };


  public static mainAfk = null;
  public static MAIN_AFK_TIME = 20000;
  // public static MAIN_AFK_TIME = 5000;
  public static pouringAudio = new Audio('/assets/audio/pouring.mp3');
  public static boilingAudio = new Audio('/assets/audio/boiling.mp3');

  public static startMainAfk() {
    clearTimeout(ButtonHelper.mainAfk);
    console.log('START AFK listener');
    ButtonHelper.mainAfk = setTimeout(() => {
      console.log('END AFK listener');
      ButtonHelper.mainSimulatorScreen();
    }, ButtonHelper.MAIN_AFK_TIME);
  }

  public static resetMainAfk() {
    console.log('RESET MAIN AFK');
    clearTimeout(ButtonHelper.mainAfk);
  }

  // set simulator to main screen
  public static mainSimulatorScreen(): void {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: true, state: IndicatorAddonStatePremium.OFF},
        uv: {active: true, state: IndicatorUvStatePremium.OFF},
        wifi: {active: true, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: true, state: IndicatorLockStatePremium.OFF},
        purifier: {active: true, state: IndicatorPurifierStatePremium.OFF},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.IDLE,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
        cold: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
        mix: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
        boiling: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
        jugs: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
        personal: {active: true, state: ButtonsPremiumState.ICON_BRIGHT}
      }
    };

    clearTimeout(ButtonHelper.afkDispensingWater);
    clearTimeout(ButtonHelper.mainAfk);
  }

  // set simulator to dispensing Hot water screen
  public static simulatorHot(isAnimationFast: boolean = false): void {
    Dispensing.isAnimationFast = isAnimationFast;
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.BLUE},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: true},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: true, state: IndicatorPurifierStatePremium.BLUE},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.HotWaterDispensing,
      pouring: {fast: isAnimationFast, animation: PouringAnimation.HOT},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
        cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
      }
    };
  }

  // set simulator to dispensing cold water screen
  public static simulatorCold(isAnimationFast: boolean = false): void {
    Dispensing.isAnimationFast = isAnimationFast;
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: true, state: IndicatorUvStatePremium.BLUE},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: true},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: true, state: IndicatorPurifierStatePremium.BLUE},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.ColdWaterDispensing,
      pouring: {fast: isAnimationFast, animation: PouringAnimation.COLD},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        cold: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
        mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
      }
    };
  }


  // set simulator to dispensing mix water screen
  public static simulatorMix(isAnimationFast: boolean = false): void {
    Dispensing.isAnimationFast = isAnimationFast;
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: true, state: IndicatorUvStatePremium.BLUE},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: true},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: true, state: IndicatorPurifierStatePremium.BLUE},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.MixWaterDispensing,
      pouring: {fast: isAnimationFast, animation: PouringAnimation.MIX},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        mix: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
        boiling: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
      }
    };
  }

  // set simulator to personal page
  public static simulatorPersonal(): void {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.BLUE},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.BLUE},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.Personal,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.BORDER},
        cold: {active: true, state: ButtonsPremiumState.BORDER},
        mix: {active: true, state: ButtonsPremiumState.BORDER},
        boiling: {active: true, state: ButtonsPremiumState.BORDER},
        jugs: {active: true, state: ButtonsPremiumState.BORDER},
        personal: {active: true, state: ButtonsPremiumState.BORDER}
      }
    };
  }

  // set simulator to personal drinks page
  public static simulatorPersonalDrinks(): void {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.BLUE},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.BLUE},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.PersonalDrinks,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.BORDER},
        cold: {active: true, state: ButtonsPremiumState.BORDER},
        mix: {active: true, state: ButtonsPremiumState.BORDER},
        boiling: {active: true, state: ButtonsPremiumState.BORDER},
        jugs: {active: true, state: ButtonsPremiumState.BORDER},
        personal: {active: true, state: ButtonsPremiumState.BORDER}
      }
    };
  }

  public static simulatorJugs(): void {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.BLUE},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.BLUE},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.Jugs,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.BORDER},
        cold: {active: true, state: ButtonsPremiumState.BORDER},
        mix: {active: true, state: ButtonsPremiumState.BORDER},
        boiling: {active: true, state: ButtonsPremiumState.BORDER},
        jugs: {active: true, state: ButtonsPremiumState.BORDER},
        personal: {active: true, state: ButtonsPremiumState.BORDER}
      }
    };
  }

  // set simulator to one of dispensing water screen (hot, cold, mix)
  public static simulatorDispensingPersonal(buttonIndex: number) {

    switch (Personal.currentUser.drinks[buttonIndex + Personal.currentDrinkPage].run) {
      case SimulatorPremiumPagesInterface.HotWaterDispensing:
        ButtonHelper.simulatorHot();
        break;
      case SimulatorPremiumPagesInterface.MixWaterDispensing:
        ButtonHelper.simulatorMix();
        break;
      case SimulatorPremiumPagesInterface.ColdWaterDispensing:
        ButtonHelper.simulatorCold();
        break;
    }

    ButtonHelper.afkDispensingWater = setTimeout(() => {
      ButtonHelper.mainSimulatorScreen();
    }, ButtonHelper.DISPENSING_WATER_TIME + 2000);
  }

  public static simulatorDispensingJugs(buttonIndex: number) {

    if (Jug.jugsList[ButtonHelper.lang][buttonIndex].active) {
      ButtonHelper.resetMainAfk();
      switch (Jug.jugsList[ButtonHelper.lang][buttonIndex].run) {
        case SimulatorPremiumPagesInterface.HotWaterDispensing:
          ButtonHelper.simulatorHot();
          break;
        case SimulatorPremiumPagesInterface.MixWaterDispensing:
          ButtonHelper.simulatorMix();
          break;
        case SimulatorPremiumPagesInterface.ColdWaterDispensing:
          ButtonHelper.simulatorCold();
          break;
      }

      ButtonHelper.afkDispensingWater = setTimeout(() => {
        ButtonHelper.mainSimulatorScreen();
      }, ButtonHelper.DISPENSING_WATER_TIME + 2000);
    } else {
      ButtonHelper.startMainAfk();
      Jug.currentJug = Jug.jugsList[ButtonHelper.lang][buttonIndex];
      Jug.step = 1;
      ButtonHelper.simulatorActivateJug(Jug.jugsList[ButtonHelper.lang][buttonIndex].run);
    }
  }

  public static simulatorActivateJug(screen: SimulatorPremiumPagesInterface = null) {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.BLUE},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.BLUE},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.ActivateJugs,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        cold: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        mix: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        boiling: {active: true, state: ButtonsPremiumState.BORDER},
        jugs: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT},
        personal: {active: true, state: ButtonsPremiumState.ICON_NO_BRIGHT}
      }
    };

    if (screen) {
      switch (screen) {
        case SimulatorPremiumPagesInterface.HotWaterDispensing:
          ButtonHelper.simulator.buttons.hot.state = ButtonsPremiumState.FLASH;
          break;
        case SimulatorPremiumPagesInterface.MixWaterDispensing:
          ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.FLASH;
          break;
        case SimulatorPremiumPagesInterface.ColdWaterDispensing:
          ButtonHelper.simulator.buttons.cold.state = ButtonsPremiumState.FLASH;
          break;
      }
    } else {
      if (Jug.step === 1) {
        switch (Jug.currentJug.run) {
          case SimulatorPremiumPagesInterface.HotWaterDispensing:
            ButtonHelper.simulator.buttons.hot.state = ButtonsPremiumState.FLASH;
            break;
          case SimulatorPremiumPagesInterface.MixWaterDispensing:
            ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.FLASH;
            break;
          case SimulatorPremiumPagesInterface.ColdWaterDispensing:
            ButtonHelper.simulator.buttons.cold.state = ButtonsPremiumState.FLASH;
            break;
        }
      } else if (Jug.step === 2) {
        ButtonHelper.simulator.buttons.boiling.state = ButtonsPremiumState.ICON_NO_BRIGHT;

        switch (Jug.currentJug.run) {
          case SimulatorPremiumPagesInterface.HotWaterDispensing:
            ButtonHelper.simulator.buttons.hot.state = ButtonsPremiumState.ICON_BRIGHT;
            break;
          case SimulatorPremiumPagesInterface.MixWaterDispensing:
            ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.ICON_BRIGHT;
            break;
          case SimulatorPremiumPagesInterface.ColdWaterDispensing:
            ButtonHelper.simulator.buttons.cold.state = ButtonsPremiumState.ICON_BRIGHT;
            break;
        }
      } else if (Jug.step === 3) {
        Jug.resetTemp();
        ButtonHelper.simulator.buttons.boiling.state = ButtonsPremiumState.BORDER;
        ButtonHelper.simulator.buttons.personal.state = ButtonsPremiumState.BORDER;
        ButtonHelper.simulator.buttons.hot.state = ButtonsPremiumState.NO_ICON;
        ButtonHelper.simulator.buttons.cold.state = ButtonsPremiumState.NO_ICON;
        ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.NO_ICON;
        ButtonHelper.simulator.buttons.jugs.state = ButtonsPremiumState.NO_ICON;
      } else if (Jug.step === 4) {
        ButtonHelper.simulator.buttons.boiling.state = ButtonsPremiumState.BORDER;
        ButtonHelper.simulator.buttons.personal.state = ButtonsPremiumState.BORDER;
        ButtonHelper.simulator.buttons.hot.state = ButtonsPremiumState.NO_ICON;
        ButtonHelper.simulator.buttons.cold.state = ButtonsPremiumState.NO_ICON;
        ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.BORDER;
        ButtonHelper.simulator.buttons.jugs.state = ButtonsPremiumState.BORDER;
      } else if (Jug.step === 5) {
        ButtonHelper.simulator.buttons.boiling.state = ButtonsPremiumState.NO_ICON;
        ButtonHelper.simulator.buttons.personal.state = ButtonsPremiumState.NO_ICON;
        ButtonHelper.simulator.buttons.hot.state = ButtonsPremiumState.NO_ICON;
        ButtonHelper.simulator.buttons.cold.state = ButtonsPremiumState.NO_ICON;
        ButtonHelper.simulator.buttons.mix.state = ButtonsPremiumState.NO_ICON;
        ButtonHelper.simulator.buttons.jugs.state = ButtonsPremiumState.NO_ICON;

        ButtonHelper.afkDispensingWater = setTimeout(() => {
          Jug.currentJug.active = true;
          ButtonHelper.mainSimulatorScreen();
        }, ButtonHelper.DISPENSING_WATER_TIME);
      }
    }
  }


  // set simulator screen to menu
  public static simulatorMenu() {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.OFF},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.Menu,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.BORDER},
        cold: {active: true, state: ButtonsPremiumState.BORDER},
        mix: {active: true, state: ButtonsPremiumState.BORDER},
        boiling: {active: true, state: ButtonsPremiumState.BORDER},
        jugs: {active: true, state: ButtonsPremiumState.BORDER},
        personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
      }
    };
  }

  // set simulator screen to settings
  public static simulatorSettings() {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.OFF},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.Settings,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
        cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
        mix: {active: true, state: ButtonsPremiumState.BORDER},
        boiling: {active: true, state: ButtonsPremiumState.BORDER},
        jugs: {active: true, state: ButtonsPremiumState.BORDER},
        personal: {active: true, state: ButtonsPremiumState.BORDER}
      }
    };
    Setting.reset();
    Setting.initSwiper();
    clearTimeout(ButtonHelper.afkDispensingWater);
  }

  // set simulator screen to Maintenance
  public static simulatorMaintenance() {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.OFF},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.Maintenance,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
        cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
        mix: {active: true, state: ButtonsPremiumState.BORDER},
        boiling: {active: true, state: ButtonsPremiumState.BORDER},
        jugs: {active: true, state: ButtonsPremiumState.BORDER},
        personal: {active: true, state: ButtonsPremiumState.BORDER}
      }
    };
    Maintenance.reset();
    Maintenance.initMaintenanceSwiper();
    clearTimeout(ButtonHelper.afkDispensingWater);
  }

  // set simulator screen to factory
  public static simulatorFactory() {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.OFF},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.Reset,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
        cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
        mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
        boiling: {active: true, state: ButtonsPremiumState.BORDER},
        jugs: {active: true, state: ButtonsPremiumState.BORDER},
        personal: {active: true, state: ButtonsPremiumState.BORDER}
      }
    };
    Reset.reset();
    Reset.initResetSwiper();
  }

  public static simulatorPreferences() {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.OFF},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.Preferences,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
        cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
        mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
        boiling: {active: true, state: ButtonsPremiumState.BORDER},
        jugs: {active: true, state: ButtonsPremiumState.BORDER},
        personal: {active: true, state: ButtonsPremiumState.BORDER}
      }
    };
    Preference.reset();
    Preference.initPreferencesSwiper();
    clearTimeout(ButtonHelper.afkDispensingWater);
  }

  // set simulator screen to quantity and temp
  public static simulatorQuantityAndTemp() {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.OFF},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.QuantityAndTemp,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.BORDER},
        cold: {active: true, state: ButtonsPremiumState.BORDER},
        mix: {active: true, state: ButtonsPremiumState.BORDER},
        boiling: {active: true, state: ButtonsPremiumState.BORDER},
        jugs: {active: true, state: ButtonsPremiumState.BORDER},
        personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
      }
    };
  }

  // set simulator screen to quantity personal
  public static simulatorQuantityPersonal() {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.OFF},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.PersonalQuantity,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.BORDER},
        cold: {active: true, state: ButtonsPremiumState.BORDER},
        mix: {active: true, state: ButtonsPremiumState.BORDER},
        boiling: {active: true, state: ButtonsPremiumState.BORDER},
        jugs: {active: true, state: ButtonsPremiumState.BORDER},
        personal: {active: true, state: ButtonsPremiumState.BORDER}
      }
    };
  }

  // set simulator screen to Edit personal quantity
  public static simulatorEditPersonalQuantity(): void {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.BLUE},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.BLUE},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.EditPersonalQuantity,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.BORDER},
        cold: {active: true, state: ButtonsPremiumState.BORDER},
        mix: {active: true, state: ButtonsPremiumState.BORDER},
        boiling: {active: true, state: ButtonsPremiumState.BORDER},
        jugs: {active: true, state: ButtonsPremiumState.BORDER},
        personal: {active: true, state: ButtonsPremiumState.BORDER}
      }
    };
    QuantityAndTemp.isPersonal = true;
  }

  // set simulator screen to internal cleaning
  public static simulatorInternalCleaning(): void {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.OFF},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.InternalCleaningMain,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
        cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
        mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
        boiling: {active: true, state: ButtonsPremiumState.BORDER},
        jugs: {active: true, state: ButtonsPremiumState.BORDER},
        personal: {active: true, state: ButtonsPremiumState.BORDER}
      }
    };

    InternalCleaning.reset();
    InternalCleaning.initCleaningSwiper();
  }


  // set simulator screen to lock buttons
  public static lockButtons(): void {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: true, state: IndicatorAddonStatePremium.OFF},
        uv: {active: true, state: IndicatorUvStatePremium.OFF},
        wifi: {active: true, state: IndicatorWifiStatePremium.OFF},
        clock: {active: true},
        lock: {active: true, state: IndicatorLockStatePremium.OFF},
        purifier: {active: true, state: IndicatorPurifierStatePremium.OFF},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.LockButtonScreen,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
        cold: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
        mix: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
        boiling: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
        jugs: {active: true, state: ButtonsPremiumState.ICON_BRIGHT},
        personal: {active: true, state: ButtonsPremiumState.ICON_BRIGHT}
      }
    };

    clearTimeout(ButtonHelper.afkDispensingWater);
  }

  // set simulator screen to lock message
  public static lockMessage(): void {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.OFF},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.LockButtonMessage,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
        cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
        mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
        boiling: {active: true, state: ButtonsPremiumState.NO_BORDER},
        jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
        personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
      }
    };
  }

  // set simulator screen to shabbatMode
  public static shabbatMode(): void {
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.OFF},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.ShabbatModeScreen,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.NO_BORDER},
        cold: {active: true, state: ButtonsPremiumState.NO_BORDER},
        mix: {active: true, state: ButtonsPremiumState.NO_BORDER},
        boiling: {active: true, state: ButtonsPremiumState.NO_BORDER},
        jugs: {active: true, state: ButtonsPremiumState.NO_BORDER},
        personal: {active: true, state: ButtonsPremiumState.NO_BORDER}
      }
    };

    clearTimeout(ButtonHelper.afkDispensingWater);
  }

  public static copyObject(source) {
    return JSON.parse(JSON.stringify(source));
  }

  public static techScreen(): void {
    console.log('HELPER TECH');
    ButtonHelper.simulator = {
      indicator: {
        addon: {active: false, state: IndicatorAddonStatePremium.OFF},
        uv: {active: false, state: IndicatorUvStatePremium.OFF},
        wifi: {active: false, state: IndicatorWifiStatePremium.OFF},
        clock: {active: false},
        lock: {active: false, state: IndicatorLockStatePremium.OFF},
        purifier: {active: false, state: IndicatorPurifierStatePremium.OFF},
        net: {active: false, state: IndicatorNetStatePremium.OFF}
      },
      page: SimulatorPremiumPagesInterface.Tech,
      pouring: {fast: false, animation: PouringAnimation.NONE},
      buttons: {
        hot: {active: true, state: ButtonsPremiumState.NO_ICON},
        cold: {active: true, state: ButtonsPremiumState.NO_ICON},
        mix: {active: true, state: ButtonsPremiumState.NO_ICON},
        boiling: {active: true, state: ButtonsPremiumState.BORDER},
        jugs: {active: true, state: ButtonsPremiumState.BORDER},
        personal: {active: true, state: ButtonsPremiumState.BORDER}
      }
    };
    TechScreen.reset();
    TechScreen.initTechScreenSwiper();

  }

}
